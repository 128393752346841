import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Avatar from 'components/avatar/Company';
import { useCompanyList } from 'hooks/permission';
import isEmpty from 'lodash/isEmpty';
import * as permissionActions from '../actions';
import { useDispatch } from 'react-redux';
import { getCompanyLogoUrl } from 'utils/images';
import { useTranslation } from 'react-i18next';

function UserCompanies() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const companyList = useCompanyList();

    React.useEffect(() => {
        dispatch(
            permissionActions.fetchCompanies({
                successCallback: () => {
                    console.log('fetch company list successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch company list');
                }
            })
        );
    }, [dispatch]);

    return (
        <Grid container spacing={2}>
            {(!companyList || isEmpty(companyList)) && (
                <Grid item>
                    <Typography variant="caption">{t('no_company')}</Typography>
                </Grid>
            )}
            {companyList &&
                companyList.map(company => {
                    return (
                        <Grid key={company.id} item>
                            <Avatar size="large" src={getCompanyLogoUrl(company?.logoUrl)} label={company?.name} />
                        </Grid>
                    );
                })}
        </Grid>
    );
}

export default UserCompanies;
