import store from "store/configStore";
import * as types from "socket/types";
import * as viewSortActions from "gridUI/actions/viewSort";
const {dispatch} = store;

const viewSortHandler = ({body})=> {
    const {subAction, data, sortOrderId} = body;
    switch(subAction){
        case types.UPDATE: return _updateViewSortOrder({sortOrderId: data.id, newSortOrder: data});
        case types.DELETE: return _deleteViewSortOrder({sortOrderId});
        case types.CREATE: return _createViewSortOrder({data});
        default: return;
    }
}

function _createViewSortOrder({data}){
    dispatch(viewSortActions.createViewSortOrderSocket({sortOrder: data}))
}

function _deleteViewSortOrder({sortOrderId}){
    dispatch(viewSortActions.deleteViewSortOrderSocket({sortOrderId}))
}

function _updateViewSortOrder({sortOrderId, newSortOrder}){
    dispatch(viewSortActions.updateViewSortOrderSocket({sortOrderId, newSortOrder}))
}

export default viewSortHandler;