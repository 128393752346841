import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TEXT_LINE_HEIGHT, PREVIEW_STATUS } from 'const/gridUI';
import ReferenceDelete from '../diffCheckComponents/delete/Reference';
import ReferenceUpdate from '../diffCheckComponents/update/Reference';
import NormalReference from '../diffCheckComponents/normal/Ref';
import DiffRefWithOtherTypes from './diffType/Ref';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        display: 'table-cell',
        verticalAlign: 'top',
        textAlign: 'left',
        lineHeight: `${TEXT_LINE_HEIGHT}px`,
        wordBreak: 'break-word',
        wordWrap: 'break-word'
    }
}));

function MultipleSelectionRender({
    rowIndex,
    columnIndex,
    rowHeight,
    status,
    columnType,
    previousColumnType,
    data = [],
    previousData = [],
    options,
    customProperties,
    ...rest
}) {
    const classes = useStyles();
    const isSameColumnType = columnType === previousColumnType;

    return (
        <div
            className={`cell_${rowIndex}_${columnIndex} ${classes.root}`}
            style={{
                height: rowHeight,
                pointerEvents: 'none'
            }}
        >
            {isSameColumnType && status === PREVIEW_STATUS.DELETED && (
                <ReferenceDelete
                    options={options}
                    customProperties={customProperties}
                    state={'normal'}
                    data={data}
                    previousData={previousData}
                    isHideMarkIcon={true}
                    {...rest}
                />
            )}
            {isSameColumnType && status === PREVIEW_STATUS.UPDATED && (
                <ReferenceUpdate
                    options={options}
                    customProperties={customProperties}
                    state={'normal'}
                    data={data}
                    previousData={previousData}
                    isHideMarkIcon={true}
                    {...rest}
                />
            )}
            {isSameColumnType && [PREVIEW_STATUS.NORMAL, PREVIEW_STATUS.CONFLICT].includes(status) && (
                <NormalReference
                    isHideMarkIcon={true}
                    customProperties={customProperties}
                    options={options}
                    data={data}
                    {...rest}
                />
            )}

            {!isSameColumnType && (
                <DiffRefWithOtherTypes
                    previousData={previousData}
                    data={data}
                    options={options}
                    customProperties={customProperties}
                    previousColumnType={previousColumnType}
                    isHideMarkIcon={true}
                    {...rest}
                />
            )}
        </div>
    );
}

export default React.memo(MultipleSelectionRender);
