import * as types from '../types';
import { requestData, receiveData } from 'api/actions';
import {
    fetchDependenciesApi,
    createDependencyApi,
    updateDependencyApi,
    deleteDependencyApi
} from 'services/dependency';
import { getViewRecordsApiV2, setViewRecords } from 'services/view';
import { enqueueSnackbar } from 'notifier/actions';
import { generateTempId, isTempId } from 'utils/uuid';
import { getDependencyColumnIds, makeDependencyStatus, makeSourceStatus } from 'utils/gridUI/dependency';
import { formatQuickFilters } from 'utils/gridUI/filter';
import uuidv1 from 'uuid/v1';
import * as statusActions from './status';
import { getEditableColumns, getDisableColumnIdsByType } from 'utils/gridUI/column';
import * as optimisticActions from './optimistic';
import {
    DATA_QUERY_OPTIONS,
    DEPENDENCY_STATUS,
    EXTRA_QUICK_FILTER,
    MAX_RECORD_LIMIT,
    MAX_SELECTION_RECORDS,
    RANGE_TYPES,
    SOURCE_STATUS
} from 'const/gridUI';
import * as columnActions from './column';
import * as rowActions from './row';
import * as dataActions from './data';
import { chunk } from 'lodash';
import { getIsShowAutoQA } from 'utils/gridUI/lqa';

export function fetchDependencies({ branchId, dbId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId: dbIdStore, branchId: branchIdStore } = gridUI;

        const dbIdCombined = dbId || dbIdStore;
        const branchIdCombined = branchId || branchIdStore;

        dispatch(requestData(types.FETCH_DEPENDENCIES));
        try {
            const dependencies = await fetchDependenciesApi({ dbId: dbIdCombined, gridId: branchIdCombined });
            dispatch(receiveData(types.FETCH_DEPENDENCIES_SUCCESS, { dependencies }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(receiveData(types.FETCH_DEPENDENCIES_FAILED, { error: message }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function createDependency({ dependencyId, parent, child, oldDependency, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, gridId, dependencies } = gridUI;
        dispatch(_createDependencyAction());
        dispatch(
            _createDependencyActionSuccess({
                dependencyId,
                dependency: {
                    ...oldDependency,
                    parent,
                    child
                }
            })
        );
        dispatch(statusActions.registerDoingAction({ actionId }));
        try {
            const newDependency = await createDependencyApi({
                dbId,
                gridId,
                body: {
                    parent,
                    child
                }
            });
            const newDependencies = dependencies.map(dpdc => {
                if (dpdc?.id === dependencyId) {
                    return newDependency;
                }
                return dpdc;
            });
            dispatch(statusActions.removeDoingAction({ actionId }));
            await _fetchRecordsAfterDoingDependency({
                gridUI: { ...gridUI, dependencies: newDependencies },
                dispatch
            });
            dispatch(_createDependencyActionSuccess({ dependencyId, dependency: newDependency }));
        } catch (error) {
            const { message } = error;
            dispatch(_createDependencyActionFailed({ dependencyId, dependency: oldDependency }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

function _createDependencyAction() {
    return {
        type: types.CREATE_DEPENDENCY
    };
}

function _createDependencyActionSuccess({ dependencyId, dependency }) {
    return {
        type: types.CREATE_DEPENDENCY_SUCCESS,
        payload: {
            dependencyId,
            dependency
        }
    };
}

function _createDependencyActionFailed({ dependencyId, dependency }) {
    return {
        type: types.CREATE_DEPENDENCY_FAILED,
        payload: {
            dependencyId,
            dependency
        }
    };
}

export function createFakeDependency() {
    return {
        type: types.CREATE_FAKE_DEPENDENCY,
        payload: {
            dependency: {
                id: generateTempId()
            }
        }
    };
}

export function updateParent({ dependencyId, value }) {
    return {
        type: types.CHANGE_PARENT,
        payload: {
            dependencyId,
            value
        }
    };
}

export function updateChild({ dependencyId, value }) {
    return {
        type: types.CHANGE_CHILD,
        payload: {
            dependencyId,
            value
        }
    };
}

export function updateDependency({ dependencyId, oldDependency, newDependency, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, gridId, dependencies } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_updateDependencyAction({ dependencyId, dependency: newDependency }));
        try {
            await updateDependencyApi({
                dbId,
                gridId,
                dependencyId,
                body: {
                    parent: newDependency?.parent,
                    child: newDependency?.child
                }
            });
            const newDependencies = dependencies.map(dpdc => {
                if (dpdc?.id === dependencyId) {
                    return newDependency;
                }
                return dpdc;
            });
            await _fetchRecordsAfterDoingDependency({
                gridUI: {
                    ...gridUI,
                    dependencies: newDependencies
                },
                dispatch
            });
            dispatch(_updateDependencyActionSuccess());
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_updateDependencyActionFailed({ dependencyId, dependency: oldDependency }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

async function _fetchRecordsAfterDoingDependency({ gridUI, dispatch }) {
    const {
        defaultAccessViewId,
        dbId,
        quickFilters,
        quickSorts,
        dependencies,
        ROW_START_INDEX,
        ROW_STOP_INDEX,
        viewColumns
    } = gridUI;
    const quickFiltersFormatted = formatQuickFilters(quickFilters);
    const isShowAutoQA = getIsShowAutoQA();

    try {
        const dependencyColumnIds = getDependencyColumnIds({ dependencies, viewColumns });
        const { data, recordMetaData } = await getViewRecordsApiV2({
            defaultAccessViewId,
            dbId,
            filterQuery: quickFiltersFormatted,
            sortQuery: quickSorts,
            offset: ROW_START_INDEX,
            limit: ROW_STOP_INDEX,
            columnIds: dependencyColumnIds,
            isShowAutoQA
        });

        dispatch(dataActions.updateData({ newData: data }));
        dispatch(rowActions.updateRecordMetaData({ newRecordMetaData: recordMetaData }));
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

export function _updateDependencyAction({ dependencyId, dependency }) {
    return {
        type: types.UPDATE_DEPENDENCY,
        payload: {
            dependencyId,
            dependency
        }
    };
}

function _updateDependencyActionFailed({ dependencyId, dependency }) {
    return {
        type: types.UPDATE_DEPENDENCY_FAILED,
        payload: {
            dependencyId,
            dependency
        }
    };
}

function _updateDependencyActionSuccess() {
    return {
        type: types.UPDATE_DEPENDENCY_SUCCESS
    };
}

export function deleteDependency({ dependencyId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, gridId, dependencies, quickFilters } = gridUI;

        if (isTempId(dependencyId)) {
            dispatch(_deleteDependencySuccess({ dependencyId }));
            return;
        }
        dispatch(statusActions.registerDoingAction({ actionId }));
        const deletedDependency = dependencies.find(dpdc => dpdc?.id === dependencyId);
        dispatch(_deleteDependency({ dependencyId }));
        try {
            await deleteDependencyApi({ dependencyId, dbId, gridId });
            const newDependencies = dependencies.filter(dpdc => dpdc?.id !== dependencyId);
            await _fetchRecordsAfterDoingDependency({
                gridUI: {
                    ...gridUI,
                    dependencies: newDependencies
                },
                dispatch
            });
            const child = deletedDependency?.child;

            const isHaveQuickFilterWithDependencyToClear = [
                EXTRA_QUICK_FILTER.IS_OUT_OF_DATE,
                EXTRA_QUICK_FILTER.IS_UNSET,
                EXTRA_QUICK_FILTER.IS_UP_TO_DATE,
                EXTRA_QUICK_FILTER.IS_TM
            ].includes(quickFilters?.[child]?.extraFilter);

            if (isHaveQuickFilterWithDependencyToClear) {
                dispatch(
                    columnActions.quickFilterExtraChange({
                        columnId: child,
                        extraFilter: EXTRA_QUICK_FILTER.NONE
                    })
                );
                dispatch(
                    rowActions.fetchRecordsWithFiltersAndSorts({
                        errorCallback: () => {
                            console.log('failed fetchRecordsWithFiltersAndSorts after dependency deleted');
                        },
                        successCallback: () => {
                            console.log('fetchRecordsWithFiltersAndSorts after dependency deleted');
                        }
                    })
                );
            }

            dispatch(_deleteDependencySuccess({ dependencyId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_deleteDependencyFailed({ dependencyId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function removeMultipleDependencies({ dependencyIds = [] }) {
    return async function(dispatch, getState) {
        dependencyIds.forEach(dependencyId => {
            dispatch(_deleteDependencySuccess({ dependencyId }));
        });
    };
}

export function _deleteDependency({ dependencyId }) {
    return {
        type: types.DELETE_DEPENDENCY,
        payload: {
            dependencyId
        }
    };
}

export function _deleteDependencySuccess({ dependencyId }) {
    return {
        type: types.DELETE_DEPENDENCY_SUCCESS,
        payload: {
            dependencyId
        }
    };
}

export function _deleteDependencyFailed({ dependencyId }) {
    return {
        type: types.DELETE_DEPENDENCY_FAILED,
        payload: {
            dependencyId
        }
    };
}

export function openNotiDependency() {
    return {
        type: types.OPEN_NOTI_DEPENDENCY
    };
}

export function closeNotiDependency() {
    return {
        type: types.CLOSE_NOTI_DEPENDENCY
    };
}

export function approveDependencyStatus(dependencyStatus = DEPENDENCY_STATUS.UP_TO_DATE, targetColumnIds = []) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const {
            defaultAccessViewId,
            dbId,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            viewColumns,
            metaData
        } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));

        const {
            columnIds: rangeColumnIds,
            recordIds,
            data,
            isOverRecordLimit,
            totalSelectedRecords
        } = await dataActions.getRangeData({
            gridUI,
            auth,
            dataOptions: [DATA_QUERY_OPTIONS.DATA, DATA_QUERY_OPTIONS.DEPENDENCY_STATUS],
            forcedColumnIds: targetColumnIds,
            type: RANGE_TYPES.INDEX
        });

        if (isOverRecordLimit) {
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            return;
        }

        if (!recordIds?.length || !rangeColumnIds?.length) {
            return dispatch(statusActions.removeDoingAction({ actionId }));
        }

        const disabledColumnIdsByType = getDisableColumnIdsByType({ viewColumns, metaData });

        const affectedColumnIds = getEditableColumns({
            columnIds: rangeColumnIds,
            viewColumns,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            disabledColumnIdsByType
        });

        const { redoData, affectedColumnIds: columnIds, cellsChanged, serverData } = makeDependencyStatus({
            recordIds,
            columnIds: affectedColumnIds,
            dependencyStatus,
            data,
            metaData
        });

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_METADATA_DEPENDENCY_STATUS,
                body: {
                    cellsChanged
                }
            })
        );

        dispatch(dataActions.updateData({ newData: redoData }));
        try {
            if (dependencyStatus === DEPENDENCY_STATUS.UNSET && !columnIds?.length) {
                dispatch(
                    enqueueSnackbar({
                        message: "Mark as Unset can't be performed on non-empty cells",
                        type: 'info'
                    })
                );
                dispatch(statusActions.removeDoingAction({ actionId }));
                dispatch(optimisticActions.removeAction({ actionId }));
                return;
            }

            if (!columnIds?.length) {
                console.log('nothing to change');
                dispatch(statusActions.removeDoingAction({ actionId }));
                dispatch(optimisticActions.removeAction({ actionId }));
                return;
            }

            const updateChunks = chunk(serverData, MAX_RECORD_LIMIT);

            await Promise.all(
                updateChunks?.map(async records => {
                    return await setViewRecords({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns: ['_recordId', ...columnIds],
                            records
                        }
                    });
                })
            );

            //call api to save
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function approveSourceStatus(sourceStatus = SOURCE_STATUS.UP_TO_DATE, sourceColumnIds = []) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const {
            defaultAccessViewId,
            dbId,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            viewColumns,
            metaData,
            dependencies
        } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));

        const dependenciesWithoutFake = dependencies?.filter(dpDc => !isTempId(dpDc?.id));

        const {
            columnIds: rangeColumnIds,
            recordIds,
            data,
            isOverRecordLimit,
            totalSelectedRecords
        } = await dataActions.getRangeData({
            gridUI,
            auth,
            dataOptions: [DATA_QUERY_OPTIONS.DATA, DATA_QUERY_OPTIONS.SOURCE_STATUS],
            forcedColumnIds: sourceColumnIds,
            type: RANGE_TYPES.INDEX
        });

        if (isOverRecordLimit) {
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            return;
        }

        if (!recordIds?.length || !rangeColumnIds?.length) {
            return dispatch(statusActions.removeDoingAction({ actionId }));
        }

        const disabledColumnIdsByType = getDisableColumnIdsByType({ viewColumns, metaData });

        const affectedColumnIds = getEditableColumns({
            columnIds: rangeColumnIds,
            viewColumns,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            disabledColumnIdsByType
        });

        const filteredColumnIds = affectedColumnIds?.filter(colId => {
            const found = dependenciesWithoutFake?.find(dpDc => dpDc?.parent === colId);
            return found && found?.child;
        });

        const { redoData, affectedColumnIds: columnIds, cellsChanged, serverData } = makeSourceStatus({
            recordIds,
            columnIds: filteredColumnIds,
            sourceStatus,
            data,
            metaData
        });

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_METADATA_SOURCE_STATUS,
                body: {
                    cellsChanged
                }
            })
        );

        dispatch(dataActions.updateData({ newData: redoData }));

        try {
            if (!columnIds?.length) {
                console.log('nothing to change');
                dispatch(statusActions.removeDoingAction({ actionId }));
                dispatch(optimisticActions.removeAction({ actionId }));
                return;
            }

            const updateChunks = chunk(serverData, MAX_RECORD_LIMIT);

            await Promise.all(
                updateChunks?.map(async records => {
                    return await setViewRecords({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns: ['_recordId', ...columnIds],
                            records
                        }
                    });
                })
            );

            //call api to save
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function addDependencyRealtimeAction(dependency) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { dependencies } = gridUI;
            const newDependencies = [...dependencies, dependency];
            await _fetchRecordsAfterDoingDependency({
                gridUI: { ...gridUI, dependencies: newDependencies },
                dispatch
            });
            dispatch(_createDependencySocket(dependency));
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

function _createDependencySocket(dependency) {
    return {
        type: types.CREATE_DEPENDENCY_SOCKET,
        payload: {
            dependency
        }
    };
}

export function updateDependencyRealtimeAction({ dependencyId, dependency }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { dependencies } = gridUI;
            const newDependencies = dependencies?.map(dpDc => {
                if (dpDc?.id === dependencyId) {
                    return dependency;
                }
                return dpDc;
            });
            await _fetchRecordsAfterDoingDependency({
                gridUI: { ...gridUI, dependencies: newDependencies },
                dispatch
            });
            dispatch(_updateDependencyAction({ dependencyId, dependency }));
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}
