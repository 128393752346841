import React from 'react';
import CellBaseDropdown from 'components/selects/virtualize/CellBaseVirtualize';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: { width: 220 }
}));

function CountrySelect({ defaultValue, options, placeholder, onChange, ...rest }) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <CellBaseDropdown
            onChange={onChange}
            options={options}
            placeholder={placeholder || t('global_languague_search')}
            isMulti={false}
            defaultValue={defaultValue}
            className={classes.root}
            maxSize={200}
            {...rest}
        />
    );
}

export default React.memo(CountrySelect);
