import store from 'store/configStore';
import * as types from 'socket/types';
import * as advancedSearchActions from 'advancedSearch/actions';

const { dispatch, getState } = store;

const recordHandler = ({ body, isYour }) => {
    const { subAction, data, user, dbId, gridId } = body;

    console.log('ADVANCED recordHandler', data?.data);

    switch (subAction) {
        case types.UPDATE:
        case types.POPULATED_DATA:
            return _updateRecord({ data, user, dbId, gridId });
        default:
            return;
    }
};

function _getMetadata() {
    let metaData = null;
    const { advanced } = getState();
    const oldData = advanced?.data;
    const recordIds = Object.keys(oldData);

    if (recordIds?.length) {
        const row = oldData?.[recordIds?.[0]];

        const hashColumnIds = Object.keys(row);
        if (!hashColumnIds?.length) return metaData;

        metaData = {};

        for (const hashColumnId of hashColumnIds) {
            const columnData = row?.[hashColumnId];
            metaData[columnData?.columnId] = {
                ...columnData,
                hashColumnId
            };
        }
    }

    return metaData;
}

function _getNewData({ updateNewData, dbId, gridId, metaData }) {
    const defaultPath = `${dbId}.${gridId}`;
    const recordIds = Object.keys(updateNewData);
    let newData = {};

    for (const recordId of recordIds) {
        newData[`${defaultPath}.${recordId}`] = {};

        const columnIds = Object.keys(updateNewData?.[recordId]);

        for (const columnId of columnIds) {
            const hashColumnId = metaData?.[columnId]?.hashColumnId;
            const cellData = updateNewData?.[recordId]?.[columnId];
            newData[`${defaultPath}.${recordId}`][hashColumnId] = cellData;
        }
    }

    return newData;
}

function _updateRecord({ data, dbId, gridId }) {
    if (!data) return;
    const metaData = _getMetadata();
    if (!metaData) return;

    const newData = _getNewData({ updateNewData: data?.data, dbId, gridId, metaData });
    console.log('newData', newData);
    dispatch(advancedSearchActions.updateData({ newData, isCareData: true }));
}

export default recordHandler;
