import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as gridActions from 'advancedSearch/actions';
import ViewCellOnly from './ViewCellOnly';
import CellBaseDropdown from 'components/selects/CellBase';
import { Paper, Grid, Popper } from '@material-ui/core';
import { checkContainId } from 'utils/clickAway';
import ViewCellInfo from './ViewCellInfo';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import { isKbEscape, isKbTab, isKbShiftTab } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import * as columnTypes from 'const/columnTypes';
import { getCellBackgroundByHex } from 'utils/color';

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        minWidth: 200
    }
}));

function MultiSelect({
    columnId,
    column,
    value: cellValue,
    rowId,
    height,
    width,
    character,
    rowIndex,
    columnIndex,
    background,
    fillColorBlindness,
    originalValue,
    t
}) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const rootRef = React.useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const anchorElRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    React.useEffect(() => {
        if (anchorElRef.current) {
            setAnchorEl(anchorElRef.current);
            setTimeout(() => {
                const input = document.getElementById('multi-select-input');
                if (input) {
                    input.focus({ preventScroll: true });
                }
            }, 0);
        }
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper' : undefined;

    const defaultSelectedOptions = useMemo(() => {
        if (!originalValue) return [];
        if (originalValue.length || !isArray(originalValue)) {
            return originalValue?.map(item => ({ value: item, label: item }));
        }
    }, [originalValue]);

    const multiSelectOptions = useMemo(() => {
        return isEmpty(column.options)
            ? []
            : column.options.map(option => {
                  return {
                      value: option.label,
                      label: option.label
                  };
              });
    }, [column.options]);

    const [selectedOptions, setSelectedOptions] = useState(defaultSelectedOptions);
    const classes = useStyles();

    useEffect(() => {
        const keyDownHandler = e => {
            if (isKbEscape(e)) {
                dispatch(gridActions.cancelCellEdit());
            }

            if (isKbTab(e)) {
                e.preventDefault();
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextColumn({ rowIndex, columnIndex }));
            }

            if (isKbShiftTab(e)) {
                e.preventDefault();
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToPreviousColumn({ rowIndex, columnIndex }));
            }
        };
        document.addEventListener('keydown', keyDownHandler, true);
        return () => {
            document.removeEventListener('keydown', keyDownHandler, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);
        const selectedValues = selectedOptions?.map(item => item.value) || null;

        if ((isEmpty(selectedValues) && isEmpty(originalValue)) || isEqual(selectedValues, originalValue)) {
            return dispatch(gridActions.cancelCellEdit());
        }
        dispatch(
            gridActions.cellClickAway({
                isClickYourSelf,
                value: selectedValues,
                rowId,
                columnId
            })
        );
    };

    const handleOptionsChange = options => {
        setSelectedOptions(options);
        dispatch(
            gridActions.cellClickAwayAndStay({
                type: columnTypes.MULTIPLE_SELECTIONS,
                value: options.map(item => item.value) || null,
                rowId,
                columnId
            })
        );
    };

    return (
        <Grid ref={rootRef} style={{ position: 'relative' }} className={classes.root}>
            <ViewCellInfo rowIndex={rowIndex} />
            <div
                ref={anchorElRef}
                style={{
                    background: background
                        ? fillColorBlindness === 'BLIND'
                            ? `${background} url(${getCellBackgroundByHex(background)})`
                            : background
                        : theme.colors.white
                }}
            >
                <ViewCellOnly dimension={{ width, height }} value={cellValue} type={column.type} {...column} />
            </div>
            <Popper container={anchorElRef.current} id={id} placement={`bottom-start`} open={open} anchorEl={anchorEl}>
                <Paper className={classes.paper}>
                    <CellBaseDropdown
                        onChange={handleOptionsChange}
                        options={multiSelectOptions}
                        placeholder={t(`global_label_find_an_option`)}
                        isMulti
                        defaultValue={selectedOptions}
                        searchKey={character}
                        autoFocus={false}
                        inputId="multi-select-input"
                    />
                </Paper>
            </Popper>
        </Grid>
    );
}

export default React.memo(MultiSelect);
