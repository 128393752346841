import React from 'react';

function Medium({ color = '#78778B', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M17 5.16895V14.2927C17 15.7883 16.1046 17.0007 15 17.0007H3C1.89543 17.0007 1 15.7883 1 14.2927V5.16895"
                stroke={color}
                strokeWidth="1.5"
            />
            <path d="M1 5.91992H17" stroke={color} strokeWidth="1.5" />
        </svg>
    );
}

export default React.memo(Medium);
