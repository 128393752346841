import React from 'react';

function PreloadImages({ paths = [] }) {
    React.useEffect(() => {
        for (const path of paths) {
            const image = new Image();
            image.src = path;
        }
    }, [paths]);

    return null;
}

export default React.memo(PreloadImages);
