import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import scrollIntoView from 'scroll-into-view-if-needed';

import Tooltip from 'components/tooltip/Base';
import InputText from 'components/inputs/InputText';
import Select from 'components/selects/Select';
import RemoveSVG from 'assets/images/svg/RemoveSVG';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';

import { getBasicColumnTypes } from 'gridUI/column-types';
import { MULTIPLE_LINES } from 'const/columnTypes';

const useStyles = makeStyles(theme => ({
    list: {
        minWidth: 500,
        height: 334,
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative'
    },
    table: {
        marginTop: 4,
        border: `1px solid #E9EAEF`
    },
    header: {
        backgroundColor: '#F7F8FA',
        padding: '8px 14px',
        zIndex: 1
    },
    row: {
        padding: `20px 16px 16px!important`,

        '&:not(:first-child)': {
            padding: `10px 16px 16px!important`,
            borderTop: `1px solid #E9EAEF`
        }
    },
    footer: {
        backgroundColor: '#fff',
        borderTop: `1px solid #F7F8FA`,
        zIndex: 1
    },
    dropdownClassName: {
        width: '100%',
        backgroundColor: '#fff'
    },
    close: {
        position: 'relative',
        top: 12,
        cursor: 'pointer'
    },
    addMoreColumn: {
        cursor: 'pointer',
        width: 190,
        '& p': {
            color: theme.colors.lightLavender
        },
        '& svg': {
            marginRight: theme.spacing(2)
        },
        padding: 14
    },
    dlFlex: {
        display: 'flex'
    },
    tutorial: {
        padding: theme.spacing(3, 2),
        borderRadius: 4,
        width: 250
    },
    whiteText: {
        color: theme.colors.white
    },
    secondaryText: {
        color: theme.colors.secondaryText
    }
}));

const BASIC_COLUMN_OPTIONS = getBasicColumnTypes();

function NormalEmptySetup({ columns = [], onChange, tooltipDataType, tooltipColumnId }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [rowIndex, setRowIndex] = React.useState(columns.length);

    const handleInput = React.useCallback(
        (index, propName, value) => {
            const newColumns = [...columns];

            newColumns[index][propName] = value;

            if (propName === 'id') {
                newColumns[index]['publicId'] = value;
            }

            onChange(newColumns);
        },
        [columns, onChange]
    );

    const handleIdInput = React.useCallback(
        (index, value) => {
            const newColumns = [...columns];

            newColumns[index]['id'] = value;
            newColumns[index]['publicId'] = value;

            onChange(newColumns);
        },
        [columns, onChange]
    );

    const handleRemove = React.useCallback(
        index => {
            const newColumns = [...columns];

            newColumns.splice(index, 1);
            onChange(newColumns);
        },
        [columns, onChange]
    );

    const handleAddMore = React.useCallback(() => {
        const newColumns = [...columns];

        newColumns.push({
            name: `Column ${rowIndex + 1}`,
            id: `column${rowIndex + 1}`,
            publicId: `column${rowIndex + 1}`,
            columnId: `column${rowIndex + 1}`,
            type: MULTIPLE_LINES
        });

        onChange(newColumns);

        setRowIndex(rowIndex + 1);

        setTimeout(() => {
            scrollIntoView(document.getElementById('listBottom'), {
                behavior: 'smooth',
                block: 'end',
                duration: 300,
                boundary: document.getElementById('list')
            });
        }, 0);
    }, [columns, rowIndex, setRowIndex, onChange]);

    const isDuplicatedValue = React.useCallback(
        (prop, value) => {
            const found = columns.filter(col => col[prop] === value);

            return found && found.length > 1;
        },
        [columns]
    );

    const tooltipTitleDataType = React.useMemo(() => {
        return {
            1: (
                <Grid container className={classes.tutorial} direction="column" spacing={2}>
                    <Grid item style={{ marginTop: 8 }}>
                        <Typography align="left" className={classes.whiteText} variant="body1">
                            Select what type of data will be stored in the columns
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align="left" className={classes.secondaryText} variant="body2">
                            To add localization columns, adjust the toggle switch above.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align="left" className={classes.secondaryText} variant="body2">
                            To add a reference column or a mix of content and localization columns, you will need to
                            first create a Grid.
                        </Typography>
                    </Grid>
                </Grid>
            )
        };
    }, [classes]);

    const tooltipTitleColumnId = React.useMemo(() => {
        return {
            1: (
                <Grid container className={classes.tutorial} direction="column" spacing={2}>
                    <Grid item style={{ marginTop: 8 }}>
                        <Typography align="left" className={classes.whiteText} variant="body1">
                            Column ID
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align="left" className={classes.secondaryText} variant="body2">
                            Is used to map columns when using the Gridly API and needs to be unique for every column.
                        </Typography>
                    </Grid>
                </Grid>
            )
        };
    }, [classes]);

    return (
        <Grid item container direction="column" className={classes.table}>
            <Grid item container direction="row" spacing={2} className={classes.header}>
                <Grid item xs={4}>
                    <Typography variant="body1">{t('column_format_column_name_label')}</Typography>
                </Grid>
                <Grid item container xs={4} wrap="nowrap">
                    <Grid item>
                        <Typography variant="body1">{t('column_format_data_type_label')}</Typography>
                    </Grid>
                    {tooltipTitleDataType[tooltipDataType] && (
                        <Tooltip title={tooltipTitleDataType[tooltipDataType]}>
                            <Grid item style={{ display: 'flex', marginLeft: 2 }}>
                                <InfoIconSVG />
                            </Grid>
                        </Tooltip>
                    )}
                </Grid>
                <Grid item container xs={4} wrap="nowrap">
                    <Grid item>
                        <Typography variant="body1">{t('column_format_column_id_label')}</Typography>
                    </Grid>
                    {tooltipTitleColumnId[tooltipColumnId] && (
                        <Tooltip title={tooltipTitleColumnId[tooltipColumnId]}>
                            <Grid item style={{ display: 'flex', marginLeft: 2 }}>
                                <InfoIconSVG />
                            </Grid>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <Grid id="list" container spacing={2} direction="column" className={classes.list} wrap="nowrap">
                    {columns.map((column, index) => {
                        return (
                            <Grid
                                key={`column-${index}`}
                                item
                                container
                                direction="row"
                                spacing={2}
                                className={classes.row}
                            >
                                <Grid item xs={4}>
                                    <InputText
                                        placeholder="Input column name"
                                        value={column.name}
                                        error={!column.name || isDuplicatedValue('name', column.name)}
                                        onChange={e => handleInput(index, 'name', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        options={BASIC_COLUMN_OPTIONS}
                                        value={column.type}
                                        border
                                        className={classes.dropdownClassName}
                                        onChange={opt => handleInput(index, 'type', opt.value)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputText
                                        placeholder="Input column ID"
                                        value={column.id}
                                        error={!column.id || isDuplicatedValue('id', column.id)}
                                        onChange={e => handleIdInput(index, e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Grid
                                        container
                                        direction="column"
                                        alignItems="center"
                                        justify="space-between"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <RemoveSVG onClick={() => handleRemove(index)} className={classes.close} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                    <span id="listBottom"></span>
                </Grid>
            </Grid>
            <Grid item container spacing={2} className={classes.footer}>
                <Grid
                    container
                    className={classes.addMoreColumn}
                    alignItems="center"
                    direction="row"
                    onClick={handleAddMore}
                >
                    <Grid item className={classes.dlFlex}>
                        <ColorAddSVG />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{t('add_another_column')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(NormalEmptySetup);
