import React from 'react';

function CreditMethodSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M21.127 7.20703H20.0408V8.44208H21.127C21.4725 8.44208 21.7513 8.71791 21.7513 9.05961V17.9767C21.7513 18.3184 21.4725 18.5942 21.127 18.5942H6.78498C6.43954 18.5942 6.16069 18.3184 6.16069 17.9767V17.5568H4.91211V17.9767C4.91211 18.9977 5.75282 19.8293 6.78498 19.8293H8.13345H9.38203H21.127C22.1592 19.8293 22.9999 18.9977 22.9999 17.9767V15.8524V14.6173V9.05961C22.9999 8.03863 22.1592 7.20703 21.127 7.20703Z"
                    fill={color}
                />
                <path
                    d="M2.24858 14.7697V5.85258C2.24858 5.51088 2.52743 5.23505 2.87287 5.23505H17.2149C17.5604 5.23505 17.8392 5.51088 17.8392 5.85258V14.7697C17.8392 15.1114 17.5604 15.3872 17.2149 15.3872H2.87287C2.52743 15.3872 2.24858 15.1114 2.24858 14.7697ZM5.46992 16.6222H17.2149C18.2471 16.6222 19.0878 15.7906 19.0878 14.7697V12.6454V11.4103V5.85258C19.0878 4.8316 18.2471 4 17.2149 4H2.87287C1.84071 4 1 4.8316 1 5.85258V14.7697C1 15.7906 1.84071 16.6222 2.87287 16.6222H4.22134H5.46992Z"
                    fill={color}
                />
                <path d="M3.49707 12.2832H5.81943V13.5183H3.49707V12.2832Z" fill={color} />
                <path d="M7.08887 12.2832H9.41123V13.5183H7.08887V12.2832Z" fill={color} />
                <path d="M10.6768 12.2832H12.9991V13.5183H10.6768V12.2832Z" fill={color} />
                <path d="M14.2681 12.2832H16.5904V13.5183H14.2681V12.2832Z" fill={color} />
                <path
                    d="M7.71311 7.10352H3.49707V10.8087H7.71311V7.10352ZM6.46453 9.57362H4.74565V8.33857H6.46453V9.57362Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="22" height="15.8293" fill="white" transform="translate(1 4)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(CreditMethodSVG);
