import React, { useCallback, useState, useRef, useEffect } from 'react';
import SettingContentLayout from 'permission/common/Layout';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TagDetailOverview from './TagDetailOverview';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { getTagModelDetail, getTokenSets } from 'gridUI/actions';
import { useCompanyId } from 'hooks/auth';

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100% - 10px)',
        padding: theme.spacing(3)
    },
    tabs: {
        margin: '0 32px 32px',
        borderBottom: `1px solid ${theme.colors.border}`
    }
}));

const TBDetail = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { id } = useParams();
    const companyId = useCompanyId();
    const [searchValue, setSearchValue] = useState('');
    const [tagModel, setTagModel] = useState(null);
    const [tokenSets, setTokenSets] = useState([]);
    const [totalTokenSets, setTotalTokenSets] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetchingTokenSets, setIsFetchingTokenSets] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [forceFetch, setForceFetch] = useState({});
    const limit = 7;
    const timerRef = useRef();
    const timerFetchRef = useRef();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        dispatch(
            getTagModelDetail({
                tokenSetId: id,
                successCallback: data => {
                    setTagModel(data);
                },
                errorCallback: () => {
                    if (location.state?.fromTag) {
                        history.goBack();
                    } else {
                        history.push('localization-settings/tag');
                    }
                }
            })
        );
    }, [companyId, id, dispatch, history, location]);

    useEffect(() => {
        setIsFetchingTokenSets(true);
        if (timerFetchRef.current) clearTimeout(timerFetchRef.current);
        timerFetchRef.current = setTimeout(() => {
            dispatch(
                getTokenSets({
                    tokenSetId: id,
                    params: {
                        tokenSetId: id,
                        search: searchValue,
                        offset: (currentPage - 1) * limit,
                        limit
                    },
                    successCallback: ({ data, headers }) => {
                        setTokenSets(data);
                        setIsFetchingTokenSets(false);
                        setTotalTokenSets(headers?.['x-total-count'] || 0);
                    },
                    errorCallback: () => {
                        setIsFetchingTokenSets(false);
                    }
                })
            );
        }, DEBOUNCE_TIME_SEARCHING);
    }, [id, dispatch, currentPage, searchValue, forceFetch]);

    const handleResetFetch = useCallback(({ resetPage, resetSelectedRows }) => {
        if (resetPage) {
            setCurrentPage(1);
        }
        if (resetSelectedRows) {
            setSelectedRows([]);
            return;
        }
        setForceFetch({});
    }, []);

    const handleSearchChange = useCallback(e => {
        const value = e?.target?.value;
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setCurrentPage(1);
            setSearchValue(value);
        }, DEBOUNCE_TIME_SEARCHING);
    }, []);

    const handleClearSearch = React.useCallback(() => {
        setSearchValue('');
    }, []);

    const handleChangePage = useCallback((e, page) => {
        setCurrentPage(page);
    }, []);

    const onBack = useCallback(() => {
        if (location.state?.fromTag) {
            history.goBack();
        } else {
            history.push('/localization-settings/tb');
        }
    }, [history, location]);

    if (!tagModel) {
        return null;
    }

    return (
        <SettingContentLayout name={tagModel?.name} onBack={onBack}>
            <Grid item className={classes.root}>
                <TagDetailOverview
                    tagModel={tagModel}
                    searchValue={searchValue}
                    tokenSets={tokenSets}
                    isFetching={isFetchingTokenSets}
                    selectedRows={selectedRows}
                    currentPage={currentPage}
                    totalTokenSets={totalTokenSets}
                    limit={limit}
                    handleSearchChange={handleSearchChange}
                    handleClearSearch={handleClearSearch}
                    setSelectedRows={setSelectedRows}
                    handleResetFetch={handleResetFetch}
                    handleChangePage={handleChangePage}
                />
            </Grid>
        </SettingContentLayout>
    );
};

export default React.memo(TBDetail);
