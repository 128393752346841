import React from 'react';

function MergedSVG({ color = 'white', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.1499 2.50002C2.1499 1.16587 3.22057 0.150024 4.4374 0.150024C5.73225 0.150024 6.7249 1.1626 6.7249 2.50002C6.7249 3.52168 6.11338 4.37963 5.20138 4.69142L5.0999 4.72612V14.1667C5.0999 14.3837 5.02992 14.5524 4.91906 14.6661C4.80854 14.7795 4.64591 14.85 4.4374 14.85C4.2289 14.85 4.06626 14.7795 3.95574 14.6661C3.84488 14.5524 3.7749 14.3837 3.7749 14.1667V4.72612L3.67343 4.69142C2.76142 4.37963 2.1499 3.52168 2.1499 2.50002ZM4.4374 1.51669C4.15841 1.51669 3.91479 1.61283 3.74094 1.79114C3.56742 1.96911 3.4749 2.21706 3.4749 2.50002C3.4749 3.08897 3.95455 3.48336 4.4374 3.48336C4.7164 3.48336 4.96001 3.38721 5.13387 3.20891C5.30738 3.03094 5.3999 2.78299 5.3999 2.50002C5.3999 2.21706 5.30738 1.96911 5.13387 1.79114C4.96001 1.61283 4.7164 1.51669 4.4374 1.51669Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.2573 2.68807C9.44218 2.49845 9.75137 2.49845 9.93625 2.68807C10.1232 2.87982 10.1232 3.20357 9.93625 3.39531L9.0379 4.31669H10.9374C12.2323 4.31669 13.2249 5.32926 13.2249 6.66669V10.2739L13.3264 10.3086C14.2384 10.6204 14.8499 11.4784 14.8499 12.5C14.8499 13.8375 13.8573 14.85 12.5624 14.85C11.2676 14.85 10.2749 13.8375 10.2749 12.5C10.2749 11.4784 10.8864 10.6204 11.7984 10.3086L11.8999 10.2739V6.66669C11.8999 6.38373 11.8074 6.13578 11.6339 5.95781C11.46 5.7795 11.2164 5.68336 10.9374 5.68336H9.11915L9.93625 6.52141C10.1232 6.71315 10.1232 7.0369 9.93625 7.22864C9.78965 7.379 9.66343 7.43336 9.55615 7.43336C9.46949 7.43336 9.43466 7.43191 9.40155 7.42059C9.37216 7.41054 9.33069 7.38725 9.2573 7.31198L7.38855 5.39531C7.21002 5.2122 7.1907 4.88464 7.39625 4.59685L9.2573 2.68807ZM12.5624 11.5167C12.2834 11.5167 12.0398 11.6128 11.8659 11.7911C11.6924 11.9691 11.5999 12.2171 11.5999 12.5C11.5999 12.783 11.6924 13.0309 11.8659 13.2089C12.0398 13.3872 12.2834 13.4834 12.5624 13.4834C12.8414 13.4834 13.085 13.3872 13.2589 13.2089C13.4324 13.0309 13.5249 12.783 13.5249 12.5C13.5249 12.2171 13.4324 11.9691 13.2589 11.7911C13.085 11.6128 12.8414 11.5167 12.5624 11.5167Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(MergedSVG);
