import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Dialog from 'components/dialog';
import DatetimeInput from 'gridUI/filters/DatetimeInput';
import { useShareViewGroupMembers } from 'hooks/gridUI';
import LDBasePortal from 'components/selects/LDBasePortal';
import Avatar from 'components/avatar/User';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { getAvatarUrl } from 'utils/images';
import { getFullName } from 'utils/user';
import DialogActions from 'components/dialog/DialogActions';
import InfoContainedIconSVG from 'assets/images/svg/InfoContainedIconSVG';
import ButtonBase from 'components/button/Base';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { exportGridHistory } from 'gridUI/actions';
import { useDispatch } from 'react-redux';
import CircularProgress from 'components/CircularProgress';

const ExportGridHistoryDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const members = useShareViewGroupMembers();
    const { dbId, branchId } = useParams();
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [selectedMemberOptions, setSelectedMemberOptions] = useState([]);
    const [isExporting, setIsExporting] = useState(false);

    const memberOptions = useMemo(() => {
        return (members || [])?.map(m => ({
            value: m?.user?.id,
            email: m?.user?.email,
            searchLabel: `${getFullName(m?.user)} ${m?.user?.email}`,
            label: (
                <div className="leading-4">
                    <p className="truncate">{getFullName(m?.user)}</p>
                    <p className="text-[10px] text-grey-blue truncate">{m?.user?.email}</p>
                </div>
            ),
            icon: () => (
                <Avatar src={getAvatarUrl(m?.user?.id)} size={24} color="#DBDCE1">
                    <AvatarIconSVG />
                </Avatar>
            )
        }));
    }, [members]);

    const isSelectAllMembers = React.useMemo(() => {
        return selectedMemberOptions?.length && selectedMemberOptions?.length === memberOptions?.length;
    }, [selectedMemberOptions, memberOptions]);

    const handleClose = useCallback(() => {
        if (isExporting) return;
        onClose();
        setFrom();
        setTo();
        setSelectedMemberOptions([]);
        setIsExporting(false);
    }, [onClose, isExporting]);

    const filterFromDate = useCallback(
        date => {
            const condition = moment(date).isBetween(moment().subtract(30, 'days'), moment());
            if (to) {
                return condition && moment(date).isSameOrBefore(to);
            }
            return condition;
        },
        [to]
    );

    const filterToDate = useCallback(
        date => {
            const condition = moment(date).isBetween(moment().subtract(30, 'days'), moment());
            if (from) {
                return condition && moment(date).isSameOrAfter(from);
            }
            return condition;
        },
        [from]
    );

    const handleExportData = useCallback(() => {
        setIsExporting(true);
        dispatch(
            exportGridHistory({
                dbId,
                gridId: branchId,
                fromDateTime: moment(from)
                    .startOf('day')
                    .utc(),
                toDateTime: moment(to)
                    .endOf('day')
                    .utc(),
                emails: selectedMemberOptions.map(m => m.email),
                successCallback: () => {
                    handleClose();
                },
                errorCallback: () => {
                    setIsExporting(false);
                }
            })
        );
    }, [dbId, branchId, from, to, selectedMemberOptions, dispatch]);

    const onChangeUsers = useCallback(options => {
        setSelectedMemberOptions(options);
    }, []);

    const onSelectAllUsers = useCallback(
        setOpenState => {
            if (isSelectAllMembers) {
                setSelectedMemberOptions([]);
            } else {
                setSelectedMemberOptions(memberOptions);
            }
            setOpenState(false);
        },
        [isSelectAllMembers, memberOptions]
    );

    const renderContent = useCallback(
        ({ open }) => (
            <div
                className={classnames(
                    'flex flex-nowrap items-center gap-[7px] !w-[240px] h-9 rounded pl-2.5 pr-[15px] border border-grey-border cursor-pointer',
                    {
                        'border-brand-main': open
                    }
                )}
            >
                <div>
                    <Avatar size={22} color="#2D97F2">
                        <AvatarIconSVG />
                    </Avatar>
                </div>
                <div className="flex-1">
                    <span
                        className={classnames({
                            'text-[13px] text-grey-blue': !selectedMemberOptions?.length
                        })}
                    >
                        Select users
                    </span>
                    {selectedMemberOptions?.length > 0 && (
                        <span className="inline-flex ml-[3px] p-[2px] min-w-[16px] h-4 items-center justify-center text-white rounded bg-brand-main text-[13px]">
                            {selectedMemberOptions?.length}
                        </span>
                    )}
                </div>
                <div>
                    <ArrowDownSVG />
                </div>
            </div>
        ),
        [selectedMemberOptions.length]
    );

    return (
        <Dialog maxWidth="md" open={open} title={t('export_grid_history')} onClose={handleClose}>
            <div className="w-[644px] px-5 pb-10">
                <div className="flex flex-col flex-nowrap gap-9">
                    <div>
                        <p className="body1">{t('export_grid_history_select_data_to_include')}</p>
                    </div>
                    <div>
                        <div className="flex flex-nowrap gap-1.5 items-center">
                            <div className="w-[18px] h-[18px] rounded-full bg-brand-main flex items-center justify-center">
                                <p className="text-white font-medium text-[10px]">1</p>
                            </div>
                            <p className="body1">{t('export_grid_history_by_period_of_time')}</p>
                        </div>
                        <div className="mt-[27px]">
                            <div className="flex items-center gap-[44px]">
                                <div className="w-[240px]">
                                    <div className="flex flex-col flex-nowrap gap-2">
                                        <div>
                                            <p className="text-text-secondary">{t('global_from')}</p>
                                        </div>
                                        <div>
                                            <DatetimeInput
                                                timeFormat="mm/dd/yyyy"
                                                placeholder="mm/dd/yyyy"
                                                defaultValue={from}
                                                onChange={setFrom}
                                                filterDate={filterFromDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[240px]">
                                    <div className="flex flex-col flex-nowrap gap-2">
                                        <div>
                                            <p className="text-text-secondary">{t('global_to')}</p>
                                        </div>
                                        <div>
                                            <DatetimeInput
                                                timeFormat="mm/dd/yyyy"
                                                placeholder="mm/dd/yyyy"
                                                defaultValue={to}
                                                onChange={setTo}
                                                filterDate={filterToDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-nowrap gap-1.5 items-center">
                            <div className="w-[18px] h-[18px] rounded-full bg-brand-main flex items-center justify-center">
                                <p className="text-white font-medium text-[10px]">2</p>
                            </div>
                            <p className="body1">{t('export_grid_history_by_users')}</p>
                        </div>
                        <div className="mt-3">
                            <LDBasePortal
                                options={memberOptions}
                                defaultValue={selectedMemberOptions}
                                onChange={onChangeUsers}
                                menuPlaceholder={'Select users'}
                                controlClassName="!pl-2.5 !pr-[15px]"
                                dropdownClassName="!min-w-[240px] w-fit"
                                isMulti
                                closedOnSelect={false}
                                isAll={memberOptions?.length > 0}
                                onSelectAll={onSelectAllUsers}
                                selectAllText={isSelectAllMembers ? 'Unselect all' : 'Select all'}
                                isUsingChildren
                                isUsingContainer
                                itemHeight={48}
                                maxMenuHeight={200}
                            >
                                {renderContent}
                            </LDBasePortal>
                        </div>
                    </div>
                </div>
            </div>
            <DialogActions>
                <div className="flex-1 flex flex-nowrap gap-2 items-center">
                    <div>
                        <InfoContainedIconSVG bgColor="#F6BD42" />
                    </div>
                    <div className="flex-1">
                        <p className="text-steel">{t('export_grid_history_format_type')}</p>
                    </div>
                    <div>
                        <ButtonBase variant="outlined" width={130} onClick={handleClose}>
                            {t(`global_cancel`)}
                        </ButtonBase>
                    </div>
                    <div>
                        <ButtonBase
                            width={130}
                            disabled={isExporting || !from || !to || !selectedMemberOptions?.length}
                            variant="contained"
                            onClick={handleExportData}
                        >
                            {t(`global_export`)}
                            {isExporting && <CircularProgress />}
                        </ButtonBase>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(ExportGridHistoryDialog);
