import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TEXT_LINE_HEIGHT, PREVIEW_STATUS } from 'const/gridUI';
import FileDelete from '../diffCheckComponents/delete/File';
import FileUpdate from '../diffCheckComponents/update/File';
import NormalFiles from '../diffCheckComponents/normal/Files';
import DiffFilesWithOtherTypes from './diffType/Files';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        display: 'table-cell',
        verticalAlign: 'top',
        textAlign: 'left',
        lineHeight: `${TEXT_LINE_HEIGHT}px`,
        wordBreak: 'break-word',
        wordWrap: 'break-word'
    }
}));

function FilesRender({
    rowIndex,
    columnIndex,
    rowHeight,
    status,
    columnType,
    previousColumnType,
    data,
    previousData,
    options,
    customProperties,
    isOverride,
    conflictType,
    ...rest
}) {
    const classes = useStyles();
    const isSameColumnType = columnType === previousColumnType;

    return (
        <div
            className={`cell_${rowIndex}_${columnIndex} ${classes.root}`}
            style={{
                height: rowHeight,
                display: 'flex',
                pointerEvents: !conflictType ? '' : 'none'
            }}
        >
            {isSameColumnType && status === PREVIEW_STATUS.DELETED && (
                <FileDelete
                    rowIndex={rowIndex}
                    conflictType={conflictType}
                    isHideMarkIcon={true}
                    previousData={previousData}
                    {...rest}
                />
            )}
            {isSameColumnType && status === PREVIEW_STATUS.UPDATED && (
                <FileUpdate
                    rowIndex={rowIndex}
                    isHideMarkIcon={true}
                    data={data}
                    previousData={previousData}
                    conflictType={conflictType}
                    {...rest}
                />
            )}
            {isSameColumnType && [PREVIEW_STATUS.NORMAL, PREVIEW_STATUS.CONFLICT].includes(status) && (
                <NormalFiles
                    rowIndex={rowIndex}
                    conflictType={conflictType}
                    customProperties={customProperties}
                    isHideMarkIcon={true}
                    options={options}
                    data={data}
                    {...rest}
                />
            )}

            {!isSameColumnType && (
                <DiffFilesWithOtherTypes
                    previousData={previousData}
                    data={data}
                    options={options}
                    customProperties={customProperties}
                    previousColumnType={previousColumnType}
                    isHideMarkIcon={true}
                    rowIndex={rowIndex}
                    {...rest}
                />
            )}
        </div>
    );
}

export default React.memo(FilesRender);
