import React from "react";

function BackgroundLoginSVG({ color = "#8C8C8C", ...other }) {
    return <svg {...other} xmlns="http://www.w3.org/2000/svg" width="1440" height="1024" viewBox="0 0 1440 1024">
        <g fill="none" fillRule="evenodd">
            <path fill="#3F2782" d="M-32 1054h1505V-29H-32z"/>
            <path fill="#4C4C93" d="M-206.393 94.007L1.228 222.87l94.755-74.963c24-18.99 36.421-43.36 34.976-68.62-1.52-26.598-17.984-50.942-46.356-68.55L-19.504-53.87-206.393 94.007zM1.292 224l-.277-.171L-208 94.099l.515-.409L-19.568-55l.277.172L85.093 9.951c28.636 17.772 45.254 42.377 46.792 69.283 1.462 25.57-11.084 50.216-35.326 69.399L1.292 224z"/>
            <path fill="#3A388C" d="M105.927 524.808c43.874 2.228 85.677-9.698 114.758-32.753L408.373 343.28 200.717 214.157l-94.79 75.136v235.515zm10.4 1.192c-3.613 0-7.242-.096-10.887-.288l-.44-.024V288.833l.185-.139.402.278-.287-.364 95.05-75.347.26-.261L410 343.2l-.544.408L221.26 492.782c-26.934 21.352-64.703 33.217-104.935 33.218z"/>
            <path fill="#874D41" d="M282.762 1.132l-92.665 73.394c-24.052 19.058-36.501 43.508-35.055 68.848 1.521 26.683 18.022 51.114 46.462 68.795l.221.129 208.516 129.571 92.62-73.37c24.082-19.077 36.546-43.544 35.098-68.895-1.525-26.677-18.028-51.098-46.469-68.766L282.762 1.132zM410.31 343l-.411-.251-208.662-129.663-.204-.118c-28.722-17.855-45.377-42.55-46.917-69.542-1.463-25.649 11.111-50.376 35.406-69.627L282.698 0l.277.173L491.98 130.05c28.705 17.832 45.363 42.515 46.905 69.501 1.466 25.66-11.123 50.406-35.447 69.675L410.309 343z"/>
            <path fill="#8C7542" d="M-101.275 423.475L25.05 501.063c22.524 14 50.415 22.28 80.681 23.959V289.698l-.61-.185.27-.214L-206.933 95.13l-92.96 73.683c-24.059 19.065-36.512 43.52-35.068 68.862 1.52 26.674 18.007 51.096 46.424 68.765l187.667 116.675-.406.36zM106.66 526l-.488-.026c-30.612-1.624-58.831-9.966-81.609-24.122l-127.388-78.242.412-.364-186.612-116.019c-28.68-17.833-45.321-42.515-46.86-69.499-1.461-25.65 11.117-50.383 35.417-69.641L-206.999 94l.382.234L107 289.207l-.341.27V526z"/>
            <path fill="#4C4C93" d="M497.607 524.418l216.237 134.45 98.682-78.208c24.996-19.815 37.934-45.242 36.43-71.6-1.585-27.752-18.732-53.153-48.282-71.524l-108.421-67.404-194.646 154.286zM713.91 660l-.277-.172L496 524.51l.515-.41L692.19 369l.277.172 108.697 67.576c29.815 18.535 47.117 44.198 48.717 72.26 1.523 26.667-11.539 52.371-36.777 72.378L713.909 660z"/>
            <path fill="#3A388C" d="M823.926 972.796c45.605 2.326 89.086-10.1 119.325-34.121l195.13-154.991-215.9-134.526-98.555 78.28v245.358zM834.755 974c-3.755 0-7.527-.1-11.316-.3l-.439-.024V726.98l.184-.139.408.273-.288-.364 98.81-78.488.26-.261.312.194L1140 783.6l-.535.408-195.638 155.393C915.83 961.641 876.573 974 834.755 974z"/>
            <path fill="#874D41" d="M1007.105 428.129l-96.536 76.194c-25.057 19.787-38.029 45.175-36.522 71.483 1.586 27.704 18.777 53.07 48.406 71.427l.23.133 217.215 134.51 96.489-76.17c25.089-19.807 38.075-45.211 36.566-71.532-1.589-27.697-18.781-53.053-48.413-71.397l-217.435-134.648zM1139.967 783l-.416-.25-217.434-134.646-.136-.075c-29.911-18.53-47.258-44.157-48.861-72.171-1.525-26.617 11.571-52.28 36.873-72.26L1007.04 427l.278.172 217.712 134.819c29.896 18.507 47.244 44.123 48.85 72.13 1.527 26.63-11.584 52.31-36.917 72.308L1139.967 783z"/>
            <path fill="#8C7542" d="M607.242 867.217l131.495 80.841c23.45 14.59 52.485 23.217 83.998 24.964v-245.19l-.605-.186.267-.213-325.09-202.302-96.76 76.77c-25.042 19.865-38.005 45.347-36.502 71.752 1.582 27.795 18.744 53.242 48.324 71.653l195.296 121.536-.423.375zM823.662 974l-.488-.026c-31.857-1.691-61.223-10.38-84.924-25.127L605.69 867.35l.43-.38-194.24-120.878c-29.843-18.573-47.16-44.281-48.76-72.386-1.522-26.716 11.566-52.475 36.852-72.532L497.242 524l.385.237L824 727.34l-.338.27V974z"/>
            <path fill="#4C4C93" d="M1346.607 811.418l216.237 134.45 98.682-78.207c24.996-19.815 37.934-45.242 36.43-71.6-1.585-27.752-18.731-53.153-48.282-71.525l-108.42-67.404-194.647 154.286zM1562.908 947l-.276-.172L1345 811.51l.515-.41L1541.188 656l.277.172 108.697 67.577c29.815 18.535 47.117 44.198 48.719 72.26 1.522 26.667-11.54 52.371-36.78 72.378L1562.909 947z"/>
            <path fill="#8C7542" d="M1455.237 1154.215l131.493 80.843c23.452 14.59 52.487 23.217 83.998 24.964v-245.19l-.619-.188.275-.216-325.077-202.298-96.76 76.77c-25.042 19.864-38.006 45.348-36.503 71.753 1.583 27.796 18.745 53.242 48.324 71.652l195.292 121.536-.423.374zM1671.655 1261l-.487-.026c-31.856-1.691-61.222-10.38-84.924-25.127l-132.556-81.496.428-.38-194.237-120.878c-29.842-18.574-47.16-44.282-48.76-72.387-1.52-26.716 11.567-52.475 36.852-72.533L1345.238 811l.386.236L1672 1014.342l-.345.27V1261z"/>
            <path fill="#3A388C" d="M-168.073 1169.763c50.993 2.596 99.59-11.243 133.394-38.014l218.052-172.687L-57.92 809.158l-110.152 87.234v273.37zm12.153 1.237c-4.194 0-8.408-.11-12.64-.335l-.44-.023V895.931l.268-.2 110.442-87.469.26-.262.314.195L185 958.98l-.543.407-218.56 173.086C-65.37 1157.238-109.216 1171-155.92 1171z"/>
            <path fill="#874D41" d="M36.133 563.132l-107.425 85.233c-27.89 22.14-42.327 50.548-40.65 79.987 1.764 31.001 20.898 59.385 53.875 79.923l.256.148L183.87 958.87l107.372-85.207c27.926-22.162 42.38-50.588 40.7-80.042-1.768-30.994-20.904-59.365-53.884-79.889l-241.925-150.6zM183.94 960l-.433-.262-241.89-150.578-.154-.086c-33.26-20.713-52.547-49.358-54.33-80.669-1.694-29.75 12.867-58.433 41-80.766L36.069 562l.276.172 242.202 150.773c33.244 20.688 52.534 49.32 54.32 80.624 1.698 29.763-12.88 58.466-41.05 80.821L183.94 960z"/>
            <path fill="#4C4C93" d="M1036.606-40.968l272.854 169.837 124.488-98.767c31.549-25.034 47.876-57.166 45.976-90.474-1.998-35.069-23.638-67.164-60.93-90.374l-136.772-85.123-245.616 194.9zM1309.525 130l-.276-.172L1035-40.876l.515-.41L1282.157-237l.276.172 137.049 85.294c37.556 23.374 59.35 55.73 61.368 91.11 1.916 33.617-14.536 66.026-46.326 91.253l-125 99.171z"/>
            <path fill="#8C7542" d="M1173.666 391.294l165.956 102.176c29.621 18.455 66.303 29.36 106.109 31.55V215.17l-.644-.194.301-.238-136.587-85.117L1035.234-40.87 913.151 56.131c-31.61 25.112-47.974 57.328-46.076 90.716 1.998 35.141 23.659 67.31 60.993 90.58L1174.2 390.82l-.534.474zM1446.657 526l-.487-.026c-40.15-2.136-77.162-13.102-107.034-31.713l-167.02-102.831.54-.48L927.58 238.216c-37.598-23.435-59.415-55.865-61.43-91.316-1.916-33.698 14.572-66.192 46.426-91.496L1035.166-42l.415.253 273.842 170.66L1447 214.649l-.343.27V526z"/>
        </g>
    </svg>
}

export default React.memo(BackgroundLoginSVG);
