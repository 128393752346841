import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import ColumnTypeDisplay from '../ColumnTypeDisplay';
import * as gridActions from '../actions';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import CloseIcon from 'assets/images/svg/CloseIconSVG';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        padding: '0 12px',
        '& img': {
            marginRight: 5
        }
    },
    headerName: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: 12,
            width: 18
        },
        color: theme.colors.primaryText,
        '& p': {
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer'
        }
    },
    dropdown: {
        cursor: 'pointer',
        fontSize: 14,
        color: theme.colors.iconHeaderColor
    }
}));

function ExportHeader({ column = {} }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const columnType = getCorrectColumnType(column);

    const handleRemoveExportColumn = () => {
        dispatch(
            gridActions.addExportRemovedColumn({
                columnId: column.id
            })
        );
    };

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.root}>
            <Grid xs={11} item className={classes.headerName}>
                <ColumnTypeDisplay
                    group={column.group}
                    type={columnType}
                    name={column.name}
                    customProperties={column.customProperties}
                />
            </Grid>
            <Grid xs={1} id={`dropdown_${column.id}`} item style={{ position: 'relative', top: 3 }}>
                <CloseIcon onClick={handleRemoveExportColumn} className={classes.dropdown} />
            </Grid>
        </Grid>
    );
}

ExportHeader.propTypes = {
    column: PropTypes.object.isRequired,
    isSelectionActive: PropTypes.bool,
    columnIndex: PropTypes.any,
    position: PropTypes.object
};

export default React.memo(ExportHeader);
