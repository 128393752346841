import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import PopperMenu from 'components/menus/Popper';
import ColumnSetup from './ColumnSetup';
import { TRANSLATION_TYPES } from 'gridUI/column-types';
import ColumnTypeDisplay from 'gridUI/ColumnTypeDisplay';
import { getLanguageDisplayName } from 'const/languageData';
import { useTranslation } from 'react-i18next';
import * as columnTypes from 'const/columnTypes';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles(theme => ({
    root: {
        paddingRight: 14,
        paddingLeft: 14,
        height: '100%',
        cursor: 'pointer'
    },
    displayFlex: {
        display: 'flex'
    },
    warning: {
        background: hexToRgba(theme.colors.snackBarWarning, 0.1),
        border: `1px solid ${theme.colors.snackBarWarning}`
    }
}));

function ColumnType({ country, importType, name }) {
    // if (!country) {
    //     return (
    //         <>
    //             <Typography style={{ position: 'relative', top: 3, marginRight: 8 }} variant="body2" display="inline">
    //                 <ColumnIcon
    //                     type={importType === TRANSLATION_TYPES.MAX_CHARACTERS ? columnTypes.NUMBER : importType}
    //                     styleCountryFlagWrapper={{
    //                         width: 24
    //                     }}
    //                 />
    //             </Typography>
    //             <Typography variant="body2" display="inline">
    //                 {name || columnTypes.getColumnLabel(importType)}
    //             </Typography>
    //         </>
    //     );
    // }

    const displayName = name || getLanguageDisplayName(country) || columnTypes.getColumnLabel(importType);

    return (
        <ColumnTypeDisplay
            type={
                [
                    TRANSLATION_TYPES.ORIGINAL_LANG,
                    TRANSLATION_TYPES.TARGET_LANG,
                    TRANSLATION_TYPES.SOURCE_LANG
                ].includes(importType)
                    ? columnTypes.TRANSLATION
                    : importType
            }
            group={country}
            name={displayName}
            customProperties={{ localizationType: importType }}
            truncated={true}
        />
    );
}

const ColumnTypeMemo = React.memo(ColumnType);

function ImportHeader({ columnId, isModeSingle, columnMap, columnIndex, isViewOnly }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const column = columnMap?.[columnId];
    const importType = column?.importType;
    const columnName = column?.name;
    const country = column?.country;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const isHasColumnType = React.useCallback(
        ({ value }) => {
            let columnIds = Object.keys(columnMap);
            let isHas = false;
            for (let i = 0; i < columnIds.length; i++) {
                const columnId = columnIds?.[i];
                const detail = columnMap?.[columnId];
                if (detail.importType === value) {
                    isHas = true;
                    break;
                }
            }
            return isHas;
        },
        [columnMap]
    );

    const isHasSource = isHasColumnType({ value: TRANSLATION_TYPES.SOURCE_LANG });
    const isHasStringIdentifer = isHasColumnType({ value: TRANSLATION_TYPES.RECORD_ID });
    const isHasPathTag = isHasColumnType({ value: TRANSLATION_TYPES.PATH_TAG });
    const isHasAdditionalInformations = isHasColumnType({ value: TRANSLATION_TYPES.ADDITIONAL_INFO });
    const isHasCharacterLimit = isHasColumnType({ value: TRANSLATION_TYPES.MAX_CHARACTERS });

    const handleClick = event => {
        !isViewOnly && setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const isDuplicateName = React.useMemo(() => {
        const names = Object.keys(columnMap)
            ?.filter(key => columnMap?.[key]?.index !== column?.index)
            ?.map(key => columnMap?.[key]?.name);
        return columnName && names?.includes(columnName);
    }, [column, columnMap, columnName]);

    return (
        <>
            <Grid
                onClick={handleClick}
                container
                alignItems="center"
                justify="space-between"
                className={`${classes.root} ${isDuplicateName ? classes.warning : ``}`}
                wrap="nowrap"
            >
                <Grid item style={{ width: `calc(100% -  16px)` }}>
                    {(columnName || importType) && (
                        <ColumnTypeMemo
                            country={country}
                            importType={importType}
                            name={columnName}
                            columnIndex={columnIndex}
                        />
                    )}
                    {!importType && !columnName && (
                        <Typography variant="caption">{t('coloumn_mapping_selection_guide')}</Typography>
                    )}
                </Grid>
                {!isViewOnly && (
                    <Grid item className={classes.displayFlex}>
                        <ArrowDownSVG />
                    </Grid>
                )}
            </Grid>
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'bottom'} id={`localization-column-setup`}>
                    <ColumnSetup
                        column={column}
                        columnId={columnId}
                        isModeSingle={isModeSingle}
                        columnMap={columnMap}
                        handleClickAway={handleClickAway}
                        importType={importType}
                        columnIndex={columnIndex}
                        country={country}
                        isHasSource={isHasSource}
                        isHasStringIdentifer={isHasStringIdentifer}
                        isHasPathTag={isHasPathTag}
                        isHasAdditionalInformations={isHasAdditionalInformations}
                        isHasCharacterLimit={isHasCharacterLimit}
                    />
                </PopperMenu>
            )}
        </>
    );
}

ImportHeader.propTypes = {
    column: PropTypes.object,
    columnIndex: PropTypes.any,
    position: PropTypes.object
};

export default React.memo(ImportHeader);
