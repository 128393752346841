import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import * as gridActions from 'gridUI/actions';
import { useDispatch } from 'react-redux';
import { BOOLEAN } from 'const/columnTypes';
import ThreeStatesCheckbox from 'components/checkbox/ThreeStateCheckBox';
import { getBooleanValue } from 'utils/gridUI/formatData';
import { OPERATOR } from 'gridUI/conditions';

const useStyles = makeStyles(theme => ({
    root: {},
    checkbox: {
        position: 'relative',
        left: 12
    }
}));

function CheckboxForPresent({
    columnId,
    isSelectionActive,
    onQuickFilterChange,
    onRefreshGrid,
    quickFilters,
    checkboxStyle = {}
}) {
    const filterData = quickFilters[columnId];
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleChange = (e, nextState) => {
        onQuickFilterChange &&
            onQuickFilterChange({
                columnId,
                value: getBooleanValue(nextState),
                type: BOOLEAN,
                currentState: nextState,
                operator: OPERATOR.equal
            });

        dispatch(gridActions.resetCellStatus());
        onRefreshGrid && onRefreshGrid();
    };

    return (
        <Grid className={classes.root} container justify="center">
            <ThreeStatesCheckbox
                className={classes.checkbox}
                state={(filterData && filterData.currentState) || 0}
                onChange={handleChange}
                style={checkboxStyle}
            />
        </Grid>
    );
}

export default React.memo(CheckboxForPresent);
