import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { HEADER_HEIGHT } from 'const';
import ArrowLeftSVG from 'assets/images/svg/ArrowLeftSVG';

const useStyles = makeStyles(theme => ({
    root: {},
    noDatabase: {
        marginTop: 70
    },
    memberContent: {
        height: props => props.height || `calc(100vh - ${28 + 2 * theme.spacing(3) + 14 * 2}px - ${HEADER_HEIGHT}px)`,
        overflow: 'hidden',
        background: theme.colors.white,
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('lg')]: {
            // '&.content-medium': {
            //     minWidth: 1000
            // },
            '&.content-auto': {
                overflowX: 'auto',
                overflowY: 'auto'
            }
        }
    },
    header: {
        minHeight: 28
    },
    leftArrow: {
        verticalAlign: 'bottom',
        cursor: 'pointer'
    },
    expand: {
        flex: 1
    }
}));

function SettingContentLayout({ contentClassName = '', name, AddComponent, children, height, onBack }) {
    const classes = useStyles({ height });

    React.useEffect(() => {
        let permissions = document.getElementById(`permissions`);
        permissions.addEventListener('contextmenu', function(e) {
            e.preventDefault();
        });
    }, []);

    return (
        <Grid id="permissions" className={classes.root} container direction="column" wrap="nowrap">
            <Grid className={classes.header} item container justify="space-between" alignItems="center">
                <Grid item container alignItems="center" spacing={2} className={classes.expand}>
                    {onBack && (
                        <Grid item>
                            <ArrowLeftSVG
                                className={classes.leftArrow}
                                size="medium"
                                color="#78778B"
                                onClick={onBack}
                            />
                        </Grid>
                    )}
                    <Grid item className={classes.expand}>
                        <Typography variant="h3">{name}</Typography>
                    </Grid>
                </Grid>
                {AddComponent && <Grid item>{AddComponent}</Grid>}
            </Grid>
            <Grid item className={`${classes.memberContent} ${contentClassName} scrollbar-app`}>
                {children}
            </Grid>
        </Grid>
    );
}

export default React.memo(SettingContentLayout);
