import uuidv1 from 'uuid/v1';
import * as types from '../types';
import { createViewApi, updateViewApi, deleteViewApi, updateViewCustomPropertiesApi, getViewsApi } from 'services/view';
import { enqueueSnackbar } from 'notifier/actions';
import * as optimisticActions from './optimistic';
import * as statusActions from './status';
import * as gridActions from './gridUI';
import history from 'utils/history';
import { VIEW_TYPES } from 'const/gridUI';
import * as viewFilterActions from './viewFilter';
import * as viewSortActions from './viewSort';
import * as columnActions from './column';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_AGGREGATION_TYPE, AGGREGATIONS_DISABLED_COLUMNS } from 'const';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { getCalcViewAggregationsApi } from 'services/aggregation';
import * as aggregationActions from './aggregation';
import { getCorrectColumnType } from 'utils/gridUI/formatData';

export function fetchViews({ branchId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId, branchId: branchIdStore } = gridUI;

        const branchIdCombined = branchId || branchIdStore;
        try {
            const views = await getViewsApi({
                gridId: branchIdCombined,
                dbId,
                paramOptions: {
                    includeDefaultView: true,
                    expand: `${VIEW_TYPES.ACCESS_VIEW},${VIEW_TYPES.USER_VIEW}`
                }
            });
            dispatch(getViewSuccess({ views }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function getViewSuccess({ views }) {
    return {
        type: types.GET_VIEW_COLUMNS,
        payload: {
            views
        }
    };
}

export function createView({ dbId, view, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_createViewAction());
        try {
            const { gridUI } = getState();
            const { currentView } = gridUI;
            // const columnsOfView = columnsPermission.map(col => ({
            //     id: col.id,
            //     editable: col.editable || true
            // }));
            const newView = await createViewApi({ dbId, view, currentViewId: currentView?.id });
            // await addColumnsToViewApi({ defaultAccessViewId: newView.id, dbId, columns: columnsOfView });
            dispatch(createViewSuccessAction({ view: newView }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            return successCallback && successCallback(newView);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(_createViewFailedAction({ error: message }));
            return errorCallback && errorCallback(message);
        }
    };
}

function _createViewAction() {
    return {
        type: types.CREATE_VIEW
    };
}

export function createViewSuccessAction({ view }) {
    return {
        type: types.CREATE_VIEW_SUCCESS,
        payload: {
            view
        }
    };
}

export function createViewRealtimeSuccessAction({ view }) {
    return {
        type: types.CREATE_VIEW_REALTIME_SUCCESS,
        payload: {
            view
        }
    };
}

function _createViewFailedAction({ error }) {
    return {
        type: types.CREATE_VIEW_FAILED,
        payload: {
            error
        }
    };
}

export function updateView({ dbId, newView, oldView, successCallback, errorCallback }) {
    return async function(dispatch) {
        const actionId = uuidv1();
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(updateViewAction({ view: newView }));
        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_VIEW,
                body: {
                    viewId: oldView.id,
                    viewData: oldView
                }
            })
        );
        try {
            await updateViewApi({ dbId, view: newView });
            dispatch(optimisticActions.removeAction({ actionId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            return successCallback && successCallback(newView);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function updateViewAction({ view }) {
    return {
        type: types.UPDATE_VIEW,
        payload: {
            view
        }
    };
}

export function deleteView({ dbId, viewId, successCallback, errorCallback }) {
    return async function(dispatch) {
        const actionId = uuidv1();
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(deleteViewAction({ viewId }));
        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_DELETE_VIEW,
                body: {
                    viewId
                }
            })
        );
        try {
            await deleteViewApi({ dbId, viewId });
            dispatch(optimisticActions.removeAction({ actionId }));
            dispatch(_deleteViewActionSuccess({ viewId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_deleteViewActionFailed({ viewId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function deleteViewAction({ viewId }) {
    return {
        type: types.DELETE_VIEW,
        payload: {
            viewId
        }
    };
}

export function deleteViewRealtimeAction({ viewId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();

        const { currentView, views, dbId, gridId, branchId, workspaceId } = gridUI;
        dispatch(deleteViewAction({ viewId }));
        if (viewId !== currentView?.id) return;

        const lastViews = views.filter(view => view.id !== viewId);
        if (lastViews?.length === 0) {
            dispatch(
                enqueueSnackbar({
                    message: `You don't have the permission to see the access view`,
                    type: 'info'
                })
            );
            history.push(`/projects/${workspaceId}`);
            return;
        }

        const firstView = lastViews?.[0];
        dispatch(
            enqueueSnackbar({
                message: `${currentView?.name} has been deleted`,
                type: 'info'
            })
        );
        history.push(
            `/projects/${workspaceId}/databases/${dbId}/grids/${gridId}/branches/${branchId}/views/${firstView?.id}`
        );
    };
}

function _deleteViewActionSuccess({ viewId }) {
    return {
        type: types.DELETE_VIEW_SUCCESS,
        payload: {
            viewId
        }
    };
}

function _deleteViewActionFailed({ viewId }) {
    return {
        type: types.DELETE_VIEW_FAILED,
        payload: {
            viewId
        }
    };
}

export function updateViewPermissionSocket({ columnId, data }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { viewFilters, viewSorts, quickFilters, quickSorts, dbId, defaultAccessViewId, metaData } = gridUI;
            let filterIdsRelateToColumnId = viewFilters
                .filter(viewFilter => viewFilter?.columnId === columnId)
                .map(viewFilter => viewFilter.id);

            const columnDetail = metaData?.[columnId];
            const columnType = getCorrectColumnType(columnDetail);

            dispatch(viewFilterActions.removeMultipleFilters({ filterIds: filterIdsRelateToColumnId }));
            //clear view Sorts
            let sortIdsRelateToColumnId = viewSorts
                .filter(viewSort => viewSort?.columnId === columnId)
                .map(viewSort => viewSort.id);

            dispatch(viewSortActions.removeMultipleSorts({ sortIds: sortIdsRelateToColumnId }));

            //clear quickFilters, quickSorts local
            dispatch(columnActions.clearQuickFiltersAfterColumnDeletedOrHidden({ columnId }));
            //clear quickSorts local
            dispatch(columnActions.clearQuickSortsAfterColumnDeletedOrHidden({ columnId }));

            dispatch(viewSortActions.removeMultipleSorts({ sortIds: sortIdsRelateToColumnId }));

            //generate new gridUI sorts, views
            const newQuickFilters = { ...quickFilters };
            const newQuickSorts = { ...quickSorts };
            const isHaveQuickFiltersToClear = !isEmpty(newQuickFilters[columnId]);
            const isHaveQuickSortsToClear = !isEmpty(newQuickSorts[columnId]);
            if (isHaveQuickFiltersToClear) {
                delete newQuickFilters[columnId];
            }
            if (isHaveQuickSortsToClear) {
                delete newQuickSorts[columnId];
            }
            if (
                filterIdsRelateToColumnId?.length ||
                sortIdsRelateToColumnId?.length ||
                isHaveQuickFiltersToClear ||
                isHaveQuickSortsToClear
            ) {
                //fetching new section
                await viewFilterActions._fetchRecordsAfterFilter({
                    gridUI: {
                        ...gridUI,
                        quickFilters: newQuickFilters,
                        quickSorts: newQuickSorts
                    },
                    dispatch
                });
            }

            if (!AGGREGATIONS_DISABLED_COLUMNS.includes(columnType)) {
                const quickFiltersFormatted = formatQuickFilters(quickFilters);

                const aggregations = await getCalcViewAggregationsApi({
                    dbId,
                    viewId: defaultAccessViewId,
                    columnIds: [columnId],
                    aggregateTypes: DEFAULT_AGGREGATION_TYPE,
                    quickFilters: quickFiltersFormatted
                });

                const agg = aggregations?.[0];
                const aggregate = {
                    ...agg,
                    value: agg?.result,
                    isFetching: false,
                    type: DEFAULT_AGGREGATION_TYPE
                };

                dispatch(aggregationActions.registerAggregation({ columnId, aggregate }));
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }

        dispatch(_updateViewPermissionSocket({ columnId, data }));
    };
}

export function _updateViewPermissionSocket({ columnId, data }) {
    return {
        type: types.UPDATE_VIEW_COLUMN_PERMISSION_SOCKET,
        payload: {
            columnId,
            data
        }
    };
}

export function deleteViewColumnPermissionSocket({ columnId }) {
    return async function(dispatch, getState) {
        dispatch(_deleteViewColumnPermissionSocket({ columnId }));
        dispatch(gridActions.triggerRecomputedGrid());
    };
}

function _deleteViewColumnPermissionSocket({ columnId }) {
    return {
        type: types.DELETE_VIEW_COLUMN_PERMISSION_SOCKET,
        payload: {
            columnId
        }
    };
}

export function changeViewFixedColumnCount({ index }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, currentView } = gridUI;
        const undoIndex = currentView?.customProperties?.fixedColumnCount;
        if (index === undoIndex) {
            return;
        }
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_changeViewFixedColumnCountAction({ index }));
        const customProperties = currentView?.customProperties || {};
        customProperties.fixedColumnCount = index;

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_CHANGE_FREEZING_INDEX,
                body: {
                    oldIndex: undoIndex
                }
            })
        );

        try {
            await updateViewCustomPropertiesApi({
                dbId,
                viewId: currentView?.id,
                body: {
                    customProperties
                }
            });
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function undoRedoFreezingPosition({ index, oldIndex }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, currentView } = gridUI;

        dispatch(_changeViewFixedColumnCountAction({ index }));

        const customProperties = currentView?.customProperties || {};
        customProperties.fixedColumnCount = index;

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_CHANGE_FREEZING_INDEX,
                body: {
                    oldIndex
                }
            })
        );

        try {
            await updateViewCustomPropertiesApi({
                dbId,
                viewId: currentView?.id,
                body: {
                    customProperties
                }
            });
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function _changeViewFixedColumnCountAction({ index }) {
    return {
        type: types.CHANGE_FREEZING_COLUMN,
        payload: {
            index
        }
    };
}

export function _toggleBlockCurrentView() {
    return {
        type: types.TOGGLE_BLOCK_CURRENT_VIEW
    };
}
