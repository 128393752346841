import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, RadioGroup, Radio, FormControlLabel, CircularProgress } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import InputText from 'components/inputs/InputText';
import { API_KEY_TYPES } from 'const';
import AccessIcon from './common/AccessIcon';
import IOSSwitch from 'components/switches/IOS';
import { HEADER_HEIGHT } from 'const';
import LDBaseCardInfo from 'components/selects/LDBaseCardInfo';
import { useWorkspacePrivilegesForApiKeys } from 'hooks/permission/privilege';
import { useGroupOptions } from 'hooks/permission/group';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';
import { useDispatch } from 'react-redux';
import * as apiKeyActions from '../actions';
import Spinner from 'components/spinner/Base';
import hexToRgba from 'hex-to-rgba';
import { useApiKeyDetailByApiKeyId } from 'hooks/permission/apiKey';
import { makeGroupOption } from 'utils/permission/group';
import { useCanViewGroupShare } from 'hooks/payment';
import * as roleConst from 'auth/roleConst';
import { DISABLED_OPACITY } from 'const/style';
import { useTranslation } from 'react-i18next';
import { sendManualTrack } from 'tracker';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        position: 'relative'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        background: hexToRgba(theme.colors.white, 0.6),
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100
    },
    footer: {
        borderTop: `1px solid ${theme.colors.divider}`,
        padding: theme.spacing(4)
    },
    content: {
        padding: theme.spacing(4),
        height: props =>
            props.apiKeyType === API_KEY_TYPES.FULL || props.apiKeyType === API_KEY_TYPES.READONLY
                ? 'auto'
                : `calc(100vh - ${28 + 2 * theme.spacing(3) + 14 * 2}px - ${HEADER_HEIGHT + 85}px)`,
        overflowY: 'auto'
    },
    mw400: {
        maxWidth: 400
    },
    radio: {
        position: 'relative',
        top: -9
    },
    icon: {
        position: 'relative',
        top: 3
    },
    table: {
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`,
        height: '100%'
    },
    tbHeader: {
        background: theme.colors.ghostwhite,
        padding: `20px 24px`
    },
    tbBody: {
        padding: theme.spacing(4)
    },
    mw500: {
        maxWidth: 500
    },
    dpFlex: {
        display: 'flex'
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    cursor: {
        cursor: 'pointer'
    },
    subPart: {
        paddingLeft: theme.spacing(4),
        borderLeft: `1px solid ${theme.colors.divider}`
    },
    btSp4: {
        marginBottom: theme.spacing(4)
    },
    btSp3: {
        marginBottom: theme.spacing(4)
    },
    w90: {
        width: '90%'
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    expand: {
        flex: 1
    }
}));
const CreateApiKeySchema = object({
    name: string().required('This field is required')
});

function CreateApiKey({ onBack, editedApiKeyId, isDuplicating }) {
    const [apiKeyType, setApiKeyType] = React.useState(API_KEY_TYPES.FULL);
    const [selectedGroups, setSelectedGroups] = React.useState([]);
    const [priIds, setPriIds] = React.useState([]);
    const [isFetchingDetail, setIsFetchingDetail] = React.useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const privileges = useWorkspacePrivilegesForApiKeys();
    const groupOptions = useGroupOptions();
    const classes = useStyles({ apiKeyType });
    const canViewGroupShare = useCanViewGroupShare();

    const apiKeyDetail = useApiKeyDetailByApiKeyId(editedApiKeyId);
    const value = apiKeyDetail?.value;
    const privilegeIds = apiKeyDetail?.privilegeIds;
    const name = apiKeyDetail?.name;
    const groups = apiKeyDetail?.groups;

    const fullAccess = apiKeyDetail?.fullAccess;
    const readOnly = apiKeyDetail?.readOnly;

    const subPrivileges = React.useMemo(() => {
        return privileges?.filter(
            pri =>
                ![roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE, roleConst.COMPANY_AUTHORITIES.MANAGE_TM]?.includes(
                    pri?.name
                )
        );
    }, [privileges]);

    const workspacePrivilege = React.useMemo(() => {
        return privileges?.find(pri => pri?.name === roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE);
    }, [privileges]);

    const tmPrivilege = React.useMemo(() => {
        return privileges?.find(pri => pri?.name === roleConst.COMPANY_AUTHORITIES.MANAGE_TM);
    }, [privileges]);

    const isWsPriOn = React.useMemo(() => {
        return priIds?.includes(workspacePrivilege?.id);
    }, [priIds, workspacePrivilege]);

    const isTmPriOn = React.useMemo(() => {
        return priIds?.includes(tmPrivilege?.id);
    }, [priIds, tmPrivilege]);

    React.useEffect(() => {
        if (value) {
            setIsFetchingDetail(true);
            dispatch(
                apiKeyActions.fetchApiKeyByApiKeyId({
                    apiKeyId: value,
                    successCallback: () => {
                        console.log('fetch api key detail success');
                        setIsFetchingDetail(false);
                    },
                    errorCallback: () => {
                        console.log('fetch api key detail failed');
                        setIsFetchingDetail(false);
                    }
                })
            );
        }
    }, [value, dispatch]);

    const formik = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: CreateApiKeySchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            const body = {
                name: values?.name?.trim(),
                fullAccess: apiKeyType === API_KEY_TYPES.FULL ? true : false,
                privilegeIds: priIds,
                groupIds: selectedGroups?.map(group => group?.id),
                readOnly: apiKeyType === API_KEY_TYPES.READONLY ? true : false
            };

            if (!value) {
                dispatch(
                    apiKeyActions.createApiKey({
                        body,
                        successCallback: () => {
                            console.log('create api key success');
                            sendManualTrack({ type: 'Create API Key' });
                            setSubmitting(false);
                            onBack && onBack();
                        },
                        errorCallback: () => {
                            console.log('create api failed');
                            setSubmitting(false);
                        }
                    })
                );
            } else {
                if (isDuplicating) {
                    dispatch(
                        apiKeyActions.createApiKey({
                            body,
                            successCallback: () => {
                                console.log('create api key success');
                                sendManualTrack({ type: 'Duplicate API Key' });
                                setSubmitting(false);
                                onBack && onBack();
                            },
                            errorCallback: () => {
                                console.log('create api failed');
                                setSubmitting(false);
                            }
                        })
                    );
                } else {
                    dispatch(
                        apiKeyActions.updateApiKey({
                            apiKeyId: value,
                            body,
                            successCallback: () => {
                                console.log('update api key success');
                                sendManualTrack({ type: 'Edit API Key' });
                                setSubmitting(false);
                                onBack && onBack();
                            },
                            errorCallback: () => {
                                console.log('update api failed');
                                setSubmitting(false);
                            }
                        })
                    );
                }
            }
            setStatus({ error: null });
        }
    });
    const {
        errors,
        touched,
        values,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        setFieldValue
    } = formik;

    React.useEffect(() => {
        if (isArray(privilegeIds)) {
            setPriIds(privilegeIds);
        }
    }, [privilegeIds]);

    React.useEffect(() => {
        if (name) {
            setFieldValue('name', name);
        }
    }, [name, setFieldValue]);

    React.useEffect(() => {
        if (isArray(groups)) {
            const selectedGroups = groups?.map(group => {
                const isDisabled = !canViewGroupShare && !group?.systemGroup;
                return makeGroupOption({ group, isDisabled });
            });
            setSelectedGroups(selectedGroups);
        }
    }, [groups, canViewGroupShare]);

    React.useEffect(() => {
        if (typeof fullAccess === 'boolean' && typeof readOnly === 'boolean') {
            if (readOnly) {
                setApiKeyType(API_KEY_TYPES.READONLY);
            } else {
                setApiKeyType(fullAccess ? API_KEY_TYPES.FULL : API_KEY_TYPES?.RESTRICTED);
            }
        }
    }, [fullAccess, readOnly]);

    const handleApiKeyTypeChange = e => {
        setApiKeyType(e?.target?.value);
    };

    const handlePrivilegeChange = ({ value, priId }) => {
        let newPriIds = [...priIds];

        if (value) {
            newPriIds = uniq([...newPriIds, priId]);
        } else {
            newPriIds = newPriIds?.filter(id => id !== priId);
        }
        setPriIds(newPriIds);
    };

    const handleTurnOnAllPrivileges = React.useCallback(
        ({ value, priId }) => {
            if (value) {
                const otherPrivilegeIds = privileges?.filter(pri => pri?.id !== tmPrivilege?.id)?.map(pri => pri?.id);
                const privilegeIds = isTmPriOn ? [...otherPrivilegeIds, tmPrivilege?.id] : otherPrivilegeIds;
                setPriIds(privilegeIds);
            } else {
                setPriIds(priIds?.filter(id => id !== priId));
            }
        },
        [priIds, privileges, tmPrivilege, isTmPriOn]
    );

    const handleGroupsChange = options => {
        setSelectedGroups(options);
    };

    const isValidSubmit = React.useMemo(() => {
        if (apiKeyType === API_KEY_TYPES.READONLY) {
            return isValid;
        } else if (apiKeyType === API_KEY_TYPES.FULL) {
            return isValid;
        } else {
            return isValid && (priIds?.length > 0 || selectedGroups?.length > 0);
        }
    }, [isValid, apiKeyType, priIds, selectedGroups]);

    return (
        <form onSubmit={handleSubmit} className={classes.root}>
            {isFetchingDetail && (
                <div className={classes.overlay}>
                    <Spinner size={20} thick={3} />
                </div>
            )}
            <Grid container direction="column">
                <Grid item className={`${classes.content}`}>
                    <Grid container direction="column" spacing={5} wrap="nowrap">
                        <Grid item className={classes.mw400}>
                            <InputText
                                name="name"
                                label={t('api_key_name_label')}
                                value={values?.name || ''}
                                height={40}
                                disabled={isSubmitting}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.name && touched.name}
                                errorText={errors.name}
                                placeholder={t('api_key_name_placeholder')}
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <Typography variant="body1">{t('api_key_permission')}</Typography>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        aria-label="quiz"
                                        name="quiz"
                                        row
                                        value={apiKeyType}
                                        onChange={handleApiKeyTypeChange}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems="flex-start"
                                            direction="column"
                                            wrap="nowrap"
                                        >
                                            <Grid item>
                                                <FormControlLabel
                                                    value={API_KEY_TYPES.FULL}
                                                    control={<Radio className={classes.radio} />}
                                                    label={
                                                        <>
                                                            <p className="body1 flex gap-1 items-center">
                                                                {t('create_api_key_full_access')}{' '}
                                                                <AccessIcon status={API_KEY_TYPES.FULL} />{' '}
                                                            </p>
                                                            <p className="caption">
                                                                {t('create_api_key_allow_full_access')}
                                                            </p>
                                                        </>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    value={API_KEY_TYPES.READONLY}
                                                    control={<Radio className={classes.radio} />}
                                                    label={
                                                        <>
                                                            <p className="body1 flex gap-1 items-center">
                                                                {t('create_api_key_read_only')}{' '}
                                                                <AccessIcon status={API_KEY_TYPES.READONLY} />{' '}
                                                            </p>
                                                            <p className="caption">
                                                                {t('create_api_key_allow_read_only')}
                                                            </p>
                                                        </>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    value={API_KEY_TYPES.RESTRICTED}
                                                    control={<Radio className={classes.radio} />}
                                                    label={
                                                        <>
                                                            <p className="body1 flex gap-1 items-center">
                                                                {t('create_api_key_restricted_access')}{' '}
                                                                <AccessIcon status={API_KEY_TYPES.RESTRICTED} />
                                                            </p>
                                                            <p className="caption">
                                                                {t('create_api_key_customize_levels')}
                                                            </p>
                                                        </>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        {apiKeyType === API_KEY_TYPES.RESTRICTED && (
                            <Grid item>
                                <Grid container spacing={3} direction="row">
                                    <Grid item className={classes.expand}>
                                        <Grid container direction="column" className={classes.table}>
                                            <Grid item className={classes.tbHeader}>
                                                <Typography variant="body1">{t('global_privileges')}</Typography>
                                            </Grid>
                                            <Grid item className={`${classes.tbBody} ${classes.mw500}`}>
                                                <Grid container direction="column" wrap={'nowrap'}>
                                                    <Grid item key={workspacePrivilege?.id} className={classes.btSp4}>
                                                        <Grid container direction="column" spacing={1}>
                                                            <Grid item>
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    justify="space-between"
                                                                >
                                                                    <Grid item>
                                                                        <Typography variant="body1">
                                                                            {tmPrivilege?.description}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item className={classes.dpFlex}>
                                                                        <IOSSwitch
                                                                            checked={priIds?.includes(tmPrivilege?.id)}
                                                                            onChange={e =>
                                                                                handlePrivilegeChange({
                                                                                    value: e.target?.checked,
                                                                                    priId: tmPrivilege?.id
                                                                                })
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item className={classes.w90}>
                                                                <Typography variant="caption">
                                                                    {tmPrivilege?.extra}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item key={workspacePrivilege?.id} className={classes.btSp4}>
                                                        <Grid container direction="column" spacing={1}>
                                                            <Grid item>
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    justify="space-between"
                                                                >
                                                                    <Grid item>
                                                                        <Typography variant="body1">
                                                                            {workspacePrivilege?.description}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item className={classes.dpFlex}>
                                                                        <IOSSwitch
                                                                            checked={priIds?.includes(
                                                                                workspacePrivilege?.id
                                                                            )}
                                                                            onChange={e =>
                                                                                handleTurnOnAllPrivileges({
                                                                                    value: e.target?.checked,
                                                                                    priId: workspacePrivilege?.id
                                                                                })
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item className={classes.w90}>
                                                                <Typography variant="caption">
                                                                    {workspacePrivilege?.extra}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        <Grid
                                                            className={classes.subPart}
                                                            container
                                                            direction="column"
                                                            wrap="nowrap"
                                                        >
                                                            {subPrivileges.map(privilege => {
                                                                const isChecked = priIds?.includes(privilege?.id);

                                                                return (
                                                                    <Grid
                                                                        item
                                                                        key={privilege.id}
                                                                        className={classes.btSp3}
                                                                    >
                                                                        <Grid container direction="column" spacing={1}>
                                                                            <Grid item>
                                                                                <Grid
                                                                                    container
                                                                                    direction="row"
                                                                                    alignItems="center"
                                                                                    justify="space-between"
                                                                                >
                                                                                    <Grid item>
                                                                                        <Typography variant="body1">
                                                                                            {privilege?.description}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        className={`${classes.dpFlex} ${
                                                                                            isWsPriOn
                                                                                                ? classes.disabled
                                                                                                : ``
                                                                                        }`}
                                                                                    >
                                                                                        <IOSSwitch
                                                                                            checked={isChecked}
                                                                                            onChange={e =>
                                                                                                handlePrivilegeChange({
                                                                                                    value:
                                                                                                        e.target
                                                                                                            ?.checked,
                                                                                                    priId: privilege?.id
                                                                                                })
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item className={classes.w90}>
                                                                                <Typography variant="caption">
                                                                                    {privilege?.extra}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.expand}>
                                        <Grid container direction="column" className={classes.table}>
                                            <Grid item className={classes.tbHeader}>
                                                <Grid container direction="row" spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {t('create_api_key_restricted_by_group_access')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.tbBody}>
                                                <Grid container direction="column" spacing={3}>
                                                    <Grid item>
                                                        <LDBaseCardInfo
                                                            ddPlaceholder={'Select a group'}
                                                            menuPlaceholder={t('global_label_find_a_group')}
                                                            options={groupOptions}
                                                            onChange={handleGroupsChange}
                                                            defaultValue={selectedGroups}
                                                            isMulti={true}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2" display="inline">
                                                            <span aria-label="check" role="img">
                                                                ✔️
                                                            </span>
                                                            Select
                                                        </Typography>{' '}
                                                        <Typography variant="body1" display="inline">
                                                            “{t('full_access')}”
                                                        </Typography>{' '}
                                                        <Typography variant="body2" display="inline">
                                                            group to grant all access.
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <span aria-label="check" role="img">
                                                                ✔️
                                                            </span>{' '}
                                                            Select other groups to grant specific access from groups.
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item className={classes.footer}>
                    <Grid container direction="row" spacing={2} alignItems="center">
                        <Grid item>
                            <ButtonBase onClick={onBack} width={120} variant="outlined">
                                {t('global_cancel')}
                            </ButtonBase>
                        </Grid>
                        <Grid item className={classes.buttonWrapper}>
                            <ButtonBase
                                type="submit"
                                disabled={!isValidSubmit || isSubmitting}
                                width={120}
                                variant="contained"
                            >
                                {t('global_save')}
                            </ButtonBase>
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

export default React.memo(CreateApiKey);
