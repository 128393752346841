import request from './request';
import { APP_API_PREFIX_ORGANIZATION } from '../config';

const apiKeyApis = {
    list: `${APP_API_PREFIX_ORGANIZATION}/api/v1/api-keys`,
    item: apiKeyId => `${APP_API_PREFIX_ORGANIZATION}/api/v1/api-keys/${apiKeyId}`,
    revoke: apiKeyId => `${APP_API_PREFIX_ORGANIZATION}/api/v1/api-keys/${apiKeyId}/revoke`,
    renew: apiKeyId => `${APP_API_PREFIX_ORGANIZATION}/api/v1/api-keys/${apiKeyId}/renew`,
    active: apiKeyId => `${APP_API_PREFIX_ORGANIZATION}/api/v1/api-keys/${apiKeyId}/active`,
    duplicate: apiKeyId => `${APP_API_PREFIX_ORGANIZATION}/api/v1/api-keys/${apiKeyId}/duplicate`
};

export async function fetchApiKeysApi() {
    const response = await request.get(apiKeyApis.list);
    return response.data;
}

export async function createApiKeyApi({ body }) {
    const response = await request.post(apiKeyApis.list, body);
    return response.data;
}

export async function updateApiKeyApi({ apiKeyId, body }) {
    const response = await request.put(apiKeyApis.item(apiKeyId), body);
    return response.data;
}

export async function deleteApiKeyApi({ apiKeyId }) {
    const response = await request.delete(apiKeyApis.item(apiKeyId));
    return response.data;
}

export async function fetchApiKeyByApiKeyApi({ apiKeyId }) {
    const response = await request.get(apiKeyApis.item(apiKeyId));
    return response.data;
}

export async function revokeApiKeyApi({ apiKeyId }) {
    const response = await request.put(apiKeyApis.revoke(apiKeyId));
    return response.data;
}

export async function renewApiKeyApi({ apiKeyId }) {
    const response = await request.put(apiKeyApis.renew(apiKeyId));
    return response.data;
}

export async function duplicateApiKeyApi({ apiKeyId }) {
    const response = await request.put(apiKeyApis.duplicate(apiKeyId));
    return response.data;
}
