import React, { useState } from 'react';
import { useAutomationAddons } from 'hooks/addons';
import AddonList from 'addons/automation/AddonList';
import AutomationAddonDetail from 'addons/automation/AddonDetail';

const STATES = {
    LIST: 'LIST',
    DETAIL: 'DETAIL'
};

function AutomationAddons({ onClose, onAddonRequest }) {
    const automationsAddons = useAutomationAddons();
    const [currentState, setCurrentState] = useState(STATES.LIST);
    const [selectedAddonName, setSelectedAddonName] = useState('');
    const selectedAddon = automationsAddons.find(addon => addon.name === selectedAddonName);
    console.log('selectedAutomationAddon', selectedAddon);

    const handleAddonSelect = addon => {
        setSelectedAddonName(addon.name);
        setCurrentState(STATES.DETAIL);
    };

    switch (currentState) {
        case STATES.LIST:
            return (
                <AddonList
                    onAddonSelect={handleAddonSelect}
                    onAddonRequest={onAddonRequest}
                    addons={automationsAddons}
                />
            );
        case STATES.DETAIL:
            return (
                <AutomationAddonDetail
                    onBack={() => setCurrentState(STATES.LIST)}
                    addon={selectedAddon}
                    onClose={onClose}
                />
            );
        default:
            break;
    }
}

export default React.memo(AutomationAddons);
