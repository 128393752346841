import React from 'react';

function AddUserViewSVG({ color = '#93929e', ...other }) {
    return (
        <svg
            {...other}
            width="19px"
            height="15px"
            viewBox="0 0 19 15"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons/AddUserView" transform="translate(-3.000000, -5.000000)">
                    <g id="Group-24" transform="translate(3.000000, 5.000000)">
                        <g id="Icons/AddAccessView" fill={color}>
                            <g id="Group-23">
                                <g id="Group-14">
                                    <rect id="Rectangle" x="3" y="0" width="1.3" height="7"></rect>
                                    <polygon
                                        id="Rectangle"
                                        transform="translate(3.650000, 3.500000) rotate(-270.000000) translate(-3.650000, -3.500000) "
                                        points="3 -1.13685552e-29 4.3 -4.2862638e-17 4.3 7 3 7"
                                    ></polygon>
                                </g>
                            </g>
                        </g>
                        <path
                            d="M9.83918129,9.64035088 C10.8021491,9.41227956 11.6890798,9.29824561 12.5,9.29824561 C13.3109202,9.29824561 14.1978509,9.41227956 15.1608187,9.64035088 C16.1237865,9.86842219 17.004382,10.2485354 17.8026316,10.7807018 C18.6008812,11.3128682 19,11.9083788 19,12.5672515 L19,15 L6,15 L6,12.5672515 C6,11.9083788 6.39911882,11.3128682 7.19736842,10.7807018 C7.99561803,10.2485354 8.87621351,9.86842219 9.83918129,9.64035088 Z M10.2192982,2.98830409 C10.8528297,2.3294314 11.613056,2 12.5,2 C13.386944,2 14.1471703,2.32309618 14.7807018,2.96929825 C15.4142332,3.61550031 15.7309942,4.38206184 15.7309942,5.26900585 C15.7309942,6.15594985 15.4142332,6.91617617 14.7807018,7.5497076 C14.1471703,8.18323904 13.386944,8.5 12.5,8.5 C11.613056,8.5 10.8528297,8.18323904 10.2192982,7.5497076 C9.58576681,6.91617617 9.26900585,6.15594985 9.26900585,5.26900585 C9.26900585,4.38206184 9.58576681,3.62183553 10.2192982,2.98830409 Z M15.8070175,11.502924 C14.7173435,11.0721226 13.6150153,10.8567251 12.5,10.8567251 C11.3849847,10.8567251 10.2826565,11.0657874 9.19298246,11.4839181 C8.10330839,11.9020489 7.55847953,12.2631564 7.55847953,12.5672515 L7.55847953,13.4415205 L17.4415205,13.4415205 L17.4415205,12.5672515 C17.4415205,12.2631564 16.8966916,11.9083841 15.8070175,11.502924 Z M13.7163743,4.05263158 C13.3869379,3.72319523 12.9814839,3.55847953 12.5,3.55847953 C12.0185161,3.55847953 11.6130621,3.72319523 11.2836257,4.05263158 C10.9541894,4.38206792 10.7894737,4.78752196 10.7894737,5.26900585 C10.7894737,5.75048974 10.9541894,6.14960855 11.2836257,6.46637427 C11.6130621,6.78313999 12.0185161,6.94152047 12.5,6.94152047 C12.9814839,6.94152047 13.3869379,6.78313999 13.7163743,6.46637427 C14.0458106,6.14960855 14.2105263,5.75048974 14.2105263,5.26900585 C14.2105263,4.78752196 14.0458106,4.38206792 13.7163743,4.05263158 Z"
                            id="p"
                            fill={color}
                            fillRule="nonzero"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default React.memo(AddUserViewSVG);
