import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import AutomationSelectSourceTarget from './language/AutomationSelectSourceTarget';
import MapTranslatedColumn from './MapTranslatedColumn';
import Payloadv2 from './Payloadv2';
import { WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import Checkbox from 'components/checkbox/Base';
import { useDispatch } from 'react-redux';
import { updateActionNode } from 'gridUI/automations/action';
import { useGetAutomationDetail } from 'hooks/gridUI/automation';
import * as columnTypes from 'const/columnTypes';

const useStyles = makeStyles(theme => ({
    mb12: {
        marginBottom: 12
    },
    mb14: {
        marginBottom: 14
    },
    mb24: {
        marginBottom: 24
    },
    editor: {
        maxHeight: 280,
        height: 'unset'
    },
    checkbox: {
        padding: `0 !important`,
        marginRight: 8
    },
    actionHint: {
        color: theme.colors.steel,
        fontSize: 13
    }
}));

const ActionGoogleTranslate = ({ selectedNode = {}, setSelectedNode }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [formality, setFormality] = useState(Boolean(selectedNode?.params?.formality));
    const [profanity, setProfanity] = useState(Boolean(selectedNode?.params?.profanity));
    const timeout = useRef();
    const automationDetail = useGetAutomationDetail();

    useEffect(() => {
        setFormality(Boolean(selectedNode?.params?.formality));
        setProfanity(Boolean(selectedNode?.params?.profanity));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const handleChangeCheckbox = useCallback(
        ({ key, value }) => {
            const setStateFn = key === 'formality' ? setFormality : setProfanity;
            setStateFn(Boolean(value));
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                [key]: value
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setStateFn(
                                Boolean(
                                    key === 'formality'
                                        ? selectedNode?.params?.formality
                                        : selectedNode?.params?.profanity
                                )
                            );
                        }
                    })
                );
            }, 100);
        },
        [automationDetail.id, dispatch, selectedNode.id, selectedNode.params, setSelectedNode]
    );

    return (
        <Grid item>
            <Grid item className={classes.mb12}>
                <Payloadv2
                    isRequired
                    label={t('automation_text_to_translate')}
                    placeholder={t('automation_text_to_translate_placeholder')}
                    editorClassName={classes.editor}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                />
            </Grid>
            <Grid item className={classes.mb24}>
                <p className="body2">{t('automation_text_to_translate_hint')}</p>
            </Grid>
            <Grid item className={classes.mb24}>
                <AutomationSelectSourceTarget selectedNode={selectedNode} setSelectedNode={setSelectedNode} />
            </Grid>
            <Grid item className={classes.mb24}>
                {selectedNode.app === WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE && (
                    <>
                        <Grid item className={classes.mb12} />
                        <Grid item container justifyContent="space-between" className={classes.mb24}>
                            <Grid item>
                                <Grid container wrap="nowrap" alignItems="center">
                                    <Grid item>
                                        <Checkbox
                                            className={classes.checkbox}
                                            onChange={e =>
                                                handleChangeCheckbox({
                                                    key: 'profanity',
                                                    value: e.target.checked ? 'MASK' : ''
                                                })
                                            }
                                            checked={profanity}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <p className="body2">Use Profanity Masking when available</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container wrap="nowrap" alignItems="center">
                                    <Grid item>
                                        <Checkbox
                                            className={classes.checkbox}
                                            onChange={e =>
                                                handleChangeCheckbox({
                                                    key: 'formality',
                                                    value: e.target.checked ? 'FORMAL' : ''
                                                })
                                            }
                                            checked={formality}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <p className="body2">Use Formal Language when available</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item className={classes.mb12}>
                <MapTranslatedColumn
                    label={t('automation_select_column_to_put_result_in')}
                    isRequired
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                    filterColumns={[...columnTypes.AUTOMATION_TEXT_COLUMNS, columnTypes.DATETIME, columnTypes.NUMBER]}
                />
            </Grid>
            <Grid item>
                <p className="body2">{t('automation_select_column_to_put_result_in_hint')}</p>
            </Grid>
        </Grid>
    );
};

export default React.memo(ActionGoogleTranslate);
