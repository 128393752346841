import React, { useCallback, useState } from 'react';
import InviteMembersForm from './InviteMembersForm';
import InviteMemberSuccess from './InviteMemberSuccess';

const MODAL_TYPE = {
    INVITE: 'INVITE',
    SUCCESS: 'SUCCESS'
};

const InviteMembersDialog = ({ isLdManager, setPaperFullWidth, onClose }) => {
    const [modalType, setModalType] = useState(MODAL_TYPE.INVITE);

    const navigateToInvite = useCallback(() => {
        setModalType(MODAL_TYPE.INVITE);
    }, []);

    const navigateToSuccess = useCallback(() => {
        setPaperFullWidth(true);
        setModalType(MODAL_TYPE.SUCCESS);
    }, [setPaperFullWidth]);

    if (modalType === MODAL_TYPE.SUCCESS) {
        return <InviteMemberSuccess navigateToInvite={navigateToInvite} onClose={onClose} />;
    }

    return (
        <InviteMembersForm
            isLdManager={isLdManager}
            setPaperFullWidth={setPaperFullWidth}
            navigateToSuccess={navigateToSuccess}
            onClose={onClose}
        />
    );
};

export default React.memo(InviteMembersDialog);
