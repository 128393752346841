import { SYSTEM_COLUMN_IDS, WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import { getHookTypeLabel, WEB_HOOK_TRIGGERS_ACTIONS_LABEL, WEB_HOOK_TRIGGER_ACTIONS } from 'const/gridUI';
import {
    AUTOMATION_COLUMN_VARIABLES,
    DYNAMIC_PAYLOAD_VARIABLE,
    MAP_TRIGGER_NAME,
    STATIC_PAYLOAD_VARIABLES,
    DYNAMIC_TICKET_PAYLOAD_VARIABLE,
    AUTOMATION_STATUS
} from 'gridUI/automations/const';
import { upperFirst } from 'lodash';
import { useSelector } from 'react-redux';
import { useSelectPublicColumnIds, useViewColumnWidthMetadata } from './column';

export function useGetAutomations() {
    return useSelector(state => state.gridUI.automations);
}

export function useIsHightlightAutomations() {
    const automations = useGetAutomations();
    return !!automations?.length;
}

export function useGetIsFetchingAutomations() {
    return useSelector(state => state.gridUI.isFetchingAutomations);
}

export function useGetAutomationDetail() {
    return useSelector(state => state.gridUI.automationDetail);
}

export function useOpenAutomationDetail() {
    return useSelector(state => state.gridUI.openAutomationDetail);
}

export function useGetAutomationDetailError(key, childKey) {
    return useSelector(state => {
        const automationDetailError = state.gridUI.automationDetailError;
        if (key) {
            if (childKey) {
                return automationDetailError?.[key]?.[childKey];
            }
            return automationDetailError?.[key] || {};
        }
        return automationDetailError || {};
    });
}

const getTreeDynamicTrigger = ({
    dynamicVariables,
    prefix,
    prefixServerParam,
    array,
    commonObj,
    viewColumnOptions
}) => {
    dynamicVariables.map(el => {
        if (typeof el === 'string') {
            array.push({
                id: `${prefix}.${el}`,
                name: el,
                serverParam: `${prefixServerParam}.${el}`,
                ...commonObj
            });
        } else if (typeof el === 'object') {
            const options = [];
            if (el?.variables?.length) {
                if (el?.indexType === 'normalColumns') {
                    viewColumnOptions.map(col => {
                        const columnPrefix = `${prefix}.${col.publicId}`;
                        const columnServerPrefix = `${prefixServerParam}.${col.publicId}`;
                        const opts = [];
                        getTreeDynamicTrigger({
                            dynamicVariables: el.variables,
                            prefix: columnPrefix,
                            prefixServerParam: columnServerPrefix,
                            array: opts,
                            commonObj
                        });
                        options.push({
                            id: columnPrefix,
                            serverParam: columnServerPrefix,
                            name: col.publicId,
                            options: opts,
                            ...commonObj
                        });
                        return false;
                    });
                }
            }
            array.push({
                id: `${prefix}.${el.label}`,
                serverParam: `${prefixServerParam}.${el.label}`,
                name: el.label,
                options,
                ...commonObj
            });
        }
        return false;
    });
};

const getCommonOptions = ({ prefixData, prefixServerParam, watchingColumns, commonObj }) => {
    if (!watchingColumns?.length || watchingColumns.length > 1) return [];
    return AUTOMATION_COLUMN_VARIABLES.map(variable => ({
        id: `${prefixData}.${watchingColumns[0]}.${variable}`,
        name: `${watchingColumns[0]}.${variable}`,
        serverParam: `${prefixServerParam}.${watchingColumns[0]}.${variable}`,
        ...commonObj
    }));
};

const getOptionsSimpleMode = ({ triggerType, viewColumnOptions, triggerRawDataName, serverRawDataParam }) => {
    switch (triggerType) {
        case 'RECORD':
            return viewColumnOptions?.map(col => ({
                id: `${triggerRawDataName}.${col.publicId}`,
                name: col.name,
                serverParam: `${serverRawDataParam}.${col.publicId}.value`,
                isDisplayColumnIcon: true,
                column: col
            }));
        case 'COLUMN':
            return ['id', 'name', 'type', 'order'].map(variable => {
                const variableName = `Column ${upperFirst(variable).replace('Id', 'ID')}`;
                return {
                    id: `${triggerRawDataName}.${variable}`,
                    name: variableName,
                    serverParam: `${serverRawDataParam}.${variable}`
                };
            });
        case 'TICKET':
            return DYNAMIC_TICKET_PAYLOAD_VARIABLE.map(variable => {
                const variableName = `Ticket ${upperFirst(variable)
                    .match(/[A-Z][a-z]+/g)
                    .join(' ')
                    .replace('Id', 'ID')}`;
                return {
                    id: `${triggerRawDataName}.${variable}`,
                    name: variableName,
                    serverParam: `${serverRawDataParam}.${variable}`
                };
            });
        case 'MANUAL':
            return ['time'].map(variable => {
                return {
                    id: `${triggerRawDataName}.${variable}`,
                    name: upperFirst(variable),
                    serverParam: `${serverRawDataParam}.${variable}`
                };
            });
        default:
            return [];
    }
};

export function useAutomationPayloadTreeOptions(advancedMode = true) {
    const automationDetail = useGetAutomationDetail();
    const viewColumns = useViewColumnWidthMetadata();
    const viewColumnOptions = viewColumns.filter(col => !SYSTEM_COLUMN_IDS?.includes(col.id) && !!col.name);

    if (!advancedMode) {
        const options = automationDetail?.triggerNodes?.reduce((acc, cur) => {
            const triggerName = MAP_TRIGGER_NAME[cur.trigger];
            const serverParam = `trigger${cur.id}`;
            const triggerRawDataName = `${triggerName}.rawData`;
            const serverRawDataParam = `${serverParam}.rawData`;
            const trigger = {
                id: cur.trigger,
                nodeId: cur.id,
                serverParam,
                nodeOrder: cur.order,
                trigger: cur.trigger,
                name: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[cur.trigger],
                isTrigger: true,
                status: cur.status,
                options: getOptionsSimpleMode({
                    triggerType: cur?.trigger?.split('_')?.[0],
                    viewColumnOptions,
                    triggerRawDataName,
                    serverRawDataParam
                })
            };
            acc.push(trigger);
            return acc;
        }, []);
        if (options?.length > 0) {
            return options;
        }
    }

    return automationDetail?.triggerNodes?.reduce((acc, cur) => {
        const triggerName = MAP_TRIGGER_NAME[cur.trigger];
        const serverParam = `trigger${cur.id}`;
        const commonObj = {
            triggerId: cur.id,
            trigger: cur.trigger,
            nodeOrder: cur.order
        };
        const staticOptions = STATIC_PAYLOAD_VARIABLES.reduce((accVariable, curVariable) => {
            const prefix = `${triggerName}.${curVariable.label}`;
            const prefixServerParam = `${serverParam}.${curVariable.label}`;
            accVariable.push({
                id: prefix,
                serverParam: `${serverParam}.${curVariable.label}`,
                name: curVariable.label,
                ...commonObj,
                options: curVariable.variables.map(el => {
                    return {
                        id: `${prefix}.${el}`,
                        name: el,
                        serverParam: `${prefixServerParam}.${el}`,
                        ...commonObj
                    };
                })
            });
            return accVariable;
        }, []);
        const prefixData = `${triggerName}.rawData`;
        const prefixServerParam = `${serverParam}.rawData`;
        const treeDynamicTriggerOptions = [];
        getTreeDynamicTrigger({
            dynamicVariables: DYNAMIC_PAYLOAD_VARIABLE[cur.trigger],
            prefix: prefixData,
            prefixServerParam,
            array: treeDynamicTriggerOptions,
            commonObj,
            viewColumnOptions
        });
        const dynamicOption = {
            id: prefixData,
            name: 'rawData',
            serverParam: prefixServerParam,
            options: treeDynamicTriggerOptions,
            ...commonObj
        };
        const trigger = {
            id: cur.trigger,
            nodeId: cur.id,
            serverParam,
            nodeOrder: cur.order,
            trigger: cur.trigger,
            name: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[cur.trigger],
            isTrigger: true,
            status: cur.status,
            options: [...staticOptions, dynamicOption],
            commonOptions: getCommonOptions({
                prefixData,
                prefixServerParam,
                watchingColumns: cur.watchingColumns,
                commonObj
            })
        };
        acc.push(trigger);
        return acc;
    }, []);
}

const _getNodeTreeOptions = ({ node, serverParam }) => {
    const options = [];
    if (node.params?.outputFields?.length) {
        const outputFields = node.params?.outputFields?.filter(field => !!field?.trim());
        outputFields.forEach(outputField => {
            options.push({
                id: `${serverParam}.${outputField}`,
                name: `(Output Fields) - ${outputField}`,
                serverParam: `${serverParam}.${outputField}`,
                nodeId: node.id,
                app: node.app,
                nodeOrder: node.order,
                isDisplayname: true
            });
        });
    }
    if (
        [
            WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE,
            WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR,
            WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE,
            WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND
        ].includes(node.app)
    ) {
        const id = `${serverParam}.result`;
        options.push({
            id,
            name: '(Translate Result) - result',
            serverParam: id,
            nodeId: node.id,
            app: node.app,
            nodeOrder: node.order,
            isDisplayname: true
        });
    }
    if ([WEB_HOOK_EXTERNAL_SYSTEMS.JIRA].includes(node.app)) {
        const id = `${serverParam}`;
        ['id', 'key', 'self'].forEach(key => {
            options.push({
                id: `${id}.${key}`,
                name: `(Created Ticket) - ${key}`,
                serverParam: `${id}.${key}`,
                nodeId: node.id,
                app: node.app,
                nodeOrder: node.order,
                isDisplayname: true
            });
        });
    }
    return options;
};

const _getSimpleNodeTreeOptions = ({ node, serverParam }) => {
    const options = [];
    if (node.params?.outputFields?.length) {
        const outputFields = node.params?.outputFields?.filter(field => !!field?.trim());
        outputFields.forEach(outputField => {
            options.push({
                id: `${serverParam}.${outputField}`,
                name: `(Output Fields) - ${outputField}`,
                serverParam: `${serverParam}.${outputField}`,
                nodeId: node.id,
                app: node.app,
                nodeOrder: node.order,
                isDisplayname: true
            });
        });
    }
    if (
        [
            WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE,
            WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR,
            WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE,
            WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND
        ].includes(node.app)
    ) {
        const id = `${serverParam}.result`;
        options.push({
            id,
            name: '(Translate Result) - result',
            serverParam: id,
            nodeId: node.id,
            app: node.app,
            nodeOrder: node.order,
            isDisplayname: true
        });
    }
    if ([WEB_HOOK_EXTERNAL_SYSTEMS.JIRA].includes(node.app)) {
        const id = `${serverParam}`;
        ['id', 'key', 'self'].forEach(key => {
            options.push({
                id: `${id}.${key}`,
                name: `(Created Ticket) - ${key}`,
                serverParam: `${id}.${key}`,
                nodeId: node.id,
                app: node.app,
                nodeOrder: node.order,
                isDisplayname: true
            });
        });
    }
    return options;
};

export function useAutomationNodeTreeOptions(advancedMode = true) {
    const automationDetail = useGetAutomationDetail();
    const { nodes } = automationDetail;

    if (!advancedMode) {
        const options = [];
        nodes.forEach(node => {
            const serverParam = `node${node.id}`;
            const nodeOptions = _getSimpleNodeTreeOptions({ node, serverParam });
            if (nodeOptions?.length === 1) {
                options.push({
                    id: node.id,
                    nodeOrder: node.order,
                    app: node.app,
                    name: nodeOptions[0].name,
                    serverParam: nodeOptions[0].serverParam
                });
            } else if (nodeOptions.length > 1) {
                options.push({
                    id: node.id,
                    nodeOrder: node.order,
                    app: node.app,
                    name: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS[node.app]),
                    serverParam,
                    options: nodeOptions
                });
            }
        });
        return options;
    }

    return nodes
        .map(node => {
            const serverParam = `node${node.id}`;
            return {
                id: node.id,
                nodeOrder: node.order,
                app: node.app,
                name: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS[node.app]),
                serverParam,
                options: _getNodeTreeOptions({ node, serverParam })
            };
        })
        .filter(node => node.options.length > 0);
}

export function useListAutomationExecutions() {
    return useSelector(state => state.gridUI.listAutomationExecutions);
}

export function useIsShowAutomationExecutions() {
    return useSelector(state => state.gridUI.listAutomationExecutions?.length > 0);
}

export function useListAutomationExecutionsRunning() {
    return useSelector(state => state.gridUI.listAutomationExecutions?.filter(e => e.executionStatus === 'STARTED'));
}

export function useListAutomationExecutionsRunningLength() {
    return useSelector(
        state => state.gridUI.listAutomationExecutions?.filter(e => e.executionStatus === 'STARTED')?.length || 0
    );
}

export function useIsAutomationExecutionsRunning() {
    return useSelector(
        state => state.gridUI.listAutomationExecutions?.filter(e => e.executionStatus === 'STARTED').length > 0
    );
}

export function useIsAutomationExecuting(automationId) {
    return useSelector(state => {
        const { gridUI } = state;
        const { listAutomationExecutions } = gridUI;
        const automation = listAutomationExecutions?.find(e => e.automationId === automationId);
        return automation?.executionStatus === 'STARTED';
    });
}

export function useAutomationLambdas() {
    return useSelector(state => state.gridUI.lambdas);
}

export function useMatchAutomations(defaultColumnIds = []) {
    const selectedColumnIds = useSelectPublicColumnIds();

    const columnIds = defaultColumnIds?.length > 0 ? defaultColumnIds : selectedColumnIds;

    const animations = useGetAutomations();

    const matches = animations?.filter(a => {
        if (a?.status !== AUTOMATION_STATUS.ACTIVE) return false;

        const triggers = a?.automationTriggers?.filter(tr =>
            [
                WEB_HOOK_TRIGGER_ACTIONS.RECORD_CREATED,
                WEB_HOOK_TRIGGER_ACTIONS.RECORD_DELETED,
                WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED
            ]?.includes(tr.trigger)
        );

        let watchColumnIds = [];

        for (const trigger of triggers) {
            if (trigger.watchingAll) return true;

            watchColumnIds = watchColumnIds.concat(trigger?.watchingColumns);
        }

        watchColumnIds = [...new Set(watchColumnIds)];

        if (!watchColumnIds?.length) return false;

        const isMatchColumn = watchColumnIds?.some(columnId => columnIds?.includes(columnId));

        return triggers?.length && isMatchColumn;
    });

    return matches;
}

export function useIsOpenMultipleAutomationDialog() {
    return useSelector(state => state.gridUI.isOpenAnimationDialog);
}
