import * as types from '../types';

const handler = {
    [types.SHOW_AUTO_QA_ERRORS](state) {
        return {
            ...state,
            isShowAutoQA: true
        };
    },
    [types.HIDE_AUTO_QA_ERRORS](state) {
        return {
            ...state,
            isShowAutoQA: false
        };
    },
    [types.OPEN_ERROR_CONTROL_PANEL](state) {
        return {
            ...state,
            showErrorControlPanel: true
        };
    },
    [types.CLOSE_ERROR_CONTROL_PANEL](state) {
        return {
            ...state,
            showErrorControlPanel: false
        };
    },
    [types.TOGGLE_ERROR_TYPE](state, { payload }) {
        const newAutoQAErrorTypes = state.autoQAErrorTypes.map(type => {
            if (type.value === payload.value) {
                type.isActive = !type.isActive;
            }
            return type;
        });
        return {
            ...state,
            autoQAErrorTypes: newAutoQAErrorTypes
        };
    },
    [types.TOGGLE_ALL_ERROR_TYPE](state, { payload }) {
        const newAutoQAErrorTypes = state.autoQAErrorTypes.map(type => {
            return { ...type, isActive: !payload };
        });
        return {
            ...state,
            autoQAErrorTypes: newAutoQAErrorTypes
        };
    },
    [types.SET_IS_RUNNING_AUTO_QA](state, { payload }) {
        return {
            ...state,
            isRunningAutoQA: payload
        };
    },
    [types.GET_AUTOQA_SETTINGS_SUCCESS](state, { payload }) {
        const { autoQASettings } = payload;
        return {
            ...state,
            autoQASettings
        };
    },
    [types.CREATE_AUTOQA_SETTING_SUCCESS](state, { payload }) {
        const { autoQASetting } = payload;
        return {
            ...state,
            autoQASettings: [...state.autoQASettings, autoQASetting]
        };
    },
    [types.UPDATE_AUTOQA_SETTING_SUCCESS](state, { payload }) {
        const { autoQASetting } = payload;
        const newAutoQASettings = state.autoQASettings.map(setting => {
            if (setting.id === autoQASetting.id) {
                setting = autoQASetting;
            }
            return setting;
        });
        return {
            ...state,
            autoQASettings: newAutoQASettings
        };
    },
    [types.DELETE_AUTOQA_SETTING_SUCCESS](state, { payload }) {
        const { settingId } = payload;
        const newAutoQASettings = state.autoQASettings.filter(setting => setting.id !== settingId);
        return {
            ...state,
            autoQASettings: newAutoQASettings
        };
    },
    [types.OPEN_IGNORE_SIMILAR_ERRORS_CONFIRM](state, action) {
        const { selectedValidation } = action.payload;
        return {
            ...state,
            ignoreSimilarErrors: {
                ...state.ignoreSimilarErrors,
                selectedValidation,
                isOpenConfirmModal: true
            }
        };
    },
    [types.CLOSE_IGNORE_SIMILAR_ERRORS_CONFIRM](state, action) {
        return {
            ...state,
            ignoreSimilarErrors: {
                ...state?.ignoreSimilarErrors,
                selectedValidation: null,
                isOpenConfirmModal: false
            }
        };
    }
};

export default handler;
