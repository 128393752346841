import React from 'react';
import { Grid, Typography, CircularProgress, IconButton, Divider } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TemplateDefaultPNG from 'assets/images/templateDefault.png';
import AvatarBase from 'components/avatar/Base';
import { getAvatarUrl } from 'utils/images';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import IconGridlySVG from 'assets/images/svg/IconGridlySVG';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import ButtonBase from 'components/button/Base';
// import GridTablePreviewSVG from 'assets/images/svg/GridTablePreviewSVG';
import { GRID_STATUS } from 'const/gridUI';
import { useDispatch } from 'react-redux';
import * as gridActions from 'grids/actions';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { sendManualTrack } from 'tracker';
import { getGridlyApiPrefix } from 'api/url';
import { useTranslation } from 'react-i18next';
import { ADD_ONS_OBJECT, automationAddonsKey } from 'const/inAppAddon';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        minWidth: 500,
        maxWidth: 750,
        position: 'relative'
    },
    card: {
        borderRadius: 0
    },
    cardHeader: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    cardContent: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    mediaContainer: {
        marginRight: theme.spacing(4),
        width: 256,
        height: 130
    },
    media: {
        height: '100%',
        width: '100%',
        backgroundSize: 'contain'
    },
    description: {
        paddingTop: theme.spacing(5)
    },
    gridView: {
        height: 425
    },
    relative: {
        position: 'relative'
    },
    previewPending: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    },
    buttonWrapper: {
        position: 'relative',
        alignSelf: 'flex-start',
        marginTop: theme.spacing(2),
        width: '100%'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    close: {
        position: 'absolute',
        top: 8,
        right: 8
    },
    italic: {
        fontStyle: 'italic'
    }
}));

function GridTemplateDetail({
    name,
    createdBy,
    alteredBy,
    dbId,
    id,
    onOpenChoosingWorkspace,
    status,
    description,
    coveredImagePath,
    customProperties = {},
    templateSource,
    template,
    onClose,
    isShortView,
    duplicateGroupAccessOption,
    duplicateRecordOption,
    compositeGridId,
    duplicateAutomationOption,
    automationApps
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const { howItWorks, proTips } = customProperties;

    const requiredAutomationAddons = React.useMemo(() => {
        const keys = automationApps?.filter(el => automationAddonsKey?.includes(el));
        return keys?.map(el => ADD_ONS_OBJECT[el]) || [];
    }, [automationApps]);

    const handleCreateTemplate = React.useCallback(() => {
        setIsSubmitting(true);
        dispatch(
            gridActions.createGridFromTemplate({
                dbId,
                template,
                successCallback: () => {
                    onClose && onClose();
                    console.log('create database from template success');
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('create database from template failed');
                }
            })
        );
    }, [dispatch, dbId, template, onClose]);

    const handleUseTemplate = React.useCallback(() => {
        sendManualTrack({ type: 'Use Template' });
        if (dbId) {
            handleCreateTemplate();
        } else {
            onOpenChoosingWorkspace && onOpenChoosingWorkspace(id);
        }
    }, [dbId, id, onOpenChoosingWorkspace, handleCreateTemplate]);

    const isActive = React.useMemo(() => {
        return status === GRID_STATUS.ACTIVE;
    }, [status]);

    const coverImageUrl = coveredImagePath ? `${getGridlyApiPrefix}${coveredImagePath}` : TemplateDefaultPNG;

    return (
        <Grid container direction="column" className={classes.content}>
            {isShortView && (
                <IconButton className={classes.close} onClick={onClose}>
                    <CloseIconSVG color={theme.colors.white} />
                </IconButton>
            )}
            <Grid item>
                <Card elevation={0} className={classes.card}>
                    <Grid container className={classes.cardHeader} direction="row" wrap="nowrap">
                        <Grid item className={classes.mediaContainer}>
                            <CardMedia className={classes.media} image={coverImageUrl} />
                        </Grid>
                        <Grid item>
                            <Grid container wrap="nowrap" direction="column" spacing={3}>
                                <Grid item className={classes.name}>
                                    <Typography variant="h4" className={classes.ellipsisText}>
                                        {name}
                                    </Typography>
                                    <Typography variant="caption" className={classes.ellipsisText}>
                                        ID: {compositeGridId}
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    {templateSource === 'gridly' ? (
                                        <Grid container spacing={2} direction="row" alignItems="center">
                                            <Grid item>
                                                <AvatarBase color={'#4D3F8A'} size={24} alt="gridly">
                                                    <IconGridlySVG style={{ width: '18px', height: 'auto' }} />
                                                </AvatarBase>
                                            </Grid>
                                            <Grid item>Gridly</Grid>
                                        </Grid>
                                    ) : (
                                        <Grid container spacing={2} direction="row" alignItems="center">
                                            <Grid item>
                                                <AvatarBase
                                                    src={getAvatarUrl(createdBy || alteredBy)}
                                                    size={24}
                                                    alt="avatar"
                                                >
                                                    <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                                </AvatarBase>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{createdBy || alteredBy}</Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" spacing={4} alignItems="center">
                                        {duplicateRecordOption === 'include' && (
                                            <Grid item>
                                                <Grid container direction="row" alignItems="center" spacing={1}>
                                                    <Grid item>
                                                        <CheckIconSVG />
                                                    </Grid>
                                                    <Grid item>{t('global_records')}</Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Grid container direction="row" alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <CheckIconSVG />
                                                </Grid>
                                                <Grid item>{t('global_views')}</Grid>
                                            </Grid>
                                        </Grid>
                                        {duplicateGroupAccessOption === 'include' && (
                                            <Grid item>
                                                <Grid container direction="row" alignItems="center" spacing={1}>
                                                    <Grid item>
                                                        <CheckIconSVG />
                                                    </Grid>
                                                    <Grid item>{t('shared_groups')}</Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {duplicateAutomationOption === 'include' && (
                                            <Grid item>
                                                <Grid container direction="row" alignItems="center" spacing={1}>
                                                    <Grid item>
                                                        <CheckIconSVG />
                                                    </Grid>
                                                    <Grid item>{t('global_automation')}</Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                {requiredAutomationAddons?.length > 0 && (
                                    <>
                                        <Divider className="!my-1" />
                                        <div>
                                            <p className="text-error text-xs mb-1">
                                                *You have to install these add-on before using the template:
                                            </p>
                                            <div className="grid grid-cols-2 gap-[14px]">
                                                {requiredAutomationAddons.map(addon => (
                                                    <div
                                                        key={addon.name}
                                                        className="flex flex-nowrap gap-1 items-center"
                                                    >
                                                        <div>{addon.icon(24)}</div>
                                                        <p className="flex-1 text-xs truncate">{addon.title}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <CardContent className={classes.cardContent}>
                        <Grid container wrap="nowrap" direction="column" spacing={3}>
                            <Grid item>
                                <Typography variant="body1">{t('global_description')}</Typography>
                            </Grid>
                            <Grid item>
                                {description ? (
                                    <Typography variant="body2">{description}</Typography>
                                ) : (
                                    <Typography className={classes.italic} variant="caption">
                                        {t('no_description')}
                                    </Typography>
                                )}
                            </Grid>
                            {howItWorks && (
                                <>
                                    <Grid item>
                                        <Typography variant="body1">How it works</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            <span dangerouslySetInnerHTML={{ __html: howItWorks }} />
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            {proTips && (
                                <>
                                    <Grid item>
                                        <Typography variant="body1">Pro tip</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            <span dangerouslySetInnerHTML={{ __html: proTips }} />
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            <Grid item container justify="center" className={classes.buttonWrapper}>
                                <ButtonBase
                                    width={120}
                                    onClick={handleUseTemplate}
                                    disabled={isSubmitting || !isActive}
                                    variant="contained"
                                    color="primary"
                                >
                                    {t('use_template')}
                                </ButtonBase>
                                {(isSubmitting || !isActive) && (
                                    <CircularProgress size={24} className={classes.buttonProgress} />
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default GridTemplateDetail;
