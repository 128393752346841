import React, { useMemo } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import FilterItem from './FilterItem';
import CircleAddIconSVG from 'assets/images/svg/CircleAddIconSVG';
import Tooltip from 'components/tooltip/Base';
import { DISABLED_OPACITY } from 'const/style';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2)
    },
    group: {
        padding: 12,
        background: theme.colors.background,
        borderRadius: 4
    },
    filterItem: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    and: {
        position: 'relative',
        padding: '20px 0',
        '& p': {
            color: theme.palette.primary.main
        },
        '& svg': {
            verticalAlign: 'text-bottom'
        },
        '&.action': {
            cursor: 'pointer'
        },
        '&.readOnly': {
            cursor: 'unset',
            opacity: DISABLED_OPACITY
        }
    },
    lineTop: {
        position: 'absolute',
        width: 1,
        height: 20,
        padding: '0 !important',
        top: 0,
        background: theme.colors.silver
    },
    lineBottom: {
        position: 'absolute',
        width: 1,
        height: 20,
        padding: '0 !important',
        bottom: 0,
        background: theme.colors.silver
    },
    mr8: {
        marginRight: 8
    }
}));

function FilterList({
    t,
    onCreated,
    viewFilters,
    addAndFilter,
    addOrFilter,
    metaData,
    dependencies,
    onDeleteFilter,
    onUpdateFilter,
    columns,
    isReadOnly
}) {
    const classes = useStyles();

    const groupViewFilters = useMemo(() => {
        if (viewFilters.length) {
            return viewFilters.reduce((acc, cur) => {
                const idx = acc.findIndex(g => g.groupId === cur.groupId);
                if (idx < 0) {
                    acc.push({
                        groupId: cur.groupId || cur.id,
                        views: [cur]
                    });
                } else {
                    acc[idx].views.push(cur);
                }
                return acc;
            }, []);
        }
        return [];
    }, [viewFilters]);

    if (viewFilters.length === 0)
        return (
            <Grid container className={classes.root}>
                <Typography variant="caption">{t(`view_filter_click_to_create`)}</Typography>
            </Grid>
        );
    return (
        <Grid container direction="column" className={classes.root}>
            {groupViewFilters.map((group, idx) => {
                const isLastGroup = idx === groupViewFilters.length - 1;
                return (
                    <>
                        <Grid key={group.groupId} item className={classes.group}>
                            {group.views.map((filter, index) => {
                                const isLastFilter = index === group.views.length - 1;
                                return (
                                    <Grid item className={classes.filterItem} key={filter.id}>
                                        <FilterItem
                                            t={t}
                                            dependencies={dependencies}
                                            handleOr={isLastFilter ? addOrFilter : undefined}
                                            metaData={metaData}
                                            columns={columns}
                                            {...filter}
                                            onUpdateFilter={onUpdateFilter}
                                            onDeleteFilter={onDeleteFilter}
                                            isReadOnly={isReadOnly}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        {!isLastGroup && (
                            <Grid className={classes.and} container justify="center" alignItems="center">
                                <Grid item>
                                    <Typography variant="body1">{t('global_and')}</Typography>
                                </Grid>
                                <Grid item className={classes.lineTop} />
                                <Grid item className={classes.lineBottom} />
                            </Grid>
                        )}
                        {isLastGroup && (
                            <Tooltip
                                title={
                                    isReadOnly ? (
                                        <Grid container className={classes.permissionTooltip}>
                                            {t('toolbar_no_permission')}
                                        </Grid>
                                    ) : (
                                        ''
                                    )
                                }
                            >
                                <Grid
                                    className={`${classes.and} ${isLastGroup ? 'action' : ''} ${
                                        isReadOnly ? 'readOnly' : ''
                                    }`}
                                    container
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Grid className={classes.mr8} item onClick={!isReadOnly ? addAndFilter : null}>
                                        <CircleAddIconSVG color="#7869B9" />
                                    </Grid>
                                    <Grid item onClick={!isReadOnly ? addAndFilter : null}>
                                        <Typography variant="body1">{t('global_and')}</Typography>
                                    </Grid>
                                    <Grid item className={classes.lineTop} />
                                </Grid>
                            </Tooltip>
                        )}
                    </>
                );
            })}
        </Grid>
    );
}

export default React.memo(FilterList);
