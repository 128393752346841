import React, { useRef } from 'react';
import { Grid, Typography, Dialog as DialogMUI } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import OutlineStarSVG from 'assets/images/svg/OutlineStarSVG';
import FillStarSVG from 'assets/images/svg/FillStarSVG';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import ConfirmBox from 'components/confirmBox/Base';
import { useDispatch } from 'react-redux';
import { deleteAlternative, updateAlternative } from 'tm/actions';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/tooltip/Base';
import EditSVG from 'assets/images/svg/EditSVG';
import RemoveSVG from 'assets/images/svg/RemoveSVG';
import AddWorkspaceSVG from 'assets/images/svg/AddWorkspaceSVG';
import EditAlternaive from './EditAlternaive';
import { useTmSourceSelected } from 'hooks/tm';
import { LOCALIZATION_FONT } from 'const/gridUI';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    inputBase: {
        width: '100%',
        height: '100px !important',
        padding: 8,
        overflowY: 'auto !important',
        resize: 'none',
        outline: 'none',
        border: `1px solid #4A91E2`,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.dimGrey,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        zIndex: 2,
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        },
        '&.Mui-disabled': {
            background: theme.colors.paleGrey,
            color: theme.colors.dimGrey
        }
    },
    hoverItem: {
        background: `rgba(45, 151, 242, 0.1)`,
        transition: 'all 500ms',
        cursor: 'pointer'
    },
    rowData: {
        padding: 8,
        borderRadius: 4,
        '&::last-child': {
            marginBottom: 0
        }
    },
    textTarget: {
        flex: 1,
        position: 'relative'
    },
    primaryTarget: {
        cursor: 'pointer',
        width: 20,
        display: 'flex'
    },
    icon: {
        width: 18,
        height: 18
    }
}));

const Alternative = ({
    unitId,
    tmId,
    target,
    targetLang,
    alternative,
    listAlternatives,
    primary,
    sourceText,
    onRestTable
    // setSegmentId
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const source = useTmSourceSelected() || null;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [confirming, setConfirming] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(null);

    const [newAlternative, setNewAlternative] = React.useState(alternative || null);
    const [showEditing, setShowEditing] = React.useState(false);

    const areaRef = useRef();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        // setSegmentId(alternative?.id);
    };

    useClickAwaitListener(areaRef, e => {
        handleClickAway(e);
    });

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleCloseDelete = React.useCallback(() => {
        setOpenDelete(false);
    }, []);

    const handleOpenDelete = React.useCallback(() => {
        setOpenDelete(true);
    }, []);

    const handleOpenAddAlternative = React.useCallback(e => {
        e.stopPropagation();
        setShowEditing(true);
        setIsEditing(false);
    }, []);

    const handleShowEditing = React.useCallback(() => {
        setShowEditing(true);
        setIsEditing(true);
    }, []);

    const handleCloseEditing = React.useCallback(() => {
        setShowEditing(false);
    }, []);

    const handleDelete = React.useCallback(() => {
        if (confirming) return;
        setConfirming(true);
        dispatch(
            deleteAlternative({
                tmId: tmId,
                segmentId: newAlternative?.id,
                unitId,
                targetLang: target?.value,
                successCallback: () => {
                    setConfirming(false);
                    setNewAlternative(null);
                    // _getListSegments && _getListSegments();
                    onRestTable({ resetPage: true, resetSelectedRows: [] });
                    handleCloseDelete();
                },
                errorCallback: () => {
                    setConfirming(false);
                    handleCloseDelete();
                }
            })
        );
    }, [confirming, dispatch, tmId, newAlternative, unitId, target.value, onRestTable, handleCloseDelete]);

    const handleChangePrimarySegment = React.useCallback(() => {
        let body = {
            value: newAlternative?.value,
            primary: !primary
        };
        dispatch(
            updateAlternative({
                tmId: tmId,
                segmentId: newAlternative?.id,
                unitId,
                body: body,
                targetLang: targetLang,
                successCallback: ({ alternative }) => {
                    setNewAlternative(alternative);
                    setConfirming(false);
                    onRestTable({ resetPage: true, resetSelectedRows: [] });
                },
                errorCallback: () => {
                    setConfirming(false);
                }
            })
        );
    }, [newAlternative, primary, dispatch, tmId, unitId, targetLang, onRestTable]);

    const fontJP = React.useMemo(() => {
        return LOCALIZATION_FONT?.[target?.value];
    }, [target]);

    if (!newAlternative) return null;
    return (
        <Grid
            ref={areaRef}
            onClick={handleClick}
            onMouseEnter={handleClick}
            onMouseLeave={handleClickAway}
            key={newAlternative?.id}
            container
            alignItems="center"
            className={`
            ${anchorEl ? classes.hoverItem : ''}
            ${classes.rowData}
            ${fontJP}
            `}
            wrap="nowrap"
            justifyContent={anchorEl ? 'space-around' : 'flex-start'}
            // style={{
            //     borderBottom: `1px solid ${theme.colors.border}`
            // }}
        >
            <Grid item className={classes.textTarget}>
                <Typography
                    variant="body2"
                    onClick={handleShowEditing}
                    style={{
                        WebkitLineClamp: 3,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitBoxOrient: 'vertical',
                        display: '-webkit-box',
                        wordBreak: 'break-all'
                    }}
                >
                    {newAlternative?.value}
                </Typography>
            </Grid>

            {listAlternatives?.length > 1 ? (
                <Tooltip title={`Use as the default translation`} maxWidth={true}>
                    <Grid
                        item
                        alignItems="center"
                        className={classes.primaryTarget}
                        onClick={handleChangePrimarySegment}
                    >
                        {primary ? (
                            <FillStarSVG className={classes.icon} />
                        ) : (
                            anchorEl && <OutlineStarSVG className={classes.icon} />
                        )}
                    </Grid>
                </Tooltip>
            ) : (
                <Grid item alignItems="center" className={classes.primaryTarget}></Grid>
            )}
            <Grid
                item
                container
                wrap="nowrap"
                spacing={1}
                style={{ width: 90, visibility: anchorEl ? 'visible' : 'hidden' }}
                justifyContent="space-around"
                alignItems="center"
            >
                <Grid item>
                    <Tooltip title={`Edit this alternative`}>
                        <Grid item alignItems="center" className={classes.primaryTarget} onClick={handleShowEditing}>
                            <EditSVG className={classes.icon} />
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={`Add an alternative translation`}>
                        <Grid
                            item
                            alignItems="center"
                            className={classes.primaryTarget}
                            onClick={handleOpenAddAlternative}
                        >
                            <AddWorkspaceSVG className={classes.icon} />
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {listAlternatives?.length > 1 ? (
                        <Tooltip title={`Delete this translation`}>
                            <Grid
                                item
                                className={classes.primaryTarget}
                                style={{ cursor: 'pointer', visibility: anchorEl ? 'visible' : 'hidden' }}
                                onClick={handleOpenDelete}
                            >
                                <RemoveSVG className={classes.icon} />
                            </Grid>
                        </Tooltip>
                    ) : (
                        <Grid item alignItems="center" className={classes.primaryTarget}></Grid>
                    )}
                </Grid>
            </Grid>

            <DialogMUI open={showEditing} onClose={handleCloseEditing}>
                <EditAlternaive
                    open={showEditing}
                    onClose={handleCloseEditing}
                    target={target}
                    source={source}
                    targetLang={targetLang}
                    sourceText={sourceText}
                    tmId={tmId}
                    unitId={unitId}
                    alternative={newAlternative}
                    setNewAlternative={setNewAlternative}
                    isEditing={isEditing}
                    onRestTable={onRestTable}
                    fontJP={fontJP}
                />
            </DialogMUI>
            <DialogMUI open={openDelete} onClose={handleCloseDelete}>
                <ConfirmBox
                    title={t(`remove_alternative`)}
                    body={t(`are_you_sure_want_to_delete_text`)}
                    handleCancel={handleCloseDelete}
                    onClose={handleCloseDelete}
                    handleAgreed={handleDelete}
                    isLoading={confirming}
                    agreeLabel={`Confirm`}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </DialogMUI>
        </Grid>
    );
};

export default React.memo(Alternative);
