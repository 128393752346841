import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import Skeleton from 'components/skeleton/Base';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: '13px 17px',
        height: 68,
        maxHeight: 68,
        borderRadius: 4
    },
    text: {
        margin: 0
    },
    avatar: {
        borderRadius: 4,
        width: 48,
        height: 48
    },
    time: {
        fontSize: 10
    }
}));

function GridItem() {
    const classes = useStyles();
    return (
        <ListItem className={classes.root}>
            <ListItemAvatar>
                <Skeleton variant="circle" width={48} height={48} />
            </ListItemAvatar>
            <ListItemText
                className={classes.text}
                primary={
                    <Grid container alignItems="flex-start" direction="column">
                        <Grid container alignItems="center" justify={'space-between'}>
                            <Grid item>
                                <Skeleton height={24} width={150} />
                            </Grid>
                            <Grid item>
                                <Skeleton height={16} width={80} />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            style={{
                                marginTop: 8
                            }}
                        >
                            <Skeleton height={16} width={50} />
                        </Grid>
                    </Grid>
                }
            />
        </ListItem>
    );
}

export default GridItem;
