import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Card, Grid, IconButton, Dialog, CircularProgress } from '@material-ui/core';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import DropdownSVG from 'assets/images/svg/DropdownSVG';
import AudioThumbnailSVG from 'assets/images/svg/files/AudioThumbnailSVG';
import UploadingDoneSVG from 'assets/images/svg/UploadingDoneSVG';
import ConfirmCloseUploadSnackbar from './ConfirmCloseUploadSnackbar';
import {
    useUploadingFileCountByColumnId,
    useFailedFileCountByColumnId,
    useUploadFilesByColumnId,
    useAllUploadingFileCount,
    useColumnMetaData
} from 'hooks/gridUI';
import UploadingErrorSVG from 'assets/images/svg/UploadingErrorSVG';
import Tooltip from 'components/tooltip/Base';
import FilesSVG from 'components/svg-icon/FilesSVG';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import { List, AutoSizer } from 'react-virtualized-dn';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '344px !important'
        },
        width: 440
    },
    card: {
        backgroundColor: theme.colors.paleGrey,
        width: '100%'
    },
    actionRoot: {
        padding: theme.spacing(3)
    },
    icons: {
        marginLeft: 'auto'
    },
    expand: {
        padding: '8px 8px',
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(0deg)'
    },
    uploadingItem: {
        padding: theme.spacing(3)
    },
    checkIcon: {
        fontSize: 20,
        color: '#b3b3b3',
        paddingRight: 4
    },
    button: {
        padding: 0,
        textTransform: 'none'
    },
    fileIcon: {
        display: 'flex',
        marginRight: theme.spacing(2)
    },
    filePath: {
        width: 340,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left'
    },
    uploadingStatusIcon: {
        marginLeft: 'auto',
        display: 'flex'
    },
    collapse: {
        backgroundColor: theme.colors.white,
        '& $uploadingItem:not(:first-child)': {
            borderTop: `1px solid ${theme.colors.divider}`
        }
    },
    confirmBox: {
        '& button': {
            width: 150
        }
    },
    spinner: {
        '&.MuiCircularProgress-root': {
            color: theme.colors.snackBarSuccess,
            width: `20px !important`,
            height: `20px !important`
        }
    },
    dlFlex: {
        display: 'flex'
    }
}));

function MultipleFileUpload({ column, t }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [modalOpen, setModal] = useState(false);
    const columnId = column.id;
    const files = useUploadFilesByColumnId(columnId);
    const uploadingFileCountByColumnId = useUploadingFileCountByColumnId(columnId);
    const allUploadingFileCount = useAllUploadingFileCount();
    const failedFileCountByColumnId = useFailedFileCountByColumnId(columnId);
    const columnMetaData = useColumnMetaData(column.id);

    React.useEffect(() => {
        const onUnload = event => {
            event.preventDefault();
            event.returnValue = '';
        };
        window.addEventListener('beforeunload', onUnload);
        if (allUploadingFileCount === 0) {
            window.removeEventListener('beforeunload', onUnload);
        }
        return () => {
            window.removeEventListener('beforeunload', onUnload);
        };
    }, [allUploadingFileCount, dispatch, columnId]);

    React.useEffect(() => {
        if (uploadingFileCountByColumnId === 0) {
            dispatch(gridActions.removeProcessingColumns({ columnIds: [columnId] }));
        }
    }, [dispatch, columnId, uploadingFileCountByColumnId]);

    const handleExpandClick = () => {
        dispatch(gridActions.expandColumnUpload({ columnId }));
    };

    const openModal = () => {
        uploadingFileCountByColumnId === 0
            ? dispatch(gridActions.resetColumnUploadStatus({ columnId }))
            : setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const handleClose = () => {
        dispatch(gridActions.resetColumnUploadStatus({ columnId }));
        closeModal();
        dispatch(gridActions.removeProcessingColumns({ columnIds: [columnId] }));
    };

    const _rowRenderer = ({ key, index, style }) => {
        const filePath = column.files[index].path;
        const error = column.files[index].error;
        const isUploading = column.files[index].isUploading;

        return (
            <div style={{ opacity: isUploading ? 0.5 : 1, ...style }} key={key} className={classes.uploadingItem}>
                <Grid container alignItems="center" wrap="nowrap" spacing={1}>
                    <Grid item className={classes.fileIcon}>
                        <AudioThumbnailSVG size={24} />
                    </Grid>
                    <Grid item>
                        <p className={'body2 w-[340px] overflow-hidden text-ellipsis whitespace-nowrap text-left'}>
                            {filePath}
                        </p>
                    </Grid>
                    <Grid item className={classes.uploadingStatusIcon}>
                        {isUploading ? (
                            <CircularProgress className={classes.spinner} thickness={5} />
                        ) : !isUploading && error ? (
                            <Tooltip title={error}>
                                <div className={classes.dlFlex}>
                                    <UploadingErrorSVG />
                                </div>
                            </Tooltip>
                        ) : (
                            <UploadingDoneSVG />
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <Grid container alignItems="center" classes={{ root: classes.actionRoot }}>
                    <IconButton
                        aria-label="Show more"
                        className={`${classes.expand} ${column.isExpanding ? classes.expandOpen : ''}`}
                        onClick={handleExpandClick}
                    >
                        <DropdownSVG size={16} />
                    </IconButton>
                    {uploadingFileCountByColumnId > 0 ? (
                        <p className="body2">
                            {t(`multiple_file_upload_count`, { count: uploadingFileCountByColumnId })}
                        </p>
                    ) : (
                        <Trans i18nKey="multiple_file_upload_failed" t={t} values={{ failedFileCountByColumnId }}>
                            <p className="mr-1 body2">Upload completed</p>{' '}
                            <p className="body1 mr-1">({failedFileCountByColumnId} failed)</p>{' '}
                            <p className="body2">to</p>
                        </Trans>
                    )}
                    <FilesSVG />
                    <p className="body1">{columnMetaData?.name}</p>
                    <div className={classes.icons}>
                        <IconButton className={classes.expand} onClick={openModal}>
                            <CloseIconSVG />
                        </IconButton>
                    </div>
                </Grid>
                <Collapse in={column.isExpanding} timeout="auto" unmountOnExit className={classes.collapse}>
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <List
                                height={200}
                                overscanRowCount={5}
                                // noRowsRenderer={this._noRowsRenderer}
                                rowCount={files?.length}
                                rowHeight={50}
                                rowRenderer={_rowRenderer}
                                width={width}
                                style={{ outline: 'none' }}
                            />
                        )}
                    </AutoSizer>
                </Collapse>
            </Card>
            <Dialog open={modalOpen} onClose={closeModal}>
                <ConfirmCloseUploadSnackbar onClose={closeModal} onAccept={handleClose} />
            </Dialog>
        </div>
    );
}

export default MultipleFileUpload;
