import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import JSONTree from 'react-json-tree';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import { INPUT_HEIGHT, INPUT_PADDING_LEFT } from 'const/style';
import DuplicateSVG from 'assets/images/svg/DuplicateSVG';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import * as clipboardy from 'clipboardy';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width || '100%'
    },
    reponseView: {
        '& > ul': {
            margin: `0 !important`,
            minHeight: 250,
            maxHeight: 400,
            padding: `${INPUT_PADDING_LEFT}px !important`,
            overflowY: 'auto',
            backgroundColor: `${theme.colors.ghostwhite} !important`,
            '& div, & label': {
                color: `#0a6aff !important`,
                fontSize: '0.875rem'
            },
            '& li': {
                '& span > span': {
                    color: `#de5e00 !important`,
                    fontSize: '0.75'
                }
            },
            '& li > span': {
                color: `${theme.colors.dimGrey} !important`,
                fontSize: '0.875rem'
            }
        },
        '& *': {
            fontFamily: 'courier !important'
        }
    },
    curl: {
        color: theme.colors.dimGrey,
        padding: `${INPUT_PADDING_LEFT}px !important`,
        overflowX: 'auto',
        width: '100%',
        fontFamily: 'courier !important',
        marginTop: 0,
        // whiteSpace: 'pre-wrap',
        // wordBreak: 'break-word',
        '& .green': {
            color: theme.colors.atlantis,
            fontFamily: 'courier !important'
        },
        '& .blue': {
            color: theme.colors.dodgerBlue,
            fontFamily: 'courier !important'
        }
    },
    copyCurl: {
        border: `1px solid ${theme.colors.codeHeader} !important`,
        color: theme.colors.codeHeader
    },
    fullWidth: {
        width: '100%'
    },
    requestHeader: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        background: theme.colors.lavender,
        '& p': {
            color: `${theme.colors.white} !important`
        },
        height: INPUT_HEIGHT,
        paddingLeft: INPUT_PADDING_LEFT,
        paddingRight: INPUT_PADDING_LEFT
    },
    requestBody: {
        maxHeight: 400,
        overflowY: 'auto',
        background: theme.colors.ghostwhite
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    icon: {
        display: 'flex',
        cursor: 'pointer'
    }
}));

function formatHeaders(headers) {
    let headerFormatted = ``;
    Object.keys(headers).forEach(property => {
        headerFormatted += `-H ${property}:${headers[property]} \\\n`;
    });
    return headerFormatted;
}

function highlightParamIds(url) {
    const params = url.split('/');
    for (let i = 0; i < params.length; i++) {
        if (['databases', 'grids', 'branches', 'views'].includes(params[i])) {
            params[i + 1] = `<span class="green">${params[i + 1]}</span>`;
            break;
        }
    }
    return params.join('/');
}

function prettyCurl(schema) {
    const { url, headers, method, body } = schema;
    let formattedHeader = '';
    if (headers) {
        formattedHeader = formatHeaders(headers);
    }
    let data = ``;
    if (!isEmpty(body)) {
        data += JSON.stringify(body);
    }
    const prettyUrl = highlightParamIds(url);
    let curl = `curl <span class="blue">${method}</span> \\
${prettyUrl} \\
${formattedHeader}${
        !!data
            ? `-d '${data}'\\\n-<span class="blue">H</span> 'Authorization: ApiKey <span class="green">YOUR_API_KEY</span>'`
            : `-<span class="blue">H</span> 'Authorization: ApiKey <span class="green">YOUR_API_KEY</span>'`
    }`;
    return curl;
}

function generateTerminalCurl(schema) {
    const { url, headers, method, Authorization, body } = schema;
    let formattedHeader = '';
    if (headers) {
        formattedHeader = formatHeaders(headers);
    }
    let data = ``;
    if (!isEmpty(body)) {
        data += JSON.stringify(body);
    }
    let curl = `curl ${method} \\
${url} \\
${formattedHeader} ${data ? `-d '${data}'` : ``} -H 'Authorization: ApiKey ${Authorization}'
`;
    return curl;
}

function DocSchema({
    schema = {
        method: 'POST',
        url: 'URL_API',
        headers: {
            // Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    },
    response = {
        name: 'Response Example'
    },
    className
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    var curl = prettyCurl(schema);

    const handleCopyCurl = async () => {
        let curl = generateTerminalCurl(schema);

        await clipboardy.write(curl);

        dispatch(
            enqueueSnackbar({
                message: `Copied`,
                type: 'info'
            })
        );
    };

    return (
        <Grid className={`${classes.root} ${className}`} container direction="column">
            <Grid item className={`${classes.fullWidth} ${classes.spacing}`}>
                <Grid container direction="column" className={classes.request}>
                    <Grid item container alignItems="center" justify="space-between" className={classes.requestHeader}>
                        <Grid item>
                            <Typography variant="body1">{t('example_request')}</Typography>
                        </Grid>
                        <Tooltip title="Copy">
                            <Grid onClick={handleCopyCurl} item className={classes.icon}>
                                <DuplicateSVG />
                            </Grid>
                        </Tooltip>
                    </Grid>
                    <Grid item className={`${classes.fullWidth} ${classes.requestBody}`}>
                        <pre className={classes.curl} dangerouslySetInnerHTML={{ __html: curl }} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={`${classes.fullWidth}`}>
                <Grid container direction="column" className={classes.response}>
                    <Grid item container alignItems="center" justify="space-between" className={classes.requestHeader}>
                        <Grid item>
                            <Typography variant="body1">{t('example_response')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.reponseView}>
                        <JSONTree data={response} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(DocSchema);
