import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import SortOrderItem from './SortOrderItem';
import { useViewSortOrderList } from 'hooks/gridUI';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3)
    },
    filterItem: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    }
}));

function SortOrderList({ t }) {
    const classes = useStyles();
    const viewSorts = useViewSortOrderList();

    if (isEmpty(viewSorts))
        return (
            <Grid container className={classes.root}>
                <p className="caption">{t(`sort_add_more_button`)}</p>
            </Grid>
        );
    return (
        <Grid container direction="column" className={classes.root}>
            {viewSorts.map(sortOrder => {
                return (
                    <Grid item className={classes.filterItem} key={sortOrder.id}>
                        <SortOrderItem t={t} id={sortOrder.id} direction={sortOrder.direction} sortOrder={sortOrder} />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(SortOrderList);
