import React from 'react';

function SearchSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" {...other}>
            <path
                fill={color}
                fillRule="nonzero"
                d="M13.021 12.752l-3.26-3.466a5.71 5.71 0 0 0 1.298-3.634C11.059 2.536 8.579 0 5.529 0 2.481 0 0 2.536 0 5.652c0 3.117 2.48 5.653 5.53 5.653a5.389 5.389 0 0 0 3.167-1.023l3.285 3.492a.708.708 0 0 0 1.02.02.75.75 0 0 0 .02-1.042zM5.53 1.475c2.254 0 4.087 1.874 4.087 4.177 0 2.304-1.833 4.178-4.087 4.178-2.253 0-4.087-1.874-4.087-4.178 0-2.303 1.834-4.177 4.087-4.177z"
            />
        </svg>
    );
}

export default React.memo(SearchSVG);
