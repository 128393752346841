import React from 'react';

function InviteCompanySVG({ color = '#FFFFFF', ...other }) {
    return (
        <svg {...other} width="90" height="40" viewBox="0 0 90 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.1504 6.00038V5.74561C54.9419 5.82646 61.2551 12.1899 61.2551 20.0001C61.2551 26.2565 57.2038 31.5846 51.5868 33.4985C55.9016 31.3966 58.9574 26.2527 58.9574 20.2553C58.9574 12.4562 53.7898 6.10039 47.4059 6.00086C47.3557 6 47.3055 6 47.2553 6C47.2203 6 47.1853 6.00013 47.1504 6.00038Z"
                fill="#FFF1B1"
            />
            <path
                d="M47 8.29785V26.4255C51.5362 26.4255 55.2127 22.7489 55.2127 18.2128V16.5107C55.2127 11.9753 51.5362 8.29785 47 8.29785Z"
                fill="#FFD0BC"
            />
            <path
                d="M46.9998 8.29785C50.1258 8.29785 52.6595 11.9753 52.6595 16.5106V18.2127C52.6595 22.7489 50.1259 26.4254 46.9998 26.4254C42.4637 26.4254 38.7871 22.7489 38.7871 18.2127V16.5106C38.7871 11.9753 42.4637 8.29785 46.9998 8.29785Z"
                fill="#FFE9E0"
            />
            <path
                d="M47.1455 28.937L52.6842 35.8181C54.3048 35.2336 55.8109 34.4075 57.1582 33.3823C54.6621 30.6818 51.1034 28.9779 47.1455 28.937Z"
                fill="#FEDC3D"
            />
            <path
                d="M47.1455 28.9369C47.0969 28.936 47.0484 28.936 46.9999 28.936C42.9837 28.936 39.3683 30.6483 36.8418 33.382C39.6655 35.5307 43.186 36.8084 46.9999 36.8084C50.2624 36.8084 53.3097 35.8728 55.8903 34.2577C53.8542 31.0621 50.6979 28.9924 47.1455 28.9369Z"
                fill="#FFE777"
            />
            <path
                d="M48.7021 29.04L47 33.8588C49.3498 33.8588 51.2545 31.955 51.2553 29.6043C50.4349 29.3388 49.5812 29.1481 48.7021 29.04Z"
                fill="#D5F6F5"
            />
            <path
                d="M48.7025 29.0399V29.6033C48.7025 31.9539 47.9408 33.8586 47.0004 33.8586C44.6507 33.8586 42.7451 31.9539 42.7451 29.6033C44.0855 29.1701 45.5153 28.936 47.0004 28.936C47.5766 28.936 48.1443 28.971 48.7025 29.0399Z"
                fill="white"
            />
            <path
                d="M50.149 15.3774C49.7965 15.3774 49.5107 15.6631 49.5107 16.0157V17.2923C49.5107 17.6449 49.7965 17.9306 50.149 17.9306C50.5015 17.9306 50.7873 17.6449 50.7873 17.2923V16.0157C50.7874 15.6631 50.5016 15.3774 50.149 15.3774Z"
                fill="#392B76"
            />
            <path
                d="M43.8512 15.3774C43.4987 15.3774 43.2129 15.6631 43.2129 16.0157V17.2923C43.2129 17.6449 43.4987 17.9306 43.8512 17.9306C44.2037 17.9306 44.4894 17.6449 44.4894 17.2923V16.0157C44.4894 15.6631 44.2037 15.3774 43.8512 15.3774Z"
                fill="#392B76"
            />
            <path
                d="M48.4366 19.6529C48.0953 19.5647 47.7471 19.7697 47.6587 20.1109C47.5807 20.4121 47.3099 20.6226 47 20.6226C46.6903 20.6226 46.4193 20.4121 46.3413 20.1109C46.2529 19.7696 45.9049 19.5644 45.5635 19.6529C45.2222 19.7413 45.0171 20.0895 45.1055 20.4308C45.3292 21.2954 46.1083 21.8992 47 21.8992C47.8917 21.8992 48.6708 21.2954 48.8946 20.4308C48.983 20.0895 48.7779 19.7413 48.4366 19.6529Z"
                fill="#392B76"
            />
            <path
                d="M46.9995 7.65967C42.119 7.65967 38.1484 11.6302 38.1484 16.5108V18.2129C38.1484 23.0934 42.119 27.064 46.9995 27.064C51.8801 27.064 55.8506 23.0934 55.8506 18.2129V16.5108C55.8506 11.6302 51.8801 7.65967 46.9995 7.65967ZM54.574 18.2128C54.574 22.3894 51.1761 25.7873 46.9995 25.7873C42.823 25.7873 39.4251 22.3894 39.4251 18.2128V16.5107C39.4251 12.3341 42.823 8.93623 46.9995 8.93623C51.1761 8.93623 54.574 12.3341 54.574 16.5107V18.2128Z"
                fill="#392B76"
            />
            <path
                d="M66.3617 19.3617H64.4339C64.1044 10.2482 56.7519 2.89562 47.6383 2.56601V0.638281C47.6383 0.285703 47.3525 0 47 0C46.6475 0 46.3617 0.285703 46.3617 0.638281V2.56601C37.2481 2.89562 29.8956 10.2482 29.5661 19.3617H27.6383C27.2858 19.3617 27 19.6474 27 20C27 20.3526 27.2858 20.6383 27.6383 20.6383H29.5661C29.8956 29.7518 37.2481 37.1043 46.3616 37.4339V39.3616C46.3616 39.7142 46.6474 39.9999 46.9999 39.9999C47.3524 39.9999 47.6382 39.7142 47.6382 39.3616V37.4339C56.7518 37.1043 64.1042 29.7519 64.4338 20.6383H66.3616C66.7141 20.6383 66.9999 20.3526 66.9999 20C66.9999 19.6474 66.7142 19.3617 66.3617 19.3617ZM42.1881 30.4889C42.606 32.766 44.604 34.4973 46.9999 34.4973C49.3959 34.4973 51.3939 32.766 51.8119 30.4889C53.4299 31.1239 54.9216 32.0754 56.1855 33.3002C53.5749 35.1087 50.4092 36.1701 46.9999 36.1701C43.5906 36.1701 40.4251 35.1086 37.8145 33.3001C39.0784 32.0753 40.5702 31.1239 42.1881 30.4889ZM43.4147 30.0752C44.5726 29.7469 45.7786 29.5744 47 29.5744C48.2214 29.5744 49.4274 29.7469 50.5853 30.0752C50.3534 31.8476 48.8346 33.2207 47 33.2207C45.1654 33.2207 43.6466 31.8476 43.4147 30.0752ZM57.2162 32.5244C54.5141 29.8319 50.8245 28.2979 47 28.2979C43.1755 28.2979 39.4859 29.8319 36.7838 32.5244C33.1523 29.5568 30.8298 25.0447 30.8298 20C30.8298 11.0837 38.0838 3.82976 47 3.82976C55.9163 3.82976 63.1702 11.0837 63.1702 20C63.1702 25.0447 60.8477 29.5567 57.2162 32.5244Z"
                fill="#4D3F8A"
            />
            <path
                d="M47 5.10645C38.7877 5.10645 32.1064 11.7877 32.1064 20C32.1064 23.9019 33.6115 27.5933 36.3446 30.3943C36.4696 30.5225 36.6354 30.5868 36.8015 30.5868C36.9623 30.5868 37.1232 30.5265 37.2472 30.4053C37.4996 30.159 37.5045 29.7549 37.2583 29.5026C34.7593 26.9416 33.383 23.5668 33.383 20C33.383 12.4915 39.4916 6.38301 47 6.38301C54.5085 6.38301 60.6171 12.4915 60.6171 20C60.6171 23.5668 59.2408 26.9416 56.7418 29.5026C56.4955 29.755 56.5006 30.1591 56.7529 30.4053C57.0051 30.6514 57.4093 30.6464 57.6554 30.3942C60.3886 27.5933 61.8937 23.9019 61.8937 20C61.8936 11.7877 55.2124 5.10645 47 5.10645Z"
                fill="#4D3F8A"
            />
            <path
                d="M81 30H84V33.0001C84 33.5524 84.4477 34 85 34C85.5522 34 86 33.5524 86 33.0001V30H89C89.5523 30 90 29.5524 90 29C90 28.4476 89.5523 28 89 28H86V25C86 24.4476 85.5522 24 85 24C84.4477 24 84 24.4476 84 25V28H81C80.4477 28 80 28.4476 80 29C80 29.5524 80.4477 30 81 30Z"
                fill="#F8AD13"
                fillOpacity="0.8"
            />
            <path
                d="M21 9.00002H18V5.99997C18 5.4476 17.5523 5 17 5C16.4478 5 16 5.4476 16 5.99997V9.00002H13C12.4477 9.00002 12 9.44762 12 10C12 10.5524 12.4477 11 13 11H16V14C16 14.5524 16.4478 15 17 15C17.5523 15 18 14.5524 18 14V11H21C21.5523 11 22 10.5524 22 10C22 9.44762 21.5523 9.00002 21 9.00002Z"
                fill="#77B32B"
                fillOpacity="0.8"
            />
            <circle cx="1.5" cy="27.5" r="1.5" fill="#F67B8E" fillOpacity="0.8" />
            <circle cx="75.5" cy="9.5" r="1.5" fill="#5DCDE3" fillOpacity="0.8" />
            <path
                d="M45 13.5001H40C41 9.00007 46 8.00007 48.5 8.50007C51.7 8.90007 53.8333 12.0001 54.5 13.5001H54H49L47 11.5001L45 13.5001Z"
                fill="#392B76"
            />
        </svg>
    );
}

export default React.memo(InviteCompanySVG);
