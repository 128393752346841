import React from 'react';

function CriticalSVG({ color = '#EB4F5E', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M7.3335 10.0007H8.66683V11.334H7.3335V10.0007ZM7.3335 4.66732H8.66683V8.66732H7.3335V4.66732ZM7.9935 1.33398C4.3135 1.33398 1.3335 4.32065 1.3335 8.00065C1.3335 11.6807 4.3135 14.6673 7.9935 14.6673C11.6802 14.6673 14.6668 11.6807 14.6668 8.00065C14.6668 4.32065 11.6802 1.33398 7.9935 1.33398ZM8.00016 13.334C5.0535 13.334 2.66683 10.9473 2.66683 8.00065C2.66683 5.05398 5.0535 2.66732 8.00016 2.66732C10.9468 2.66732 13.3335 5.05398 13.3335 8.00065C13.3335 10.9473 10.9468 13.334 8.00016 13.334Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(CriticalSVG);
