import React from 'react';

function TMBannerSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="188" height="135" viewBox="0 0 188 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M126.128 119.389V121.496C126.128 127.194 121.523 131.878 115.747 131.878H72.5037C66.7275 131.8 62.1222 127.194 62.1222 121.496V95.5037C62.1222 89.7275 66.7275 85.1222 72.5037 85.1222H115.747C121.523 85.1222 126.128 89.7275 126.128 95.5037V104.012C126.128 104.87 126.831 105.573 127.689 105.573C128.548 105.573 129.25 104.87 129.25 104.012V95.5037C129.25 88.0103 123.24 82 115.747 82H72.5037C65.0103 82 59 88.0103 59 95.5037V121.496C59 128.912 65.0103 135 72.5037 135H115.747C123.162 135 129.25 128.99 129.25 121.496V119.389C129.25 118.53 128.548 117.828 127.689 117.828C126.831 117.828 126.128 118.53 126.128 119.389Z"
                fill="#DAE0EC"
            />
            <path d="M90.4488 101.802H84.3763V119H81.019V101.802H75V99H90.4488V101.802Z" fill="#DAE0EC" />
            <path
                d="M97.4041 99L103.022 114.33L108.626 99H113V119H109.629V112.407L109.964 103.588L104.212 119H101.791L96.0532 103.602L96.3875 112.407V119H93.0169V99H97.4041Z"
                fill="#DAE0EC"
            />
            <path d="M41.0952 0.0195312L33.0195 8.09517H41.0952V0.0195312Z" fill="#DAE0EC" />
            <path
                d="M75.057 0H44.9013V11.9013H33V55.5185H75.0571V0H75.057ZM67.2036 36.8394H40.8535V33.0263H67.2036V36.8394ZM67.2036 28.7386H40.8535V24.9255H67.2036V28.7386ZM67.2036 20.6376H40.8535V16.8245H67.2036V20.6376Z"
                fill="#ECEFF5"
            />
            <path d="M78.8702 9.55859V59.3318H42.5586V65.0771H84.6156V9.55859H78.8702Z" fill="#F7F8FA" />
            <path d="M111.019 0.0195312L102.943 8.09517H111.019V0.0195312Z" fill="#DAE0EC" />
            <path
                d="M144.981 0H114.825V11.9013H102.924V55.5185H144.981L144.981 0ZM129.27 36.8394H110.777V33.0263H129.27V36.8394ZM119.77 28.7386H110.777V24.9255H119.77V28.7386ZM137.127 20.6376H110.777V16.8245H137.127V20.6376Z"
                fill="#ECEFF5"
            />
            <path d="M148.792 9.55859V59.3318H112.48V65.0771H154.537V9.55859H148.792Z" fill="#F7F8FA" />
            <path
                d="M140.58 110.187C145.056 113.046 150.468 113.98 155.867 112.72C161.323 111.497 166.137 108.181 169.331 103.411C172.526 98.6414 173.808 92.9867 172.825 87.4822C171.899 82.0139 168.871 77.3052 164.32 74.3142L163.545 75.5009C167.813 78.3109 170.579 82.6838 171.49 87.8062C172.362 92.9879 171.199 98.2142 168.198 102.688C162.042 111.872 149.833 114.584 141.071 108.819L140.297 110.006C140.41 110.078 140.467 110.114 140.58 110.187Z"
                fill="#DAE0EC"
                stroke="#DAE0EC"
            />
            <path
                d="M162.732 85.5338L164.108 85.4003L164.183 75.6683L173.81 75.2361L173.845 73.8255L162.786 74.3549L162.732 85.5338Z"
                fill="#DAE0EC"
                stroke="#DAE0EC"
            />
            <path
                d="M20.5865 69.1785C16.0022 71.7802 12.73 76.0891 11.4385 81.399C10.0889 86.7417 10.9101 92.4871 13.7682 97.485C16.6264 102.483 21.1394 106.215 26.5352 107.893C31.8729 109.604 37.4712 109.124 42.2076 106.517L41.4875 105.291C37.0413 107.733 31.8632 108.138 26.8436 106.574C21.7879 104.948 17.607 101.518 14.9288 96.8264C9.42857 87.1982 12.4721 75.2548 21.5966 70.2398L20.8766 69.0139C20.7605 69.0797 20.7025 69.1127 20.5865 69.1785Z"
                fill="#DAE0EC"
                stroke="#DAE0EC"
            />
            <path
                d="M32.5771 99.9279L32.0789 101.194L40.819 105.752L36.8812 114.38L38.137 115.062L42.6313 105.135L32.5771 99.9279Z"
                fill="#DAE0EC"
                stroke="#DAE0EC"
            />
        </svg>
    );
}

export default React.memo(TMBannerSVG);
