import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import { useWorkspaceRoles } from 'hooks/workspace';
import { SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    root: {},
    roleItem: {
        cursor: 'pointer',
        padding: `8px ${SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT}px`,
        '&:hover': {
            background: theme.colors.hoverItem
        }
    },
    arrowDown: {
        display: 'flex',
        marginLeft: SPACING_LIST_NAME_WITH_ICON
    }
}));

const DESCRIPTION = {
    Creator: i18n.t('creator_description'),
    Editor: i18n.t('editor_description'),
    Translator: i18n.t('translator_description'),
    Viewer: i18n.t('viewer_description'),
    Commenter: i18n.t(`commenter_description`),
    LocalizationManager: i18n.t(`localization_manager_description`)
};

function MemberRolePopup({ handleClickAway, member, onRoleChange }) {
    const classes = useStyles();
    const workspaceRoles = useWorkspaceRoles();
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const handleRoleChange = ({ member, role }) => {
        onRoleChange({ member, role });
        handleClickAway();
    };

    return (
        <Grid ref={rootRef} container direction="column">
            {workspaceRoles.map(role => (
                <Grid
                    item
                    key={role?.id}
                    className={classes.roleItem}
                    onClick={() => handleRoleChange({ member, role })}
                >
                    <Grid container justify="space-between">
                        <Grid item>
                            <Grid style={{ height: '100%' }} container direction="column" justify="center">
                                <Grid item>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item>
                                            <p className="body1">{role?.name}</p>
                                        </Grid>
                                        {member?.role?.id === role?.id && (
                                            <Grid item>
                                                <CheckIconSVG />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <p className="caption">{DESCRIPTION[role?.name]}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

export default React.memo(MemberRolePopup);
