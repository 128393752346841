import React from 'react';
import { Grid, Divider, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextSVG from 'assets/images/svg/TextSVG';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { INPUT_RADIUS, SPACING_LIST_NAME_WITH_ICON } from 'const/style';

const useStyles = makeStyles(theme => ({
    root: {
        width: 224,
        background: theme.colors.white,
        boxShadow: theme.shadows[1],
        borderRadius: INPUT_RADIUS
    },
    colorList: {
        padding: `14px 20px`
    },
    colorItem: {
        width: 24,
        height: 24,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        border: `1px solid ${theme.colors.divider}`,
        background: theme.colors.white
    },
    noFill: {
        padding: `14px 20px`
    }
}));

const FILL_COLOR_OPTIONS = [
    '#ACADB9',
    '#DE4419',
    '#77B32B',
    '#E99C00',
    '#5767A6',
    '#F9772A',

    '#37A4BA',
    '#1675C7',
    '#4D3F8A',
    '#943C75',
    '#E83452',
    '#51CEE7'
];

function ColorPallet({ handleClickAway, selectedCellColor, onChangeHandler, resetTextColor, isMulti }) {
    const classes = useStyles();
    const theme = useTheme();
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const changeColorHandler = (e, color) => {
        if (color !== selectedCellColor || (!color && color === selectedCellColor)) {
            onChangeHandler && onChangeHandler(e, color);
        }
    };

    return (
        <Grid ref={rootRef} id={'rich-text-fill-color-panel'} className={classes.root} container direction="column">
            <Grid item className={classes.noFill}>
                <Grid container direction="row" alignItems="center" wrap="nowrap">
                    <Grid item style={{ marginRight: SPACING_LIST_NAME_WITH_ICON }}>
                        <span onClick={e => resetTextColor(e)} className={classes.colorItem}>
                            <TextSVG color={theme.colors.primaryText} />
                        </span>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">Default</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
            <Grid item className={classes.colorList}>
                <Grid container direction="row" spacing={2}>
                    {FILL_COLOR_OPTIONS.map(color => {
                        return (
                            <Grid item key={color}>
                                <span onClick={e => changeColorHandler(e, color)} className={classes.colorItem}>
                                    <TextSVG color={color} />
                                </span>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ColorPallet);
