import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import DependencySVG from 'assets/images/svg/DependencySVG';
import TMStatusAprrovedSVG from 'assets/images/svg/localization/TMStatusAprrovedSVG';
import TMStatusNotAprrovedSVG from 'assets/images/svg/localization/TMStatusNotAprrovedSVG';
// import CommentsSVG from 'assets/images/svg/CommentsSVG';
import CannotEditSVG from 'assets/images/svg/CannotEditSVG';
import hexToRgba from 'hex-to-rgba';
import {
    useIsChildDependencyByColumnId,
    useIsShowAutoQA,
    useIsTmDisabled,
    useIsParentDependencyByColumnId
} from 'hooks/gridUI';
import * as columnTypes from 'const/columnTypes';
import { EXTRA_QUICK_FILTER, FILL_COLOR_OPTIONS, SOURCE_STATUS } from 'const/gridUI';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useFillColorBlindness } from 'hooks/auth';

function ExtraFilterOptions({ column, columnType, extraFilter, onChange, t, onResetExtraFilter }) {
    const theme = useTheme();
    const isShowAutoQA = useIsShowAutoQA();

    const isChildDependency = useIsChildDependencyByColumnId(column?.id);
    const isParentDependency = useIsParentDependencyByColumnId(column?.id);

    const isTmDisabled = useIsTmDisabled();
    const fillColorBlindness = useFillColorBlindness();

    const isLanguageColumn = columnType === columnTypes.TRANSLATION;
    const isBoolean = columnType === columnTypes.BOOLEAN;

    const DEPENDENCY_OPTIONS = React.useMemo(
        () => [
            {
                name: t(`global_is_up_to_date`),
                value: EXTRA_QUICK_FILTER.IS_UP_TO_DATE,
                icon: <DependencySVG color={hexToRgba(theme.colors.atlantis, 0.8)} />,
                isShow: isChildDependency
            },
            {
                name: t(`global_is_out_of_date`),
                value: EXTRA_QUICK_FILTER.IS_OUT_OF_DATE,
                icon: <DependencySVG color={hexToRgba(theme.colors.sun, 0.8)} />,
                isShow: isChildDependency
            },
            {
                name: t(`global_is_unset`),
                value: EXTRA_QUICK_FILTER.IS_UNSET,
                icon: <DependencySVG color={hexToRgba(theme.colors.brightRed, 0.8)} />,
                isShow: isChildDependency
            }
        ],
        [isChildDependency, t, theme]
    );

    const SOURCE_STATUS_OPTIONS = React.useMemo(
        () => [
            {
                name: t(`do_not_translate`),
                value: SOURCE_STATUS.DO_NOT_TRANSLATE,
                isShow: isChildDependency
            },
            {
                name: t(`not_ready_for_translation`),
                value: SOURCE_STATUS.NOT_READY_FOR_TRANSLATION,
                isShow: isChildDependency
            },
            {
                name: t(`ready_for_translation`),
                value: SOURCE_STATUS.READY_FOR_TRANSLATION,
                isShow: isChildDependency
            },
            {
                name: t(`locked`),
                value: SOURCE_STATUS.LOCKED,
                isShow: isChildDependency
            },
            {
                name: t(`notSet`),
                value: SOURCE_STATUS.UNSET,
                isShow: isChildDependency
            }
        ],
        [isChildDependency, t]
    );

    const TM_OPTIONS = React.useMemo(
        () => [
            {
                name: t(`global_is_auto_filled_in_with_tm`),
                value: EXTRA_QUICK_FILTER.IS_TM,
                icon: <TMStatusAprrovedSVG />,
                isShow: isChildDependency && isLanguageColumn && !isTmDisabled
            },
            {
                name: t(`global_is_not_filled_in_with_tm`),
                value: EXTRA_QUICK_FILTER.IS_NOT_TM,
                icon: <TMStatusNotAprrovedSVG />,
                isShow: isChildDependency && isLanguageColumn && !isTmDisabled
            }
        ],
        [isChildDependency, isLanguageColumn, isTmDisabled, t]
    );

    const DATA_OPTIONS = React.useMemo(
        () => [
            {
                name: t(`global_is_empty`),
                value: EXTRA_QUICK_FILTER.IS_EMPTY,
                icon: <CannotEditSVG color={theme.colors.steel} />,
                isShow: !isBoolean
            },
            {
                name: t(`global_is_filled`),
                value: EXTRA_QUICK_FILTER.IS_FILLED,
                icon: <CheckIconSVG color={theme.colors.steel} />,
                isShow: !isBoolean
            }
        ],
        [isBoolean, t, theme]
    );

    const handleOnChange = option => {
        onChange && onChange(option);
    };

    return (
        <Grid container className={'min-w-[220px] max-w-[450px] py-0.5 px-3'} direction="column">
            <Grid item className={'mb-2'}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                    <Grid item>
                        <h4 className="prose prose-lg font-medium">{t('column_filter')}</h4>
                    </Grid>
                    <Grid item onClick={onResetExtraFilter}>
                        <p className={'body2 cursor-pointer text-brand-main'}>{t('clear_all')}</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    {!isBoolean && (
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <p className="caption">{t('global_status')}</p>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        defaultValue={extraFilter?._data}
                                        onChange={e => handleOnChange({ _data: e?.target?.value })}
                                    >
                                        <Grid container direction="row" alignItems="center">
                                            {DATA_OPTIONS.map(option => {
                                                return (
                                                    <Grid item key={option?.value}>
                                                        <FormControlLabel
                                                            value={option?.value}
                                                            control={<Radio size="small" />}
                                                            label={<p className="body2 !font-normal">{option?.name}</p>}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {isChildDependency && (
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <p className="caption">Dependency Options</p>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        defaultValue={extraFilter?._dependencyStatus}
                                        onChange={e => handleOnChange({ _dependencyStatus: e?.target?.value })}
                                    >
                                        <Grid container direction="row" alignItems="center">
                                            {DEPENDENCY_OPTIONS.map(option => {
                                                return (
                                                    <Grid item key={option?.value}>
                                                        <FormControlLabel
                                                            value={option?.value}
                                                            control={<Radio size="small" />}
                                                            label={
                                                                <div className="items-center gap-1 flex">
                                                                    {option.icon}
                                                                    <p className="body2 !font-normal">{option?.name}</p>
                                                                </div>
                                                            }
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {isParentDependency && (
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <p className="caption">Source Status Options</p>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        defaultValue={extraFilter?._sourceStatus}
                                        onChange={e => handleOnChange({ _sourceStatus: e?.target?.value })}
                                    >
                                        <Grid container direction="row" alignItems="center">
                                            {SOURCE_STATUS_OPTIONS.map(option => {
                                                return (
                                                    <Grid xs={6} item key={option?.value}>
                                                        <FormControlLabel
                                                            value={option?.value}
                                                            control={<Radio size="small" />}
                                                            label={
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    spacing={1}
                                                                >
                                                                    <Grid item className={'flex'}>
                                                                        {option.icon}
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <p className="body2 !font-normal">
                                                                            {option?.name}
                                                                        </p>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {isChildDependency && isLanguageColumn && !isTmDisabled && (
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <p className="caption">Tm Options</p>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        defaultValue={extraFilter?._tm}
                                        onChange={e => handleOnChange({ _tm: e?.target?.value })}
                                    >
                                        <Grid container direction="row" alignItems="center">
                                            {TM_OPTIONS.map(option => {
                                                return (
                                                    <Grid item key={option?.value}>
                                                        <FormControlLabel
                                                            value={option?.value}
                                                            control={<Radio size="small" />}
                                                            label={
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    spacing={1}
                                                                >
                                                                    <Grid item className={'flex'}>
                                                                        {option.icon}
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <p className="body2 !font-normal">
                                                                            {option?.name}
                                                                        </p>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {isShowAutoQA && (
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <p className="caption">Auto QA by lexiqa Option</p>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        defaultValue={extraFilter?._cell_metadata?.toString()}
                                        onChange={e => handleOnChange({ _cell_metadata: true })}
                                    >
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item>
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio size="small" />}
                                                    label={
                                                        <Grid container direction="row" alignItems="center" spacing={1}>
                                                            <Grid item>
                                                                <p className="body2 !font-normal">contains error</p>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item className={'mb-2'}>
                                <p className="caption">{t('fill_color')}</p>
                            </Grid>
                            <Grid item>
                                <div className="flex flex-col gap-2">
                                    <div className="flex items-center gap-1">
                                        <div
                                            className={
                                                'w-6 h-6 rounded-full flex justify-center items-center cursor-pointer'
                                            }
                                            style={{
                                                border: `1px solid ${theme.colors.border}`
                                            }}
                                            onClick={() => handleOnChange({ _color: undefined })}
                                        >
                                            {!extraFilter?._color ? <CheckIconSVG /> : null}
                                        </div>
                                        <p className="body2 !font-normal">{t('no_fill_color')}</p>
                                    </div>
                                    <div container direction="column" className="grid grid-cols-6 gap-2 max-w-[200px]">
                                        {Object.keys(FILL_COLOR_OPTIONS)?.map(hex => {
                                            const image = FILL_COLOR_OPTIONS?.[hex];
                                            return (
                                                <div
                                                    onClick={() => handleOnChange({ _color: hex })}
                                                    key={hex}
                                                    className={
                                                        'w-6 h-6 rounded-full flex justify-center items-center cursor-pointer'
                                                    }
                                                    style={{
                                                        background:
                                                            fillColorBlindness === 'BLIND'
                                                                ? `${hex} url(${image})`
                                                                : `${hex}`
                                                    }}
                                                >
                                                    {extraFilter?._color === hex ? <CheckIconSVG /> : null}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ExtraFilterOptions);
