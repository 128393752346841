import React from 'react';

function DefaultSystemItemSVG({ color = '#C9C9CE', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.4001 16L8.0001 11.5556L1.6001 16V1.77778C1.6001 1.30628 1.79275 0.854097 2.13567 0.520699C2.4786 0.187301 2.9437 0 3.42867 0H12.5715C13.0565 0 13.5216 0.187301 13.8645 0.520699C14.2074 0.854097 14.4001 1.30628 14.4001 1.77778V16Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(DefaultSystemItemSVG);
