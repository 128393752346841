/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import ListItem from 'components/list/Item';
import ToolTipClickContent from 'components/tooltip/ToolTipClickContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {},
    focused: {
        background: theme.colors.hoverItem
    },
    pointerEventsNone: {
        pointerEvents: 'none'
    }
}));
function Option(props) {
    const classes = useStyles();
    const { data, isSelected, innerRef, children, innerProps, isFocused, selectProps } = props;

    const { icon: Icon, isDisabled, disabledTitle = '', moreIcon: MoreIcon, placement } = data;

    const { itemHeight } = selectProps;

    // const { showTooltip } = selectProps;

    const tooltipTitle = React.useMemo(() => {
        return isDisabled ? disabledTitle : null;
    }, [isDisabled, disabledTitle]);

    return (
        <>
            <ToolTipClickContent placement={placement} title={tooltipTitle}>
                <div
                    className={`${isFocused ? classes.focused : ``} ${isDisabled ? classes.pointerEventsNone : ``}`}
                    ref={innerRef}
                    {...innerProps}
                >
                    <ListItem
                        disabled={isDisabled}
                        icon={Icon}
                        name={children}
                        isSelected={isSelected}
                        moreIcon={MoreIcon ? <MoreIcon /> : undefined}
                    />
                </div>
            </ToolTipClickContent>
        </>
    );
}

Option.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        onMouseMove: PropTypes.func.isRequired,
        onMouseOver: PropTypes.func.isRequired,
        tabIndex: PropTypes.number.isRequired
    }).isRequired,
    /**
     * Inner ref to DOM Node
     */
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired
        })
    ]),
    /**
     * Whether the option is focused.
     */
    isFocused: PropTypes.bool.isRequired,
    /**
     * Whether the option is selected.
     */
    isSelected: PropTypes.bool.isRequired
};

export default React.memo(Option);
