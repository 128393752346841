import * as types from '../types';

const handler = {
    [types.FETCH_PLANS](state, { payload }) {
        return {
            ...state,
            isFetchingPlans: true
        };
    },
    [types.FETCH_PLANS_FAILED](state, { payload }) {
        return {
            ...state,
            isFetchingPlans: false
        };
    },
    [types.FETCH_PLANS_SUCCESS](state, { payload }) {
        const { plans } = payload;
        return {
            ...state,
            isFetchingPlans: false,
            plans
        };
    }
};

export default handler;
