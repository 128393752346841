import React from 'react';

function ConflictSVG({ color = 'white', ...other }) {
    return (
        <svg {...other} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.94094 0.503379C6.27974 0.237909 6.85373 0.305319 7.17606 0.645457C7.48859 0.975262 7.42823 1.48293 7.02446 1.68513L7.00193 1.69641L4.83879 3.3671L7.58493 4.128C7.93747 4.22569 8.17732 4.45984 8.2274 4.74686L8.22984 4.76081L8.23399 4.77454C8.32885 5.08805 8.20512 5.38787 7.96196 5.57912L3.5921 8.90386L3.57997 8.91621C3.41352 9.08586 3.10155 9.12896 2.84084 9.05672C2.70173 9.01818 2.55337 8.90505 2.42424 8.76879C2.11755 8.44515 2.15539 7.909 2.51864 7.62207L5.64227 5.3104L2.88406 4.54615C2.53151 4.44846 2.29166 4.21431 2.24158 3.92729L2.23914 3.91334L2.23499 3.89961C2.14018 3.58626 2.26374 3.28657 2.50667 3.09531L5.93742 0.506139L5.94094 0.503379Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <path d="M0 0H10V10H0V0Z" fill={color} />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(ConflictSVG);
