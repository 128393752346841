import React from 'react';

function IconLimitAccessSVG({ color = '#FA0', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                fill="#FA0"
                fillRule="nonzero"
                d="M9.811 1.24a6.997 6.997 0 0 1 5.116 7.762A6.997 6.997 0 0 1 8 14.998a6.969 6.969 0 0 1-3.386-.872 6.956 6.956 0 0 1-1.736-1.355l-.184-.207-.173-.21-.092-.117-.097-.132-.193-.28a6.942 6.942 0 0 1-.76-1.554l-.101-.32-.045-.16-.063-.256-.02-.093a6.935 6.935 0 0 1-.121-2.068l.038-.335.034-.217A6.928 6.928 0 0 1 2.01 4.38c.287-.477.628-.911 1.01-1.299l.266-.254.214-.187.258-.207.144-.106a7.03 7.03 0 0 1 1.803-.94l.404-.126.252-.066.19-.043a6.965 6.965 0 0 1 3.261.088zm-1.78 1.056l-.206.004a5.726 5.726 0 0 0-1.088.138l-.328.083-.174.054-.274.097-.163.065-.128.056c-.155.07-.308.146-.456.23l-.255.15-.125.082c-.101.067-.2.138-.298.212l-.123.098-.121.1c-.138.119-.27.243-.397.374l-.103.11-.153.174-.136.167a5.71 5.71 0 0 0-.225.309l-.105.16-.09.149a5.748 5.748 0 0 0-.7 1.913l-.039.248-.01.081a5.785 5.785 0 0 0 .281 2.534l.102.267c.106.261.232.514.375.756l.2.315a5.943 5.943 0 0 0 .556.69l.267.266-.067-.064.157.145.161.138c.126.104.256.203.39.296l.28.18c.377.23.786.417 1.223.557l.289.086c.463.121.95.186 1.452.186a5.698 5.698 0 0 0 5.502-4.197l.048-.199A5.773 5.773 0 0 0 13.702 8l.002-.009a5.698 5.698 0 0 0-4.228-5.498 5.728 5.728 0 0 0-1.445-.196zm2.886 5.111c.34 0 .614.275.614.615v.087c0 .34-.275.615-.614.615H5.126a.614.614 0 0 1-.614-.615v-.087c0-.34.275-.615.614-.615h5.791z"
            />
        </svg>
    );
}

export default React.memo(IconLimitAccessSVG);
