import React from 'react';
import LDBasePortal from 'components/selects/LDBasePortal';
import i18n from 'i18n';

function NormalSelect({
    options,
    defaultValue,
    handleOptionChange,
    ddPlaceholder = 'Select a type',
    menuPlaceholder = i18n.t('global_find_a_type'),
    dropdownClassName,
    isUsingContainer = true,
    ...rest
}) {
    return (
        <LDBasePortal
            ddPlaceholder={ddPlaceholder}
            menuPlaceholder={menuPlaceholder}
            options={options}
            onChange={handleOptionChange}
            defaultValue={defaultValue}
            isMulti={false}
            dropdownClassName={dropdownClassName}
            isUsingContainer={isUsingContainer}
            {...rest}
        />
    );
}

export default React.memo(NormalSelect);
