import React from 'react';
import { Button as ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddRowBelowSVG from 'assets/images/svg/AddRowBelowSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import { DISABLED_OPACITY } from 'const/style';
import { useIsCreatingRecord, useIsDisabledCreateRecord } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as gridActions from '../actions';
import { useIsUserBlockedGridByPayment } from 'hooks/payment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    }
}));

const ManageCreateRecord = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { dbId, gridId, branchId, viewId } = useParams();
    const isDisabledCreateRecord = useIsDisabledCreateRecord({ dbId, gridId });
    const isCreatingRecord = useIsCreatingRecord();

    const handleCreateRecord = React.useCallback(() => {
        if (!branchId || !viewId || viewId === 'undefined' || !dbId) {
            return;
        }

        dispatch(
            gridActions.createViewRecord({
                recordCount: 1,
                errorCallback: error => {
                    console.log('failed to create record');
                },
                successCallback: () => {
                    console.log('success');
                }
            })
        );
    }, [dispatch, branchId, dbId, viewId]);

    return (
        <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_RECORD}>
            {({ isReadOnly }) => (
                <Tooltip
                    title={
                        isReadOnly ? (
                            <>
                                <p className="text-light">
                                    You do not have the appropriate permission rights to select.
                                </p>
                                <p className="text-light">Please message your admin.</p>
                            </>
                        ) : isDisabledCreateRecord ? (
                            <p className="text-center text-white body2">
                                Creating record is not
                                <br />
                                available for the this file
                            </p>
                        ) : (
                            t('global_create_record')
                        )
                    }
                >
                    <Grid item className={classes.menuItem}>
                        <ButtonBase
                            id="g-h-menu-item-records-add"
                            className={`${classes.addButton} ${classes.button} ${
                                isReadOnly || isCreatingRecord || isDisabledCreateRecord ? classes.disabled : ''
                            }`}
                            onClick={handleCreateRecord}
                            tr-dt="Create Record"
                        >
                            <AddRowBelowSVG height={18} width={18} color={theme.colors.darkness} />
                        </ButtonBase>
                    </Grid>
                </Tooltip>
            )}
        </AccessControl>
    );
};

const ManageCreateRecordWrapper = () => {
    const isUserBlockedGridByPayment = useIsUserBlockedGridByPayment();
    if (isUserBlockedGridByPayment) return null;
    return <ManageCreateRecord />;
};

export default React.memo(ManageCreateRecordWrapper);
