import React from 'react';

function ArrowMoveUpSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M8.00081 14C7.56397 14 7.20292 13.6731 7.14577 13.2488L7.1379 13.1308L7.13782 3.96765L5.47391 5.63929C5.20454 5.91175 4.80051 5.96624 4.47699 5.80306L4.35987 5.73166L4.25281 5.63986C3.94954 5.33447 3.91902 4.85811 4.16178 4.51835L4.25283 4.41057L7.38942 1.25237C7.47862 1.16254 7.58614 1.09347 7.72076 1.04763L7.86475 1.00964L8.03479 1L8.19859 1.0216L8.3056 1.05431L8.42546 1.11075L8.52634 1.17829L8.61043 1.25243L11.7469 4.41088C12.0843 4.75019 12.0844 5.3008 11.7471 5.64008C11.5834 5.80494 11.3641 5.8945 11.1379 5.8945C10.9561 5.89481 10.7789 5.8373 10.6312 5.72977L10.5263 5.63986L8.86373 3.96643L8.86373 13.1308C8.86373 13.527 8.60088 13.8611 8.24119 13.9659L8.11794 13.9921L8.00081 14Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ArrowMoveUpSVG);
