import React from 'react';
import { Tabs as MaterialTabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: { borderBottom: `1px solid ${theme.colors.border}` }
}));

const Tabs = ({ children, ...rest }) => {
    const classes = useStyles();
    return (
        <MaterialTabs classes={classes} indicatorColor="primary" textColor="primary" {...rest}>
            {children}
        </MaterialTabs>
    );
};

export default React.memo(Tabs);
