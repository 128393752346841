import React from 'react';

function SwapSVG({ color = '#4A90E2', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <rect width="16" height="16" rx="2.40908" fill="#4A90E2" />
            <g clipPath="url(#swap)">
                <path
                    d="M10.2494 2.26699C10.2262 2.291 10.2046 2.31644 10.1846 2.34316C9.96017 2.67789 10.0091 3.12553 10.3007 3.4038L10.5528 3.65595C10.6399 3.74273 10.7398 3.81562 10.849 3.87208L11.1492 4.02818L3.68866 4.02818C3.28223 4.01232 2.92439 4.29384 2.84415 4.69258C2.78001 5.12999 3.08264 5.53659 3.52005 5.6007C3.5613 5.60676 3.60295 5.60956 3.64463 5.60913L11.1372 5.60913L10.901 5.7172C10.7748 5.77561 10.6597 5.85551 10.5608 5.95334L10.2966 6.2175C10.0051 6.49577 9.95617 6.94341 10.1806 7.27814C10.4418 7.63483 10.9426 7.71228 11.2994 7.4511C11.3282 7.42998 11.3556 7.40697 11.3813 7.3822L13.3825 5.38099C13.6953 5.06856 13.6956 4.56172 13.3831 4.24893C13.3829 4.24873 13.3827 4.24851 13.3825 4.24831L11.3813 2.2471C11.0632 1.94004 10.5565 1.94894 10.2494 2.26699Z"
                    fill="white"
                />
                <path
                    d="M4.64068 8.65906L2.66348 10.6323C2.3507 10.9447 2.35042 11.4515 2.66286 11.7643C2.66306 11.7645 2.66328 11.7647 2.66348 11.7649L4.66469 13.7661C4.94296 14.0576 5.39061 14.1066 5.72533 13.8822C6.08203 13.621 6.15947 13.1202 5.89829 12.7634C5.87718 12.7346 5.85416 12.7072 5.8294 12.6815L5.55723 12.4093C5.42668 12.2789 5.26781 12.1802 5.09295 12.1212L4.8448 12.0131L12.4054 12.0131C12.8143 12.0283 13.1731 11.7426 13.2499 11.3407C13.3207 10.9043 13.0243 10.4931 12.5879 10.4224C12.5421 10.4149 12.4958 10.4115 12.4494 10.4121L4.8448 10.4121L5.07294 10.3321C5.24169 10.272 5.39486 10.1749 5.52121 10.0479L5.80939 9.75973C6.1221 9.44724 6.1223 8.94041 5.80981 8.62767C5.79428 8.61213 5.77812 8.59722 5.76136 8.58301C5.42438 8.31455 4.93828 8.34755 4.64068 8.65906Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="swap">
                    <rect width="12" height="12" fill="white" transform="translate(2.02271 14.0226) rotate(-90)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(SwapSVG);
