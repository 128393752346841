import React from 'react';

const DownloadSVG = ({ color = '#78778B', ...rest }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M7.16728 8.40795C7.19588 8.43655 7.23889 8.4451 7.27626 8.42962C7.31362 8.41415 7.33799 8.37768 7.33799 8.33724V1.46274C7.33799 1.09674 7.63468 0.800049 8.00068 0.800049C8.36667 0.800049 8.66336 1.09674 8.66336 1.46274V8.3386C8.66336 8.37904 8.68773 8.41551 8.72509 8.43099C8.76246 8.44646 8.80547 8.43791 8.83407 8.40931L11.1646 6.07877C11.4234 5.81998 11.843 5.81998 12.1018 6.07877C12.3606 6.33757 12.3606 6.75716 12.1018 7.01595L8.46995 10.6478C8.34567 10.7721 8.17711 10.8419 8.00136 10.8419C7.98993 10.8419 7.97836 10.8416 7.967 10.841C7.78942 10.8321 7.63028 10.7534 7.51632 10.6313L3.90093 7.01595C3.64213 6.75716 3.64213 6.33757 3.90093 6.07877C4.15972 5.81998 4.57931 5.81998 4.83811 6.07877L7.16728 8.40795Z"
                fill={color}
            />
            <path
                d="M1.46347 9.51647C1.82946 9.51647 2.12615 9.81317 2.12615 10.1792V13.0846C2.12615 13.2942 2.20939 13.4951 2.35755 13.6433C2.50572 13.7914 2.70667 13.8747 2.9162 13.8747H13.0854C13.2949 13.8747 13.4958 13.7914 13.644 13.6433C13.7922 13.4951 13.8754 13.2942 13.8754 13.0846V10.1792C13.8754 9.81317 14.1721 9.51647 14.5381 9.51647C14.9041 9.51647 15.2008 9.81317 15.2008 10.1792V13.0846C15.2008 13.6457 14.9779 14.1837 14.5812 14.5805C14.1845 14.9772 13.6464 15.2001 13.0854 15.2001H2.9162C2.35516 15.2001 1.81709 14.9772 1.42037 14.5805C1.02366 14.1837 0.800781 13.6457 0.800781 13.0846V10.1792C0.800781 9.81317 1.09748 9.51647 1.46347 9.51647Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(DownloadSVG);
