import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.FETCH_BRANCHES_OF_DATABASE_SUCCESS](state, { payload }) {
        const { branches } = payload;
        return {
            ...state,
            branches
        };
    },
    [types.FETCH_BRANCHES_OF_GRID](state, { payload }) {
        const { gridId } = payload;
        const { gridBranches } = state;

        const newGridBranches = produce(gridBranches, draft => {
            if (!draft[gridId]) {
                draft[gridId] = {};
            }

            draft[gridId].isFetching = true;
        });

        return {
            ...state,
            gridBranches: newGridBranches
        };
    },
    [types.FETCH_BRANCHES_OF_GRID_FAILED](state, { payload }) {
        const { gridId } = payload;
        const { gridBranches } = state;

        const newGridBranches = produce(gridBranches, draft => {
            if (!draft[gridId]) {
                draft[gridId] = {};
            }

            draft[gridId].isFetching = false;
        });

        return {
            ...state,
            gridBranches: newGridBranches
        };
    },
    [types.FETCH_BRANCHES_OF_GRID_SUCCESS](state, { payload }) {
        const { branches, gridId } = payload;
        const { gridBranches } = state;

        const newGridBranches = produce(gridBranches, draft => {
            if (!draft[gridId]) {
                draft[gridId] = {};
            }

            draft[gridId].list = branches;
            draft[gridId].isFetching = false;
        });

        return {
            ...state,
            gridBranches: newGridBranches
        };
    },
    [types.CREATE_GRID_BRANCH_SUCCESS](state, { payload }) {
        const { branch, gridId } = payload;
        const { gridBranches } = state;

        const newGridBranches = produce(gridBranches, draft => {
            if (!draft[gridId]) {
                draft[gridId] = {};
            }
            draft[gridId].list = [...draft[gridId].list, branch];
        });

        return {
            ...state,
            gridBranches: newGridBranches
        };
    },
    [types.INITITAL_BRANCH_TYPE](state, { payload }) {
        const { type } = payload;
        return {
            ...state,
            branchType: type
        };
    },
    [types.SWITCH_BRANCH](state, { payload }) {
        const { branchId } = payload;
        return {
            ...state,
            selectedBranchId: branchId
        };
    },
    [types.DELETE_GRID_BRANCH](state, { payload }) {
        const { masterId, gridId } = payload;
        const { gridBranches } = state;

        const newGridBranches = produce(gridBranches, draft => {
            if (!draft[masterId]) {
                draft[masterId] = {};
            }
            const newList = draft?.[masterId]?.list?.map(grid => {
                if (grid?.id === gridId) {
                    grid.isDeleted = true;
                }
                return grid;
            });
            draft[masterId].list = newList;
        });
        return {
            ...state,
            gridBranches: newGridBranches
        };
    },
    [types.DELETE_GRID_BRANCH_FAILED](state, { payload }) {
        const { masterId, gridId } = payload;
        const { gridBranches } = state;

        const newGridBranches = produce(gridBranches, draft => {
            if (!draft[masterId]) {
                draft[masterId] = {};
            }
            const newList = draft?.[masterId]?.list?.map(grid => {
                if (grid?.id === gridId) {
                    grid.isDeleted = false;
                }
                return grid;
            });

            draft[masterId].list = newList;
        });
        return {
            ...state,
            gridBranches: newGridBranches
        };
    },
    [types.DELETE_GRID_BRANCH_SUCCESS](state, { payload }) {
        const { masterId, gridId } = payload;
        const { gridBranches } = state;

        const newGridBranches = produce(gridBranches, draft => {
            if (!draft[masterId]) {
                draft[masterId] = {};
            }

            const newList = draft?.[masterId]?.list?.filter(grid => grid.id !== gridId);
            draft[masterId].list = newList;
        });

        return {
            ...state,
            gridBranches: newGridBranches
        };
    },
    [types.UPDATE_GRID_BRANCH](state, { payload }) {
        const { masterId, gridId, grid } = payload;
        const { gridBranches } = state;

        const newGridBranches = produce(gridBranches, draft => {
            if (!draft[masterId]) {
                draft[masterId] = {};
            }
            const newList = draft?.[masterId]?.list?.map(gridBranch => {
                if (gridBranch?.id === gridId) {
                    return {
                        ...gridBranch,
                        ...grid
                    };
                }
                return gridBranch;
            });

            draft[masterId].list = newList;
        });

        return {
            ...state,
            gridBranches: newGridBranches
        };
    },
    [types.CHANGE_BRANCH_STATUS](state, { payload }) {
        const { masterId, gridId, status } = payload;
        const { gridBranches } = state;

        const newGridBranches = produce(gridBranches, draft => {
            if (!draft[masterId]) {
                draft[masterId] = {};
            }
            const newList = draft?.[masterId]?.list?.map(gridBranch => {
                if (gridBranch?.id === gridId) {
                    gridBranch.status = status;
                }
                return gridBranch;
            });

            draft[masterId].list = newList;
        });

        return {
            ...state,
            gridBranches: newGridBranches
        };
    },
    [types.MERGE_BRANCH](state, { payload }) {
        return {
            ...state,
            isMerging: true
        };
    },
    [types.MERGE_BRANCH_FAILED](state, { payload }) {
        return {
            ...state,
            isMerging: false
        };
    },
    [types.MERGE_BRANCH_SUCCESS](state, { payload }) {
        return {
            ...state,
            isMerging: false
        };
    }
};

export default handler;
