import { serialize } from 'components/formula/serialize';
import { deserialize, deserializeMention, deserializePayload } from 'components/formula/deserialize';
import { DESERIALIZE_TYPES, _clearTag } from 'components/formula/const';
import { UpperCaseFirstCharacter } from 'utils/name';

export function getFormulaServerValue(slateData) {
    const value = serialize(slateData);
    return value?.replace(/(<p>|<\/p>)/g, '');
}

export function getSlateDataFromFormula({ string, metaData }) {
    const slateData = deserialize(string);

    const children = slateData?.map(token => {
        switch (token?.type) {
            case DESERIALIZE_TYPES.PARENTHESIS:
            case DESERIALIZE_TYPES.SPECIAL:
            case DESERIALIZE_TYPES.COMMA:
            case DESERIALIZE_TYPES.TEXT:
            case DESERIALIZE_TYPES.OPERATOR:
            case DESERIALIZE_TYPES.COMPARATOR:
            case DESERIALIZE_TYPES.LITERAL:
            case DESERIALIZE_TYPES.QUOTE:
            case DESERIALIZE_TYPES.SPACE:
            case DESERIALIZE_TYPES.POINT:
                return { text: token.value };
            case DESERIALIZE_TYPES.FUNCTION:
                return {
                    type: 'func',
                    suggestion: {
                        type: 'func',
                        label: UpperCaseFirstCharacter(_clearTag(token.value)),
                        value: token.value
                    },
                    children: [{ text: '' }]
                };
            case DESERIALIZE_TYPES.VARIABLE: {
                const columnMatch = token.value.match(/^col_val_(\w+)$/);
                const columnId = columnMatch?.[1];
                const columnDetail = metaData?.[columnId];
                return {
                    type: 'tag',
                    suggestion: {
                        type: 'column',
                        columnType: columnDetail?.type,
                        label: columnDetail?.name,
                        value: `col_val_${columnDetail?.id}`,
                        group: columnDetail?.group
                    },
                    children: [{ text: '' }]
                };
            }

            default:
                return { text: token.value };
        }
    });

    return [{ type: 'paragraph', children: [{ text: '' }, ...children] }];
}

export function getSlateDataFromMention({ string, userData }) {
    if (!string) {
        return [{ type: 'paragraph', children: [{ text: '' }] }];
    }
    const slateData = deserializeMention(string);
    const children = slateData?.map(token => {
        switch (token?.type) {
            case DESERIALIZE_TYPES.MENTION: {
                const id = token.value.replace(/[@{}]/g, '');
                if (id && !isNaN(id)) {
                    const user = userData[parseInt(id)];
                    return { type: 'mention', children: [{ text: '' }], user };
                }
                return { text: token.value };
            }
            default:
                return { text: token.value };
        }
    });

    return [{ type: 'paragraph', children: [{ text: '' }, ...children, { text: '' }] }];
}

export function getSlateDataFromPayload({ string }) {
    if (!string) {
        return [{ type: 'paragraph', children: [{ text: '' }] }];
    }
    const slateData = deserializePayload(string);
    const children = slateData?.map(token => {
        switch (token?.type) {
            case DESERIALIZE_TYPES.PAYLOAD: {
                return { type: DESERIALIZE_TYPES.PAYLOAD, children: [{ text: token?.value }], slug: token?.value };
            }
            default:
                return { text: token.value };
        }
    });

    return [{ type: 'paragraph', children: [{ text: '' }, ...children, { text: '' }] }];
}
