import React from 'react';
import CountryFlag from 'components/svg-icon/CountryFlag';
import SvgIcon from 'components/svg-icon/Base';
import { TRANSLATION_TYPES } from './column-types';
import { Grid } from '@material-ui/core';
import * as columnTypes from 'const/columnTypes';
import TwoDigitsLang from 'common/TwoDigitsLang';
import OverflowTypography from 'components/textTruncated/OverflowTypography';
import classNames from 'classnames';

function getPrefix(localizationType) {
    let label = null;

    if (localizationType === TRANSLATION_TYPES.SOURCE_LANG) {
        label = 'Source';
    } else if (localizationType === TRANSLATION_TYPES.TARGET_LANG) {
        label = 'Target';
    } else if (localizationType === TRANSLATION_TYPES.ORIGINAL_LANG) {
        label = 'Original';
    }

    return label;
}

export function ColumnIcon({
    type,
    customProperties = {},
    group,
    hideLabelCountry,
    styleCountryFlagWrapper = {},
    style2Digit = {},
    containerClassname,
    normalWrapper,
    normalWrapperStyle = {}
}) {
    const { localizationType } = customProperties;

    if (
        localizationType &&
        type === columnTypes.TRANSLATION &&
        (localizationType === TRANSLATION_TYPES.SOURCE_LANG ||
            localizationType === TRANSLATION_TYPES.TARGET_LANG ||
            localizationType === TRANSLATION_TYPES.ORIGINAL_LANG)
    ) {
        let label = getPrefix(localizationType);
        const is2Digits = group?.length === 2;
        return (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}
                wrap="nowrap"
                className={classNames(`containerClassname !w-fit`)}
            >
                {is2Digits ? (
                    <Grid item style={style2Digit}>
                        <TwoDigitsLang lang={group} />
                    </Grid>
                ) : (
                    <Grid item style={{ display: 'flex', ...styleCountryFlagWrapper }}>
                        <CountryFlag languageCountryCode={group} />
                    </Grid>
                )}

                {label && !hideLabelCountry && (
                    <Grid item>
                        <p className="caption !font-normal">
                            ({label}_{group})
                        </p>
                    </Grid>
                )}
            </Grid>
        );
    } else if (normalWrapper) {
        return (
            <Grid item style={{ ...normalWrapperStyle }}>
                <SvgIcon type={type} />
            </Grid>
        );
    } else {
        return <SvgIcon type={type} />;
    }
}

export function ColumnTypeSelectListDisplay({ type, group, customProperties, name }) {
    const localizationType = customProperties?.localizationType;

    if (!localizationType) return name;

    const prefix = getPrefix(localizationType);

    return (
        <>
            <span className="caption inline !font-normal">
                ({prefix}_{group})
            </span>
            <p className="body2 inline">{name}</p>
        </>
    );
}

export default function ColumnTypeDisplay({ type, name, customProperties, group, truncated }) {
    return (
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} wrap="nowrap">
            <Grid item style={{ display: 'flex' }}>
                <ColumnIcon group={group} type={type} customProperties={customProperties} />
            </Grid>
            <Grid item style={{ overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }}>
                <OverflowTypography tooltipClassName="!whitespace-nowrap" truncated={truncated} variant="body2">
                    {name}
                </OverflowTypography>
            </Grid>
        </Grid>
    );
}
