import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, useTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { useDropzone } from 'react-dropzone';
import * as importActions from 'gridUI/actions/import';
import { sendManualTrack } from 'tracker';
import {
    GRID_UPLOAD_MAX_SIZE,
    EXPORT_LOCALIZATION_EXTENSIONS,
    EXPORT_LOCALIZATION_JSON_EXTENSIONS,
    EXPORT_LOCALIZATION_PO_EXTENSIONS
} from 'const';
import hexToRgba from 'hex-to-rgba';
import ColorLinearProgress from 'components/progress/ColorLinearProgress';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import { useTranslation } from 'react-i18next';
import { getUploadErrorMessage } from 'utils/upload';
import { isFileJSON, isFilePO } from 'utils/fileUtils';
import { GRID_TYPE_SETTING, RECORDS_RENDER_GRID_PREVIEW, SHOW_GRID_TYPES, TASK_STATUS } from 'const/gridUI';
import ImportLocalizationFileSVG from 'assets/images/svg/localization/ImportLocalizationFileSVG';
import InfoContainedIconSVG from 'assets/images/svg/InfoContainedIconSVG';
import { useParams } from 'react-router-dom';
import LocalizationImportPreview from 'gridUI/importExport/LocalizationImportPreview';
import { getPreviewImportTranslationsApi, getPreviewImportTranslationsTaskIdApi } from 'services/grid';
import { formatDataGridPreview } from 'utils/gridUI/formatData';
import produce from 'immer';
import { useTaskStatus } from 'hooks/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        width: 800,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            minWidth: 'initial'
        }
    },
    fw400: {
        fontWeight: 400
    },
    box: {
        background: theme.colors.ghostwhite,
        minHeight: 400,
        border: `1px dashed ${theme.colors.lightGrey}`,
        borderRadius: 4,
        outline: 'none',
        cursor: 'pointer',
        position: 'relative'
    },
    description: {
        marginBottom: theme.spacing(3),
        '& ul': {
            paddingLeft: 30,
            '& li': {
                marginTop: 10
            }
        }
    },
    note: {
        width: 180,
        textAlign: 'center'
    },
    reject: {
        background: hexToRgba(theme.colors.brightRed, 0.5)
    },
    progressBar: {
        marginTop: theme.spacing(3),
        minWidth: 276
    },
    supportedFile: {
        '& p': {
            color: theme.palette.primary.main
        }
    },
    dflex: {
        display: 'flex'
    },
    warningBox: {
        position: 'absolute',
        bottom: 16,
        left: 0,
        margin: '0 auto',
        width: '100%'
    },
    warning: {
        margin: '0 auto',
        padding: '8px 16px',
        background: 'rgba(110, 91, 190, 0.1)',
        borderRadius: 4,
        width: 'fit-content'
    },

    inkBox: {
        background: theme.colors.paleGrey,
        padding: `6px 8px`,
        borderRadius: 4
    }
}));

function ExportLocalization({ onClose, handleSetFiles, gridDetail }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { workspaceId, gridId, viewId, dbId, branchId } = useParams();
    const [isImporting, setIsImporting] = React.useState(false);
    const [isPreview, setIsPreview] = React.useState(false);
    const { t } = useTranslation();
    const [files, setFiles] = React.useState(null);
    const [columnsWithPublicId, setColumnsWithPublicId] = React.useState();
    const [haveChanged, setHaveChanged] = React.useState(false);
    const [isFetchingDiffCheck, setIsFetchingDiffCheck] = React.useState(false);
    const [resourceKey, setResourceKey] = React.useState();
    const [taskId, setTaskId] = React.useState();
    const taskStatus = useTaskStatus(taskId);

    const [previewData, setPreviewData] = React.useState({
        isFetchingMore: false,
        rows: [],
        data: {},
        metaData: {},
        totalRecords: 0,
        ROW_START_INDEX: 0,
        ROW_STOP_INDEX: RECORDS_RENDER_GRID_PREVIEW,
        tree: []
    });

    const exportLocalizationExtensions = React.useMemo(() => {
        let arr = [...EXPORT_LOCALIZATION_EXTENSIONS];
        if (gridDetail?.type === GRID_TYPE_SETTING.JSON) {
            arr = [...arr, ...EXPORT_LOCALIZATION_JSON_EXTENSIONS];
        }
        if (gridDetail?.type === GRID_TYPE_SETTING.PO) {
            arr = [...arr, ...EXPORT_LOCALIZATION_PO_EXTENSIONS];
        }
        return arr;
    }, [gridDetail]);

    const onDropRejected = React.useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles, exportLocalizationExtensions);
            const fileError = fileErrors?.[0];

            sendManualTrack({ type: `Localization Import File In Reject Mode` });
            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch, exportLocalizationExtensions]
    );

    const onDropAccepted = React.useCallback(
        acceptedFiles => {
            setFiles(acceptedFiles);
            if (isFileJSON(acceptedFiles?.[0]) || isFilePO(acceptedFiles?.[0])) {
                handleSetFiles(acceptedFiles);
                return;
            }
            setIsImporting(true);

            sendManualTrack({ type: `Start Localization Import File` });
            acceptedFiles.forEach(file => {
                dispatch(
                    importActions.importPreviewLocalizationGrid({
                        file,
                        successCallback: async ({ metaData, _columnsWithPublicId, firstRow, resourceKey }) => {
                            setColumnsWithPublicId(_columnsWithPublicId);
                            setResourceKey(resourceKey);
                            setIsImporting(false);
                            setIsPreview(true);
                        },
                        errorCallback: () => {
                            setIsImporting(false);
                            sendManualTrack({ type: `Localization Import File Failed` });
                        }
                    })
                );
            });
        },
        [dispatch, handleSetFiles]
    );

    const manageCheckDiff = React.useCallback(async () => {
        try {
            const task = await getPreviewImportTranslationsApi({
                dbId,
                viewId,
                resourceKey
            });

            setTaskId(task?.id);
        } catch (e) {
            console.error(e);
        }
    }, [dbId, resourceKey, viewId]);

    const fetchingDataGridPreview = React.useCallback(
        async ({ ROW_START_INDEX = 0, ROW_STOP_INDEX = RECORDS_RENDER_GRID_PREVIEW }) => {
            setIsFetchingDiffCheck(true);
            try {
                const [{ totalRecords, records }] = await Promise.all([
                    await getPreviewImportTranslationsTaskIdApi({
                        dbId,
                        viewId,
                        taskId,
                        limit: ROW_STOP_INDEX,
                        offset: ROW_START_INDEX
                    })
                ]);

                const { rows, data } = formatDataGridPreview(records);
                setIsFetchingDiffCheck(false);
                if (rows.length < 1) setHaveChanged(true);
                setPreviewData(
                    produce(previewData, draft => {
                        draft.isFetchingMore = false;
                        draft.rows = rows;
                        draft.data = data;
                        draft.totalRecords = totalRecords;
                        draft.ROW_STOP_INDEX = ROW_STOP_INDEX;
                        draft.ROW_START_INDEX = ROW_START_INDEX;
                    })
                );
            } catch (e) {
                setIsFetchingDiffCheck(false);
            }
        },
        [dbId, previewData, taskId, viewId]
    );

    React.useEffect(() => {
        if (taskStatus === TASK_STATUS.SUCCESS) {
            fetchingDataGridPreview({ offset: 0, limit: RECORDS_RENDER_GRID_PREVIEW });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskStatus]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDropAccepted,
        onDropRejected,
        maxSize: GRID_UPLOAD_MAX_SIZE,
        accept: exportLocalizationExtensions,
        noDragEventsBubbling: true
    });

    const style = React.useMemo(
        () => ({
            ...(isDragActive
                ? {
                      border: `1px dashed ${theme.colors.highlight}`,
                      background: hexToRgba(theme.colors.highlight, 0.05),
                      borderRadius: 4
                  }
                : {})
        }),
        [isDragActive, theme]
    );

    const TitleRender = React.useMemo(() => {
        return (
            <Grid container alignItems="center" direction="row" spacing={1}>
                <Grid item>
                    <h3 className="prose prose-2xl font-medium">{t(`localization_import`)} </h3>
                </Grid>
                {files && (
                    <div className="bg-grey-pale rounded py-1.5 px-2">
                        <p className="body2 line-clamp-1">{files?.[0]?.name}</p>
                    </div>
                )}
            </Grid>
        );
    }, [classes.inkBox, files, t]);

    return (
        <>
            {isPreview ? (
                <>
                    <LocalizationImportPreview
                        onClose={onClose}
                        files={files}
                        workspaceId={workspaceId}
                        gridId={gridId}
                        viewId={viewId}
                        dbId={dbId}
                        taskId={taskId}
                        branchId={branchId}
                        isMulti={false}
                        columnsWithPublicId={columnsWithPublicId}
                        previewData={previewData}
                        haveChanged={haveChanged}
                        isFetchingDiffCheck={isFetchingDiffCheck}
                        manageCheckDiff={manageCheckDiff}
                        fetchingDataGridPreview={fetchingDataGridPreview}
                    />
                </>
            ) : (
                <>
                    {' '}
                    <DialogTitle title={TitleRender} onClose={onClose} />
                    <DialogContent>
                        <Grid container className={classes.root} direction="column" wrap="nowrap">
                            <Grid item className={classes.supportedFile}>
                                <p className="body1">{t(`supported_file`)}</p>
                            </Grid>
                            <Grid item className={classes.description}>
                                <ul>
                                    <li>
                                        <p className="body2">
                                            <b>XLSX</b> file which is exported by the <b>Localization Export</b> feature
                                            from Gridly
                                        </p>
                                    </li>
                                    {SHOW_GRID_TYPES.includes(gridDetail?.type) && (
                                        <li>
                                            <p className="body2">
                                                <b>{gridDetail?.type.toUpperCase()}</b> file to update this grid
                                            </p>
                                        </li>
                                    )}
                                </ul>
                            </Grid>

                            <Grid
                                item
                                container
                                direction="column"
                                alignItems="center"
                                className={classes.box}
                                justify="center"
                                {...getRootProps({ style })}
                            >
                                <Grid item>
                                    <Grid
                                        className={`${isDragActive ? classes.circle : ``}`}
                                        container
                                        alignItems="center"
                                        justify="center"
                                        direction="column"
                                        spacing={3}
                                    >
                                        <Grid item>
                                            <ImportLocalizationFileSVG />
                                        </Grid>
                                        <Grid item className={classes.note}>
                                            <p className="caption">
                                                {isImporting
                                                    ? `${t(`global_uploading`)}...`
                                                    : t(`global_drag_and_drop_preview_message`)}
                                            </p>
                                        </Grid>
                                    </Grid>
                                    <input multiple style={{ outline: 'none' }} {...getInputProps()} />
                                    <Grid item className={classes.warningBox}>
                                        <Grid container alignItems="center" className={classes.warning} spacing={1}>
                                            <Grid item className={classes.dflex}>
                                                <InfoContainedIconSVG />
                                            </Grid>
                                            <Grid item>
                                                <p className="body2">
                                                    <b>Important:</b> The translations will be updated automatically
                                                    following Record IDs
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {isImporting && (
                                    <Grid item className={classes.progressBar}>
                                        <ColorLinearProgress />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                </>
            )}
        </>
    );
}

export default React.memo(ExportLocalization);
