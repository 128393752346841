import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Picker } from 'emoji-mart';
import useClickAwaitListener from 'hooks/useClickAwaitListener';

const useStyles = makeStyles(theme => ({
    root: {
        '& .emoji-mart-bar:last-child': {
            display: 'none'
        }
    }
}));

function EmojiBox({ handleClickAway, onChange }) {
    const classes = useStyles();
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    return (
        <Grid ref={rootRef} container className={classes.root}>
            <Grid item>
                <Picker onSelect={onChange} />
            </Grid>
        </Grid>
    );
}

export default React.memo(EmojiBox);
