import React from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import Option from 'components/option/Base';
import { ROW_HEIGHT } from 'const/gridUI';

function ReferenceSelectRender({ value, rowHeight = ROW_HEIGHT, rowIndex, columnIndex }) {
    const theme = useTheme();

    if (isEmpty(value)) return null;

    const getJsonValue = value => {
        if (isArray(value)) {
            let values = value.map(i => {
                const referencedDataItem = i?.referencedDataItem;
                if (typeof referencedDataItem !== 'string') return null;
                return referencedDataItem;
            });
            return values.filter(Boolean);
        }
        return null;
    };

    let newData = getJsonValue(value);

    if (!isArray(newData) || !newData) return null;

    return (
        <Grid
            container
            className={'w-full pointer-events-none'}
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{
                height: rowHeight
            }}
        >
            <Grid item className={`cell_${rowIndex}_${columnIndex} flex flex-wrap`}>
                {newData.map((item, index) => (
                    <Option
                        className={'mr-1 mb-1'}
                        key={index}
                        size="small"
                        label={item}
                        color={theme.colors.dodgerBlue}
                    />
                ))}
            </Grid>
        </Grid>
    );
}

export default React.memo(ReferenceSelectRender);
