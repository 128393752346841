import React, { useEffect, useState } from 'react';
import { useWorkspaceList } from 'hooks/workspace';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchWorkspaces } from './actions';
import { fetchMe, fetchUserWorkspaceRole, _setCompanyId } from 'auth/actions';

export default function WorkspaceRedirect() {
    const location = useLocation();
    const dispatch = useDispatch();
    const workspaceList = useWorkspaceList();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        dispatch(
            fetchWorkspaces({
                successCallback: workspaces => {
                    if (workspaces.length === 0) return;
                    dispatch(
                        fetchUserWorkspaceRole({
                            workspaceId: workspaces[0].id,
                            successCallback: companyId => {
                                dispatch(_setCompanyId({ companyId }));
                                dispatch(fetchMe({ companyId }));
                                setIsLoading(false);
                            },
                            errorCallback: () => {
                                console.log('failed to fetch project role');
                                setIsLoading(false);
                            }
                        })
                    );
                }
            })
        );
    }, [dispatch]);

    if (isLoading || workspaceList.length === 0) return null;
    return <Redirect to={{ pathname: `/projects/${workspaceList[0].id}`, search: location.search }} />;
}
