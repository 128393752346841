import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import BooleanSVG from './BooleanSVG';
import DateTimeSVG from './DateTimeSVG';
import FilesSVG from './FilesSVG';
import MultiSelectSVG from './MultiSelectSVG';
import MultipleLinesSVG from './MultiLinesSVG';
import NumberSVG from './NumberSVG';
import SingleSelectSVG from './SingleSelectSVG';
import SingleLineSVG from './SingleLineSVG';
import ReferenceSVG from './ReferenceSVG';
import LocalizationSVG from './LocalizationSVG';
import FormulaSVG from './FormulaSVG';
import RichtextSVG from './RichtextSVG';
import MarkdownSVG from './MarkdownSVG';
import PathTagSVG from './PathTagSVG';
import YamlSVG from './YamlSVG';
import JsonSVG from './JsonSVG';
import HtmlSVG from './HtmlSVG';
import RecordIdSVG from './RecordIdSVG';
import ModifiedBySVG from './ModifiedBySVG';
import * as columnTypes from 'const/columnTypes';

function getSVGImage({ type, color, classNameFull, theme }) {
    switch (type) {
        case columnTypes.FILES:
            return <FilesSVG className={classNameFull} color={color} />;
        case columnTypes.SINGLE_LINE:
            return <SingleLineSVG className={classNameFull} color={color} />;
        case columnTypes.MULTIPLE_LINES:
            return <MultipleLinesSVG className={classNameFull} color={color} />;
        case columnTypes.BOOLEAN:
            return <BooleanSVG className={classNameFull} color={color} />;
        case columnTypes.NUMBER:
            return <NumberSVG className={classNameFull} color={color} />;
        case columnTypes.DATETIME:
            return <DateTimeSVG className={classNameFull} color={color} />;
        case columnTypes.SINGLE_SELECTION:
            return <SingleSelectSVG className={classNameFull} color={color} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultiSelectSVG className={classNameFull} color={color} />;
        case columnTypes.REFERENCE:
            return <ReferenceSVG color={color} className={classNameFull} />;
        case columnTypes.LOCALIZATION:
            return <LocalizationSVG color={color} className={classNameFull} />;
        case columnTypes.FORMULA:
            return <FormulaSVG color={color} className={classNameFull} />;
        case columnTypes.RICH_TEXT:
            return <RichtextSVG color={color} className={classNameFull} />;
        case columnTypes.MARKDOWN:
            return <MarkdownSVG color={color} className={classNameFull} />;
        case columnTypes.TRANSLATION:
            return <LocalizationSVG color={color} className={classNameFull} />;
        case columnTypes.PATH_TAG:
            return <PathTagSVG color={theme.colors.systemIconColor} className={classNameFull} />;
        case columnTypes.ALTERED_TIME:
            return <DateTimeSVG color={theme.colors.systemIconColor} className={classNameFull} />;
        case columnTypes.ALTERED_BY:
            return <ModifiedBySVG className={classNameFull} color={theme.colors.systemIconColor} />;
        case columnTypes.CREATED_TIME:
            return <DateTimeSVG color={theme.colors.systemIconColor} className={classNameFull} />;
        case columnTypes.CREATED_BY:
            return <ModifiedBySVG className={classNameFull} color={theme.colors.systemIconColor} />;
        case columnTypes.JSON_LD:
            return <JsonSVG className={classNameFull} color={color} />;
        case columnTypes.HTML:
            return <HtmlSVG className={classNameFull} color={color} />;
        case columnTypes.YAML:
            return <YamlSVG className={classNameFull} color={color} />;
        case columnTypes.RECORD_ID:
            return <RecordIdSVG className={classNameFull} color={theme.colors.systemIconColor} />;
        default:
            return <MultipleLinesSVG color={color} className={classNameFull} />;
    }
}

function SvgIcon({ type, color, className }) {
    // const classes = useStyles();
    const theme = useTheme();
    let classNameFull = `max-w-[18px] ${className}`;
    const iconSVG = getSVGImage({ type, color, classNameFull, theme });

    return iconSVG;
}

SvgIcon.propTypes = {
    type: PropTypes.string.isRequired
};

export default React.memo(SvgIcon);
