import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import { getPlansApi } from 'services/payment';

export function fetchPlans({ errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchPlansAction());

        try {
            const plans = await getPlansApi();
            dispatch(_fetchPlansSuccess(plans));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
            dispatch(_fetchPlansFailed());
        }
    };
}

function _fetchPlansAction() {
    return {
        type: types.FETCH_PLANS
    };
}

function _fetchPlansSuccess(plans) {
    return {
        type: types.FETCH_PLANS_SUCCESS,
        payload: {
            plans
        }
    };
}

function _fetchPlansFailed() {
    return {
        type: types.FETCH_PLANS_FAILED
    };
}
