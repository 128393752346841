import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
// import LocalizationRender from '../table/components/Localization';
// import FormulaTextRender from '../table/components/FormulaText';
// import Tags from '../table/components/Tag';
import * as columnTypes from 'const/columnTypes';
import { CELL_PADDING, CONFLICT_TYPES, BORDER_HIGHLIGHT, PREVIEW_STATUS, LOCALIZATION_FONT } from 'const/gridUI';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import hexToRgba from 'hex-to-rgba';
import PopperMenu from 'components/menus/Popper';
import DiffCheckUI from './DiffCheckUI';
import SwapSVG from 'assets/images/svg/SwapSVG';
import TextRender from './components/Text';
import NumberRender from './components/Number';
import BooleanRender from './components/Boolean';
import DatetimeRender from './components/Datetime';
import SingleSelectionRender from './components/SingleSelection';
import MultipleSelectionRender from './components/MultipleSelection';
import RefSelectionRender from './components/RefSelection';
import FilesRender from './components/Files';
import AlterBy from './components/AlterBy';
import BranchSVG from 'assets/images/svg/BranchSVG';
import GridSVG from 'assets/images/svg/GridSVG';
import ImportSVG from 'assets/images/svg/ImportSVG';
import LangStatus from 'components/langStatus';
import DiffCheckUIBeforeAfter from './DiffCheckUIBeforeAfter';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import { useDependencyBlindness } from 'hooks/auth';

const useStyles = makeStyles(theme => ({
    '@keyframes blinker': {
        '0%': {
            opacity: 0.2
        },
        '100%': {
            opacity: 0.8
        }
    },
    root: {
        padding: CELL_PADDING,
        border: `${BORDER_HIGHLIGHT}px solid transparent`,
        margin: 0,
        width: '100%',
        height: '100%',
        cursor: 'default',
        background: props => props.background || `transparent`,
        '& .highlight': {
            background: theme.colors.sun,
            color: theme.colors.white
        },
        position: 'relative'
    },
    cellSelected: {
        border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight} !important`
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    loading: {
        animation: '$blinker 1s infinite alternate linear',
        background: '#E6EAF1',
        borderRadius: 5
    },
    cellHighlight: {
        background: props => (props.background ? hexToRgba(props.background, 0.8) : theme.colors.selectionColor)
    },
    cellContextMenu: {
        position: 'relative'
    },
    commentActiveWrapper: {
        position: 'absolute',
        top: -2,
        right: -2,
        cursor: 'pointer',
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
    },
    commentActive: {
        width: 0,
        height: 0,
        borderStyle: `solid`,
        borderWidth: `0 10px 10px 0`,
        borderColor: `transparent ${theme.colors.sun} transparent transparent`
    },
    previewCell: {
        position: 'absolute',
        cursor: 'pointer',
        right: 4,
        top: 5,
        display: 'flex',
        alignItems: 'center'
    },
    iconAction: {
        background: theme.colors.white,
        padding: 2,
        borderRadius: 2,
        display: 'flex'
    },
    revertIcon: {
        marginRight: theme.spacing(1)
    },
    dependencyStatus: {
        position: 'absolute',
        top: 6,
        left: 4
    },
    branchTag: {
        position: 'absolute',
        bottom: -6,
        left: -6,
        fontSize: 10,
        pointerEvents: 'none',
        padding: 2,
        borderRadius: 4
    },

    outOfDate: {
        background: hexToRgba(theme.colors.sun, 0.8)
    },
    upToDate: {
        background: hexToRgba(theme.colors.atlantis, 0.8)
    },
    notTranslated: {
        background: hexToRgba(theme.colors.brightRed, 0.8)
    }
}));

function checkTypeAndRender({ type, rowData, rowHeight, ...rest }) {
    switch (type) {
        case columnTypes.FILES:
            return <FilesRender data={rowData} rowHeight={rowHeight} {...rest} />;
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.RICH_TEXT:
        case columnTypes.MARKDOWN:
        case columnTypes.TRANSLATION:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
        case columnTypes.RECORD_ID:
            return <TextRender data={rowData} rowHeight={rowHeight} {...rest} />;

        case columnTypes.ALTERED_BY:
            return <AlterBy data={rowData} rowHeight={rowHeight} {...rest} />;

        case columnTypes.NUMBER:
            return <NumberRender data={rowData} rowHeight={rowHeight} {...rest} />;
        case columnTypes.BOOLEAN:
            return <BooleanRender data={rowData} rowHeight={rowHeight} {...rest} />;
        case columnTypes.DATETIME:
        case columnTypes.ALTERED_TIME:
        case columnTypes.CREATED_TIME:
            return <DatetimeRender data={rowData} rowHeight={rowHeight} {...rest} />;
        case columnTypes.SINGLE_SELECTION:
            return <SingleSelectionRender data={rowData} rowHeight={rowHeight} {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultipleSelectionRender data={rowData} rowHeight={rowHeight} {...rest} />;
        case columnTypes.REFERENCE:
            return <RefSelectionRender data={rowData} rowHeight={rowHeight} {...rest} />;
        // case columnTypes.LOCALIZATION:
        //     return <LocalizationRender data={rowData} rowHeight={rowHeight} {...rest} />;
        // case columnTypes.GROUP_TAGS:
        //     return <Tags data={rowData} {...rest} />;
        // case columnTypes.FORMULA:
        //     return <FormulaTextRender rowHeight={rowHeight} data={rowData} {...rest} />;
        default:
            return <TextRender data={rowData} rowHeight={rowHeight} {...rest} />;
    }
}

function CellRow({
    className,
    column,
    rowData,
    rowIndex,
    columnIndex,
    rowId,
    columnId,
    rowHeight,
    rdCellInfo,
    isScrolling,
    editable,
    filterValue,
    rowDataByRowId,
    columnWidth,
    background,
    isCellHighlight,
    isSelectedByRowOrColumn,
    status,
    previousData,
    columnType,
    previousColumnType,
    currentData,
    prefixId,
    dbId,
    t,
    isOverride,
    conflictMode,
    onConflictChanged,
    branchName,
    childData,
    cellConflictPickedResult,
    conflictType,
    mergeToBranchName,
    ldUserEmailMap,
    onRevert,
    showRevert,
    isDisabled,
    isShareViewLink,
    dependencyStatus,
    previousDependencyStatus,
    sourceStatus,
    previousSourceStatus,
    recordHistoryId,
    onRevertBefore,
    isMergeGrid
}) {
    const classes = useStyles({ isCursor: !!rdCellInfo, background });
    const theme = useTheme();
    const dependencyBlindness = useDependencyBlindness();

    const type = React.useMemo(() => {
        return getCorrectColumnType(column);
    }, [column]);

    const fontJP = React.useMemo(() => {
        return LOCALIZATION_FONT?.[column?.group];
    }, [column]);

    const isShowDetailDiff = React.useMemo(() => {
        return ![PREVIEW_STATUS.NORMAL].includes(status);
    }, [status]);

    const isShowRevert = React.useMemo(() => {
        const isSameType = !previousColumnType || previousColumnType === columnType;

        return isShowDetailDiff && (type === columnType || [columnTypes.PATH_TAG]?.includes(type)) && isSameType;
    }, [isShowDetailDiff, type, columnType, previousColumnType]);

    const isShowDiffDependencies = React.useMemo(() => {
        if (!previousSourceStatus && !sourceStatus && !previousDependencyStatus && !dependencyStatus) return false;
        return !!(previousSourceStatus !== sourceStatus || previousDependencyStatus !== dependencyStatus);
    }, [previousSourceStatus, sourceStatus, previousDependencyStatus, dependencyStatus]);

    // console.log('This is is show diff check', {
    //     previousSourceStatus,
    //     sourceStatus,
    //     previousDependencyStatus,
    //     dependencyStatus
    // });

    // console.log('This is check diff', { isShowDiffDependencies, dependencyStatus, sourceStatus });

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleCellClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const cellPreviewClick = e => {
        const $currentCell = document.getElementById(`${prefixId || `cell_preview`}_${rowIndex}_${columnIndex}`);
        if ($currentCell) {
            $currentCell.click();
        }
    };

    const revertCellClick = e => {
        stopPropagation(e);
        onRevert && onRevert({ type, value: currentData, columnId: column?.id });
    };

    const handleRevertBefore = () => {
        onRevertBefore && onRevertBefore({ type, value: previousData, columnId: column?.id });
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const getChildIcon = React.useMemo(() => {
        switch (conflictType) {
            case CONFLICT_TYPES.BRANCHING:
                return cellConflictPickedResult === 'child' ? (
                    <BranchSVG />
                ) : (
                    <BranchSVG color={theme.palette.primary.main} />
                );
            case CONFLICT_TYPES.IMPORT:
                return cellConflictPickedResult === 'child' ? (
                    <ImportSVG color={theme.colors.crusta} />
                ) : (
                    <GridSVG
                        width={18}
                        style={{ position: 'relative', top: 2, left: 2 }}
                        height={18}
                        color={theme.palette.primary.main}
                    />
                );

            default:
                return null;
        }
    }, [theme, conflictType, cellConflictPickedResult]);

    const showRevertIcon = React.useMemo(() => {
        return isShowRevert && showRevert && !isDisabled && !isShareViewLink;
    }, [isShowRevert, showRevert, isDisabled, isShareViewLink]);

    return (
        <>
            <Grid
                id={`${prefixId || `cell_preview`}_${rowIndex}_${columnIndex}`}
                className={`
            ${isCellHighlight ? classes.cellHighlight : ''}
            ${isSelectedByRowOrColumn ? classes.cellHighlight : ''}
            ${className}
            ${classes.root} 
            ${rdCellInfo?.columnId === columnId && rdCellInfo?.rowId === rowId ? classes.cursorPointer : ''}
            cell_preview_${rowIndex}_${columnIndex}
            ${fontJP}
        `}
                onClick={handleCellClick}
                style={{
                    pointerEvents: editable ? '' : 'none',
                    opacity: editable ? 1 : 0.5,
                    background: editable ? '' : theme.colors.paleGrey
                }}
            >
                {checkTypeAndRender({
                    type: columnType || type,
                    rowData,
                    isScrolling,
                    rowHeight,
                    columnWidth,
                    rowIndex,
                    columnIndex,
                    filterValue,
                    rowId,
                    columnId,
                    previousData,
                    rowDataByRowId,
                    customProperties: column?.customProperties || {},
                    options: column?.options || [],
                    numberFormat: column?.numberFormat,
                    status,
                    columnType,
                    previousColumnType,
                    data: currentData,
                    dbId,
                    isOverride,
                    conflictMode,
                    conflictType,
                    ldUserEmailMap,
                    isShowDiffDependencies
                })}

                {isShowDetailDiff && (
                    <span className={classes.previewCell}>
                        <span onClick={cellPreviewClick} className={`${classes.iconAction}`}>
                            <SwapSVG width={12} height={12} />
                        </span>
                    </span>
                )}

                {isShowDiffDependencies && (dependencyStatus || sourceStatus) && (
                    <Grid className={classes.dependencyStatus}>
                        <Grid
                            alignItems="center"
                            justifyContent="space-around"
                            className={classes.diffDependency}
                            container
                            direction="row"
                        >
                            <Grid item>
                                <LangStatus
                                    dependencyBlindness={dependencyBlindness}
                                    sourceStatus={previousSourceStatus}
                                    dependencyStatus={previousDependencyStatus}
                                />
                            </Grid>

                            <Grid item style={{ display: 'flex', paddingLeft: 2, paddingRight: 2 }}>
                                <ArrowNextSVG color={'black'} />
                            </Grid>

                            <Grid item>
                                <LangStatus
                                    dependencyBlindness={dependencyBlindness}
                                    sourceStatus={sourceStatus}
                                    dependencyStatus={dependencyStatus}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {!!cellConflictPickedResult && <span className={`${classes.branchTag}`}>{getChildIcon}</span>}
            </Grid>
            {anchorEl && isShowDetailDiff && (
                <PopperMenu
                    className={`${classes.cellContextMenu} ${fontJP}`}
                    anchorEl={anchorEl}
                    placement={'right-start'}
                    id={`cell_context_menu`}
                >
                    {isMergeGrid ? (
                        <>
                            <DiffCheckUI
                                isMergeGrid={isMergeGrid}
                                rowId={rowId}
                                columnId={columnId}
                                rowIndex={rowIndex}
                                columnIndex={columnIndex}
                                dbId={dbId}
                                status={status}
                                handleClickAway={handleClickAway}
                                previousData={previousData}
                                data={childData}
                                previousColumnType={!previousColumnType ? columnType : previousColumnType}
                                columnType={columnType}
                                customProperties={column?.customProperties || {}}
                                options={column?.options || []}
                                group={column?.group}
                                numberFormat={column?.numberFormat}
                                isOverride={isOverride}
                                conflictMode={conflictMode}
                                onConflictChanged={onConflictChanged}
                                branchName={branchName}
                                mergeToBranchName={mergeToBranchName}
                                conflictType={conflictType}
                                showRevertIcon={showRevertIcon}
                                onRevert={revertCellClick}
                                onRevertBefore={handleRevertBefore}
                                onOpenFilePreview={handleClickAway}
                                previousDependencyStatus={previousDependencyStatus}
                                dependencyStatus={dependencyStatus}
                                sourceStatus={sourceStatus}
                                previousSourceStatus={previousSourceStatus}
                                recordHistoryId={recordHistoryId}
                            />{' '}
                        </>
                    ) : (
                        <>
                            <DiffCheckUIBeforeAfter
                                isMergeGrid={isMergeGrid}
                                rowId={rowId}
                                columnId={columnId}
                                rowIndex={rowIndex}
                                columnIndex={columnIndex}
                                dbId={dbId}
                                status={status}
                                handleClickAway={handleClickAway}
                                previousData={previousData}
                                data={isOverride ? childData : currentData}
                                previousColumnType={!previousColumnType ? columnType : previousColumnType}
                                columnType={columnType}
                                customProperties={column?.customProperties || {}}
                                options={column?.options || []}
                                group={column?.group}
                                numberFormat={column?.numberFormat}
                                isOverride={isOverride}
                                conflictMode={conflictMode}
                                onConflictChanged={onConflictChanged}
                                branchName={branchName}
                                mergeToBranchName={mergeToBranchName}
                                conflictType={conflictType}
                                showRevertIcon={showRevertIcon}
                                onRevert={revertCellClick}
                                onRevertBefore={handleRevertBefore}
                                onOpenFilePreview={handleClickAway}
                                previousDependencyStatus={previousDependencyStatus}
                                dependencyStatus={dependencyStatus}
                                sourceStatus={sourceStatus}
                                previousSourceStatus={previousSourceStatus}
                                recordHistoryId={recordHistoryId}
                            />
                        </>
                    )}
                </PopperMenu>
            )}
        </>
    );
}

CellRow.propTypes = {
    column: PropTypes.object.isRequired,
    rowData: PropTypes.any
};

export default React.memo(CellRow);
