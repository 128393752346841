import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        maxHeight: props => (props.orientation === 'vertical' ? 500 : ``),
        display: props => (props.orientation === 'vertical' ? 'flex' : 'block')
    },
    tabs: {
        marginBottom: 8,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    indicator: {
        backgroundColor: theme.palette.primary.main
    },
    tabRoot: {
        minWidth: 120,
        '& *': {
            color: theme.colors.primaryText,
            opacity: 0.5
        }
    },
    selected: {
        '& *': {
            color: `${theme.palette.primary.main} !important`,
            fontWeight: 500,
            opacity: 1
        }
    },
    tablePanelContent: {
        color: 'red'
    }
}));

function ScrollableTabsButtonAuto({
    defaultValue = 0,
    orientation = 'horizontal',
    tabs = [
        { label: 'Grid Record', content: <Grid container>Item 1</Grid> },
        { label: 'Grid Column', content: <Grid container>Item 2</Grid> },
        { label: 'View Record', content: <Grid container>Item 3</Grid> }
    ],
    className,
    tabContentClassName,
    extraContent,
    ...rest
}) {
    const classes = useStyles({ orientation });
    const [value, setValue] = React.useState(defaultValue);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={`${classes.root} ${className}`}>
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                orientation={orientation}
                aria-label="scrollable auto tabs example"
                className={`${classes.tabs} tabs`}
                classes={{
                    indicator: classes.indicator
                }}
                {...rest}
            >
                {tabs.map((tab, index) => (
                    <Tab
                        classes={{ root: classes.tabRoot, selected: classes.selected }}
                        key={index}
                        label={tab.label}
                        {...a11yProps(index)}
                    />
                ))}
            </Tabs>

            {tabs.map((tab, index) => {
                return (
                    <TabPanel className={tabContentClassName} key={index} value={value} index={index}>
                        {tab.content}
                    </TabPanel>
                );
            })}
            {extraContent}
        </div>
    );
}

export default React.memo(ScrollableTabsButtonAuto);
