import React, { memo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import ListItem from 'components/list/Item';
import PopperMenu from 'components/menus/Popper';
import PopupItem from './PopupItem';
import { useIsFetchingList, useIsHasManageAdvancedSearchPermission, useSelectedAdvancedSearchId } from 'hooks/advanced';
import { useDispatch } from 'react-redux';
import * as advancedSearchActions from 'advancedSearch/actions';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import AvatarBase from 'components/avatar/Base';
import { getAvatarUrl } from 'utils/images';
import SaveAsDuplicate from '../grid/saveAs/Duplicate';
import Tooltip from 'components/tooltip/Base';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import Share from '../grid/share';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { COLOR_TYPES } from 'const';

function AdvancedSearchSearchItem(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isFetchingList = useIsFetchingList();
    const selectedAdvancedSearchId = useSelectedAdvancedSearchId();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [isDoing, setIsDoing] = React.useState(false);
    const [isOpenDuplicate, setIsOpenDuplicate] = React.useState(false);
    const [isOpenShare, setIsOpenShare] = React.useState(false);
    const { url } = useRouteMatch();
    const isHasManageAdvancedSearchPermission = useIsHasManageAdvancedSearchPermission();

    const handleClick = React.useCallback(() => {
        if (isFetchingList) return;

        if (selectedAdvancedSearchId !== props?.advancedSearchId) {
            if (window.advancedSearchDirty) {
                dispatch(
                    advancedSearchActions.setOpenConfirmation({
                        open: true,
                        nextAdvancedSearchId: props?.advancedSearchId
                    })
                );
                return;
            }
            dispatch(advancedSearchActions.setSearch(''));
            dispatch(advancedSearchActions.resetGrid(['list', `sharedList`, 'routeInfo', 'tab']));
            dispatch(advancedSearchActions.setSelectedAdvancedSearchId(props?.advancedSearchId));
            history.push(`${url}/${props?.advancedSearchId}`);
        }
    }, [isFetchingList, dispatch, props, selectedAdvancedSearchId, history, url]);

    const stopPropagation = React.useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const openEdit = React.useCallback(
        event => {
            stopPropagation(event);
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl, stopPropagation]
    );

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleOpenDeleteConfirm = React.useCallback(() => {
        setOpen(true);
        setAnchorEl(null);
    }, []);

    const handleClose = React.useCallback(e => {
        setOpen(false);
    }, []);

    const handleOpenDuplicateConfirm = React.useCallback(() => {
        setIsOpenDuplicate(true);
        setAnchorEl(null);
    }, []);

    const handleCloseDuplicate = React.useCallback(e => {
        setIsOpenDuplicate(false);
    }, []);

    const handleOpenShareConfirm = React.useCallback(() => {
        setIsOpenShare(true);
        setAnchorEl(null);
    }, []);

    const handleCloseShare = React.useCallback(e => {
        setIsOpenShare(false);
    }, []);

    const handleRemove = React.useCallback(
        e => {
            setIsDoing(true);
            dispatch(
                advancedSearchActions.deleteAdvancedSearch({
                    aId: props.advancedSearchId,
                    success: () => {
                        setIsDoing(false);
                        setOpen(false);
                    },
                    error: () => {
                        setIsDoing(false);
                    }
                })
            );
        },
        [dispatch, props]
    );

    return (
        <>
            <ListItem
                isSelected={selectedAdvancedSearchId === props?.advancedSearchId}
                onClick={handleClick}
                name={props?.name}
                hoverColor={theme.colors.divider}
                moreIcon={isHasManageAdvancedSearchPermission ? <IconMoreActionsSVG onClick={openEdit} /> : undefined}
                isShowSelectedIcon={false}
                icon={
                    <Tooltip
                        title={
                            <Grid container direction="column" style={{ padding: theme.spacing(1) }}>
                                <Grid item>
                                    <Typography variant="overline">
                                        Author: <strong>{props?.author}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="overline">
                                        Date: <strong>{moment(props?.createdTime).format(`MMM DD,YYYY`)}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    >
                        <div>
                            <AvatarBase src={getAvatarUrl(props?.userId?.toString())} size={24} alt={props?.author}>
                                {(props?.author || '').slice(0, 1)}
                            </AvatarBase>
                        </div>
                    </Tooltip>
                }
            />
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'bottom-start'}>
                    <PopupItem
                        {...props}
                        onClickAway={handleClickAway}
                        onDelete={handleOpenDeleteConfirm}
                        onOpenNew={handleOpenDuplicateConfirm}
                        onShare={handleOpenShareConfirm}
                    />
                </PopperMenu>
            )}
            <Dialog open={open} onClose={handleClose}>
                <ConfirmBox
                    title={`Delete search query`}
                    body={`Are you sure you want to delete search query <strong>${props?.name}</strong>?<br/>This action cannot be undonne.`}
                    handleCancel={handleClose}
                    onClose={handleClose}
                    handleAgreed={handleRemove}
                    agreeLabel={t('global_delete')}
                    isLoading={isDoing}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>

            <Dialog open={isOpenDuplicate} onClose={handleCloseDuplicate}>
                <SaveAsDuplicate {...props} onClose={handleCloseDuplicate} />
            </Dialog>

            <Dialog maxWidth={false} open={isOpenShare}>
                <Share {...props} onClose={handleCloseShare} />
            </Dialog>
        </>
    );
}

export default memo(AdvancedSearchSearchItem);
