import React from 'react';

function SynchronizeTMSVG({ color = '#93929E', ...other }) {
    return (
        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5856 9.80564V9.71724C14.5856 9.36365 14.9392 9.09846 15.2044 9.18685C15.6464 9.18685 15.9116 9.45205 15.9116 9.80564V9.89404C15.9116 11.4852 14.5856 12.8112 12.9945 12.8112H2.91713C1.32597 12.8112 0 11.4852 0 9.89404V3.70619C0 2.11503 1.32597 0.789062 2.91713 0.789062H12.9945C14.674 0.789062 15.9116 2.11503 16 3.70619V6.09293C16 6.44652 15.6464 6.71171 15.2928 6.71171C14.8508 6.71171 14.5856 6.44652 14.5856 6.09293V3.61779C14.5856 2.73381 13.8785 2.02663 12.9945 2.02663H2.91713C2.03315 2.02663 1.32597 2.73381 1.32597 3.61779V9.80564C1.32597 10.6896 2.03315 11.3968 2.91713 11.3968H12.9945C13.8785 11.3968 14.5856 10.6896 14.5856 9.80564ZM5.4368 5.16676H6.94576V4.34462H2.93315V5.16676H4.42181V9.27073H5.4368V5.16676ZM10.1193 7.9174L8.85395 4.34462H7.52769V9.27073H8.54268V7.92417L8.44118 5.60321L9.76744 9.27073H10.4644L11.794 5.59983L11.6925 7.92417V9.27073H12.7109V4.34462H11.3779L10.1193 7.9174Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(SynchronizeTMSVG);
