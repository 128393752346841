import React from 'react';

function ProjectAboutSVG({ ...other }) {
    return (
        <svg {...other} width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M31.4141 13.0703V35.9453C31.4141 37.8594 29.8594 39.4141 27.9375 39.4141H4.0625C2.14062 39.4141 0.585938 37.8594 0.585938 35.9453V4.05469C0.585938 2.14063 2.14062 0.585938 4.0625 0.585938H18.9297C19.625 0.585938 20.2891 0.859375 20.7734 1.35156L30.6562 11.2344C31.1406 11.7188 31.4141 12.3828 31.4141 13.0703Z"
                    fill="white"
                />
                <path
                    d="M31.4141 13.0703V14.3359H22.3516C19.7656 14.3359 17.6562 12.2344 17.6562 9.64062V0.585938H18.9219C19.6172 0.585938 20.2812 0.859375 20.7656 1.35156L30.6484 11.2344C31.1406 11.7188 31.4141 12.3828 31.4141 13.0703Z"
                    fill="white"
                />
                <path
                    d="M6.40625 39.4141H4.0625C2.14062 39.4141 0.585938 37.8594 0.585938 35.9453V4.05469C0.585938 2.14063 2.14062 0.585938 4.0625 0.585938H6.40625C4.49219 0.585938 2.92969 2.14063 2.92969 4.05469V35.9453C2.92969 37.8594 4.48438 39.4141 6.40625 39.4141Z"
                    fill="white"
                />
                <path
                    d="M31.1797 11.9922H22.3516C21.0547 11.9922 20 10.9375 20 9.64062V0.8125C20.2812 0.9375 20.5391 1.11719 20.7656 1.34375L30.6484 11.2266C30.875 11.4531 31.0547 11.7109 31.1797 11.9922Z"
                    fill="#2D97F2"
                    fillOpacity="0.8"
                />
                <path
                    d="M31.4141 18.5547C31.0938 18.5547 30.8281 18.8203 30.8281 19.1406V35.9453C30.8281 37.5312 29.5312 38.8281 27.9375 38.8281H4.0625C2.46875 38.8281 1.17188 37.5391 1.17188 35.9453V4.05469C1.17188 2.46094 2.46875 1.17188 4.0625 1.17188H6.09375C6.41406 1.17188 6.67969 0.90625 6.67969 0.585938C6.67969 0.265625 6.41406 0 6.09375 0H4.0625C1.82031 0 0 1.82031 0 4.05469V35.9453C0 38.1797 1.82031 40 4.0625 40H27.9453C30.1875 40 32.0078 38.1797 32.0078 35.9453V19.1406C32 18.8203 31.7422 18.5547 31.4141 18.5547Z"
                    fill="#4D3F8A"
                />
                <path
                    d="M31.0703 10.8203L21.1875 0.9375C20.5859 0.335938 19.7812 0 18.9297 0H8.82812C8.50781 0 8.24219 0.265625 8.24219 0.585938C8.24219 0.90625 8.50781 1.17188 8.82812 1.17188H18.9297C19.1016 1.17188 19.2656 1.19531 19.4219 1.23438V9.64844C19.4219 11.2656 20.7422 12.5859 22.3594 12.5859H30.7734C30.8125 12.7422 30.8359 12.9062 30.8359 13.0781V16.4062C30.8359 16.7266 31.1016 16.9922 31.4219 16.9922C31.7422 16.9922 32.0078 16.7266 32.0078 16.4062V13.0703C32 12.2188 31.6719 11.4219 31.0703 10.8203ZM20.5938 9.64844V2L30 11.4062H22.3516C21.3828 11.4062 20.5938 10.6172 20.5938 9.64844Z"
                    fill="#4D3F8A"
                />
                <path
                    d="M6.38281 15.1016H16.0469C16.3672 15.1016 16.6328 14.8359 16.6328 14.5156C16.6328 14.1953 16.3672 13.9297 16.0469 13.9297H6.38281C6.0625 13.9297 5.79688 14.1953 5.79688 14.5156C5.79688 14.8359 6.0625 15.1016 6.38281 15.1016Z"
                    fill="#4D3F8A"
                />
                <path
                    d="M6.38281 27.9922C6.0625 27.9922 5.79688 28.2578 5.79688 28.5781C5.79688 28.8984 6.0625 29.1641 6.38281 29.1641H16.0469C16.3672 29.1641 16.6328 28.8984 16.6328 28.5781C16.6328 28.2578 16.3672 27.9922 16.0469 27.9922H6.38281Z"
                    fill="#4D3F8A"
                />
                <path
                    d="M24.3125 19.2031C24.3125 18.8828 24.0469 18.6172 23.7266 18.6172H6.22656C5.90625 18.6172 5.64062 18.8828 5.64062 19.2031C5.64062 19.5234 5.90625 19.7891 6.22656 19.7891H23.7266C24.0547 19.7891 24.3125 19.5234 24.3125 19.2031Z"
                    fill="#4D3F8A"
                />
                <path
                    d="M20.4063 23.8906C20.4063 23.5703 20.1406 23.3047 19.8203 23.3047H6.22656C5.90625 23.3047 5.64062 23.5703 5.64062 23.8906C5.64062 24.2109 5.90625 24.4766 6.22656 24.4766H19.8203C20.1485 24.4766 20.4063 24.2109 20.4063 23.8906Z"
                    fill="#4D3F8A"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="32" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(ProjectAboutSVG);
