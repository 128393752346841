import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.RESET_EXPORT_REMOVED_COLUMNS](state) {
        return {
            ...state,
            exportRemovedColumns: []
        };
    },

    [types.ADD_EXPORT_REMOVED_COLUMN](state, action) {
        const { columnId } = action.payload;
        return {
            ...state,
            exportRemovedColumns: [...(state?.exportRemovedColumns || []), columnId]
        };
    },

    [types.RESTORE_EXPORT_REMOVED_COLUMN](state, action) {
        const { columnId } = action.payload;
        return {
            ...state,
            exportRemovedColumns: [...(state?.exportRemovedColumns || [])].filter(id => columnId !== id)
        };
    },
    [types.FETCH_EXPORT_PREVIEW_DATA](state, { payload }) {
        const { viewId, data, rows } = payload;
        const { exportPreview } = state;

        const newExportPreview = produce(exportPreview, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].data = data;
            draft[viewId].rows = rows;
        });

        return {
            ...state,
            exportPreview: newExportPreview
        };
    }
};

export default handler;
