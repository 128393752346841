import { useSelector } from 'react-redux';
import { useColumnIdsWithoutDisabledColumns } from 'hooks/gridUI/column';
import { useMetaData } from 'hooks/gridUI/metaData';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import * as columnTypes from 'const/columnTypes';
import { generateDefaultDateApiPublic } from 'utils/datetime';

export function useRows() {
    return useSelector(state => state.gridUI.rows) || [];
}

export function useRecordArray(numberOfRecords) {
    const data = useSelector(state => state.gridUI.data) || {};
    const rows = useRows();
    const columnIds = useColumnIdsWithoutDisabledColumns();
    let rowIds = rows.slice(0, numberOfRecords);

    let dataFormatted = [];
    rowIds.forEach(rowId => {
        const rowData = {};
        rowData.id = rowId;
        rowData['cells'] = [];
        columnIds.forEach(columnId => {
            let columnData = {};
            let value = data?.[rowId]?.[columnId];
            columnData = {
                value,
                columnId
            };
            rowData['cells'] = [...rowData['cells'], columnData];
        });
        dataFormatted.push(rowData);
    });

    return dataFormatted;
}

const DEFAULT_TEXT = 'created by API';

const DEFAULT_JSON_TEXT = `{
    "key": "value"
}`;

const DEFAULT_HTML_TEXT = `
<html>
<head></head>
<body></body>
</html>
`;

const DEFAULT_YAML_TEXT = `key: value`;

function _generateDefaultValueByColumnType({ columnType, column }) {
    const defaultValue = column?.defaultValue;
    switch (columnType) {
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.RICH_TEXT:
        case columnTypes.MARKDOWN:
        case columnTypes.TRANSLATION:
            return defaultValue || DEFAULT_TEXT;
        case columnTypes.HTML:
            return defaultValue || DEFAULT_HTML_TEXT;
        case columnTypes.JSON_LD:
            return defaultValue || DEFAULT_JSON_TEXT;
        case columnTypes.YAML:
            return defaultValue || DEFAULT_YAML_TEXT;
        case columnTypes.REFERENCE:
        case columnTypes.PATH_TAG:
        case columnTypes.FORMULA:
        case columnTypes.FILES:
            return null;
        case columnTypes.DATETIME:
            return defaultValue || generateDefaultDateApiPublic(new Date());
        case columnTypes.SINGLE_SELECTION: {
            const firstOption = column?.options?.[0]?.label;
            return defaultValue || (!firstOption ? null : firstOption);
        }
        case columnTypes.MULTIPLE_SELECTIONS: {
            const firstOption = column?.options?.[0]?.label;
            return defaultValue || (!firstOption ? [] : [firstOption]);
        }
        case columnTypes.BOOLEAN:
            return defaultValue || true;
        case columnTypes.NUMBER:
            return defaultValue || 0;
        default:
            return defaultValue || DEFAULT_TEXT;
    }
}

export function useBodyRequestCreateRecord() {
    const columnIds = useColumnIdsWithoutDisabledColumns();
    const metaData = useMetaData();
    const dataFormatted = [];
    let rowData = {};
    rowData['cells'] = [];
    columnIds.forEach(columnId => {
        const column = metaData?.[columnId];
        const columnType = getCorrectColumnType(column);
        const defaultValue = _generateDefaultValueByColumnType({ columnType, column });
        let columnData = {
            value: defaultValue,
            columnId: column?.publicId
        };
        rowData['cells'] = [...rowData['cells'], columnData];
    });
    dataFormatted.push(rowData);
    return dataFormatted;
}

export function useBodyResponseCreateRecord() {
    const columnIds = useColumnIdsWithoutDisabledColumns();
    const metaData = useMetaData();
    const dataFormatted = [];
    let rowData = {};
    rowData.id = 'created_record_id';
    rowData['cells'] = [];
    columnIds.forEach(columnId => {
        const column = metaData?.[columnId];
        const columnType = getCorrectColumnType(column);
        const defaultValue = _generateDefaultValueByColumnType({ columnType, column });
        let columnData = {
            value: defaultValue,
            columnId: column?.publicId
        };
        rowData['cells'] = [...rowData['cells'], columnData];
    });
    dataFormatted.push(rowData);
    return dataFormatted;
}

export function useBodyRequestSetRecord() {
    const columnIds = useColumnIdsWithoutDisabledColumns();
    const dataFormatted = [];
    let rowData = {};
    const rows = useRows();
    const rowId = rows?.[0];
    if (!rowId) {
        return [];
    }
    rowData.id = rowId;
    rowData['cells'] = [];
    columnIds.forEach(columnId => {
        let columnData = {
            value: 'Updated value',
            columnId
        };
        rowData['cells'] = [...rowData['cells'], columnData];
    });
    dataFormatted.push(rowData);
    console.log('dataFormatted', dataFormatted);
    return dataFormatted;
}

export function useBodyResponseSetRecord() {
    const columnIds = useColumnIdsWithoutDisabledColumns();
    const dataFormatted = [];
    let rowData = {};
    const rows = useRows();
    const rowId = rows?.[0];
    if (!rowId) {
        return [];
    }

    rowData.id = rowId;
    rowData['cells'] = [];
    columnIds.forEach(columnId => {
        let columnData = {
            value: 'Updated value',
            columnId
        };
        rowData['cells'] = [...rowData['cells'], columnData];
    });
    dataFormatted.push(rowData);
    return dataFormatted;
}
