import React from 'react';
import { getCompanyLogoUrl } from 'utils/images';
import Avatar from 'components/avatar/Company';
import { Typography } from '@material-ui/core';
import OverflowTypography from 'components/typography/OverflowTypography';
import { useTranslation } from 'react-i18next';
import PlanTag from 'payment/components/tags/Plan';
import { PLAN_TYPES } from 'const';
import { getFriendlyTime } from 'utils/datetime';

const ListCompanyInfo = ({ maxHeight = 374, companies, showMemberNumber, showRole, showPlan }) => {
    const { t } = useTranslation();
    return (
        <div
            className="p-5 overflow-auto scrollbar-app flex flex-col flex-nowrap gap-4 border !border-[#D7D7DC] rounded"
            style={{ maxHeight }}
        >
            {companies?.map(company => (
                <div key={company?.companyId}>
                    <div className="flex flex-nowrap gap-3 items-center">
                        <div>
                            <Avatar size={48} src={getCompanyLogoUrl(company?.logoUrl)} label={company?.name} />
                        </div>
                        <div>
                            <div className="flex flex-col flex-nowrap gap-1">
                                <OverflowTypography maxLines={1} variant="body1">
                                    {company?.name}
                                </OverflowTypography>
                                {showMemberNumber && (
                                    <Typography variant="caption">
                                        {t('global_count_members', { count: company?.totalMember })}
                                    </Typography>
                                )}
                                {showRole && <Typography variant="caption">{company?.roleName}</Typography>}
                                {showPlan && (
                                    <div className="flex gap-2 items-center">
                                        <PlanTag planType={company?.plan} />
                                        {company?.nextBillingDate &&
                                            company?.plan &&
                                            company?.plan !== PLAN_TYPES.FREE && (
                                                <Typography variant="body2" className="!text-[#78778B]">
                                                    - Next billing:{' '}
                                                    {getFriendlyTime(company?.nextBillingDate, 'MMM DD,yyyy')}
                                                </Typography>
                                            )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default React.memo(ListCompanyInfo);
