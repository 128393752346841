import request from './request';
import { APP_API_PREFIX_EVENT_LOG, APP_API_PREFIX_ORGANIZATION } from '../config';
import { objectToQueryParams } from 'utils/urlQueryUtils';

const authApis = {
    login: '/auth/login',
    logout: '/auth/logout',
    refresh: ({ companyId }) => `/auth/refresh-token/${companyId}`,
    register: `${APP_API_PREFIX_ORGANIZATION}/public/api/register`,
    activate: key => `${APP_API_PREFIX_ORGANIZATION}/public/api/activate?key=${key}`,
    getUser: `${APP_API_PREFIX_ORGANIZATION}/api/v1/account`,
    authenticate: `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/authenticate`,
    check: ({ email, companyName }) =>
        `${APP_API_PREFIX_ORGANIZATION}/public/api/check?email=${email}&company=${companyName}`,
    apiKey: ({ companyId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/apikey`,
    updateUserInfo: () => `${APP_API_PREFIX_ORGANIZATION}/api/v1/account`,
    updateUserPassword: () => `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/change-password`,
    getUserWorkspaceRole: ({ workspaceId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/workspace/${workspaceId}`,
    resetPasswordInit: `${APP_API_PREFIX_ORGANIZATION}/public/api/reset-password/init`,
    resetPasswordFinish: `${APP_API_PREFIX_ORGANIZATION}/public/api/reset-password/finish`,
    refreshToken: () => `/auth/refresh-token`,
    transferOwner: ({ companyId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/users/transfer-role`,
    uploadAvatar: () => `${APP_API_PREFIX_ORGANIZATION}/api/v1/media/avatars`,
    userSettings: () => `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/setting`,
    graphql: () => `${APP_API_PREFIX_ORGANIZATION}/graphql`,
    setUserSetting: companyId => `/organization/api/v1/companies/${companyId}/settings`,
    twoFactorSignIn: `/auth/2fa`,
    signInFactorStatus: `/api/account/info`,
    resendOtpEmail: `/auth/2fa/sendOtpEmail`,
    ssoRegisterInfo: `/auth/sso-register-info`,
    changeEmail: `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/change-email`,
    notificationCenter: `${APP_API_PREFIX_EVENT_LOG}/api/v1/messages`,
    requestDeleteAccount: `${APP_API_PREFIX_ORGANIZATION}/api/v1/account`,
    deleteAccount: key => `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/confirm-delete-account/${key}`,
    getAdminCompanyInfo: key => `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/admin-company-info/${key}`,
    adminDeleteAccount: key => `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/admin-confirm-delete-account/${key}`
};

export async function uploadAvatarApi(file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.post(authApis.uploadAvatar(), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function transferOwnerApi({ companyId, body }) {
    const response = await request.put(authApis.transferOwner({ companyId }), body);
    return response.data;
}

export async function updateAuthoritiesSocketApi() {
    const response = await request.get(authApis.refreshToken());
    return response.data;
}

export async function getUserWorkspaceRoleApi({ workspaceId }) {
    const response = await request.get(authApis.getUserWorkspaceRole({ workspaceId }));
    return response.data;
}

export async function updateUserPasswordApi(body) {
    const response = await request.post(authApis.updateUserPassword(), body);
    return response.data;
}

export async function updateUserInfoApi({ user }) {
    const response = await request.put(authApis.updateUserInfo(), user);
    return response.data;
}

export async function getApiKeyApi({ companyId }) {
    const response = await request.get(authApis.apiKey({ companyId }));
    return response.data;
}

export async function regenerateApiKeyApi({ companyId, currentApiKey }) {
    const response = await request.put(authApis.apiKey({ companyId }) + `?key=${currentApiKey}`);
    return response.data;
}

export async function login(data) {
    const response = await request.post(authApis.login, { ...data, rememberMe: true });
    return response.data;
}

export async function logout() {
    const response = await request.post(authApis.logout, {});
    return response.data;
}

export async function activate({ email, key, firstName, lastName, password }) {
    const response = await request.post(authApis.activate(key), { email, firstName, lastName, password });
    return response.data;
}

export async function signup(data) {
    const response = await request.post(authApis.register, data);
    return response.data;
}

export async function refresh({ companyId }) {
    const response = await request.get(authApis.refresh({ companyId }));
    return response.data;
}

export async function check({ email, companyName }) {
    const response = await request.get(authApis.check({ email, companyName }));
    return response.data;
}

export async function getUser(companyId) {
    const response = await request.get(authApis.getUser, companyId && { headers: { 'X-Company': companyId } });
    return response.data;
}

export async function checkIsAuthenticated() {
    const response = await request.get(authApis.authenticate);
    return {
        isOk: response.status === 200,
        info: response.data
    };
}

export async function resetPasswordInitApi({ email }) {
    const response = await request.post(authApis.resetPasswordInit, { email });
    return response.data;
}

export async function resetPasswordFinishApi({ key, newPassword }) {
    const response = await request.post(authApis.resetPasswordFinish, { key, newPassword });
    return response.data;
}

export async function setUserSettingsApi({ settings }) {
    const response = await request.put(authApis.userSettings(), settings);
    return response.data;
}

export async function getUserSettingsApi({ dbId, viewId, gridId, workspaceId }) {
    const query = `query($viewId: String $gridId: String){
        grid:setting(id: $gridId, type: GRID){
            uri
            value
        } 
        view:setting(id: $viewId, type: VIEW){
            uri
            value
        }
    }`;

    const data = {
        query,
        variables: {
            gridId,
            viewId
        },
        operationName: null
    };

    const response = await request.post(authApis.graphql(), data);
    return response?.data?.data;
}

export async function setUserSettingApi({ companyId, body }) {
    const response = await request.put(authApis.setUserSetting(companyId), body);
    return response.data;
}

export async function twoFactorSignInApi(otp) {
    const body = { grant_type: 'mfa', mfa_code: otp };
    const response = await request.post(authApis.twoFactorSignIn, body);
    return response.data;
}

export async function getSignInFactorStatusApi() {
    const response = await request.get(authApis.signInFactorStatus);
    return response.data;
}

export async function resendOtpEmailApi() {
    const response = await request.post(authApis.resendOtpEmail);
    return response.data;
}

export async function getSsoRegisterInfoApi() {
    const response = await request.get(authApis.ssoRegisterInfo);
    return response.data;
}

export async function resendChangeEmailApi() {
    const response = await request.put(`${authApis.changeEmail}?action=resend`);
    return response;
}

export async function getNotificationCenterApi(query) {
    const extra = objectToQueryParams(query);
    const response = await request.get(`${authApis.notificationCenter}?${extra}`);
    return response;
}

export async function markAllNotificationCenterAsReadApi(type) {
    const response = await request.put(`${authApis.notificationCenter}/${type}/all_as_read`);
    return response;
}

export async function getNotificationCenterTotalApi({ type, read }) {
    const response = await request.get(`${authApis.notificationCenter}/${type}/count/${read}`);
    return response.data;
}

export async function markNotificationCenterAsReadApi(messageId) {
    const response = await request.put(`${authApis.notificationCenter}/mark_as_read/${messageId}`);
    return response.data;
}

export async function markNotificationCenterAsUnreadApi(messageId) {
    const response = await request.put(`${authApis.notificationCenter}/mark_as_unread/${messageId}`);
    return response.data;
}

export async function resetNotificationCenterCountApi() {
    const response = await request.put(`${authApis.notificationCenter}/count/reset`);
    return response.data;
}

export async function requestDeleteAccountApi({ isResend, unsubscribe }) {
    const response = await request.delete(
        isResend
            ? `${authApis.requestDeleteAccount}?action=resend`
            : `${authApis.requestDeleteAccount}?unsubscribe=${unsubscribe}`
    );
    return response.data;
}

export async function deleteAccountApi(key) {
    const response = await request.delete(authApis.deleteAccount(key));
    return response.data;
}

export async function getAdminCompanyInfoApi(key) {
    const response = await request.get(authApis.getAdminCompanyInfo(key));
    return response.data;
}

export async function adminDeleteAccountApi(key) {
    const response = await request.delete(authApis.adminDeleteAccount(key));
    return response.data;
}
