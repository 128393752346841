import React from 'react';

function NoBackUpFileSVG({ color = 'white', ...other }) {
    return (
        <svg {...other} width="230" height="200" viewBox="0 0 230 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M169.333 13.2C155.333 13.2 141.733 18.1333 130.933 27.0667C126.533 30.6667 121.6 35.8667 118.8 40.1333C114.933 46.1333 111.466 51.7333 107.333 56.8H174.133C180.8 56.8 186.266 62.2667 186.266 68.9333V130.133C192.533 128.667 198.666 126.267 204.533 122.133C220 110.8 229.333 92.4 229.333 73.2C229.333 40.1333 202.533 13.2 169.333 13.2Z"
                fill="#DDD7EB"
            />
            <path
                d="M62.5333 155.6C55.8667 155.6 50.4 150.133 50.4 143.467V82.1333C46.4 82.5333 42.4 82.9333 38.5333 84.1333C15.8667 91.0666 0 112.667 0 136.4C0 166.533 24.5333 191.067 54.6667 191.067C74.6667 191.067 92.1333 180.4 101.6 164.4C103.467 161.2 105.867 158.4 108.4 155.6H62.5333Z"
                fill="#DDD7EB"
            />
            <path
                d="M31.0661 133.867C33.7908 133.867 35.9995 131.658 35.9995 128.933C35.9995 126.209 33.7908 124 31.0661 124C28.3415 124 26.1328 126.209 26.1328 128.933C26.1328 131.658 28.3415 133.867 31.0661 133.867Z"
                fill="#1A1A1A"
            />
            <path
                d="M41.9997 144.667C43.6198 144.667 44.9331 143.353 44.9331 141.733C44.9331 140.113 43.6198 138.8 41.9997 138.8C40.3797 138.8 39.0664 140.113 39.0664 141.733C39.0664 143.353 40.3797 144.667 41.9997 144.667Z"
                fill="white"
            />
            <path
                d="M138.533 170.267C140.153 170.267 141.466 168.953 141.466 167.333C141.466 165.713 140.153 164.4 138.533 164.4C136.913 164.4 135.6 165.713 135.6 167.333C135.6 168.953 136.913 170.267 138.533 170.267Z"
                fill="#1A1A1A"
            />
            <path
                d="M123.467 173.867C127.665 173.867 131.067 170.464 131.067 166.267C131.067 162.069 127.665 158.667 123.467 158.667C119.27 158.667 115.867 162.069 115.867 166.267C115.867 170.464 119.27 173.867 123.467 173.867Z"
                fill="#E8DDEC"
            />
            <path
                d="M155.114 33.4286C156.416 33.18 157.27 31.9231 157.021 30.621C156.773 29.319 155.516 28.465 154.214 28.7135C152.912 28.962 152.058 30.219 152.306 31.521C152.555 32.8231 153.812 33.6771 155.114 33.4286Z"
                fill="white"
            />
            <path
                d="M184.4 86.1333V143.333C184.4 149.067 179.734 153.6 174.134 153.6H62.5337C56.8004 153.6 52.2671 148.933 52.2671 143.333V68.9333C52.2671 63.2 56.9337 58.6667 62.5337 58.6667H157.6C157.734 58 157.867 57.4667 158 56.8H62.5337C55.8671 56.8 50.4004 62.2667 50.4004 68.9333V143.467C50.4004 150.133 55.8671 155.6 62.5337 155.6H174C180.667 155.6 186.134 150.133 186.134 143.467V85.8667C185.6 86 184.934 86.1333 184.4 86.1333Z"
                fill="black"
            />
            <path
                d="M181.333 86.4C168 86.4 157.2 75.6 157.2 62.2667C157.2 61.0667 157.333 59.7333 157.467 58.5333H62.5333C56.7999 58.5333 52.2666 63.2 52.2666 68.8V143.333C52.2666 149.067 56.9333 153.6 62.5333 153.6H174C179.733 153.6 184.267 148.933 184.267 143.333V86.1333C183.333 86.2667 182.4 86.4 181.333 86.4Z"
                fill="#EFEDF6"
            />
            <path d="M100.134 89.4666H78.9336V91.3333H100.134V89.4666Z" fill="black" />
            <path d="M91.0669 95.3333H78.9336V97.2H91.0669V95.3333Z" fill="black" />
            <path d="M159.867 89.4666H138.667V91.3333H159.867V89.4666Z" fill="black" />
            <path d="M150.8 95.3333H138.667V97.2H150.8V95.3333Z" fill="black" />
            <path d="M100.134 122.933H78.9336V124.8H100.134V122.933Z" fill="black" />
            <path d="M91.0669 128.933H78.9336V130.8H91.0669V128.933Z" fill="black" />
            <path
                d="M126.933 102.267C121.999 102.267 118.133 98.2667 118.133 93.4667C118.133 88.6667 122.133 84.6667 126.933 84.6667C131.733 84.6667 135.733 88.6667 135.733 93.4667C135.733 98.2667 131.866 102.267 126.933 102.267ZM126.933 86.4C123.066 86.4 119.999 89.4667 119.999 93.3333C119.999 97.2 123.066 100.267 126.933 100.267C130.799 100.267 133.866 97.2 133.866 93.3333C133.866 89.4667 130.799 86.4 126.933 86.4Z"
                fill="black"
            />
            <path
                d="M126.933 100.267C130.763 100.267 133.867 97.1625 133.867 93.3333C133.867 89.5042 130.763 86.4 126.933 86.4C123.104 86.4 120 89.5042 120 93.3333C120 97.1625 123.104 100.267 126.933 100.267Z"
                fill="#E8DDEC"
            />
            <path
                d="M126.933 135.733C121.999 135.733 118.133 131.733 118.133 126.933C118.133 122.133 122.133 118.133 126.933 118.133C131.733 118.133 135.733 122.133 135.733 126.933C135.733 131.733 131.866 135.733 126.933 135.733ZM126.933 120C123.066 120 119.999 123.067 119.999 126.933C119.999 130.8 123.066 133.867 126.933 133.867C130.799 133.867 133.866 130.8 133.866 126.933C133.866 123.067 130.799 120 126.933 120Z"
                fill="black"
            />
            <path
                d="M126.933 133.867C130.763 133.867 133.867 130.762 133.867 126.933C133.867 123.104 130.763 120 126.933 120C123.104 120 120 123.104 120 126.933C120 130.762 123.104 133.867 126.933 133.867Z"
                fill="#E8DDEC"
            />
            <path
                d="M67.8 102.267C62.8667 102.267 59 98.2667 59 93.4667C59 88.6667 63 84.6667 67.8 84.6667C72.6 84.6667 76.6 88.6667 76.6 93.4667C76.6 98.2667 72.7333 102.267 67.8 102.267ZM67.8 86.4C63.9333 86.4 60.8667 89.4667 60.8667 93.3333C60.8667 97.2 63.9333 100.267 67.8 100.267C71.6667 100.267 74.7333 97.2 74.7333 93.3333C74.7333 89.4667 71.6667 86.4 67.8 86.4Z"
                fill="black"
            />
            <path
                d="M67.8005 100.267C71.6297 100.267 74.7339 97.1625 74.7339 93.3333C74.7339 89.5042 71.6297 86.4 67.8005 86.4C63.9713 86.4 60.8672 89.5042 60.8672 93.3333C60.8672 97.1625 63.9713 100.267 67.8005 100.267Z"
                fill="#E8DDEC"
            />
            <path
                d="M67.8 135.733C62.8667 135.733 59 131.733 59 126.933C59 122.133 63 118.133 67.8 118.133C72.6 118.133 76.6 122.133 76.6 126.933C76.6 131.733 72.7333 135.733 67.8 135.733ZM67.8 120C63.9333 120 60.8667 123.067 60.8667 126.933C60.8667 130.8 63.9333 133.867 67.8 133.867C71.6667 133.867 74.7333 130.8 74.7333 126.933C74.7333 123.067 71.6667 120 67.8 120Z"
                fill="black"
            />
            <path
                d="M67.8005 133.867C71.6297 133.867 74.7339 130.762 74.7339 126.933C74.7339 123.104 71.6297 120 67.8005 120C63.9713 120 60.8672 123.104 60.8672 126.933C60.8672 130.762 63.9713 133.867 67.8005 133.867Z"
                fill="#E8DDEC"
            />
            <path d="M159.867 122.933H138.667V124.8H159.867V122.933Z" fill="black" />
            <path d="M150.8 128.933H138.667V130.8H150.8V128.933Z" fill="black" />
            <path
                d="M115.733 30.8C115.466 30.8 115.333 30.6667 115.066 30.5333C114.666 30.1333 114.666 29.6 114.933 29.2C115.066 28.9333 133.466 8.39999 157.733 6.66666C158.266 6.66666 158.666 7.06666 158.666 7.46666C158.666 7.99999 158.266 8.39999 157.866 8.39999C134.266 10.1333 116.4 30.1333 116.266 30.2667C116.133 30.6667 115.866 30.8 115.733 30.8Z"
                fill="black"
            />
            <path
                d="M79.0664 193.733C78.6664 193.733 78.3997 193.467 78.1331 193.2C77.9997 192.667 78.1331 192.133 78.6664 192C93.7331 186 102.133 173.733 102.266 173.733C102.533 173.333 103.2 173.2 103.6 173.467C104 173.733 104.133 174.4 103.866 174.8C103.466 175.333 95.0664 187.6 79.4664 193.867C79.3331 193.733 79.1997 193.733 79.0664 193.733Z"
                fill="black"
            />
            <path
                d="M181.334 38.2667C180.534 38.2667 179.867 38.2667 179.2 38.4C179.067 38.4 178.934 38.4 178.8 38.4C178.134 38.5333 177.467 38.5333 176.667 38.6667C176.534 38.6667 176.4 38.6667 176.267 38.8C175.6 38.9333 175.067 39.0667 174.4 39.2C174.267 39.2 174.134 39.2 174 39.3333C173.334 39.6 172.667 39.7333 172.134 40C172 40 171.867 40.1333 171.734 40.1333C171.067 40.4 170.534 40.6667 169.867 41.0667C170.267 42 170.4 42.9333 170.4 43.8667C170.4 48 167.067 51.4667 162.8 51.4667C161.867 51.4667 160.934 51.3333 160 50.9333C159.734 51.4667 159.467 52 159.2 52.5333C159.067 52.8 158.934 53.0667 158.934 53.2C158.667 53.7333 158.534 54.2667 158.267 54.9333C158.267 55.0667 158.134 55.3333 158.134 55.4667C158 56 157.867 56.5333 157.734 57.0667C157.734 57.2 157.6 57.4667 157.6 57.6C157.467 58.2667 157.334 58.9333 157.334 59.6C157.334 59.7333 157.334 59.8667 157.334 60.1333C157.334 60.8 157.2 61.6 157.2 62.2667C157.2 75.6 168 86.4 181.334 86.4C194.667 86.4 205.334 75.6 205.334 62.2667C205.334 48.9333 194.534 38.2667 181.334 38.2667Z"
                fill="#DDD7EB"
            />
            <path
                d="M181.334 36.4C176.8 36.4 172.667 37.6 168.934 39.6C167.6 37.7333 165.334 36.5333 162.8 36.5333C158.667 36.5333 155.2 39.8667 155.2 44.1333C155.2 46.6667 156.4 48.9333 158.4 50.2667C156.4 53.8667 155.334 58 155.334 62.4C155.334 76.6667 166.934 88.4 181.334 88.4C195.6 88.4 207.334 76.8 207.334 62.4C207.334 48 195.6 36.4 181.334 36.4ZM181.334 86.4C168 86.4 157.2 75.6 157.2 62.2667C157.2 58.1333 158.267 54.4 160 50.9333C160.8 51.3333 161.867 51.4667 162.8 51.4667C166.934 51.4667 170.4 48.1333 170.4 43.8667C170.4 42.8 170.134 41.8667 169.867 41.0667C173.334 39.2 177.2 38.1333 181.334 38.1333C194.667 38.1333 205.467 48.9333 205.467 62.2667C205.467 75.6 194.534 86.4 181.334 86.4Z"
                fill="#1A1A1A"
            />
            <path
                d="M182 50.2666C181.733 49.8666 180.933 49.8666 180.667 50.2666L172.4 61.8666C172.267 62.1333 172.133 62.4 172.4 62.6666C172.533 62.9333 172.8 63.0666 173.2 63.0666H177.333V73.0666C177.333 73.4666 177.467 73.8666 177.867 74.2666C178.133 74.5333 178.533 74.8 179.067 74.8C179.2 74.8 184 74.8 184 74.8C184.4 74.8 184.8 74.6666 185.2 74.2666C185.467 74 185.733 73.6 185.733 73.0666V63.0666H189.867C190.133 63.0666 190.4 62.9333 190.667 62.6666C190.8 62.4 190.8 62 190.667 61.8666L182 50.2666Z"
                fill="#010101"
            />
            <path d="M116.134 67.0667H59.2002V72H116.134V67.0667Z" fill="#AEA1CD" />
        </svg>
    );
}

export default React.memo(NoBackUpFileSVG);
