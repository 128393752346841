import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import hexToRgba from 'hex-to-rgba';
import { getViewIconByType, getViewBackgroundByType } from 'utils/gridUI/formatData';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width,
        height: props => props.height,
        borderRadius: '50%',
        '& svg': {
            width: props => props.iconSize,
            height: props => props.iconSize
        },
        cursor: 'pointer'
    }
}));

const SIZES = {
    small: {
        width: 20,
        height: 20,
        iconSize: 12
    },
    medium: {
        width: 36,
        height: 36,
        iconSize: 20
    },
    large: {
        width: 48,
        height: 48,
        iconSize: 24
    }
};

function getSize(size) {
    if (typeof size === 'number')
        return {
            width: size,
            height: size,
            fontSize: size / 2
        };
    switch (size) {
        case 'small':
            return SIZES['small'];
        case 'medium':
            return SIZES['medium'];
        case 'large':
            return SIZES['large'];
        default:
            return SIZES['small'];
    }
}

function ViewIcon({ view, size = 'medium', className, ...rest }) {
    const theme = useTheme();
    const { width, height, iconSize } = getSize(size);
    const icon = getViewIconByType(view, 'white');
    const color = getViewBackgroundByType({ view, theme });

    const classes = useStyles({
        width,
        height,
        iconSize
    });

    return (
        <Avatar
            className={`${classes.root} ${className}`}
            style={{
                background: hexToRgba(color, 0.8),
                border: `1px solid ${hexToRgba(color, 1)}`
            }}
            {...rest}
        >
            {icon}
        </Avatar>
    );
}

export default React.memo(ViewIcon);
