import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, CircularProgress, useTheme, Tabs, Tab } from '@material-ui/core';
import AddonLayout from 'addons/layout';
import ButtonBase from 'components/button/Base';
import hexToRgba from 'hex-to-rgba';
import GridlyShortLogoSVG from 'assets/images/svg/addon/GridlyShortLogoSVG';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import PopperMenu from 'components/menus/Popper';
import MenuOption from '../MenuOption';
import * as addonActions from 'addons/actions';
import { useDispatch } from 'react-redux';
import LQASetting from './Setting';
import { ADD_ON_STATES } from 'const';
import CheckedSVG from 'assets/images/svg/CheckedSVG';
import { useTranslation } from 'react-i18next';
import { sendManualTrack } from 'tracker';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';

const useStyles = makeStyles(theme => ({
    root: {},
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(2),
        position: 'relative',
        top: 3
    },
    stepSpacing: {
        marginBottom: theme.spacing(3)
    },
    sendFeedback: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8)
    },
    dlFlex: {
        display: 'flex'
    },
    avatar: {
        '& svg': {
            width: 88,
            height: 88
        }
    },
    expand: {
        flex: 1
    },
    more: {
        background: theme.colors.white,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        padding: 10
    },
    notEnabled: {
        background: `#F1F7FA`,
        height: '100%',
        padding: theme.spacing(3)
    },
    highlight: {
        color: theme.colors.dodgerBlue,
        fontWeight: 700
    },
    settingIcon: {
        position: 'relative',
        top: 4
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    center: {
        textAlign: 'center',
        maxWidth: '70%',
        margin: `0 auto`
    },
    spacing2: {
        marginBottom: theme.spacing(2)
    },
    sidebar: {
        padding: theme.spacing(4)
    },
    upgradeIcon: {
        marginRight: theme.spacing(1)
    },
    greyText: {
        color: theme.colors.midGrey
    },
    contentLayoutRoot: {
        borderLeft: 0,
        paddingLeft: 0
    },
    contentLayout: {
        height: 525,
        padding: 20,
        background: theme.colors.paleNavy,
        borderRadius: 6
    },
    addonWrapper: {
        padding: 40,
        background: theme.colors.ghostwhite,
        borderRadius: 8,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    tabContent: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    }
}));

const useTabStyles = makeStyles(theme => ({
    root: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.lightGreyBlue
    },
    selected: {
        color: theme.palette.primary.main
    }
}));

const TABS = {
    SETTING: 'SETTING',
    OVERVIEW: 'OVERVIEW'
};

function LQA({ addon, onBack }) {
    const { icon, title, description, integration, provider } = addon;
    const integrationId = integration?.id;
    const integrationState = integration?.state;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const tabClasses = useTabStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const [isDoing, setIsDoing] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(TABS.OVERVIEW);

    const [openConfirmUninstallModal, setOpenConfirmUninstallModal] = React.useState(false);

    const handleOpenConfirmUninstallModal = React.useCallback(() => {
        setOpenConfirmUninstallModal(true);
    }, []);

    const handleCloseConfirmUninstallModal = React.useCallback(() => {
        setOpenConfirmUninstallModal(false);
    }, []);

    const handleClick = React.useCallback(
        event => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl]
    );

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const isInstalled = React.useMemo(() => {
        return integrationState === ADD_ON_STATES.ACTIVE || integrationState === ADD_ON_STATES.DRAFT;
    }, [integrationState]);

    const handleEnableAddon = React.useCallback(() => {
        setIsDoing(true);
        sendManualTrack({ type: 'Install memoQ' });
        if (!integrationId) {
            dispatch(
                addonActions.enableAddon({
                    body: {
                        title,
                        description,
                        provider
                    },
                    successCallback: () => {
                        setIsDoing(false);
                        console.log('Enable add on success');
                    },
                    errorCallback: () => {
                        setIsDoing(false);
                        console.log('Enable add on failed');
                    }
                })
            );
        } else {
            dispatch(
                addonActions.changeAddonState({
                    addonId: integrationId,
                    newState: ADD_ON_STATES.DRAFT,
                    successCallback: () => {
                        setIsDoing(false);
                        console.log('disabled Add On Success');
                    },
                    errorCallback: () => {
                        setIsDoing(false);
                        console.log('disabled Add On Failed');
                    }
                })
            );
        }
    }, [description, integrationId, dispatch, title, provider]);

    const handleRemoveAddon = React.useCallback(
        e => {
            sendManualTrack({ type: 'Uninstall memoQ' });
            setIsDoing(true);
            dispatch(
                addonActions.changeAddonState({
                    addonId: integrationId,
                    newState: ADD_ON_STATES.INACTIVE,
                    successCallback: () => {
                        setIsDoing(false);
                        console.log('disabled Add On Success');
                        handleCloseConfirmUninstallModal();
                    },
                    errorCallback: () => {
                        setIsDoing(false);
                        console.log('disabled Add On Failed');
                    }
                })
            );
        },
        [dispatch, integrationId, handleCloseConfirmUninstallModal]
    );

    const ButtonInstallRender = React.useMemo(() => {
        const isInstallOrNot = isInstalled ? (
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <CheckedSVG
                        color={theme.colors.white}
                        fillColor={hexToRgba(theme.colors.atlantis, 0.8)}
                        style={{ marginRight: 4 }}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="body1">{t('global_installed')}</Typography>
                </Grid>
            </Grid>
        ) : (
            <Typography variant="body1" style={{ color: theme.colors.white }}>
                {t('global_install')}
            </Typography>
        );

        return (
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item className={`${classes.expand} ${classes.buttonWrapper}`}>
                    <ButtonBase
                        onClick={handleEnableAddon}
                        disabled={isInstalled || isDoing}
                        variant="contained"
                        fullWidth
                    >
                        {isInstallOrNot}
                    </ButtonBase>
                    {isDoing && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Grid>
                {isInstalled && (
                    <Grid item>
                        <IconButton onClick={handleClick} className={classes.more}>
                            <IconMoreActionsSVG />
                        </IconButton>
                    </Grid>
                )}
                {anchorEl && (
                    <PopperMenu
                        className={classes.commentPopup}
                        anchorEl={anchorEl}
                        placement={'bottom-end'}
                        id={`add-on-setting`}
                    >
                        <MenuOption
                            onRemoveAddon={() => {
                                handleOpenConfirmUninstallModal();
                                handleClickAway();
                            }}
                            id={integrationId}
                            handleClickAway={handleClickAway}
                        />
                    </PopperMenu>
                )}
            </Grid>
        );
    }, [
        anchorEl,
        classes,
        handleClick,
        handleClickAway,
        handleEnableAddon,
        integrationId,
        handleOpenConfirmUninstallModal,
        isDoing,
        isInstalled,
        theme,
        t
    ]);

    return (
        <>
            <AddonLayout onBack={onBack}>
                <Grid container direction="column">
                    <Grid item>
                        <Grid item className={classes.addonWrapper}>
                            <Grid container direction="column" spacing={5}>
                                <Grid item>
                                    <Grid
                                        container
                                        alignItems="flex-start"
                                        justify="space-between"
                                        direction="row"
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <Grid container direction="row" wrap="nowrap" spacing={3}>
                                                <Grid item className={classes.avatar}>
                                                    {icon(64)}
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="h4">{title}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="caption">
                                                                {t('global_last_update_in')} Sep 14th, 2020
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item className={classes.dlFlex}>
                                                                    <GridlyShortLogoSVG />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="body1">
                                                                        {t('global_gridly_text')}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>{ButtonInstallRender}</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid item>
                        <Tabs
                            className={classes.tabs}
                            value={tabValue}
                            onChange={(e, newValue) => setTabValue(newValue)}
                        >
                            <Tab classes={tabClasses} value={TABS.OVERVIEW} label={t('overview')} />
                            <Tab classes={tabClasses} value={TABS.SETTING} label={t('global_settings')} />
                        </Tabs>
                    </Grid>
                    <Grid item className={classes.tabContent}>
                        {tabValue === TABS.OVERVIEW ? (
                            <Grid container direction="column" spacing={5}>
                                <Grid item>
                                    <Grid container direction="column" spacing={4}>
                                        <Grid item>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body1">{t('global_description')}</Typography>{' '}
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        {t(`addon_lqa_install_description`)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        {t(`addon_lqa_install_sub_description`)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        • {t(`addon_lqa_install_benefit_1`)}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        • {t(`addon_lqa_install_benefit_2`)}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        • {t(`addon_lqa_install_benefit_3`)}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        • {t(`addon_lqa_install_benefit_4`)}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        • {t(`addon_lqa_install_benefit_5`)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body1">{t('global_permission')}:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        • {t(`addon_lqa_install_note`)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <LQASetting />
                        )}
                    </Grid>
                </Grid>
            </AddonLayout>

            <Dialog open={openConfirmUninstallModal} onClose={handleCloseConfirmUninstallModal}>
                <ConfirmBox
                    title={'Uninstall LQA Add-on'}
                    body={
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography component="div" style={{ marginBottom: theme.spacing(2) }} variant="body2">
                                    Are you sure you want to uninstall LQA Add-on?
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    handleCancel={handleCloseConfirmUninstallModal}
                    onClose={handleCloseConfirmUninstallModal}
                    handleAgreed={handleRemoveAddon}
                    agreeLabel="Uninstall"
                    isLoading={isDoing}
                />
            </Dialog>
        </>
    );
}

export default React.memo(LQA);
