import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Radio } from '@material-ui/core';

const RadioBase = withStyles(theme => ({
    root: {
        '&$checked path': {
            fill: theme.colors.lightLavender
        },
        '& path': {
            fill: theme.colors.checkboxBorder
        }
    },
    disabled: {
        '& path': {
            fill: `${theme.colors.disabledGrey} !important`
        }
    },
    checked: {}
}))(props => <Radio color="default" {...props} />);

export default React.memo(RadioBase);
