import React from 'react';

function OverrideRecordSVG({ color = 'white', ...other }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <circle cx="20" cy="20" r="20" fill="#7869B9" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8099 11.5048C24.4702 11.1651 23.9194 11.1651 23.5797 11.5048C23.24 11.8445 23.24 12.3952 23.5797 12.7349L25.1876 14.3428H16.4626C15.4117 14.3428 14.4038 14.7603 13.6606 15.5034C12.9175 16.2465 12.5 17.2544 12.5 18.3054V19.8518C12.5 20.3322 12.8894 20.7216 13.3698 20.7216C13.8502 20.7216 14.2397 20.3322 14.2397 19.8518V18.3054C14.2397 17.7158 14.4739 17.1504 14.8908 16.7335C15.3077 16.3167 15.8731 16.0825 16.4626 16.0825H25.1876L23.5797 17.6903C23.24 18.03 23.24 18.5808 23.5797 18.9205C23.9194 19.2602 24.4702 19.2602 24.8099 18.9205L27.9027 15.8277C28.2424 15.488 28.2424 14.9373 27.9027 14.5976L24.8099 11.5048ZM17.0777 22.3173C17.4174 22.657 17.4174 23.2077 17.0777 23.5474L15.4576 25.1676H24.1946C24.7842 25.1676 25.3496 24.9333 25.7665 24.5165C26.1833 24.0996 26.4175 23.5342 26.4175 22.9446V21.3982C26.4175 20.9178 26.807 20.5284 27.2874 20.5284C27.7678 20.5284 28.1572 20.9178 28.1572 21.3982V22.9446C28.1572 23.9956 27.7397 25.0035 26.9966 25.7466C26.2535 26.4898 25.2456 26.9072 24.1946 26.9072H15.4821L17.0777 28.5028C17.4174 28.8425 17.4174 29.3933 17.0777 29.733C16.738 30.0727 16.1873 30.0727 15.8476 29.733L12.8478 26.7332C12.6366 26.5745 12.5 26.3219 12.5 26.0374C12.5 26.0353 12.5 26.0333 12.5 26.0312C12.4985 25.8066 12.5834 25.5815 12.7548 25.4101L15.8476 22.3173C16.1873 21.9776 16.738 21.9776 17.0777 22.3173Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(OverrideRecordSVG);
