import produce from 'immer';
import { combinedData } from 'utils/gridUI/data';
import * as types from '../types';

const handler = {
    [types.RESET_CELL_INFO](state) {
        return {
            ...state
        };
    },

    [types.CHANGE_BOOLEAN_STATUS](state, action) {
        const { data } = state;
        const { value, columnId, rowId } = action.payload;

        const newData = combinedData({
            data,
            newData: {
                [rowId]: {
                    [columnId]: {
                        value
                    }
                }
            }
        });

        return {
            ...state,
            data: newData
        };
    },

    [types.OPEN_CELL_EDIT](state) {
        return {
            ...state,
            isOpenCellEdit: true,
            isOpenCellPreview: false
        };
    },

    [types.RESET_CELL_STATUS](state) {
        return {
            ...state,
            isOpenCellEdit: false,
            columnsSelected: [],
            isSelecting: false,
            rowStartIndex: -1,
            rowStopIndex: -1,
            columnStartIndex: -1,
            columnStopIndex: -1,
            rowIndexMap: {},
            rowsRangeIndexes: [],
            character: '',
            searchRange: null
        };
    },

    [types.DELETE_CELL_INFO](state, { payload }) {
        const { columnId, recordId } = payload;
        let { data } = state;
        const newData = combinedData({
            data,
            newData: {
                [recordId]: {
                    [columnId]: {
                        value: null
                    }
                }
            }
        });

        return {
            ...state,
            data: newData
        };
    },

    [types.CELL_CLICK_AWAY](state, action) {
        const { value, rowId, columnId } = action.payload;
        let { data } = state;

        const newData = combinedData({
            data,
            newData: {
                [rowId]: {
                    [columnId]: {
                        value
                    }
                }
            }
        });

        return {
            ...state,
            isOpenCellEdit: false,
            data: newData,
            character: ''
        };
    },

    [types.CELL_CLICK_AWAY_AND_STAY](state, action) {
        const { value, rowId, columnId } = action.payload;

        let { data } = state;
        const newData = combinedData({
            data,
            newData: {
                [rowId]: {
                    [columnId]: {
                        value
                    }
                }
            }
        });

        return {
            ...state,
            isOpenCellEdit: false,
            data: newData,
            character: ''
        };
    },

    [types.UPDATE_METADATA_VALIDATIONS](state, action) {
        const { validations, rowId, columnId } = action.payload;

        let { data } = state;
        const newData = combinedData({
            data,
            newData: {
                [rowId]: {
                    [columnId]: {
                        metadata: {
                            validations
                        }
                    }
                }
            }
        });

        return {
            ...state,
            data: newData
        };
    },
    [types.UPDATE_CELL_VALUE_WITHOUT_CELL_INFO](state, action) {
        const { value, rowId, columnId } = action.payload;
        let { data } = state;
        const newData = combinedData({
            data,
            newData: {
                [rowId]: {
                    [columnId]: {
                        value
                    }
                }
            }
        });

        return {
            ...state,
            data: newData
        };
    },
    [types.UNDO_UPDATE_CELL](state, action) {
        const { rowId, columnId, value } = action.payload;
        let { data } = state;

        const newData = combinedData({
            data,
            newData: {
                [rowId]: {
                    [columnId]: {
                        value
                    }
                }
            }
        });

        return {
            ...state,
            isOpenCellEdit: false,
            data: newData
        };
    },

    [types.CANCEL_CELL_EDIT](state) {
        return {
            ...state,
            isOpenCellEdit: false,
            character: ''
        };
    },

    [types.ENABLE_SELECTING](state) {
        return {
            ...state,
            isSelecting: true,
            isOpenCellEdit: false,
            rowIndexMap: {}
        };
    },

    [types.STARTING_CELL_COPY_SELECTION](state, action) {
        const { rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex } = action.payload;
        const { cellCopySelection } = state;
        const newCellCopySelection = produce(cellCopySelection, draft => {
            if (!draft) {
                draft = {};
            }
            draft.rowStartIndex = rowStartIndex;
            draft.rowStopIndex = rowStopIndex;
            draft.columnStartIndex = columnStartIndex;
            draft.columnStopIndex = columnStopIndex;
        });

        return {
            ...state,
            cellCopySelection: newCellCopySelection
        };
    },

    [types.STOPPING_CELL_COPY_SELECTION](state, action) {
        return {
            ...state,
            cellCopySelection: {
                rowStartIndex: -1,
                rowStopIndex: -1,
                columnStartIndex: -1,
                columnStopIndex: -1
            }
        };
    },

    [types.STOPPING_CELL_SELECTION](state) {
        return {
            ...state,
            isSelecting: false
        };
    },

    [types.SELECTING_CELL_SELECTION](state, action) {
        const { rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex } = action.payload;

        return {
            ...state,
            rowStartIndex,
            rowStopIndex,
            columnStartIndex,
            columnStopIndex,
            rowsRangeIndexes: [],
            rowIndexMap: {},
            columnsSelected: []
        };
    },
    [types.INCREASE_SELECTION_RIGHT](state, action) {
        const { columnStopIndex } = action.payload;
        return {
            ...state,
            columnStopIndex
        };
    },

    [types.DELETE_RANGE_SELECTION_INFO](state, action) {
        const { newRowsData } = action.payload;
        return {
            ...state,
            data: newRowsData
        };
    },

    [types.DELETE_FILE_OF_CELL](state, action) {
        const { columnId, rowId, value } = action.payload;
        const { data } = state;

        const newData = combinedData({
            data,
            newData: {
                [rowId]: {
                    [columnId]: {
                        value
                    }
                }
            }
        });

        return {
            ...state,
            data: newData
        };
    },
    [types.UPDATE_LAST_SCROLL_POSITION](state, action) {
        const { currentScrollTop, currentScrollLeft } = action.payload;

        return {
            ...state,
            lastScrollPosition: {
                currentScrollTop,
                currentScrollLeft
            }
        };
    },
    [types.UPDATE_CELL_VALUE_ONLY](state, action) {
        const { columnId, rowId, value } = action.payload;
        const { data } = state;

        const newData = combinedData({
            data,
            newData: {
                [rowId]: {
                    [columnId]: {
                        value
                    }
                }
            }
        });

        return {
            ...state,
            data: newData
        };
    },
    [types.RESET_SELECTION](state, action) {
        return {
            ...state,
            rowStartIndex: -1,
            rowStopIndex: -1,
            columnStartIndex: -1,
            columnStopIndex: -1,
            columnsSelected: [],
            rowsRangeIndexes: [],
            rowIndexMap: {}
        };
    },
    [types.SET_CHARACTER_KEY](state, action) {
        const { character } = action?.payload;
        return {
            ...state,
            character
        };
    },
    [types.OPEN_CELL_CONTEXT](state, action) {
        return {
            ...state,
            isOpenCellContext: true
        };
    },
    [types.CLOSE_CELL_CONTEXT](state, action) {
        return {
            ...state,
            isOpenCellContext: false
        };
    },
    [types.IGNORE_AUTOQA_SINGLE_ERROR](state, action) {
        const { errorId, rowId, columnId } = action.payload;
        let { data } = state;

        const newValidations = data?.[rowId]?.[columnId]?.metadata?.validations.map(validation => {
            if (validation.errorid === errorId) validation.ignored = true;
            return validation;
        });

        const newData = combinedData({
            data,
            newData: {
                [rowId]: {
                    [columnId]: {
                        metadata: {
                            validations: newValidations
                        }
                    }
                }
            }
        });
        return {
            ...state,
            data: newData
        };
    },
    [types.IGNORE_AUTOQA_SIMILAR_ERRORS](state, action) {
        const { errorCategory, columnId } = action.payload;
        let { data } = state;
        const cloneData = { ...data };
        Object.keys(cloneData).forEach(rowId => {
            const validations = cloneData[rowId][columnId].metadata.validations;
            if (!validations) return;
            const newValidations = validations.map(validation => {
                if (validation.category === errorCategory) validation.ignored = true;
                return validation;
            });
            cloneData[rowId][columnId].metadata.validations = newValidations;
        });
        return {
            ...state,
            data: cloneData
        };
    },
    [types.SET_SELECTING_COPY](state, action) {
        const { enable } = action?.payload;
        return {
            ...state,
            isCellCopying: enable
        };
    }
};

export default handler;
