import React from 'react';
import { LinearProgress, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.colors.divider
    },
    bar: {
        borderRadius: 5,
        backgroundColor: props => props.color
    }
}));

export function CustomLinearProgress({ value, ...rest }) {
    const theme = useTheme();
    const getColor = value => {
        if (value <= 25) return theme.colors.shamRock;
        if (value <= 50) return theme.colors.dodgerBlue;
        if (value <= 75) return theme.colors.sun;
        return theme.colors.burntSlena;
    };
    const classes = useStyles({ color: getColor(value) });

    return <LinearProgress classes={classes} variant="determinate" value={value} {...rest} />;
}
