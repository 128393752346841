import React from 'react';

function ColorBucketSVG({ selectedColor = '#F8AD13', color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g id="Toolbar/ColorBucket">
                <path id="Vector 10" d="M13 7L7.5 2L1.5 8.5L6.5 14L13 7Z" fill={selectedColor} />
                <g id="Subtract">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.95151 1.314L3.8689 0.400024H3.92909L3.99061 0.426384L5.90276 2.43425L7.70604 0.593146L7.8353 0.619113L14.1932 7.33154L14.1697 7.46201L6.54061 15.419L6.4116 15.3931L0.0537109 8.67591L0.0772396 8.54533L4.865 3.52293L2.92806 1.44405L2.95151 1.314ZM12.1867 7.36416L7.73418 2.69176L6.93823 3.52539L8.26911 4.95921L8.24569 5.08946L7.33263 5.99916L7.20288 5.9732L5.90209 4.61261L2.05808 8.64656L6.51171 13.3235L12.1867 7.36416Z"
                        fill={color}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.5707 10.772L14.1122 10.0421L14.1723 10.0422L14.2427 10.0801L14.7669 10.8737C14.9487 11.158 15.1302 11.4596 15.2949 11.7541C15.4787 12.0881 15.6114 12.3547 15.7164 12.6036C15.8998 13.0414 15.95 13.239 15.95 13.534C15.95 14.5712 15.1301 15.4235 14.1359 15.4235C13.1417 15.4235 12.3218 14.5712 12.3218 13.534C12.3218 13.391 12.338 13.2584 12.3735 13.116L12.4161 12.9681L12.5339 12.656L12.5556 12.6031C12.6131 12.4669 12.6795 12.3242 12.7564 12.1717L12.8798 11.9352L12.8803 11.9342L12.9806 11.7513L13.1677 11.4243L13.2769 11.2409L13.2775 11.24L13.5707 10.772ZM14.2822 12.7951L14.1369 12.5219L14.036 12.7052L14.0355 12.7061C13.9795 12.8134 13.9261 12.9214 13.8789 13.0229L13.8134 13.1689L13.762 13.3046L13.7043 13.5003L13.6902 13.5192L13.6951 13.6001L13.6956 13.6037C13.7187 13.775 13.824 13.9125 13.9691 13.9701L14.0542 13.9939L14.132 14H14.1359C14.3281 14 14.4901 13.8809 14.5525 13.7113L14.5861 13.5756L14.5381 13.3923L14.5376 13.3908C14.5151 13.3147 14.4896 13.2426 14.4605 13.1742C14.4297 13.1009 14.3925 13.0194 14.3499 12.9314L14.2822 12.7951Z"
                        fill={color}
                    />
                </g>
            </g>
        </svg>
    );
}

export default React.memo(ColorBucketSVG);
