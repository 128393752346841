import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import Option from 'components/option/Base';
import { ROW_HEIGHT } from 'const/gridUI';
import { isReferenceDataForMulti } from 'utils/gridUI/data';

function ReferenceSelectMultiRender({ value, rowHeight = ROW_HEIGHT, rowIndex, columnIndex }) {
    const theme = useTheme();
    if (isEmpty(value) || !isReferenceDataForMulti(value)) return null;

    const getJsonValue = value => {
        if (isArray(value)) {
            let values = value.map(i => {
                const referencedDataItem = i?.referencedDataItem;
                if (!(referencedDataItem instanceof Array)) return [];
                return referencedDataItem?.filter(item => typeof item === 'string');
            });
            return values.filter(Boolean);
        }
        return null;
    };

    let newData = getJsonValue(value);

    if (!isArray(newData) || !newData) return null;

    return (
        <div className="w-fit pointer-events-none flex-wrap gap-1">
            {newData?.map((items = [], indexItems) => {
                if (!(items instanceof Array)) return null;
                return (
                    <div
                        key={indexItems}
                        className={`rounded px-1 py-0.5 mr-1 mb-1 bg-option-fill border inline-flex w-fit gap-1 border-option-stroke`}
                    >
                        {items?.map(item => (
                            <Option
                                className={'py-1'}
                                key={item}
                                size="small"
                                label={item}
                                color={theme.colors.dodgerBlue}
                            />
                        ))}
                    </div>
                );
            })}
        </div>
    );
}

export default React.memo(ReferenceSelectMultiRender);
