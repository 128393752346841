import React from 'react';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'components/button/Base';
import Checkbox from 'components/checkbox/Base';
import InputText from 'components/inputs/InputText';
import * as gridActions from './actions';
// import { useDropzone } from 'react-dropzone';
// import { IMAGE_EXTENSIONS } from 'const';
// import { getUploadErrorMessage } from 'utils/upload';
// import { enqueueSnackbar } from 'notifier/actions';
// import TemplateDefaultPNG from 'assets/images/templateDefault.png';
import Tooltip from 'components/tooltip/Base';

const useStyles = makeStyles(theme => ({
    root: {
        width: 445,
        borderRadius: 6
    },
    spacing3: {
        marginBottom: theme.spacing(3)
    },
    spacing2: {
        marginBottom: theme.spacing(2)
    },
    sp1: {
        marginBottom: theme.spacing(1)
    },
    img: {
        maxWidth: '100%',
        objectFit: 'cover'
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function SaveTemplate({ isEdit, dbId, grid, onClose, template, onTemplateCreated }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [includeRecords, setIncludeRecords] = React.useState(true);
    // const [includeViews, setIncludeViews] = React.useState(true);
    const [includeSharedGroups, setIncludeSharedGroups] = React.useState(true);
    const [includeAutomations, setIncludeAutomations] = React.useState(true);

    const formik = useFormik({
        initialValues: {
            name: isEdit ? template?.name : grid?.name || '',
            description: isEdit ? template?.description : ''
        },
        validationSchema: object().shape({
            name: string().required(t('global_this_field_is_required')),
            description: string()
        }),
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });

            setSubmitting(true);

            if (!isEdit) {
                let body = {
                    dbId,
                    cloneGridId: grid?.id,
                    cloneGridColor: grid?.customProperties?.color,
                    name: values?.name?.trim(),
                    description: values?.description,
                    duplicateRecordOption: includeRecords ? 'include' : 'exclude',
                    duplicateGroupAccessOption: includeSharedGroups ? 'include' : 'exclude',
                    duplicateAutomationOption: includeAutomations ? 'include' : 'exclude'
                };

                dispatch(
                    gridActions.saveGridAsTemplate({
                        ...body,
                        successCallback: template => {
                            onClose && onClose();
                            onTemplateCreated && onTemplateCreated(template);
                            setSubmitting(false);
                        },
                        errorCallback: () => {
                            setSubmitting(false);
                        }
                    })
                );
            } else {
                const updateBody = {
                    description: values?.description?.trim(),
                    name: values?.name?.trim()
                };

                dispatch(
                    gridActions.updateGridTemplate({
                        dbId,
                        templateId: template?.id,
                        body: updateBody,
                        successCallback: () => {
                            onClose && onClose();
                            setSubmitting(false);
                        },
                        errorCallback: () => {
                            setSubmitting(false);
                        }
                    })
                );
            }

            setStatus({ error: null });
        }
    });

    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid } = formik;

    // const onDropAccepted = React.useCallback(acceptedFiles => {
    //     console.log('');
    // }, []);

    // const onDropRejected = React.useCallback(
    //     rejectedFiles => {
    //         const fileErrors = getUploadErrorMessage(rejectedFiles);
    //         const fileError = fileErrors?.[0];
    //         dispatch(
    //             enqueueSnackbar({
    //                 message: fileError?.[0],
    //                 type: 'info'
    //             })
    //         );
    //     },
    //     [dispatch]
    // );
    // const { getRootProps, getInputProps } = useDropzone({
    //     onDropAccepted,
    //     onDropRejected,
    //     multiple: false,
    //     maxSize: IMAGE_EXTENSIONS
    // });

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle
                title={<Typography variant="h4">{isEdit ? t('edit_template') : t('save_grid_as_template')}</Typography>}
                onClose={onClose}
            />
            <DialogContent>
                <Grid container direction="column" wrap="nowrap" className={classes.root}>
                    <Grid item className={classes.spacing3}>
                        <Grid container direction="column">
                            <Grid item>
                                <InputText
                                    label={t('template_name_label')}
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    errorText={errors.name}
                                    placeholder={t('template_name_label')}
                                    autoFocus
                                    autoSelect={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isEdit && (
                        <Grid item container direction="column" className={`${classes.spacing3} ${classes.options}`}>
                            <Grid item>
                                <Typography variant="body1">{t('template_included_parts_title')}</Typography>
                            </Grid>

                            <Grid item container direction="column">
                                <Grid item container alignItems="center" direction="row" spacing={2}>
                                    <Tooltip title="View included by default">
                                        <Grid item>
                                            <Checkbox disabled checked={true} onChange={() => {}} size="small" />
                                        </Grid>
                                    </Tooltip>
                                    <Grid item>
                                        <Typography variant="body2">Views</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item container alignItems="center" direction="row" spacing={2}>
                                    <Grid item>
                                        <Checkbox
                                            checked={includeRecords}
                                            onChange={e => setIncludeRecords(e.target.checked)}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">{t('global_records')}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item container alignItems="center" direction="row" spacing={2}>
                                    <Grid item>
                                        <Checkbox
                                            checked={includeSharedGroups}
                                            onChange={e => setIncludeSharedGroups(e.target.checked)}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">{t('shared_groups')}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item container alignItems="center" direction="row" spacing={2}>
                                    <Grid item>
                                        <Checkbox
                                            checked={includeAutomations}
                                            onChange={e => setIncludeAutomations(e.target.checked)}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">{t('global_automation')}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item container direction="column" className={`${classes.spacing3}`}>
                        <Grid item>
                            <InputText
                                label={t('template_description_label')}
                                name="description"
                                value={values.description}
                                disabled={isSubmitting}
                                onChange={handleChange}
                                error={errors.description && touched.description}
                                errorText={errors.description}
                                placeholder={t('global_description')}
                                className={classes.description}
                                height={70}
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item container direction="column">
                        <Grid item className={classes.sp1}>
                            <Typography variant="body1">Cover image (optional)</Typography>
                        </Grid>
                        <Grid item>
                            <div {...getRootProps()} tr-dt="Click To Update TM">
                                <input {...getInputProps()} />
                                <img className={classes.img} src={TemplateDefaultPNG} alt="img" />
                            </div>
                        </Grid>
                    </Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" spacing={2} wrap="nowrap">
                    <Grid item>
                        <Button onClick={onClose} width={120} variant="outlined">
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <Button width={120} disabled={!isValid || isSubmitting} type="submit" variant="contained">
                                {t('global_text_save')}
                            </Button>
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </form>
    );
}

export default SaveTemplate;
