import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { DISABLED_OPACITY } from 'const/style';

const useStyles = makeStyles(theme => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        border: 'none'
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main
            }
        }
    },
    customColor: {
        '& .MuiSwitch-track': {
            backgroundColor: props => `${props.color} !important`,
            opacity: props => (props.checked ? 1 : DISABLED_OPACITY)
        }
    },
    disabled: {
        color: `${theme.colors.white} !important`,
        '&$disabled + $track': {
            opacity: DISABLED_OPACITY,
            backgroundColor: props => (props.checked ? theme.palette.primary.main : theme.colors.silver)
        }
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none'
    },
    track: {
        border: `none`,
        borderRadius: theme.spacing(2),
        opacity: 1,
        backgroundColor: theme.colors.silver
    },
    checked: {}
}));

function IOSSwitch({ color, checked, ...rest }) {
    const classes = useStyles({ color, checked });
    return <Switch classes={classes} className={classes.customColor} checked={checked} {...rest} />;
}

export default React.memo(IOSSwitch);
