import React from 'react';
import { Grid } from '@material-ui/core';
import Skeleton from 'components/skeleton/Base';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `40px 20px`
    }
}));

function RoleContentSkeleton() {
    const classes = useStyles();
    const loadTest = {
        1: [1, 2, 3, 4, 5],
        2: [11]
    };
    return (
        <Grid className={classes.root} direction="column" container>
            <Grid item>
                <Grid direction="column" container spacing={5}>
                    <Grid item>
                        <Grid container direction="column" spacing={1} wrap="nowrap">
                            <Grid item>
                                <Grid direction="row" container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Skeleton width={140} height={28} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Skeleton width={400} height={23} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Skeleton width={'100%'} height={1} />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container direction="column" wrap="nowrap" spacing={5}>
                            {Object.keys(loadTest).map(key => {
                                const privileges = loadTest?.[key];
                                return (
                                    <Grid key={key} item>
                                        <Grid container spacing={4} direction="column" wrap="nowrap">
                                            <Grid item>
                                                <Skeleton width={100} height={22} />
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column" spacing={4} wrap="nowrap">
                                                    {privileges?.map(privilege => {
                                                        return (
                                                            <Grid key={privilege} item>
                                                                <Grid container direction={'row'} wrap="nowrap">
                                                                    <Grid item xs={10}>
                                                                        <Grid container direction="column" spacing={2}>
                                                                            <Grid item>
                                                                                <Skeleton width={100} height={21} />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Skeleton width={400} height={23} />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Grid container justify="flex-end">
                                                                            <Grid item>
                                                                                <Skeleton width={30} height={16} />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(RoleContentSkeleton);
