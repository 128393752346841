import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import ListItem from 'components/list/Item';
import Spinner from 'components/spinner/Base';
import hexToRgba from 'hex-to-rgba';
import { DISABLED_OPACITY, SELECTION_LIST_ITEM_MIN_HEIGHT, SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT } from 'const/style';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: 0,
        position: 'relative',
        borderRadius: 4
    },

    createIcon: {
        color: theme.colors.lightLavender
    },
    createText: {
        color: theme.palette.primary.main
    },
    listItem: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        position: 'relative',
        marginTop: theme.spacing(2),
        '& .textContent': {
            color: theme.colors.lightLavender
        }
    },
    loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: hexToRgba(theme.colors.white, DISABLED_OPACITY)
    },
    optAll: {
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        minHeight: SELECTION_LIST_ITEM_MIN_HEIGHT,
        paddingLeft: props => (props.noPadding ? 0 : SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT),
        paddingRight: props => (props.noPadding ? 0 : SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT),
        cursor: 'pointer'
    },
    link: {
        color: theme.colors?.dodgerBlue,
        textDecoration: 'underline'
    }
}));

function Menu(props) {
    const { children, innerProps, ...rest } = props;
    const {
        isCreatable,
        createText,
        createIcon,
        onCreateNew,
        menuClassName,
        createMoreIcon,
        isLoadingCreating,
        handleClickAway,
        setOpenState,
        isAll,
        onSelectAll,
        selectAllText = 'Select all'
    } = rest.selectProps;
    const classes = useStyles();
    const theme = useTheme();

    let defaultCreateIcon = createIcon ? createIcon : <ColorAddSVG color={theme.palette.primary.main} />;
    return (
        <Paper
            elevation={0}
            square
            className={`${classes.paper} ${menuClassName ? menuClassName : ''}`}
            {...innerProps}
        >
            {isAll && (
                <Grid
                    className={classes.optAll}
                    onClick={() => onSelectAll(setOpenState)}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid item>
                        <Typography variant="body2" className={classes.link}>
                            {selectAllText}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {children}
            {isCreatable && (
                <>
                    <Divider />
                    <ListItem
                        className={classes.listItem}
                        onClick={() => onCreateNew && onCreateNew(handleClickAway, setOpenState)}
                        name={
                            <>
                                <Typography className={classes.createText} variant="body1">
                                    {createText}
                                </Typography>
                                {isLoadingCreating && (
                                    <div className={classes.loading}>
                                        <Spinner size={18} thick={3} />
                                    </div>
                                )}
                            </>
                        }
                        icon={typeof defaultCreateIcon === 'function' ? defaultCreateIcon() : defaultCreateIcon}
                        moreIcon={createMoreIcon}
                        disabled={isLoadingCreating}
                    />
                </>
            )}
        </Paper>
    );
}
Menu.propTypes = {
    children: PropTypes.element.isRequired,
    innerProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired
};

export default React.memo(Menu);
