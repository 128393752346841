import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import BranchSVG from 'assets/images/svg/BranchSVG';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import MergedSVG from 'assets/images/svg/versionControl/MergedSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import CardInfo from 'components/cardInfo/Base';
import BranchIcon from 'versionControl/sharedComponents/BranchIcon';
import { useSelectedBranchDetail, useMaster } from 'hooks/versionControl';
import { useDispatch } from 'react-redux';
import { useContextMenuId } from 'hooks/app';
import PopperMenu from 'components/menus/Popper';
import SidebarRightLayout from '../layout/rightSidebar';
import Merge from './Merge';
import Update from './Update';
import { DISABLED_OPACITY } from 'const/style';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { useBranchesOptions } from 'hooks/versionControl/branch';
import CellBaseDropdown from 'components/selects/CellBase';
import { useTranslation } from 'react-i18next';
// import VersionControlBannerSVG from 'assets/images/svg/versionControl/VersionControlBannerSVG';
// import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import ModalBase from 'components/modal/Base';
import CreateGridBranchForm from 'versionControl/CreateGridBranchForm';
import { useCanVersionControl } from 'hooks/payment';
import * as gridUIActions from 'gridUI/actions';
import * as branchActions from 'versionControl/actions';
import { sendManualTrack } from 'tracker';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import { useRole } from 'hooks/auth/role';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    flex: {
        display: 'flex'
    },

    actions: {
        marginTop: theme.spacing(3)
    },
    actionItem: {
        padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
        borderRadius: 10,
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    active: {
        backgroundColor: theme.colors.ghostwhite
    },
    expand: {
        flex: 1
    },

    more: {
        cursor: 'pointer'
    },

    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    masterIllustration: {
        height: `calc(100vh - ${72 + 60 + 52 + 24}px)`,
        overflowY: 'auto'
    },

    wrapper: {
        padding: theme.spacing(4)
    },
    selection: {
        background: theme.colors.ghostwhite,
        borderRadius: 4,
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        cursor: 'pointer',
        minWidth: 200
    },
    paper: {
        width: '100%'
    },
    center: {
        textAlign: 'center'
    },
    link: {
        color: theme.colors.dodgerBlue,
        textDecoration: 'none',
        fontWeight: 400
    },
    notAllow: {
        pointerEvents: 'none',
        transform: 'scale(0.8) translate(-10%, 0%);',
        position: 'relative',
        top: 8
    },
    scaleHaft: {
        transform: 'scale(0.7) translate(-20%, 0%);'
    },
    fullWidth: {
        width: '100%'
    }
}));

const STATES = {
    INITIAL: 'INITIAL',
    UPDATE: 'UPDATE',
    MERGED: 'MERGED'
};

function BranchView({ handleClose, gridId, branchId, workspaceId, dbId, viewId }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMaster = React.useMemo(() => {
        return branchId === gridId;
    }, [branchId, gridId]);

    const branchDetail = useSelectedBranchDetail({ masterGridId: gridId, selectedBranchIdOutSide: branchId });
    const [currentState, setCurrentState] = React.useState(STATES.INITIAL);
    const dispatch = useDispatch();
    const contextMenuId = useContextMenuId();
    const canUseVersionControl = useCanVersionControl();
    const roles = useRole();

    const branchOptions = useBranchesOptions({ dbId, gridId }) || [];
    const anchorElRef = React.useRef();
    const [isOpenCreateBranchForm, setIsOpenCreateBranchForm] = React.useState(false);
    const handleCloseModal = () => setIsOpenCreateBranchForm(false);
    const [isOpenConfirm, setIsOpenConfirm] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const master = useMaster({ dbId, gridId });

    const isHasManageBranch = React.useMemo(() => {
        return roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_BRANCH] === roleConst.FULL;
    }, [roles]);

    const CONTEXT_MENU_ID = React.useMemo(() => {
        return `BRANCH_CONTEXT_MENU_ID_${branchDetail?.id}`;
    }, [branchDetail]);

    const handleClick = React.useCallback(
        e => {
            stopPropagation(e);
            setAnchorEl(anchorEl ? null : e.currentTarget);
        },
        [anchorEl]
    );

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleCreateBranch = React.useCallback(() => {
        handleClickAway();
        setIsOpenCreateBranchForm(true);
    }, []);

    React.useEffect(() => {
        if (contextMenuId && contextMenuId !== CONTEXT_MENU_ID) {
            handleClickAway();
        }
    }, [contextMenuId, CONTEXT_MENU_ID]);

    const stopPropagation = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    const header = React.useCallback(() => {
        switch (currentState) {
            case STATES.INITIAL:
                return t('version_control');
            case STATES.MERGED:
                return t('merge_branch');
            case STATES.UPDATE:
                return 'Update branch';
            default:
                return null;
        }
    }, [currentState, t]);

    const moveToMergeScreen = React.useCallback(() => {
        setCurrentState(STATES.MERGED);
    }, []);

    const handleSwitchBranch = React.useCallback(
        branch => {
            branch &&
                history.push(
                    `/projects/${workspaceId}/databases/${dbId}/grids/${gridId}/branches/${branch.id}/views/${branch?.defaultAccessViewId}`
                );
            dispatch(gridUIActions.resetAllUploadStatus());
            dispatch(gridUIActions.clearQuickFilterAndQuickSort());
            dispatch(gridUIActions.resetDisabledAndPendingColumnIds());
            handleClickAway();
        },
        [dbId, dispatch, gridId, workspaceId, history]
    );

    const handleBrachChange = React.useCallback(
        branch => {
            handleSwitchBranch(branch);
        },
        [handleSwitchBranch]
    );

    const ChangeBranch = React.useCallback(
        ({ isReadOnly }) => {
            return (
                <Grid
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    container
                    className={`${classes.selection} ${isReadOnly ? classes.notAllow : ``}`}
                    wrap="nowrap"
                    onClick={handleClick}
                    ref={isReadOnly ? null : anchorElRef}
                >
                    <Grid item direction="row" container alignItems="center" spacing={2}>
                        <Grid item className={classes.flex}>
                            <BranchSVG color={theme.palette.primary.main} />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <p className="caption">{t('current_branch')}</p>
                                </Grid>
                                <Grid item>
                                    <p className="body1">{isMaster ? 'Master' : branchDetail?.name}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.flex}>
                        <ArrowDownSVG />
                    </Grid>
                </Grid>
            );
        },
        [branchDetail, classes, handleClick, isMaster, theme, t]
    );

    const handleBack = React.useCallback(() => {
        setCurrentState(STATES.INITIAL);
    }, []);

    const onBrandCreated = React.useCallback(
        newBranch => {
            history.push(
                `/projects/${workspaceId}/databases/${dbId}/grids/${gridId}/branches/${newBranch.id}/views/${newBranch?.defaultAccessViewId}`
            );
        },
        [dbId, history, gridId, workspaceId]
    );

    const openDeleteConfirm = React.useCallback(() => {
        setIsOpenConfirm(true);
    }, []);

    const handleCloseDeleteConfirm = React.useCallback(() => {
        setIsOpenConfirm(false);
    }, []);

    const handleDeleteBranch = React.useCallback(() => {
        setIsDeleting(true);
        sendManualTrack({
            type: `Delete Branch`,
            customData: {
                parentGridId: gridId,
                dbId,
                gridId: branchId
            }
        });
        dispatch(
            branchActions.deleteGridBranch({
                masterId: gridId,
                dbId,
                gridId: branchId,
                successCallback: () => {
                    history.push(
                        `/projects/${workspaceId}/databases/${dbId}/grids/${gridId}/branches/${gridId}/views/${master?.defaultAccessViewId}`
                    );
                    setIsDeleting(false);
                    handleCloseDeleteConfirm();
                    console.log('delete branch success');
                },
                errorCallback: () => {
                    setIsDeleting(false);
                    console.log('failed to delete branch');
                }
            })
        );
    }, [dbId, gridId, history, workspaceId, branchId, dispatch, master, handleCloseDeleteConfirm]);

    const content = React.useCallback(() => {
        switch (currentState) {
            case STATES.INITIAL:
                return (
                    <>
                        <Grid item>
                            <Grid container className={classes.actions} spacing={3} direction="column" wrap="nowrap">
                                <Grid item>
                                    <h4 className="prose prose-lg font-medium">{t('action')}</h4>
                                </Grid>
                                <Grid item>
                                    <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_MERGING}>
                                        {({ isReadOnly }) => {
                                            const isDisabled = !canUseVersionControl || isReadOnly;
                                            return (
                                                <Grid
                                                    item
                                                    className={`${classes.actionItem} ${
                                                        isDisabled ? classes.disabled : ''
                                                    }`}
                                                    onClick={moveToMergeScreen}
                                                >
                                                    <CardInfo
                                                        avatar={
                                                            <BranchIcon
                                                                color={theme.colors.lightLavender}
                                                                icon={<MergedSVG color={theme.colors.white} />}
                                                                size="medium"
                                                            />
                                                        }
                                                        title={t('merge_branch')}
                                                        subTitle={t('merge_changes_into_another_branch')}
                                                    />
                                                </Grid>
                                            );
                                        }}
                                    </AccessControl>
                                </Grid>
                                {!isMaster && (
                                    <Grid item>
                                        <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_MERGING}>
                                            {({ isReadOnly }) => {
                                                const isDisabled = !canUseVersionControl || isReadOnly;
                                                return (
                                                    <Grid
                                                        item
                                                        className={`${classes.actionItem} ${
                                                            isDisabled ? classes.disabled : ''
                                                        }`}
                                                        onClick={openDeleteConfirm}
                                                    >
                                                        <CardInfo
                                                            avatar={
                                                                <BranchIcon
                                                                    color={theme.colors.brightRed}
                                                                    icon={<DeleteSVG color={theme.colors.white} />}
                                                                    size="medium"
                                                                />
                                                            }
                                                            title="Delete branch"
                                                            subTitle="Delete this branch"
                                                        />
                                                    </Grid>
                                                );
                                            }}
                                        </AccessControl>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        {anchorEl && (
                            <PopperMenu
                                container={anchorElRef.current}
                                style={{
                                    width: anchorEl.offsetWidth
                                }}
                                anchorEl={anchorEl}
                                className={classes.paper}
                                handleClickAway={handleClickAway}
                            >
                                <CellBaseDropdown
                                    onChange={handleBrachChange}
                                    options={branchOptions}
                                    placeholder={t(`global_label_find_an_option`)}
                                    defaultValue={null}
                                    isCreatable={isHasManageBranch}
                                    onCreateNew={isHasManageBranch && handleCreateBranch}
                                    createText={isHasManageBranch && t('create_new_branch')}
                                />
                            </PopperMenu>
                        )}
                    </>
                );

            case STATES.MERGED:
                return (
                    <Merge
                        handleBack={handleBack}
                        handleClose={handleClose}
                        workspaceId={workspaceId}
                        gridId={gridId}
                        viewId={viewId}
                        dbId={dbId}
                        branchId={branchId}
                    />
                );

            case STATES.UPDATE:
                return (
                    <Update
                        handleBack={handleBack}
                        handleClose={handleClose}
                        workspaceId={workspaceId}
                        gridId={gridId}
                        viewId={viewId}
                        dbId={dbId}
                        branchId={branchId}
                    />
                );

            default:
                return null;
        }
    }, [
        isHasManageBranch,
        canUseVersionControl,
        // ChangeBranch,
        openDeleteConfirm,
        handleClose,
        branchOptions,
        t,
        handleBrachChange,
        currentState,
        anchorEl,
        classes,
        // histories,
        isMaster,
        // moveToUpdateScreen,
        moveToMergeScreen,
        theme,
        viewId,
        branchId,
        dbId,
        gridId,
        workspaceId,
        handleBack,
        handleCreateBranch
    ]);

    return (
        <SidebarRightLayout
            title={header()}
            onBack={currentState !== STATES.INITIAL ? handleBack : undefined}
            onClose={handleClose}
        >
            <Grid className={classes.wrapper} container direction="column">
                {currentState === STATES.INITIAL && (
                    <Grid item className={classes.fullWidth}>
                        {ChangeBranch({ isReadOnly: false })}
                    </Grid>
                )}
                <Grid item className={classes.fullWidth}>
                    {content()}
                </Grid>
            </Grid>
            <ModalBase id={'clone-grid-modal'} open={isOpenCreateBranchForm} handleClose={handleCloseModal}>
                <CreateGridBranchForm onbrandCreated={onBrandCreated} onClose={handleCloseModal} />
            </ModalBase>
            <Dialog open={isOpenConfirm} onClose={handleCloseDeleteConfirm}>
                <ConfirmBox
                    title={'Delete branch'}
                    body={
                        <>
                            <p className="body2 inline">Are you sure you want to delete the</p>{' '}
                            <p className="body1 inline">{branchDetail?.name}?</p>{' '}
                            <p className="body2 inline">All data in this branch will be vanish.</p>
                        </>
                    }
                    onClose={handleCloseDeleteConfirm}
                    handleAgreed={handleDeleteBranch}
                    handleCancel={handleCloseDeleteConfirm}
                    isLoading={isDeleting}
                    agreeLabel={'Delete'}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </SidebarRightLayout>
    );
}

export default React.memo(BranchView);
