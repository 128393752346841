import { useDependencyListWithoutFakeId } from './dependency';
import { useMetaData } from './metaData';
import * as columnTypes from 'const/columnTypes';
import { useViewColumnIdsWithReOrder } from './column';

export function useIsCanExportImportLocalization() {
    const dependencies = useDependencyListWithoutFakeId();
    const metaData = useMetaData();
    const viewColumnIds = useViewColumnIdsWithReOrder();
    const matchedDpDcs = dependencies?.filter(
        dpDc => viewColumnIds?.includes(dpDc?.parent) && viewColumnIds?.includes(dpDc?.child)
    );

    if (!matchedDpDcs?.length) return false;

    let isValid = false;
    for (let dpDc of matchedDpDcs) {
        const parent = dpDc?.parent;
        const child = dpDc?.child;

        const parentDetail = metaData?.[parent];
        const childDetail = metaData?.[child];

        const isParentIsTranslation = parentDetail?.type === columnTypes.TRANSLATION;

        const isChildLanguage = childDetail?.type === columnTypes.TRANSLATION;
        if (isParentIsTranslation && isChildLanguage) {
            isValid = true;
            break;
        }
    }

    return isValid;
}
