import React from 'react';

function FullAccessSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g id="Common/FullAccessIcon">
                <circle id="Ellipse 50" cx="8" cy="8" r="7" fill="#2DD2A3" fillOpacity="0.8" />
                <g id="Common Icons/CheckIcon">
                    <path
                        id="Path"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.0292 5.78372L6.99157 10.9347C6.9472 10.9801 6.87535 10.9801 6.83108 10.9347L3.9707 8.00987C3.92643 7.96472 3.92643 7.89125 3.9707 7.84588L4.66574 7.13521C4.71011 7.08994 4.78196 7.08994 4.82622 7.13521L6.91143 9.26723L11.1738 4.90895C11.2183 4.86368 11.2899 4.86368 11.3343 4.90895L12.0292 5.61962C12.0736 5.66489 12.0736 5.73824 12.0292 5.78372Z"
                        fill="white"
                    />
                </g>
            </g>
        </svg>
    );
}

export default React.memo(FullAccessSVG);
