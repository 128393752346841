import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import ShareLinkViewIlluSVG from 'assets/images/svg/ShareLinkViewIlluSVG';
import LinkSVG from 'assets/images/svg/LinkSVG';
import CodeSVG from 'assets/images/svg/CodeSVG';
import * as gridUIActions from 'gridUI/actions';
import { useDispatch } from 'react-redux';
import InputViewCopyOnly from 'components/inputs/InputViewCopyOnly';
import { useCurrentView } from 'hooks/gridUI';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import OpenNewPageSVG from 'assets/images/svg/OpenNewPageSVG';
import { sendManualTrack } from 'tracker';
import IOSSwitch from 'components/switches/IOS';
import ShareLinkOptionSVG from 'assets/images/svg/ShareLinkOptionSVG';
import * as roleConst from 'auth/roleConst';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: `calc(100vh - ${72 + 24 + 36 + 54}px)`,
        position: 'relative'
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    centerText: {
        width: '80%',
        textAlign: 'center'
    },
    detail: {
        padding: theme.spacing(4)
    },
    footer: {
        width: '100%',
        borderTop: `1px solid ${theme.colors.divider}`,
        height: 54,
        paddingLeft: theme.spacing(4)
    },
    expand: {
        flex: 1
    },
    dpFlex: {
        display: 'flex'
    },
    colorLightLavender: {
        color: theme.colors.lightLavender
    },
    disabledShareLinkButton: {
        width: 'fit-content'
    },
    shareIcon: {
        cursor: 'pointer',
        display: 'flex'
    }
}));

function ShareLinkView({ shareLinkDetail, compositeViewId }) {
    const classes = useStyles();
    const theme = useTheme();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const canAccessGridHistoryInShareView = shareLinkDetail?.privileges?.includes(
        roleConst.WORKSPACE_AUTHORITIES.ACCESS_GRID_HISTORY
    );
    const [isOpenGridHistory, setIsOpenGridHistory] = React.useState(canAccessGridHistoryInShareView);
    const dispatch = useDispatch();
    const currentView = useCurrentView();
    const { t } = useTranslation();

    const createShareLink = () => {
        setIsSubmitting(true);
        dispatch(
            gridUIActions.createShareLinkView({
                compositeViewId,
                body: {
                    role: 'VIEWER',
                    shareType: 'COMPANY'
                },
                successCallback: () => {
                    console.log('create share link success');
                    setIsSubmitting(false);
                },
                errorCallback: () => {
                    console.log('create share link failed');
                    setIsSubmitting(false);
                }
            })
        );
    };

    const handleUpdateShareLink = () => {
        setIsSubmitting(true);
        setIsOpenGridHistory(!isOpenGridHistory);
        dispatch(
            gridUIActions.createShareLinkView({
                compositeViewId,
                body: {
                    role: 'VIEWER',
                    shareType: 'COMPANY',
                    privileges: isOpenGridHistory ? [] : ['ACCESS_GRID_HISTORY']
                },
                successCallback: () => {
                    console.log('update share link success');
                    setIsSubmitting(false);
                },
                errorCallback: () => {
                    console.log('update share link failed');
                    setIsSubmitting(false);
                }
            })
        );
    };

    const shareLink = React.useMemo(() => {
        return `${window.location.origin}/shared/${shareLinkDetail?.uid}`;
    }, [shareLinkDetail]);

    const handleDisabledShareLink = React.useCallback(() => {
        dispatch(
            gridUIActions.deleteShareLink({
                compositeViewId,
                shareLinkDetail,
                successCallback: () => {
                    console.log('delete share link success');
                    setIsSubmitting(false);
                },
                errorCallback: () => {
                    console.log('delete share link failed');
                    setIsSubmitting(false);
                }
            })
        );
    }, [dispatch, compositeViewId, shareLinkDetail]);

    const openShareLink = React.useCallback(() => {
        window.open(shareLink, '_blank');
        sendManualTrack({ type: 'Open Share Link' });
    }, [shareLink]);

    if (!shareLinkDetail)
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={3}
                className={classes.root}
            >
                <Grid item>
                    <ShareLinkViewIlluSVG />
                </Grid>
                <Grid item>
                    <h3 className="prose prose-2xl font-medium">Publish this view</h3>
                </Grid>
                <Grid item className={classes.centerText}>
                    <p className="body2">
                        Create a shareable link for this view to share it with anyone or embed it on a website
                    </p>
                </Grid>
                <Grid item className={classes.buttonWrapper}>
                    <ButtonBase onClick={createShareLink} disabled={isSubmitting} variant="contained" width={212}>
                        {t('create_shareable_link')}
                    </ButtonBase>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Grid>
            </Grid>
        );

    return (
        <Grid container className={`${classes.root}`} wrap="nowrap" direction="column">
            <Grid item className={`${classes.expand} ${classes.detail}`}>
                <Grid wrap="nowrap" container direction="column" spacing={3}>
                    <Grid item>
                        <p className="body2">
                            People with the sharable link can only see this view or embed it on a website.
                        </p>
                        <p className="body2 inline">The title of this page is the view name,</p>{' '}
                        <p className="body1 inline">"{currentView?.name}".</p>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={2}>
                                    <Grid item className={classes.dpFlex}>
                                        <LinkSVG />
                                    </Grid>
                                    <Grid item>
                                        <p className="body1">Shareable Link</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <InputViewCopyOnly
                                    action={
                                        <Grid container alignItems="center" direction="row">
                                            <Grid item className={classes.shareIcon}>
                                                <OpenNewPageSVG
                                                    color={theme.palette.primary.main}
                                                    onClick={openShareLink}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    value={shareLink}
                                    secure={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={2}>
                                    <Grid item className={classes.dpFlex}>
                                        <CodeSVG />
                                    </Grid>
                                    <Grid item>
                                        <p className="body1">Embed code</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <InputViewCopyOnly
                                    value={`<iframe class="gridly-embed" src="${shareLink}" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;" allow="clipboard-read; clipboard-write"></iframe>`}
                                    secure={false}
                                    multiline
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item className={classes.dpFlex}>
                                        <ShareLinkOptionSVG />
                                    </Grid>
                                    <Grid item>
                                        <p className="body1">Options</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container alignItems="center" spacing={2}>
                                <Grid item>
                                    <IOSSwitch checked={isOpenGridHistory} onChange={handleUpdateShareLink} />
                                </Grid>
                                <Grid item>
                                    <p className="body2">Include Grid history in this view</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.footer}>
                <Grid
                    container
                    className={classes.disabledShareLinkButton}
                    style={{ height: '100%', cursor: 'pointer' }}
                    alignItems="center"
                    spacing={2}
                    onClick={handleDisabledShareLink}
                >
                    <Grid item className={classes.dpFlex}>
                        <CloseIconSVG size={20} color={theme.colors.lightLavender} />
                    </Grid>
                    <Grid item>
                        <p className="body1 text-lavender-light">Disable Shareable Link</p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ShareLinkView);
