import { ENV } from 'config';
import { STAGING, PRODUCTION, DEVELOPMENT, INTEGRATION, LOCAL, EXTERNAL } from 'config/env';

import React from 'react';
import * as columnTypesConst from 'const/columnTypes';
import SvgIcon from 'components/svg-icon/Base';
import { COLUMN_GROUP_SECTIONS } from 'const';
import i18n from 'i18n';

export function getLabelColumnType(type) {
    switch (type) {
        case columnTypesConst.SINGLE_LINE:
            return i18n.t('column_single_line');
        case columnTypesConst.MULTIPLE_LINES:
            return i18n.t('column_multi_line');
        case columnTypesConst.NUMBER:
            return i18n.t('global_number');
        case columnTypesConst.DATETIME:
            return i18n.t('date_time');
        case columnTypesConst.GROUP_TAGS:
            return i18n.t('column_group_tags');
        case columnTypesConst.SINGLE_SELECTION:
            return i18n.t('column_single_selection');
        case columnTypesConst.MULTIPLE_SELECTIONS:
            return i18n.t('column_multiple_selection');
        case columnTypesConst.FILES:
            return i18n.t('column_files');
        case columnTypesConst.BOOLEAN:
            return i18n.t('column_boolean');
        case columnTypesConst.REFERENCE:
            return i18n.t('column_reference');
        case columnTypesConst.TRANSLATION:
            return i18n.t('column_localization');
        case columnTypesConst.LOCALIZATION:
            return i18n.t('column_localization');
        case columnTypesConst.FORMULA:
            return i18n.t('column_formula');
        case columnTypesConst.RICH_TEXT:
            return i18n.t('column_rich_text');
        case columnTypesConst.MARKDOWN:
            return i18n.t('column_markdown');
        case columnTypesConst.PATH_TAG:
            return i18n.t('column_path_tag');
        case columnTypesConst.ALTERED_TIME:
            return i18n.t('column_altered_time');
        case columnTypesConst.ALTERED_BY:
            return i18n.t('column_altered_by');
        case columnTypesConst.CREATED_TIME:
            return 'Created time';
        case columnTypesConst.CREATED_BY:
            return 'Created by';
        case columnTypesConst.JSON_LD:
            return i18n.t('column_json_ld');
        case columnTypesConst.HTML:
            return i18n.t('column_html');
        case columnTypesConst.YAML:
            return i18n.t('column_yaml');
        case columnTypesConst.RECORD_ID:
            return i18n.t('column_record_id');
        default:
            return null;
    }
}

export const TRANSLATION_TYPES = {
    SOURCE_LANG: 'sourceLanguage',
    ORIGINAL_LANG: 'originLanguage',
    TARGET_LANG: 'targetLanguage',
    RECORD_ID: '_publicRecordId',
    PATH_TAG: '_pathTag',
    STRING_DESCRIPTON: 'stringDescription',
    MAX_CHARACTERS: 'maxCharacters',
    ADDITIONAL_INFO: 'additionalInfo'
};

export const TRANSLATION_TYPES_LABEL = {
    sourceLanguage: `Source language`,
    originLanguage: `Original language`,
    targetLanguage: `Target language`,
    _publicRecordId: `Record ID`,
    _pathTag: `Path`,
    maxCharacters: `Character limit`,
    additionalInfo: `Additional information`
};

export const TRANSLATION_TYPE_OPTIONS = [
    {
        value: TRANSLATION_TYPES.SOURCE_LANG,
        label: getLocalizationColumnTypeLabel({ value: TRANSLATION_TYPES.SOURCE_LANG })
    },
    {
        value: TRANSLATION_TYPES.TARGET_LANG,
        label: getLocalizationColumnTypeLabel({ value: TRANSLATION_TYPES.TARGET_LANG })
    }
    // {
    //     value: TRANSLATION_TYPES.RECORD_ID,
    //     label: getLocalizationColumnTypeLabel({ value: TRANSLATION_TYPES.RECORD_ID })
    // },
    // {
    //     value: TRANSLATION_TYPES.ADDITIONAL_INFO,
    //     label: getLocalizationColumnTypeLabel({ value: TRANSLATION_TYPES.ADDITIONAL_INFO })
    // },
    // {
    //     value: TRANSLATION_TYPES.MAX_CHARACTERS,
    //     label: getLocalizationColumnTypeLabel({ value: TRANSLATION_TYPES.MAX_CHARACTERS })
    // }
];

export function getLocalizationColumnTypeLabel({ value }) {
    return TRANSLATION_TYPES_LABEL?.[value] || 'no matching';
}

// const addonOptions = [
//     { value: LOCALIZATION, label: getLabelColumnType(LOCALIZATION), icon: () => <SvgIcon type={LOCALIZATION} /> },
//     { value: FORMULA, label: getLabelColumnType(FORMULA), icon: () => <SvgIcon type={FORMULA} /> }
// ];

export const COLUMN_TYPES = {
    [LOCAL]: [
        {
            label: COLUMN_GROUP_SECTIONS.SPECIAL,
            options: [
                {
                    value: columnTypesConst.REFERENCE,
                    label: getLabelColumnType(columnTypesConst.REFERENCE),
                    icon: () => <SvgIcon type={columnTypesConst.REFERENCE} />
                },
                {
                    value: columnTypesConst.TRANSLATION,
                    label: getLabelColumnType(columnTypesConst.TRANSLATION),
                    icon: () => <SvgIcon type={columnTypesConst.TRANSLATION} />
                },
                {
                    value: columnTypesConst.FORMULA,
                    label: getLabelColumnType(columnTypesConst.FORMULA),
                    icon: () => <SvgIcon type={columnTypesConst.FORMULA} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.BASIC,
            options: [
                {
                    value: columnTypesConst.SINGLE_LINE,
                    label: getLabelColumnType(columnTypesConst.SINGLE_LINE),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_LINE} />
                },
                {
                    value: columnTypesConst.MULTIPLE_LINES,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_LINES),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_LINES} />
                },
                {
                    value: columnTypesConst.MARKDOWN,
                    label: getLabelColumnType(columnTypesConst.MARKDOWN),
                    icon: () => <SvgIcon type={columnTypesConst.MARKDOWN} />
                },
                {
                    value: columnTypesConst.RICH_TEXT,
                    label: getLabelColumnType(columnTypesConst.RICH_TEXT),
                    icon: () => <SvgIcon type={columnTypesConst.RICH_TEXT} />
                },
                {
                    value: columnTypesConst.NUMBER,
                    label: getLabelColumnType(columnTypesConst.NUMBER),
                    icon: () => <SvgIcon type={columnTypesConst.NUMBER} />
                },
                // { value: GROUP_TAGS, label: getLabelColumnType(GROUP_TAGS), icon: () => <SvgIcon type={GROUP_TAGS} /> },
                {
                    value: columnTypesConst.SINGLE_SELECTION,
                    label: getLabelColumnType(columnTypesConst.SINGLE_SELECTION),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_SELECTION} />
                },
                {
                    value: columnTypesConst.MULTIPLE_SELECTIONS,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_SELECTIONS),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_SELECTIONS} />
                },
                {
                    value: columnTypesConst.DATETIME,
                    label: getLabelColumnType(columnTypesConst.DATETIME),
                    icon: () => <SvgIcon type={columnTypesConst.DATETIME} />
                },
                {
                    value: columnTypesConst.FILES,
                    label: getLabelColumnType(columnTypesConst.FILES),
                    icon: () => <SvgIcon type={columnTypesConst.FILES} />
                },
                {
                    value: columnTypesConst.BOOLEAN,
                    label: getLabelColumnType(columnTypesConst.BOOLEAN),
                    icon: () => <SvgIcon type={columnTypesConst.BOOLEAN} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.CODE_FORMATTING,
            options: [
                {
                    value: columnTypesConst.JSON_LD,
                    label: getLabelColumnType(columnTypesConst.JSON_LD),
                    icon: () => <SvgIcon type={columnTypesConst.JSON_LD} />
                },
                {
                    value: columnTypesConst.HTML,
                    label: getLabelColumnType(columnTypesConst.HTML),
                    icon: () => <SvgIcon type={columnTypesConst.HTML} />
                },
                {
                    value: columnTypesConst.YAML,
                    label: getLabelColumnType(columnTypesConst.YAML),
                    icon: () => <SvgIcon type={columnTypesConst.YAML} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.SYSTEM_COLUMNS,
            options: [
                {
                    value: columnTypesConst.RECORD_ID,
                    label: getLabelColumnType(columnTypesConst.RECORD_ID),
                    icon: () => <SvgIcon type={columnTypesConst.RECORD_ID} />
                },
                {
                    value: columnTypesConst.PATH_TAG,
                    label: getLabelColumnType(columnTypesConst.PATH_TAG),
                    icon: () => <SvgIcon type={columnTypesConst.PATH_TAG} />
                }
            ]
        }
        // {
        //     label: 'ADD-ONS',
        //     options: addonOptions
        // }
    ],
    [DEVELOPMENT]: [
        {
            label: COLUMN_GROUP_SECTIONS.SPECIAL,
            options: [
                {
                    value: columnTypesConst.REFERENCE,
                    label: getLabelColumnType(columnTypesConst.REFERENCE),
                    icon: () => <SvgIcon type={columnTypesConst.REFERENCE} />
                },
                {
                    value: columnTypesConst.TRANSLATION,
                    label: getLabelColumnType(columnTypesConst.TRANSLATION),
                    icon: () => <SvgIcon type={columnTypesConst.TRANSLATION} />
                },
                {
                    value: columnTypesConst.FORMULA,
                    label: getLabelColumnType(columnTypesConst.FORMULA),
                    icon: () => <SvgIcon type={columnTypesConst.FORMULA} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.BASIC,
            options: [
                {
                    value: columnTypesConst.SINGLE_LINE,
                    label: getLabelColumnType(columnTypesConst.SINGLE_LINE),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_LINE} />
                },
                {
                    value: columnTypesConst.MULTIPLE_LINES,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_LINES),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_LINES} />
                },
                {
                    value: columnTypesConst.MARKDOWN,
                    label: getLabelColumnType(columnTypesConst.MARKDOWN),
                    icon: () => <SvgIcon type={columnTypesConst.MARKDOWN} />
                },
                {
                    value: columnTypesConst.RICH_TEXT,
                    label: getLabelColumnType(columnTypesConst.RICH_TEXT),
                    icon: () => <SvgIcon type={columnTypesConst.RICH_TEXT} />
                },
                {
                    value: columnTypesConst.NUMBER,
                    label: getLabelColumnType(columnTypesConst.NUMBER),
                    icon: () => <SvgIcon type={columnTypesConst.NUMBER} />
                },
                // { value: GROUP_TAGS, label: getLabelColumnType(GROUP_TAGS), icon: () => <SvgIcon type={GROUP_TAGS} /> },
                {
                    value: columnTypesConst.SINGLE_SELECTION,
                    label: getLabelColumnType(columnTypesConst.SINGLE_SELECTION),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_SELECTION} />
                },
                {
                    value: columnTypesConst.MULTIPLE_SELECTIONS,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_SELECTIONS),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_SELECTIONS} />
                },
                {
                    value: columnTypesConst.DATETIME,
                    label: getLabelColumnType(columnTypesConst.DATETIME),
                    icon: () => <SvgIcon type={columnTypesConst.DATETIME} />
                },
                {
                    value: columnTypesConst.FILES,
                    label: getLabelColumnType(columnTypesConst.FILES),
                    icon: () => <SvgIcon type={columnTypesConst.FILES} />
                },
                {
                    value: columnTypesConst.BOOLEAN,
                    label: getLabelColumnType(columnTypesConst.BOOLEAN),
                    icon: () => <SvgIcon type={columnTypesConst.BOOLEAN} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.CODE_FORMATTING,
            options: [
                {
                    value: columnTypesConst.JSON_LD,
                    label: getLabelColumnType(columnTypesConst.JSON_LD),
                    icon: () => <SvgIcon type={columnTypesConst.JSON_LD} />
                },
                {
                    value: columnTypesConst.HTML,
                    label: getLabelColumnType(columnTypesConst.HTML),
                    icon: () => <SvgIcon type={columnTypesConst.HTML} />
                },
                {
                    value: columnTypesConst.YAML,
                    label: getLabelColumnType(columnTypesConst.YAML),
                    icon: () => <SvgIcon type={columnTypesConst.YAML} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.SYSTEM_COLUMNS,
            options: [
                {
                    value: columnTypesConst.RECORD_ID,
                    label: getLabelColumnType(columnTypesConst.RECORD_ID),
                    icon: () => <SvgIcon type={columnTypesConst.RECORD_ID} />
                },
                {
                    value: columnTypesConst.PATH_TAG,
                    label: getLabelColumnType(columnTypesConst.PATH_TAG),
                    icon: () => <SvgIcon type={columnTypesConst.PATH_TAG} />
                }
            ]
        }
        // {
        //     label: 'ADD-ONS',
        //     options: addonOptions
        // }
    ],
    [INTEGRATION]: [
        {
            label: COLUMN_GROUP_SECTIONS.SPECIAL,
            options: [
                {
                    value: columnTypesConst.REFERENCE,
                    label: getLabelColumnType(columnTypesConst.REFERENCE),
                    icon: () => <SvgIcon type={columnTypesConst.REFERENCE} />
                },
                {
                    value: columnTypesConst.TRANSLATION,
                    label: getLabelColumnType(columnTypesConst.TRANSLATION),
                    icon: () => <SvgIcon type={columnTypesConst.TRANSLATION} />
                },
                {
                    value: columnTypesConst.FORMULA,
                    label: getLabelColumnType(columnTypesConst.FORMULA),
                    icon: () => <SvgIcon type={columnTypesConst.FORMULA} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.BASIC,
            options: [
                {
                    value: columnTypesConst.SINGLE_LINE,
                    label: getLabelColumnType(columnTypesConst.SINGLE_LINE),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_LINE} />
                },
                {
                    value: columnTypesConst.MULTIPLE_LINES,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_LINES),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_LINES} />
                },
                {
                    value: columnTypesConst.MARKDOWN,
                    label: getLabelColumnType(columnTypesConst.MARKDOWN),
                    icon: () => <SvgIcon type={columnTypesConst.MARKDOWN} />
                },
                {
                    value: columnTypesConst.RICH_TEXT,
                    label: getLabelColumnType(columnTypesConst.RICH_TEXT),
                    icon: () => <SvgIcon type={columnTypesConst.RICH_TEXT} />
                },
                {
                    value: columnTypesConst.NUMBER,
                    label: getLabelColumnType(columnTypesConst.NUMBER),
                    icon: () => <SvgIcon type={columnTypesConst.NUMBER} />
                },
                // { value: GROUP_TAGS, label: getLabelColumnType(GROUP_TAGS), icon: () => <SvgIcon type={GROUP_TAGS} /> },
                {
                    value: columnTypesConst.SINGLE_SELECTION,
                    label: getLabelColumnType(columnTypesConst.SINGLE_SELECTION),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_SELECTION} />
                },
                {
                    value: columnTypesConst.MULTIPLE_SELECTIONS,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_SELECTIONS),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_SELECTIONS} />
                },
                {
                    value: columnTypesConst.DATETIME,
                    label: getLabelColumnType(columnTypesConst.DATETIME),
                    icon: () => <SvgIcon type={columnTypesConst.DATETIME} />
                },
                {
                    value: columnTypesConst.FILES,
                    label: getLabelColumnType(columnTypesConst.FILES),
                    icon: () => <SvgIcon type={columnTypesConst.FILES} />
                },
                {
                    value: columnTypesConst.BOOLEAN,
                    label: getLabelColumnType(columnTypesConst.BOOLEAN),
                    icon: () => <SvgIcon type={columnTypesConst.BOOLEAN} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.CODE_FORMATTING,
            options: [
                {
                    value: columnTypesConst.JSON_LD,
                    label: getLabelColumnType(columnTypesConst.JSON_LD),
                    icon: () => <SvgIcon type={columnTypesConst.JSON_LD} />
                },
                {
                    value: columnTypesConst.HTML,
                    label: getLabelColumnType(columnTypesConst.HTML),
                    icon: () => <SvgIcon type={columnTypesConst.HTML} />
                },
                {
                    value: columnTypesConst.YAML,
                    label: getLabelColumnType(columnTypesConst.YAML),
                    icon: () => <SvgIcon type={columnTypesConst.YAML} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.SYSTEM_COLUMNS,
            options: [
                {
                    value: columnTypesConst.RECORD_ID,
                    label: getLabelColumnType(columnTypesConst.RECORD_ID),
                    icon: () => <SvgIcon type={columnTypesConst.RECORD_ID} />
                },
                {
                    value: columnTypesConst.PATH_TAG,
                    label: getLabelColumnType(columnTypesConst.PATH_TAG),
                    icon: () => <SvgIcon type={columnTypesConst.PATH_TAG} />
                }
            ]
        }
        // {
        //     label: 'ADD-ONS',
        //     options: addonOptions
        // }
    ],
    [STAGING]: [
        {
            label: COLUMN_GROUP_SECTIONS.SPECIAL,
            options: [
                {
                    value: columnTypesConst.REFERENCE,
                    label: getLabelColumnType(columnTypesConst.REFERENCE),
                    icon: () => <SvgIcon type={columnTypesConst.REFERENCE} />
                },
                {
                    value: columnTypesConst.TRANSLATION,
                    label: getLabelColumnType(columnTypesConst.TRANSLATION),
                    icon: () => <SvgIcon type={columnTypesConst.TRANSLATION} />
                },
                {
                    value: columnTypesConst.FORMULA,
                    label: getLabelColumnType(columnTypesConst.FORMULA),
                    icon: () => <SvgIcon type={columnTypesConst.FORMULA} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.BASIC,
            options: [
                {
                    value: columnTypesConst.SINGLE_LINE,
                    label: getLabelColumnType(columnTypesConst.SINGLE_LINE),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_LINE} />
                },
                {
                    value: columnTypesConst.MULTIPLE_LINES,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_LINES),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_LINES} />
                },
                {
                    value: columnTypesConst.MARKDOWN,
                    label: getLabelColumnType(columnTypesConst.MARKDOWN),
                    icon: () => <SvgIcon type={columnTypesConst.MARKDOWN} />
                },
                {
                    value: columnTypesConst.RICH_TEXT,
                    label: getLabelColumnType(columnTypesConst.RICH_TEXT),
                    icon: () => <SvgIcon type={columnTypesConst.RICH_TEXT} />
                },
                {
                    value: columnTypesConst.NUMBER,
                    label: getLabelColumnType(columnTypesConst.NUMBER),
                    icon: () => <SvgIcon type={columnTypesConst.NUMBER} />
                },
                {
                    value: columnTypesConst.SINGLE_SELECTION,
                    label: getLabelColumnType(columnTypesConst.SINGLE_SELECTION),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_SELECTION} />
                },
                {
                    value: columnTypesConst.MULTIPLE_SELECTIONS,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_SELECTIONS),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_SELECTIONS} />
                },
                {
                    value: columnTypesConst.DATETIME,
                    label: getLabelColumnType(columnTypesConst.DATETIME),
                    icon: () => <SvgIcon type={columnTypesConst.DATETIME} />
                },
                {
                    value: columnTypesConst.FILES,
                    label: getLabelColumnType(columnTypesConst.FILES),
                    icon: () => <SvgIcon type={columnTypesConst.FILES} />
                },
                {
                    value: columnTypesConst.BOOLEAN,
                    label: getLabelColumnType(columnTypesConst.BOOLEAN),
                    icon: () => <SvgIcon type={columnTypesConst.BOOLEAN} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.CODE_FORMATTING,
            options: [
                {
                    value: columnTypesConst.JSON_LD,
                    label: getLabelColumnType(columnTypesConst.JSON_LD),
                    icon: () => <SvgIcon type={columnTypesConst.JSON_LD} />
                },
                {
                    value: columnTypesConst.HTML,
                    label: getLabelColumnType(columnTypesConst.HTML),
                    icon: () => <SvgIcon type={columnTypesConst.HTML} />
                },
                {
                    value: columnTypesConst.YAML,
                    label: getLabelColumnType(columnTypesConst.YAML),
                    icon: () => <SvgIcon type={columnTypesConst.YAML} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.SYSTEM_COLUMNS,
            options: [
                {
                    value: columnTypesConst.RECORD_ID,
                    label: getLabelColumnType(columnTypesConst.RECORD_ID),
                    icon: () => <SvgIcon type={columnTypesConst.RECORD_ID} />
                },
                {
                    value: columnTypesConst.PATH_TAG,
                    label: getLabelColumnType(columnTypesConst.PATH_TAG),
                    icon: () => <SvgIcon type={columnTypesConst.PATH_TAG} />
                }
            ]
        }
        // {
        //     label: 'ADD-ONS',
        //     options: addonOptions
        // }
    ],
    [PRODUCTION]: [
        {
            label: COLUMN_GROUP_SECTIONS.SPECIAL,
            options: [
                {
                    value: columnTypesConst.REFERENCE,
                    label: getLabelColumnType(columnTypesConst.REFERENCE),
                    icon: () => <SvgIcon type={columnTypesConst.REFERENCE} />
                },
                {
                    value: columnTypesConst.TRANSLATION,
                    label: getLabelColumnType(columnTypesConst.TRANSLATION),
                    icon: () => <SvgIcon type={columnTypesConst.TRANSLATION} />
                },
                {
                    value: columnTypesConst.FORMULA,
                    label: getLabelColumnType(columnTypesConst.FORMULA),
                    icon: () => <SvgIcon type={columnTypesConst.FORMULA} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.BASIC,
            options: [
                {
                    value: columnTypesConst.SINGLE_LINE,
                    label: getLabelColumnType(columnTypesConst.SINGLE_LINE),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_LINE} />
                },
                {
                    value: columnTypesConst.MULTIPLE_LINES,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_LINES),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_LINES} />
                },
                {
                    value: columnTypesConst.MARKDOWN,
                    label: getLabelColumnType(columnTypesConst.MARKDOWN),
                    icon: () => <SvgIcon type={columnTypesConst.MARKDOWN} />
                },
                {
                    value: columnTypesConst.RICH_TEXT,
                    label: getLabelColumnType(columnTypesConst.RICH_TEXT),
                    icon: () => <SvgIcon type={columnTypesConst.RICH_TEXT} />
                },
                {
                    value: columnTypesConst.NUMBER,
                    label: getLabelColumnType(columnTypesConst.NUMBER),
                    icon: () => <SvgIcon type={columnTypesConst.NUMBER} />
                },
                {
                    value: columnTypesConst.SINGLE_SELECTION,
                    label: getLabelColumnType(columnTypesConst.SINGLE_SELECTION),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_SELECTION} />
                },
                {
                    value: columnTypesConst.MULTIPLE_SELECTIONS,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_SELECTIONS),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_SELECTIONS} />
                },
                {
                    value: columnTypesConst.DATETIME,
                    label: getLabelColumnType(columnTypesConst.DATETIME),
                    icon: () => <SvgIcon type={columnTypesConst.DATETIME} />
                },
                {
                    value: columnTypesConst.FILES,
                    label: getLabelColumnType(columnTypesConst.FILES),
                    icon: () => <SvgIcon type={columnTypesConst.FILES} />
                },
                {
                    value: columnTypesConst.BOOLEAN,
                    label: getLabelColumnType(columnTypesConst.BOOLEAN),
                    icon: () => <SvgIcon type={columnTypesConst.BOOLEAN} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.CODE_FORMATTING,
            options: [
                {
                    value: columnTypesConst.JSON_LD,
                    label: getLabelColumnType(columnTypesConst.JSON_LD),
                    icon: () => <SvgIcon type={columnTypesConst.JSON_LD} />
                },
                {
                    value: columnTypesConst.HTML,
                    label: getLabelColumnType(columnTypesConst.HTML),
                    icon: () => <SvgIcon type={columnTypesConst.HTML} />
                },
                {
                    value: columnTypesConst.YAML,
                    label: getLabelColumnType(columnTypesConst.YAML),
                    icon: () => <SvgIcon type={columnTypesConst.YAML} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.SYSTEM_COLUMNS,
            options: [
                {
                    value: columnTypesConst.RECORD_ID,
                    label: getLabelColumnType(columnTypesConst.RECORD_ID),
                    icon: () => <SvgIcon type={columnTypesConst.RECORD_ID} />
                },
                {
                    value: columnTypesConst.PATH_TAG,
                    label: getLabelColumnType(columnTypesConst.PATH_TAG),
                    icon: () => <SvgIcon type={columnTypesConst.PATH_TAG} />
                }
            ]
        }
    ],
    [EXTERNAL]: [
        {
            label: COLUMN_GROUP_SECTIONS.SPECIAL,
            options: [
                {
                    value: columnTypesConst.REFERENCE,
                    label: getLabelColumnType(columnTypesConst.REFERENCE),
                    icon: () => <SvgIcon type={columnTypesConst.REFERENCE} />
                },
                {
                    value: columnTypesConst.TRANSLATION,
                    label: getLabelColumnType(columnTypesConst.TRANSLATION),
                    icon: () => <SvgIcon type={columnTypesConst.TRANSLATION} />
                },
                {
                    value: columnTypesConst.FORMULA,
                    label: getLabelColumnType(columnTypesConst.FORMULA),
                    icon: () => <SvgIcon type={columnTypesConst.FORMULA} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.BASIC,
            options: [
                {
                    value: columnTypesConst.SINGLE_LINE,
                    label: getLabelColumnType(columnTypesConst.SINGLE_LINE),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_LINE} />
                },
                {
                    value: columnTypesConst.MULTIPLE_LINES,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_LINES),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_LINES} />
                },
                {
                    value: columnTypesConst.MARKDOWN,
                    label: getLabelColumnType(columnTypesConst.MARKDOWN),
                    icon: () => <SvgIcon type={columnTypesConst.MARKDOWN} />
                },
                {
                    value: columnTypesConst.RICH_TEXT,
                    label: getLabelColumnType(columnTypesConst.RICH_TEXT),
                    icon: () => <SvgIcon type={columnTypesConst.RICH_TEXT} />
                },
                {
                    value: columnTypesConst.NUMBER,
                    label: getLabelColumnType(columnTypesConst.NUMBER),
                    icon: () => <SvgIcon type={columnTypesConst.NUMBER} />
                },
                {
                    value: columnTypesConst.SINGLE_SELECTION,
                    label: getLabelColumnType(columnTypesConst.SINGLE_SELECTION),
                    icon: () => <SvgIcon type={columnTypesConst.SINGLE_SELECTION} />
                },
                {
                    value: columnTypesConst.MULTIPLE_SELECTIONS,
                    label: getLabelColumnType(columnTypesConst.MULTIPLE_SELECTIONS),
                    icon: () => <SvgIcon type={columnTypesConst.MULTIPLE_SELECTIONS} />
                },
                {
                    value: columnTypesConst.DATETIME,
                    label: getLabelColumnType(columnTypesConst.DATETIME),
                    icon: () => <SvgIcon type={columnTypesConst.DATETIME} />
                },
                {
                    value: columnTypesConst.FILES,
                    label: getLabelColumnType(columnTypesConst.FILES),
                    icon: () => <SvgIcon type={columnTypesConst.FILES} />
                },
                {
                    value: columnTypesConst.BOOLEAN,
                    label: getLabelColumnType(columnTypesConst.BOOLEAN),
                    icon: () => <SvgIcon type={columnTypesConst.BOOLEAN} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.CODE_FORMATTING,
            options: [
                {
                    value: columnTypesConst.JSON_LD,
                    label: getLabelColumnType(columnTypesConst.JSON_LD),
                    icon: () => <SvgIcon type={columnTypesConst.JSON_LD} />
                },
                {
                    value: columnTypesConst.HTML,
                    label: getLabelColumnType(columnTypesConst.HTML),
                    icon: () => <SvgIcon type={columnTypesConst.HTML} />
                },
                {
                    value: columnTypesConst.YAML,
                    label: getLabelColumnType(columnTypesConst.YAML),
                    icon: () => <SvgIcon type={columnTypesConst.YAML} />
                }
            ]
        },
        {
            label: COLUMN_GROUP_SECTIONS.SYSTEM_COLUMNS,
            options: [
                {
                    value: columnTypesConst.RECORD_ID,
                    label: getLabelColumnType(columnTypesConst.RECORD_ID),
                    icon: () => <SvgIcon type={columnTypesConst.RECORD_ID} />
                },
                {
                    value: columnTypesConst.PATH_TAG,
                    label: getLabelColumnType(columnTypesConst.PATH_TAG),
                    icon: () => <SvgIcon type={columnTypesConst.PATH_TAG} />
                }
            ]
        }
    ]
};

export const columnTypes = COLUMN_TYPES[ENV];

export const getBasicColumnTypes = () => {
    const result = columnTypes.find(group => group.label === COLUMN_GROUP_SECTIONS.BASIC);

    return result ? result.options : [];
};
