import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as columnTypes from 'const/columnTypes';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import InputQuickFilter from './components/InputFilter';
import CheckBoxFilter from './components/CheckboxFilter';
import DatetimeFilter from './components/DatetimeFilter';
import PathTagQuickFilter from './components/PathTagQuickFilter';
import SingleSelectionFilter from './components/SingleSelectionFilter';
import MultipleSelectionFilter from './components/MultipleSelectionFilter';
import ReferenceFilter from './components/ReferenceFilter';
import GroupTagsFilter from './components/GroupTagsFilter';
// import SortDescendingSVG from 'assets/images/svg/SortDescendingSVG';
// import SortAscendingSVG from 'assets/images/svg/SortAscendingSVG';
// import RegexSVG from 'assets/images/svg/RegexSVG';
import NormalSortSVG from 'assets/images/svg/NormalSortSVG';
import QuickFilterSVG from 'assets/images/svg/QuickFilterSVG';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import {
    INPUT_PADDING_LEFT,
    SPACING_LIST_NAME_WITH_ICON,
    DISABLED_OPACITY,
    SPACING_LIST_NAME_WITH_DOTS_ICON
} from 'const/style';
// import { sendManualTrack } from 'tracker';
import PopperMenu from 'components/menus/Popper';
import ExtraFilterConflictOptions from './components/ExtraFilterConflictOptions';
import { EXTRA_PREVIEW_STATUS, EXTRA_QUICK_FILTER } from 'const/gridUI';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_COLUMN_IDS } from 'const';
import isEqual from 'lodash/isEqual';
import { isEmpty } from 'lodash';
// import Tooltip from 'components/tooltip/Base';
import { useWindowColumnStopIndex, useWindowColumnStartIndex, usePathTagRef } from 'hooks/gridUI';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import ListItem from 'components/list/Item';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import { checkContainId } from 'utils/clickAway';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `0 ${INPUT_PADDING_LEFT}px`,
        height: '100%',
        display: 'flex',
        position: 'relative'
    },
    showDots: {
        paddingRight: 6
    },
    sortIcon: {},
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    boolean: {},
    booleanSortWrapper: {
        position: 'absolute',
        right: 0,
        marginRight: theme.spacing(3)
    },
    flex: {
        display: 'flex'
    },
    dependencyBoolean: {
        display: 'flex',
        position: 'absolute',
        left: INPUT_PADDING_LEFT,
        top: 0,
        bottom: 0,
        margin: 'auto',
        height: '100%',
        alignItems: 'center'
    },
    quickIconRight: {
        marginLeft: 4
    },
    quickFilterIcon: {
        cursor: 'pointer',
        position: 'relative',
        borderRadius: 3,
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    highlight: {
        background: `${hexToRgba(theme.colors.fuchsiaBlue, 0.3)} !important`
    },
    highlightIconMore: {
        background: '#EBEBF6'
    },
    booleanRelative: {
        position: 'relative',
        left: 14
    },
    selected: {
        background: theme.colors.lightGrey
    },
    moreWrapper: {
        display: 'flex',
        cursor: 'pointer',
        position: 'relative',
        padding: '5px 4px'
    },
    popperMenu: {
        maxWidth: 360,
        minWidth: 200
    },
    popperSortMenu: {
        maxWidth: 360,
        minWidth: 156
    }
}));

function checkTypeAndRender({ type, isShowDots, ...rest }) {
    switch (type) {
        case columnTypes.FILES:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.SINGLE_LINE:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.MULTIPLE_LINES:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.NUMBER:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.BOOLEAN:
            return <CheckBoxFilter checkboxStyle={isShowDots ? { left: -4 } : {}} {...rest} />;
        case columnTypes.DATETIME:
            return <DatetimeFilter {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultipleSelectionFilter {...rest} />;
        case columnTypes.SINGLE_SELECTION:
            return <SingleSelectionFilter {...rest} />;
        case columnTypes.GROUP_TAGS:
            return <GroupTagsFilter {...rest} />;
        case columnTypes.REFERENCE:
            return <ReferenceFilter {...rest} />;
        case columnTypes.LOCALIZATION:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.FORMULA:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.PATH_TAG:
            return <PathTagQuickFilter type={type} {...rest} />;
        case columnTypes.ALTERED_TIME:
        case columnTypes.CREATED_TIME:
            return <DatetimeFilter {...rest} />;
        case columnTypes.ALTERED_BY:
        case columnTypes.CREATED_BY:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.MARKDOWN:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.RICH_TEXT:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.JSON_LD:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.HTML:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.YAML:
            return <InputQuickFilter type={type} {...rest} />;
        default:
            return <InputQuickFilter type={type} {...rest} />;
    }
}

// const SUPPORTED_REGEX = [
//     columnTypes.ALTERED_BY,
//     columnTypes.SINGLE_LINE,
//     columnTypes.MULTIPLE_LINES,
//     columnTypes.RECORD_ID,
//     columnTypes.RICH_TEXT,
//     columnTypes.JSON_LD,
//     columnTypes.HTML,
//     columnTypes.TRANSLATION,
//     columnTypes.MARKDOWN,
//     columnTypes.YAML
// ];

function HeaderFilter({
    id,
    column,
    quickSorts = {},
    isSelectionActive,
    quickFilters = {},
    isImporting,
    workspaceId,
    gridId,
    viewId,
    dbId,
    branchId,
    // dependencies,
    columnIndex,
    columnWidth,
    onRefreshGrid,
    onExtraFilterChange,
    onQuickFilterChange,
    tree,
    conflictType
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const columnId = useMemo(() => column.id, [column.id]);
    const isDown = useMemo(() => quickSorts[columnId], [columnId, quickSorts]);
    const columnType = getCorrectColumnType(column);
    const pathRef = usePathTagRef();

    const columnStartIndex = useWindowColumnStartIndex();
    const columnStopIndex = useWindowColumnStopIndex();

    const isHighlightHeaderFilter = React.useMemo(() => {
        return columnIndex >= columnStartIndex && columnIndex <= columnStopIndex;
    }, [columnStartIndex, columnStopIndex, columnIndex]);

    const quickFilter = useMemo(() => quickFilters?.[columnId], [columnId, quickFilters]);
    const extraFilter = useMemo(() => quickFilter?.extraFilter, [quickFilter]);
    const isRegex = useMemo(() => quickFilter?.isRegex, [quickFilter]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorMoreEl, setAnchorMoreEl] = React.useState(null);
    const [anchorSortEl, setAnchorSortEl] = React.useState(null);

    // const sortHandler = useCallback(() => {
    //     const sortValue = !isDown ? 'asc' : isDown === 'asc' ? 'desc' : '';

    //     sendManualTrack({
    //         type: `Click Quick Sort Column`,
    //         customData: {
    //             columnId,
    //             sortValue
    //         }
    //     });
    //     dispatch(
    //         gridActions.QuickSortChange({
    //             columnId,
    //             value: sortValue
    //         })
    //     );
    //     dispatch(
    //         gridActions.fetchRecordsWithFiltersAndSorts({
    //             successCallback: () => {
    //                 console.log('sort successfully');
    //             },
    //             errorCallback: () => {
    //                 console.log('failed to sort');
    //             }
    //         })
    //     );
    // }, [dispatch, columnId, isDown]);

    // const handleSort = useCallback(
    //     sortValue => {
    //         sendManualTrack({
    //             type: `Click Quick Sort Column`,
    //             customData: {
    //                 columnId,
    //                 sortValue
    //             }
    //         });
    //         if (isDown === sortValue) return;
    //         dispatch(
    //             gridActions.QuickSortChange({
    //                 columnId,
    //                 value: sortValue
    //             })
    //         );
    //         dispatch(
    //             gridActions.fetchRecordsWithFiltersAndSorts({
    //                 successCallback: () => {
    //                     console.log('sort successfully');
    //                 },
    //                 errorCallback: () => {
    //                     console.log('failed to sort');
    //                 }
    //             })
    //         );
    //     },
    //     [dispatch, columnId, isDown]
    // );

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleClickMore = event => {
        setAnchorMoreEl(anchorMoreEl ? null : event.currentTarget);
    };

    const handleClickSort = event => {
        setAnchorSortEl(anchorSortEl ? null : event.currentTarget);
    };

    const handleMoreClickAway = e => {
        if (checkContainId(e, 'extraFilter') || checkContainId(e, 'sortExtra')) return;
        setAnchorMoreEl(null);
    };

    // const handleSortClickAway = e => {
    //     setAnchorSortEl(null);
    // };

    const handleExtraFilterChange = changedFilter => {
        const newExtraFilter = { ...extraFilter, ...changedFilter };

        onExtraFilterChange({
            columnId,
            extraFilter: newExtraFilter
        });

        if (!isEqual(extraFilter, newExtraFilter)) {
            onRefreshGrid && onRefreshGrid();
        }
    };

    const isHavingExtraFilter = React.useMemo(() => {
        return extraFilter && extraFilter?._previewStatus !== EXTRA_PREVIEW_STATUS.ALL;
    }, [extraFilter]);

    const handleResetExtraFilter = React.useCallback(() => {
        dispatch(
            gridActions.quickFilterExtraChange({
                columnId,
                extraFilter: null
            })
        );

        if (!isEmpty(extraFilter)) {
            dispatch(
                gridActions.fetchRecordsWithFiltersAndSorts({
                    successCallback: () => {
                        console.log('sort successfully');
                    },
                    errorCallback: () => {
                        console.log('failed to sort');
                    }
                })
            );
        }

        handleClickAway();
    }, [columnId, dispatch, extraFilter]);

    // const handleToggleRegex = React.useCallback(() => {
    //     const value = quickFilters?.[columnId]?.value;

    //     dispatch(
    //         gridActions.quickFilterRegexChange({
    //             columnId,
    //             isRegex: !isRegex
    //         })
    //     );

    //     if (value) {
    //         dispatch(
    //             gridActions.fetchRecordsWithFiltersAndSorts({
    //                 successCallback: () => {
    //                     console.log('sort successfully');
    //                 },
    //                 errorCallback: () => {
    //                     console.log('failed to sort');
    //                 }
    //             })
    //         );
    //     }
    // }, [columnId, dispatch, isRegex, quickFilters]);

    const isShowExtraFilter = React.useMemo(() => {
        return !SYSTEM_COLUMN_IDS?.filter(id => ![columnTypes.PATH_TAG].includes(id)).includes(columnId);
    }, [columnId]);

    const isShowDots = React.useMemo(() => {
        return columnWidth <= 160;
    }, [columnWidth]);

    return (
        <Grid
            id={id}
            container
            className={`${classes.root} ${isHighlightHeaderFilter ? classes.selected : ``} ${
                isImporting ? classes.disabled : ''
            } ${isShowDots ? classes.showDots : ''}`}
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
        >
            <Grid
                item
                style={{
                    flex: 1,
                    overflow: 'hidden'
                }}
                className={columnType === columnTypes.BOOLEAN ? classes.booleanRelative : ``}
            >
                {checkTypeAndRender({
                    type: columnType,
                    t,
                    columnId: column.id,
                    isSelectionActive,
                    quickFilters,
                    column,
                    pathRef,
                    isDisabled: extraFilter === EXTRA_QUICK_FILTER.IS_EMPTY,
                    workspaceId,
                    gridId,
                    viewId,
                    dbId,
                    branchId,
                    isRegex,
                    isShowDots,
                    onRefreshGrid,
                    onQuickFilterChange,
                    onExtraFilterChange,
                    tree
                })}
            </Grid>

            <Grid
                item
                style={{ marginLeft: isShowDots ? SPACING_LIST_NAME_WITH_DOTS_ICON : SPACING_LIST_NAME_WITH_ICON }}
            >
                {isShowDots ? (
                    <>
                        <Grid
                            item
                            className={`${classes.moreWrapper} ${classes.quickFilterIcon} ${
                                isRegex || isHavingExtraFilter || isDown || anchorMoreEl
                                    ? classes.highlightIconMore
                                    : ``
                            }`}
                            onClick={handleClickMore}
                        >
                            <IconMoreActionsSVG
                                color={
                                    isRegex || isHavingExtraFilter || isDown || anchorMoreEl
                                        ? theme.palette.primary.main
                                        : '#ACADB9'
                                }
                            />
                            {(isRegex || isHavingExtraFilter || isDown) && (
                                <div
                                    style={{
                                        width: 6,
                                        height: 6,
                                        borderRadius: '50%',
                                        background: theme.colors.indicator,
                                        border: `1px solid ${theme.colors.solitude}`,
                                        position: 'absolute',
                                        right: 2,
                                        top: 2
                                    }}
                                />
                            )}
                        </Grid>
                        <PopperMenu
                            anchorEl={anchorMoreEl}
                            handleClickAway={handleMoreClickAway}
                            placement={'bottom-end'}
                            className={classes.popperMenu}
                        >
                            <Grid container direction="column">
                                {/* {SUPPORTED_REGEX?.includes(columnType) && (
                                    <Grid item onClick={handleToggleRegex}>
                                        <ListItem
                                            isSelected={isRegex}
                                            icon={<RegexSVG color={isRegex ? theme.palette.primary.main : undefined} />}
                                            name={t('filter_using_regex')}
                                        />
                                    </Grid>
                                )} */}
                                {isShowExtraFilter && (
                                    <Grid item onClick={handleClick}>
                                        <ListItem
                                            selectedIcon={<></>}
                                            isSelected={isHavingExtraFilter}
                                            icon={
                                                <QuickFilterSVG
                                                    color={isHavingExtraFilter ? theme.palette.primary.main : undefined}
                                                />
                                            }
                                            name={t('column_filter')}
                                            moreIcon={
                                                <ArrowRightSVG
                                                    color={isHavingExtraFilter ? theme.palette.primary.main : undefined}
                                                />
                                            }
                                        />
                                    </Grid>
                                )}
                                <Grid item onClick={handleClickSort}>
                                    <ListItem
                                        selectedIcon={<></>}
                                        isSelected={isDown}
                                        icon={
                                            <NormalSortSVG
                                                color={isDown ? theme.palette.primary.main : theme.colors.lightGreyBlue}
                                            />
                                        }
                                        name={t('toolbar_sort_tooltip')}
                                        moreIcon={
                                            <ArrowRightSVG color={isDown ? theme.palette.primary.main : undefined} />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </PopperMenu>
                        {/* <PopperMenu
                            anchorEl={anchorSortEl}
                            handleClickAway={handleSortClickAway}
                            placement={'right-start'}
                            className={classes.popperSortMenu}
                            id="sortExtra"
                        >
                            <Grid container direction="column">
                                <Grid item onClick={() => handleSort('asc')}>
                                    <ListItem
                                        isSelected={isDown === 'asc'}
                                        icon={
                                            <SortAscendingSVG
                                                color={isDown === 'asc' ? theme.palette.primary.main : undefined}
                                            />
                                        }
                                        name="Sort A-Z"
                                    />
                                </Grid>
                                <Grid item onClick={() => handleSort('desc')}>
                                    <ListItem
                                        isSelected={isDown === 'desc'}
                                        icon={
                                            <SortDescendingSVG
                                                color={isDown === 'desc' ? theme.palette.primary.main : undefined}
                                            />
                                        }
                                        name="Sort Z-A"
                                    />
                                </Grid>
                                <Grid item onClick={() => handleSort('')}>
                                    <ListItem
                                        icon={<NormalSortSVG color={theme.colors.lightGreyBlue} />}
                                        name="Back to default"
                                    />
                                </Grid>
                            </Grid>
                        </PopperMenu> */}
                    </>
                ) : (
                    <Grid container direction="row" spacing={2} alignItems="center" justify="flex-end">
                        {/* {SUPPORTED_REGEX?.includes(columnType) && (
                            <Tooltip title={t('filter_using_regex')}>
                                <Grid
                                    item
                                    className={`${classes.flex}  ${classes.quickFilterIcon} ${
                                        isRegex ? classes.highlight : ``
                                    }`}
                                    onClick={handleToggleRegex}
                                    style={{ marginRight: isShowExtraFilter ? 4 : 0 }}
                                >
                                    <RegexSVG color={isRegex ? theme.palette.primary.main : undefined} />
                                </Grid>
                            </Tooltip>
                        )} */}

                        {isShowExtraFilter && (
                            <Grid
                                item
                                className={`${classes.flex}  ${classes.quickFilterIcon} ${
                                    isHavingExtraFilter ? classes.highlight : ``
                                }`}
                                onClick={handleClick}
                            >
                                <QuickFilterSVG color={isHavingExtraFilter ? theme.palette.primary.main : undefined} />
                                {isHavingExtraFilter && (
                                    <div
                                        style={{
                                            width: 6,
                                            height: 6,
                                            borderRadius: '50%',
                                            background: theme.colors.indicator,
                                            border: `1px solid ${theme.colors.solitude}`,
                                            position: 'absolute',
                                            right: 3,
                                            top: 3
                                        }}
                                    ></div>
                                )}
                            </Grid>
                        )}

                        {/* {![columnTypes.PATH_TAG]?.includes(columnType) && (
                            <Grid
                                item
                                className={`${classes.flex} ${classes.quickIconRight} ${classes.quickFilterIcon} ${
                                    isDown ? classes.highlight : ``
                                }`}
                            >
                                {!isDown ? (
                                    <NormalSortSVG
                                        color={theme.colors.lightGreyBlue}
                                        onClick={sortHandler}
                                        className={classes.sortIcon}
                                    />
                                ) : isDown === 'desc' ? (
                                    <SortDescendingSVG color={theme.palette.primary.main} onClick={sortHandler} />
                                ) : (
                                    <SortAscendingSVG color={theme.palette.primary.main} onClick={sortHandler} />
                                )}
                            </Grid>
                        )} */}
                    </Grid>
                )}
            </Grid>
            {anchorEl && (
                <PopperMenu
                    anchorEl={anchorEl}
                    handleClickAway={handleClickAway}
                    placement={isShowDots ? 'right-start' : 'bottom'}
                    id={`extraFilter`}
                >
                    <ExtraFilterConflictOptions
                        onChange={handleExtraFilterChange}
                        extraFilter={extraFilter}
                        columnType={columnType}
                        column={column}
                        gridId={gridId}
                        workspaceId={workspaceId}
                        viewId={viewId}
                        dbId={dbId}
                        branchId={branchId}
                        onResetExtraFilter={handleResetExtraFilter}
                        conflictType={conflictType}
                        t={t}
                    />
                </PopperMenu>
            )}
        </Grid>
    );
}

HeaderFilter.propTypes = {
    column: PropTypes.object.isRequired,
    quickSorts: PropTypes.object,
    isSelectionActive: PropTypes.bool
};

export default React.memo(HeaderFilter);
