import React from 'react';

function CommentReOpenSVG({ color = '#F96557', ...other }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...other}>
            <g fill="none" fillRule="evenodd">
                <rect width="18" height="18" fill={color} rx="9" />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M12.446 5.386a.946.946 0 0 0-1.248.078L8.999 7.661 6.802 5.464a.946.946 0 0 0-1.338 0l-.078.09a.946.946 0 0 0 .078 1.248L7.662 9l-2.198 2.199a.946.946 0 0 0 0 1.338l.09.078a.946.946 0 0 0 1.248-.078L9 10.336l2.199 2.2c.37.369.968.369 1.338 0l.078-.09a.946.946 0 0 0-.078-1.248l-2.199-2.199 2.199-2.197a.946.946 0 0 0 0-1.338l-.09-.078z"
                />
            </g>
        </svg>
    );
}

export default React.memo(CommentReOpenSVG);
