import React, { useCallback } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import * as roleConst from 'auth/roleConst';
import ListItem from 'components/list/Item';
import TMStatusAprrovedSVG from 'assets/images/svg/localization/TMStatusAprrovedSVG';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { sendManualTrack } from 'tracker';
import ApproveDependencySVG from 'assets/images/svg/dependencies/ApproveSVG';
import SourceStatusSVG from 'assets/images/svg/dependencies/SourceStatusSVG';
import {
    DEPENDENCY_STATUS,
    TM_STATUS,
    CREATE_RECORD_TYPES,
    MAX_SELECTION_RECORDS,
    DATA_QUERY_OPTIONS,
    EXTEND_VIEW_TYPES,
    SOURCE_STATUS,
    RANGE_TYPES
} from 'const/gridUI';
import hexToRgba from 'hex-to-rgba';
import { isShowApproveTmStatusByRange } from 'utils/gridUI/tm';
import {
    isShowMarkAsOutOfDateByRange,
    isShowMarkAsUnsetByRange,
    isShowMarkAsUpToDateByRange,
    getSourceStatusLabelByStatus,
    getSourceStatusIcon
} from 'utils/gridUI/dependency';
import { useIsTmDisabled, useAllPendingColumnIds, useDependencyList, useDisabledSourceColumns } from 'hooks/gridUI';
import store from 'store/configStore';
import AddRecordAboveSVG from 'assets/images/svg/AddRecordAboveSVG';
import AddRowBelowSVG from 'assets/images/svg/AddRowBelowSVG';
import ExtendViewSVG from 'assets/images/svg/ExtendViewSVG';
import { enqueueSnackbar } from 'notifier/actions';
import Spinner from 'components/spinner/Base';
import * as workspaceActions from 'workspaces/actions';
import { useUserGridSettings } from 'hooks/workspace';
import { useParams } from 'react-router-dom';
import { RESOURCE_TYPES, USER_SETTINGS } from 'const';
import HistorySVG from 'assets/images/svg/HistorySVG';
import BetaTag from 'components/BetaTag';
import { useRole } from 'hooks/auth/role';
import NestedMenuItem from 'components/menus/HoverMenu';
import { isTempId } from 'utils/uuid';
import LinkIconSVG from 'assets/images/svg/LinkSVG';
import { copyToClipboard } from 'utils/clipboard';
import PlayFileActions from './cellOverlay/PlayFileActions';
import PlayAutomationMenu from './cellOverlay/PlayAutomationMenu';

function CellSingleRowPopup({
    rowId,
    handleClickAway,
    closePopup,
    rowIndex,
    accessManageGridRecord,
    accessRecordHistory,
    accessEditTranslation,
    accessEditRecords,
    isDisabledCreateRecord,
    t,
    onRePosContext
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const rootRef = React.useRef();
    const { dbId, branchId, workspaceId } = useParams();

    const userGridSetting = useUserGridSettings({ wsId: workspaceId, dbId, gridId: branchId });
    const [rangeData, setRangeData] = React.useState({});
    const [recordIds, setRecordIds] = React.useState([]);
    const [columnIds, setColumnIds] = React.useState([]);
    const pendingColumnIds = useAllPendingColumnIds();
    const disabledSourceColumns = useDisabledSourceColumns();
    const isTmDisabled = useIsTmDisabled();
    const dependencies = useDependencyList();
    const [isLoading, setIsLoading] = React.useState(true);
    // const [isConfirm, setIsConfirm] = React.useState(false);
    // const [isDeleting, setIsDeleting] = React.useState(false);
    const roles = useRole();
    const workspaceRole = roles?.[roleConst.WORKSPACE_ROLE];

    React.useEffect(() => {
        const { getState } = store;
        const { gridUI, auth } = getState();
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const {
                    data,
                    recordIds: rangeRecordIds,
                    columnIds: rangeColumnIds,
                    isOverRecordLimit,
                    totalSelectedRecords
                } = await gridUIActions.getRangeData({
                    gridUI,
                    auth,
                    dataOptions: [
                        DATA_QUERY_OPTIONS.COLOR,
                        DATA_QUERY_OPTIONS.TM,
                        DATA_QUERY_OPTIONS.DEPENDENCY_STATUS,
                        DATA_QUERY_OPTIONS.DATA,
                        DATA_QUERY_OPTIONS.SOURCE_STATUS
                    ],
                    type: RANGE_TYPES.INDEX
                });
                setIsLoading(false);
                if (isOverRecordLimit) {
                    dispatch(
                        enqueueSnackbar({
                            type: 'info',
                            message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                        })
                    );
                    handleClickAway();
                    return;
                }

                setRangeData(data);
                setRecordIds(rangeRecordIds);
                setColumnIds(rangeColumnIds);
                setTimeout(() => {
                    onRePosContext && onRePosContext();
                }, 0);
            } catch (err) {
                setIsLoading(false);
                handleClickAway();
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const isShowApproveTMStatus = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        if (isTmDisabled) return false;
        return isShowApproveTmStatusByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, isTmDisabled, recordIds, pendingColumnIds]);

    const isShowMarkAsOutOfDate = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        return isShowMarkAsOutOfDateByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, recordIds, pendingColumnIds]);

    const isShowMarkAsUnset = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        return isShowMarkAsUnsetByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, recordIds, pendingColumnIds]);

    const isShowMarkAsUpToDate = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        return isShowMarkAsUpToDateByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, recordIds, pendingColumnIds]);

    const isShowSourceStatus = React.useMemo(() => {
        const dependenciesWithoutFake = dependencies?.filter(dpDc => !isTempId(dpDc?.id));
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        const filteredColumnIds = affectedColumnIds?.filter(colId => {
            const found = dependenciesWithoutFake?.find(dpDc => dpDc?.parent === colId);
            return found && found?.child && !disabledSourceColumns?.includes(colId);
        });
        return filteredColumnIds?.length > 0;
    }, [columnIds, pendingColumnIds, dependencies, disabledSourceColumns]);

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const deleteRecordHandler = useCallback(
        e => {
            e.stopPropagation();
            e.preventDefault();
            sendManualTrack({
                type: `Delete Records`,
                customData: {
                    recordIds: [rowId]
                }
            });
            dispatch(
                gridUIActions.deleteViewRecordsByIndex({
                    successCallback: () => {
                        console.log('Record Deleted');
                        closePopup();
                    },
                    errorCallback: () => {
                        console.log('Failed to delete record');
                    }
                })
            );
        },
        [dispatch, rowId, closePopup]
    );

    const openRecordHistoryHandler = type => {
        sendManualTrack({
            type: `Open Record History`,
            customData: {
                recordId: rowId
            }
        });
        dispatch(gridUIActions.openRecordHistory({ rowId, rowIndex, type }));
        dispatch(
            workspaceActions.setUserSettings({
                dbId,
                workspaceId,
                resourceId: branchId,
                resourceType: RESOURCE_TYPES.GRID,
                value: {
                    ...userGridSetting,
                    [USER_SETTINGS.GRID.OPEN_EXTEND_VIEW]: true,
                    [USER_SETTINGS.GRID.EXTEND_VIEW_TYPE]: type
                }
            })
        );
        closePopup();
    };

    const openExtendViewHandler = () => {
        openRecordHistoryHandler(
            workspaceRole === roleConst.TRANSLATOR ? EXTEND_VIEW_TYPES.TRANSLATOR : EXTEND_VIEW_TYPES.EDITOR
        );
    };

    const handleApproveStatus = () => {
        sendManualTrack({ type: `Approve TM Status` });
        dispatch(gridUIActions.approveTMStatus(TM_STATUS.APPROVED));
        closePopup();
    };

    const handleMarkDependencyUpToDate = () => {
        sendManualTrack({ type: `Mark as Up-to-date'` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.UP_TO_DATE));
        closePopup();
    };

    const handleMarkDependencyOutOfDate = () => {
        sendManualTrack({ type: `Mark as Out-of-date` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.OUT_OF_DATE));
        closePopup();
    };

    const handleMarkDependencyNotTranslated = () => {
        sendManualTrack({ type: `Mark as Unset` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.UNSET));
        closePopup();
    };

    const handleMarkSourceStatus = React.useCallback(
        sourceStatus => {
            sendManualTrack({
                type: `Mark Source Status As ${getSourceStatusLabelByStatus({ status: sourceStatus })}`
            });
            dispatch(gridUIActions.approveSourceStatus(sourceStatus));
            closePopup();
        },
        [dispatch, closePopup]
    );

    const handleAdRecordAbove = React.useCallback(
        recordCount => {
            dispatch(
                gridUIActions.createRecordsRelative({
                    recordCount,
                    rowIndex,
                    type: CREATE_RECORD_TYPES.ABOVE,
                    successCallback: () => {
                        console.log('create below record error');
                    },
                    errorCallback: () => {
                        console.log('create above record error');
                    }
                })
            );
            closePopup();
        },
        [dispatch, rowIndex, closePopup]
    );

    const handleAdRecordBelow = React.useCallback(
        recordCount => {
            dispatch(
                gridUIActions.createRecordsRelative({
                    recordCount,
                    rowIndex,
                    type: CREATE_RECORD_TYPES.BELOW,
                    successCallback: () => {
                        console.log('create below record error');
                    },
                    errorCallback: () => {
                        console.log('create above record error');
                    }
                })
            );
            closePopup();
        },
        [dispatch, rowIndex, closePopup]
    );

    const getLink = React.useCallback(
        e => {
            e.stopPropagation();
            const currentUrl = new URL(window.location);
            const href = currentUrl?.href;
            copyToClipboard({ copyValue: href, internalValue: href });
            dispatch(enqueueSnackbar({ message: t('copied_range_link') }));
            closePopup && closePopup();
        },
        [closePopup, dispatch, t]
    );

    return (
        <Grid ref={rootRef} id={`grid_popup_${rowId}`} container direction="column">
            {isLoading && (
                <Grid item>
                    <ListItem
                        icon={<Spinner size={18} thick={3} style={{ marginRight: theme.spacing(2) }} />}
                        name={'loading...'}
                    />
                </Grid>
            )}

            {!isLoading && (
                <>
                    <Grid item onClick={getLink}>
                        <ListItem icon={<LinkIconSVG width={16} height={16} />} name={t(`get_link_to_this_range`)} />
                    </Grid>
                    <PlayFileActions columnIds={columnIds} closePopup={closePopup} />
                    <PlayAutomationMenu closePopup={closePopup} />
                    {accessManageGridRecord === roleConst.FULL && !isDisabledCreateRecord && (
                        <Grid item onClick={() => handleAdRecordAbove(1)}>
                            <ListItem icon={<AddRecordAboveSVG />} name={t('insert_record_above')} />
                        </Grid>
                    )}
                    {accessManageGridRecord === roleConst.FULL && !isDisabledCreateRecord && (
                        <Grid item onClick={() => handleAdRecordBelow(1)}>
                            <ListItem icon={<AddRowBelowSVG />} name={t('insert_record_below')} />
                        </Grid>
                    )}
                    {accessRecordHistory === roleConst.FULL && (
                        <Grid item onClick={() => openRecordHistoryHandler(EXTEND_VIEW_TYPES.RECORD_HISTORY)}>
                            <ListItem icon={<HistorySVG />} name={t('cell_options_record_history')} />
                        </Grid>
                    )}
                    {accessRecordHistory === roleConst.FULL && (
                        <Grid item onClick={openExtendViewHandler}>
                            <ListItem
                                icon={<ExtendViewSVG />}
                                name={
                                    <Grid container>
                                        <Grid item style={{ marginRight: '5px' }}>
                                            {t('global_open_extended_view')}
                                        </Grid>
                                        <BetaTag />
                                    </Grid>
                                }
                            />
                        </Grid>
                    )}
                    {accessEditTranslation === roleConst.FULL && isShowApproveTMStatus && (
                        <Grid item onClick={handleApproveStatus}>
                            <ListItem icon={<TMStatusAprrovedSVG />} name={t('record_options_approve_tm')} />
                        </Grid>
                    )}
                    {accessEditRecords === roleConst.FULL && isShowMarkAsUpToDate && (
                        <Grid item onClick={handleMarkDependencyUpToDate}>
                            <ListItem icon={<ApproveDependencySVG />} name={t('dependency_mark_as_uptodate')} />
                        </Grid>
                    )}
                    {accessEditRecords === roleConst.FULL && isShowMarkAsOutOfDate && (
                        <Grid item onClick={handleMarkDependencyOutOfDate}>
                            <ListItem
                                icon={<ApproveDependencySVG color={hexToRgba(theme.colors.sun, 0.8)} />}
                                name={t('dependency_mark_as_outofdate')}
                            />
                        </Grid>
                    )}
                    {accessEditRecords === roleConst.FULL && isShowMarkAsUnset && (
                        <Grid item onClick={handleMarkDependencyNotTranslated}>
                            <ListItem
                                icon={<ApproveDependencySVG color={hexToRgba(theme.colors.brightRed, 0.8)} />}
                                name={t('dependency_mark_as_unset')}
                            />
                        </Grid>
                    )}
                    {accessManageGridRecord === roleConst.FULL && isShowSourceStatus && (
                        <NestedMenuItem
                            icon={<SourceStatusSVG color={theme.colors.steel} />}
                            label={t('set_source_status')}
                            parentMenuOpen={true}
                            anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'bottom'
                            }}
                        >
                            <Grid container direction="column">
                                <Grid item onClick={() => handleMarkSourceStatus(SOURCE_STATUS.DO_NOT_TRANSLATE)}>
                                    <ListItem
                                        icon={getSourceStatusIcon({
                                            status: SOURCE_STATUS.DO_NOT_TRANSLATE,
                                            size: 18
                                        })}
                                        name={t('do_not_translate')}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    onClick={() => handleMarkSourceStatus(SOURCE_STATUS.NOT_READY_FOR_TRANSLATION)}
                                >
                                    <ListItem
                                        icon={getSourceStatusIcon({
                                            status: SOURCE_STATUS.NOT_READY_FOR_TRANSLATION,
                                            size: 18
                                        })}
                                        name={t('not_ready_for_translation')}
                                    />
                                </Grid>

                                <Grid item onClick={() => handleMarkSourceStatus(SOURCE_STATUS.READY_FOR_TRANSLATION)}>
                                    <ListItem
                                        icon={getSourceStatusIcon({
                                            status: SOURCE_STATUS.READY_FOR_TRANSLATION,
                                            size: 18
                                        })}
                                        name={t('ready_for_translation')}
                                    />
                                </Grid>
                                <Grid item onClick={() => handleMarkSourceStatus(SOURCE_STATUS.LOCKED)}>
                                    <ListItem
                                        icon={getSourceStatusIcon({
                                            status: SOURCE_STATUS.LOCKED,
                                            size: 18
                                        })}
                                        name={t('locked')}
                                    />
                                </Grid>
                            </Grid>
                        </NestedMenuItem>
                    )}
                    {accessManageGridRecord === roleConst.FULL && (
                        <>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item className={'pt-2'}>
                                <ListItem
                                    onClick={deleteRecordHandler}
                                    icon={<DeleteSVG />}
                                    name={t('record_option_delete')}
                                />
                            </Grid>
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}

export default React.memo(CellSingleRowPopup);
