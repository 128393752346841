import React, { useCallback, useMemo, useState } from 'react';
import { Chip, Grid, IconButton } from '@material-ui/core';
import TableVirtualize from 'components/tables/Table';
import { AutoSizer } from 'react-virtualized-dn';
import { makeStyles } from '@material-ui/styles';
import { useGroupOptions, useMapGroupOptionsById, useMapRoleOptionsById, useRoleOptions } from 'hooks/permission';
import { useWorkspaceRoles, useMapWorkspaceRolesById } from 'hooks/workspace';
import LDBasePortal from 'components/selects/LDBasePortal';
import { ALL_LANGUAGES, DEFAULT_ROLE_ADMIN } from 'const';
import Checkbox from 'components/checkbox/Base';
import { useTranslation } from 'react-i18next';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import Tooltip from 'components/tooltip/Base';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import CompanyGroupTutorial from 'components/tutorial/CompanyGroupTutorial';
import ProjectRoleSelect from 'workspaces/setting/members/RoleSelect';
import LDBasePortalVirtualize from 'components/selects/virtualize/LDBasePortalVirtualize';
import { SUPPORTED_LANGUAGES } from 'const/languageData';
import CountryFlag from 'components/svg-icon/CountryFlag';
import i18n from 'i18n';
import AddIconSVGFilled from 'assets/images/svg/AddIconSVGFilled';
import PathSVG from 'assets/images/svg/localization/PathSVG';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        marginTop: 23
    },
    flx: {
        flex: 1
    },
    ml4: {
        marginLeft: 4
    },
    ml10: {
        marginLeft: 10
    },
    tableClassName: {
        '& .ReactVirtualized__Table__headerRow': {
            border: `1px solid ${theme.colors.border}`
        },
        '& .ReactVirtualized__Table__Grid': {
            border: `1px solid ${theme.colors.border}`,
            borderTop: 0
        },
        '& .ReactVirtualized__Table__row': {
            alignItems: 'baseline'
        },
        '& .ReactVirtualized__Table__rowColumn': {
            '& .cell': {
                padding: '14px 0'
            }
        }
    },
    roleEndAdorment: {
        margin: `0 6px`,
        '& .MuiCheckbox-root': {
            padding: 0,
            marginRight: 6
        }
    },
    endAdorment: {
        borderLeft: `1px solid ${theme.colors.border}`,
        paddingLeft: 6
    },
    groupWrapper: {
        position: 'relative',
        top: -2
    },
    chip: {
        maxWidth: 150,
        height: 24,
        background: `rgba(74, 145, 226, 0.1)`,
        border: `1px solid rgba(74, 145, 226, 0.3)`,
        borderRadius: 15,
        '& svg': {
            minWidth: 16
        }
    },
    groupList: {
        marginTop: 12
    },
    roleSelect: {
        width: '100%'
    },
    triggerClassName: {
        justifyContent: 'space-between',
        border: `1px solid ${theme.colors.border}`,
        padding: '0 14px',
        background: 'transparent',
        '&.open': {
            borderColor: theme.palette.primary.main
        }
    },
    emptyRender: {
        position: 'relative',
        top: 12,
        margin: '0 0 0 auto',
        width: 8,
        height: 3,
        background: `#D9D9D9`
    },
    languagePairs: {
        marginTop: 12
    },
    languagePairControl: {
        padding: '0 7px 0 14px'
    },
    languagePairClose: {
        maxWidth: 21,
        display: 'flex',
        cursor: 'pointer'
    },
    languagePairTo: {
        position: 'relative',
        flex: 1
    },
    languagePairMiddle: {
        position: 'absolute',
        width: 22,
        height: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        left: -11,
        top: 9,
        zIndex: 1,
        borderRadius: '50%',
        backgroundColor: 'white',
        borderLeft: `1px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`
    }
}));

const EmailRenderer = React.memo(({ email }) => {
    return <Grid item>{email}</Grid>;
});

const ProjectRoleRenderer = React.memo(({ canManageMember, roleId, defaultProjectRoleId, onChange }) => {
    const classes = useStyles();
    const workspaceRoles = useWorkspaceRoles();
    const workspaceRolesById = useMapWorkspaceRolesById();

    const isAdminRole = roleId === DEFAULT_ROLE_ADMIN.id;

    const selectedRole = useMemo(() => {
        return workspaceRolesById[defaultProjectRoleId];
    }, [defaultProjectRoleId, workspaceRolesById]);

    const handleChange = useCallback(
        option => {
            onChange('defaultProjectRoleId')(option.id);
        },
        [onChange]
    );

    if (isAdminRole) {
        return <Grid item className={classes.emptyRender} />;
    }

    return (
        <Grid item className={classes.groupWrapper}>
            <ProjectRoleSelect
                wrapperClassName={classes.roleSelect}
                triggerClassName={classes.triggerClassName}
                onRoleChange={handleChange}
                defaultRoleName={selectedRole?.name}
                workspaceRoles={workspaceRoles}
                isDisabled={!canManageMember}
            />
        </Grid>
    );
});

const allLanguageOption = {
    value: ALL_LANGUAGES,
    label: i18n.t('global_all')
};
const langOptions = [
    allLanguageOption,
    ...SUPPORTED_LANGUAGES.map(lang => ({
        value: lang.id,
        label: lang.id,
        icon: () => <CountryFlag languageCountryCode={lang.id} />
    }))
];

const RoleRenderer = React.memo(({ canManageMember, roleId, isTranslator, onChange, languagePairs }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const roleOptions = useRoleOptions({ isIgnoreOwner: true });
    const mapRoleOptionsById = useMapRoleOptionsById({ isIgnoreOwner: true });

    const isAdminRole = roleId === DEFAULT_ROLE_ADMIN.id;

    const roles = useMemo(() => {
        return roleOptions?.map(r => {
            return {
                ...r,
                value: r.id,
                label: r.name
            };
        });
    }, [roleOptions]);

    const selectedRole = useMemo(() => {
        const selectedRole = mapRoleOptionsById[roleId];
        return selectedRole
            ? Object.assign(selectedRole, {
                  value: selectedRole.id,
                  label: selectedRole.name
              })
            : undefined;
    }, [mapRoleOptionsById, roleId]);

    const handleChangeTranslator = useCallback(
        e => {
            const checked = e.target.checked;
            onChange('isTranslator')(checked);
        },
        [onChange]
    );

    const endAdorment = useMemo(() => {
        if (!isAdminRole) {
            return (
                <Grid item className={classes.roleEndAdorment}>
                    <Grid className={classes.endAdorment} container wrap="nowrap" alignItems="center">
                        <Grid item onClick={e => e.stopPropagation()}>
                            <Checkbox checked={isTranslator} onChange={handleChangeTranslator} />
                        </Grid>
                        <Grid item>{t('global_translator')}</Grid>
                    </Grid>
                </Grid>
            );
        }
        return null;
    }, [isAdminRole, classes.roleEndAdorment, classes.endAdorment, isTranslator, handleChangeTranslator, t]);

    const getSelectedLanguageOption = useCallback(language => {
        return langOptions.find(lang => lang.value === language);
    }, []);

    const handleChange = useCallback(
        option => {
            onChange('roleId')(option.value);
        },
        [onChange]
    );

    const handleLanguageChange = useCallback(
        ({ type, index }) => language => {
            languagePairs[index][type] = language.value;
            onChange('languagePairs')(languagePairs);
        },
        [languagePairs, onChange]
    );

    const handleAddLanguagePair = useCallback(() => {
        languagePairs.push({ from: 'all', to: 'all' });
        onChange('languagePairs')(languagePairs);
    }, [languagePairs, onChange]);

    const handleDeleteLanguagePair = useCallback(
        index => () => {
            languagePairs.splice(index, 1);
            onChange('languagePairs')(languagePairs);
        },
        [languagePairs, onChange]
    );

    return (
        <Grid item>
            <LDBasePortal
                ddPlaceholder={t('select_a_role')}
                menuPlaceholder={'Find a role'}
                options={roles}
                onChange={handleChange}
                defaultValue={selectedRole}
                isMulti={false}
                endAdorment={endAdorment}
                isDisabled={!canManageMember}
            />
            {!isAdminRole && isTranslator && (
                <Grid item container spacing={2} direction="column" wrap="nowrap" className={classes.languagePairs}>
                    {languagePairs.map((languagePair, i) => {
                        const isLast = i === languagePairs.length - 1;
                        return (
                            <Grid key={i} item>
                                <Grid container wrap="nowrap" alignItems="center" justifyContent="flex-end" spacing={1}>
                                    <Grid item className={classes.flx}>
                                        <LDBasePortalVirtualize
                                            menuPlaceholder={`${t('global_search')}...`}
                                            options={langOptions}
                                            onChange={handleLanguageChange({ type: 'from', index: i })}
                                            defaultValue={getSelectedLanguageOption(languagePair?.from)}
                                            popperStyle={{ width: 200 }}
                                            controlClassName={classes.languagePairControl}
                                        />
                                    </Grid>
                                    <Grid item className={classes.languagePairTo}>
                                        <Grid item className={classes.languagePairMiddle}>
                                            <PathSVG />
                                        </Grid>
                                        <LDBasePortalVirtualize
                                            menuPlaceholder={`${t('global_search')}...`}
                                            options={langOptions}
                                            onChange={handleLanguageChange({ type: 'to', index: i })}
                                            defaultValue={getSelectedLanguageOption(languagePair?.to)}
                                            popperStyle={{ width: 200 }}
                                            controlClassName={classes.languagePairControl}
                                        />
                                    </Grid>
                                    <Grid item className={classes.languagePairClose}>
                                        {!isLast ? (
                                            <CloseIconSVG onClick={handleDeleteLanguagePair(i)} />
                                        ) : (
                                            <AddIconSVGFilled onClick={handleAddLanguagePair} />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </Grid>
    );
});

const GroupRenderer = React.memo(({ canManageMember, roleId, groupIds, onChange }) => {
    const classes = useStyles();
    const groupOptions = useGroupOptions();
    const groupOptionsById = useMapGroupOptionsById();
    const { t } = useTranslation();

    const isAdminRole = roleId === DEFAULT_ROLE_ADMIN.id;

    const groups = useMemo(() => {
        return groupOptions?.map(g => ({
            ...g,
            value: g.id,
            label: g.name
        }));
    }, [groupOptions]);

    const selectedGroups = useMemo(() => {
        return groupIds?.map(id => groupOptionsById[id]);
    }, [groupOptionsById, groupIds]);

    const handleChange = useCallback(
        options => {
            onChange('groupIds')(options.map(opt => opt.value));
        },
        [onChange]
    );

    const handleDelete = useCallback(
        groupId => () => {
            const newGroupIds = groupIds.filter(id => id !== groupId);
            onChange('groupIds')(newGroupIds);
        },
        [groupIds, onChange]
    );

    if (isAdminRole) {
        return <Grid item className={classes.emptyRender} />;
    }

    return (
        <Grid item className={classes.groupWrapper}>
            <LDBasePortal
                ddPlaceholder={'Select groups'}
                menuPlaceholder={t('global_label_find_a_group')}
                options={groups}
                onChange={handleChange}
                defaultValue={selectedGroups}
                isMulti
                isNotDisplayTarget
                isDisabled={!canManageMember}
            />
            {groupIds?.length > 0 && (
                <Grid className={classes.groupList} item>
                    <Grid container spacing={2}>
                        {groupIds.map(id => {
                            const group = groupOptionsById[id];
                            return (
                                <Grid item key={id}>
                                    <Chip
                                        className={classes.chip}
                                        label={group.name}
                                        onDelete={handleDelete(id)}
                                        deleteIcon={<CloseIconSVG size="small" />}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
});

const InviteMembersTable = ({ selectedEmails, setSelectedEmails }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [sorting, setSorting] = useState({});

    const roles = useRole();
    const canManageMember = roles?.[roleConst.COMPANY_AUTHORITIES.MANAGE_MEMBER] === roleConst.FULL;

    const handleChangeEmail = useCallback(
        index => key => value => {
            setSelectedEmails(prev => {
                prev[index][key] = value;
                return [...prev];
            });
        },
        [setSelectedEmails]
    );

    const handleDelete = useCallback(
        index => () => {
            setSelectedEmails(prev => {
                prev.splice(index, 1);
                return [...prev];
            });
        },
        [setSelectedEmails]
    );

    const COLUMNS = useMemo(() => {
        return [
            {
                label: t('global_email'),
                dataKey: 'email',
                flexGrow: 1,
                sort: true,
                cellRenderer: ({ rowData }) => <EmailRenderer {...rowData} />
            },
            {
                label: t('global_role'),
                dataKey: 'roleId',
                flexGrow: 1,
                cellRenderer: ({ rowIndex, rowData }) => {
                    return (
                        <RoleRenderer
                            {...rowData}
                            canManageMember={canManageMember}
                            onChange={handleChangeEmail(rowIndex)}
                        />
                    );
                }
            },
            {
                label: (
                    <Grid item>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>{t('invite_member_user_groups')}</Grid>
                            <Grid item>
                                <Tooltip placement="top" title={<CompanyGroupTutorial />}>
                                    <Grid item style={{ display: 'flex' }}>
                                        <QuestionSVG />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                ),
                dataKey: 'groupIds',
                flexGrow: 1,
                cellRenderer: ({ rowIndex, rowData }) => {
                    return (
                        <GroupRenderer
                            {...rowData}
                            canManageMember={canManageMember}
                            onChange={handleChangeEmail(rowIndex)}
                        />
                    );
                }
            },
            {
                label: (
                    <Grid item>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>{t('global_default_project_role')}</Grid>
                            <Grid item>
                                <Tooltip placement="top" title={t('this_default_role_will_be_set')}>
                                    <Grid item style={{ display: 'flex' }}>
                                        <QuestionSVG />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                ),
                dataKey: 'defaultProjectRoleId',
                flexGrow: 1,
                cellRenderer: ({ rowIndex, rowData }) => {
                    return (
                        <ProjectRoleRenderer
                            {...rowData}
                            canManageMember={canManageMember}
                            onChange={handleChangeEmail(rowIndex)}
                        />
                    );
                }
            },
            {
                label: '',
                dataKey: 'actions',
                width: 40,
                cellRenderer: ({ rowIndex }) => (
                    <IconButton onClick={handleDelete(rowIndex)}>
                        <CloseIconSVG />
                    </IconButton>
                )
            }
        ];
    }, [canManageMember, handleChangeEmail, handleDelete, t]);

    const data = React.useMemo(() => {
        const cloneData = [...selectedEmails];
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];

                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, selectedEmails]);

    const handleOnSort = useCallback((sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    }, []);

    return (
        <Grid item className={classes.root}>
            <AutoSizer>
                {({ width, height }) => (
                    <TableVirtualize
                        className={classes.tableClassName}
                        data={data}
                        columns={COLUMNS}
                        width={width}
                        height={height}
                        onSort={handleOnSort}
                    />
                )}
            </AutoSizer>
        </Grid>
    );
};

export default React.memo(InviteMembersTable);
