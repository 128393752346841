import React, { useCallback } from 'react';
import Tooltip from 'components/tooltip/Base';
import IOSSwitch from 'components/switches/IOS';
import { Grid } from '@material-ui/core';
import { AUTOMATION_STATUS } from './const';
import { useTranslation } from 'react-i18next';

const AutomationSwitch = ({ automation = {}, onChange }) => {
    const { t } = useTranslation();
    const checked = automation.status === AUTOMATION_STATUS.ACTIVE;

    const handleChange = useCallback(
        (e, value) => {
            onChange({
                automationId: automation.id,
                status: !value ? AUTOMATION_STATUS.INACTIVE : AUTOMATION_STATUS.ACTIVE
            });
        },
        [automation.id, onChange]
    );

    return (
        <Tooltip title={!checked ? t('automation_switch_hint') : ''}>
            <Grid item>
                <IOSSwitch checked={automation.status === AUTOMATION_STATUS.ACTIVE} onChange={handleChange} />
            </Grid>
        </Tooltip>
    );
};

export default React.memo(AutomationSwitch);
