import * as types from '../types';
import { mergeObjects } from 'utils/object';

const handler = {
    [types.COPY](state, action) {
        const { copyPasteStack } = action.payload;
        return {
            ...state,
            copyPasteStack
        };
    },

    [types.PASTE](state, action) {
        const { cellRowsData } = action.payload;
        let newData = { ...state.data };

        const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

        Object.keys(cellRowsData).forEach(rowId => {
            newData[rowId] = mergeObjects(
                { ...newData[rowId] },
                { ...cellRowsData[rowId] },
                { arrayMerge: overwriteMerge }
            );
        });

        return {
            ...state,
            data: newData
        };
    }
};

export default handler;
