export const SAVE_SOCKET_ID = '[APP]SAVE_SOCKET_ID';
export const SET_ACCESS_TOKEN = '[APP]SET_ACCESS_TOKEN';
export const SET_CURRENT_PAGE = '[APP]SET_CURRENT_PAGE';
export const CHANGE_COMPANY_LOGO = '[APP]CHANGE_COMPANY_LOGO';

export const SET_COMPANY_INFO = '[APP]SET_COMPANY_INFO';

export const SET_RIGHT_SIDEBAR = '[APP]SET_RIGHT_SIDEBAR';

export const CHANGE_CONTEXT_MENU = '[CONTEXT_MENU]CHANGE_CONTEXT_MENU';

export const DASHBOARD_RECONNECT = '[NETWORKING]DASHBOARD_RECONNECT';

export const WRITE_CLIPBOARD = '[APP]WRITE_CLIPBOARD';

export const GET_JIRA_CREATE_TYPES = '[APP]GET_JIRA_CREATE_TYPES';
export const CREATE_JIRA_ISSUE = '[APP]CREATE_JIRA_ISSUE';

export const SET_FEEDBACK_OPTION = '[APP]SET_FEEDBACK_OPTION';
export const SET_IS_OPEN_FEEDBACK = '[APP]SET_IS_OPEN_FEEDBACK';

export const OPEN_WELCOME = '[APP]OPEN_WELCOME';
export const CLOSE_WELCOME = '[APP]CLOSE_WELCOME';

export const OPEN_HELP_MENU_TOOTLTIP = '[APP]OPEN_HELP_MENU_TOOTLTIP';
export const CLOSE_HELP_MENU_TOOTLTIP = '[APP]CLOSE_HELP_MENU_TOOTLTIP';
export const SET_APP_VERSION = '[APP]SET_APP_VERSION';

export const TOGGLE_CHROME_NOTIFY = '[APP]TOGGLE_CHROME_NOTIFY';
export const SET_STATUS_CHROME_NOTIFY = '[APP]SET_STATUS_CHROME_NOTIFY';

export const TURN_ON_MAINTAIN_POPUP = '[APP]TURN_ON_MAINTAIN_POPUP';
export const TURN_OFF_MAINTAIN_POPUP = '[APP]TURN_OFF_MAINTAIN_POPUP';

export const IS_TOGGLE_DISABLED_SNACKBAR = '[APP]IS_TOGGLE_DISABLED_SNACKBAR';

export const SAVE_SHARE_KEY_ID = '[APP]SAVE_SHARE_KEY_ID';

export const SET_CURRENT_TUTORIAL = '[APP]SET_CURRENT_TUTORIAL';

export const SHOW_FREE_TRIAL = '[APP]SHOW_FREE_TRIAL';
export const HIDE_FREE_TRIAL = '[APP]HIDE_FREE_TRIAL';

export const TOGGLE_KEY_BOARD_SHORT_CUT = '[APP]TOGGLE_KEY_BOARD_SHORT_CUT';

export const OPEN_NOTIFICATION_INFO = '[APP]OPEN_NOTIFICATION_INFO';
export const CLOSE_NOTIFICATION_INFO = '[APP]CLOSE_NOTIFICATION_INFO';

export const SET_SHOW_TEMPLATES = '[APP]SET_SHOW_TEMPLATES';

export const SET_API_STATUS = `[APP]SET_API_STATUS`;

export const SET_PROMOTE_FEATURES = `[APP]SET_PROMOTE_FEATURES`;

export const SET_SELF_HOSTING_LICENSE = `[APP]SET_SELF_HOSTING_LICENSE`;
