import { useSelector } from 'react-redux';
import { useSectionRowStartIndex, useWindowRowStartIndex, useWindowRowStopIndex, useRows, useRowHeight } from './row';
import { useData } from './data';
import {
    useDisabledColumns,
    useDisabledColumnsByType,
    useDisabledSourceColumns,
    useViewColumnsWithReOrder,
    useWindowColumnStartIndex,
    useWindowColumnStopIndex,
    useProcessingColumns,
    useReferenceDisabledColumns
} from './column';
import { isSelecting } from 'utils/gridUI/range';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import { useMetaData } from './metaData';
import { DEFAULT_COLUMN_WIDTH, SOURCE_STATUS } from 'const/gridUI';
import * as columnTypes from 'const/columnTypes';
import { TRANSLATION_TYPES } from 'gridUI/column-types';
import { useSourceTextAndSourceLangueColumns } from '.';
import { useIsShareViewLink } from 'hooks/app';
import { getCellData } from 'utils/gridUI/cell';
import { between } from 'utils/usage';
import { useDependencies } from './dependency';

export function useIsOpenCellEdit() {
    return useSelector(state => state.gridUI?.isOpenCellEdit);
}

export function useIsOpenCellPreview() {
    return useSelector(state => state.gridUI?.isOpenCellPreview);
}

export function useIsOpenCellIssue() {
    return useSelector(state => state.gridUI?.isOpenCellIssue);
}

export function useIsOpenViewCellTicket() {
    return useSelector(state => state.gridUI?.isOpenViewCellTicket);
}

export function useSelectedCellData() {
    const data = useData();
    /**
     * TODO: refactor read language pair and role faster
     */
    const roles = useRole();
    const accessEditRecords = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    const columns = useViewColumnsWithReOrder();
    const rows = useRows();
    const rowStartIndex = useWindowRowStartIndex();
    const rowStopIndex = useWindowRowStopIndex();
    const columnStartIndex = useWindowColumnStartIndex();
    const columnStopIndex = useWindowColumnStopIndex();
    const ROW_START_INDEX = useSectionRowStartIndex();
    // const columnIds = columns?.map(col => col?.id);
    const metaData = useMetaData();
    const disabledColumns = useDisabledColumns();
    const disabledSourceColumns = useDisabledSourceColumns();
    const disabledColumnIdsByType = useDisabledColumnsByType();
    const processingColumns = useProcessingColumns();
    const isShareViewLink = useIsShareViewLink();
    const referenceDisabledColumns = useReferenceDisabledColumns();
    const dependencies = useDependencies();
    const height = useRowHeight();

    if (!isSelecting({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex })) {
        return null;
    }

    if (rowStartIndex !== rowStopIndex && columnStartIndex !== columnStopIndex) return null;

    const rowId = rows?.[rowStartIndex - ROW_START_INDEX];
    const viewColumn = columns?.[columnStartIndex];

    if (!rowId || !viewColumn) return null;

    const gridColumn = metaData?.[viewColumn?.id];
    const columnId = gridColumn?.id;
    const isEditTable =
        gridColumn?.editable &&
        ![
            ...disabledColumns,
            ...disabledSourceColumns,
            ...disabledColumnIdsByType,
            ...processingColumns,
            ...referenceDisabledColumns
        ]?.includes(columnId);
    const cellData = data?.[rowId]?.[columnId];

    const childDependency = dependencies?.find(dpDc => dpDc?.child === columnId);
    const parentDependency = dependencies?.find(dpDc => dpDc?.parent === columnId);

    const parentColumnId = childDependency?.parent;
    const parentCellData = data?.[rowId]?.[parentColumnId];
    const isParentDependency = !!parentDependency && !!parentDependency?.child;

    const isParentDisabledStatus = [SOURCE_STATUS.DO_NOT_TRANSLATE, SOURCE_STATUS.NOT_READY_FOR_TRANSLATION]?.includes(
        parentCellData?._sourceStatus
    );

    const isStatusLocked = cellData?._sourceStatus === SOURCE_STATUS.LOCKED;

    const isReadOnly =
        accessEditRecords !== roleConst.FULL ||
        !isEditTable ||
        isShareViewLink ||
        isStatusLocked ||
        isParentDisabledStatus;

    return {
        columnId,
        rowId,
        data: cellData,
        isReadOnly,
        width: viewColumn?.customProperties?.width || DEFAULT_COLUMN_WIDTH,
        column: gridColumn,
        tm: cellData?._tm,
        rowIndex: rowStartIndex,
        columnIndex: columnStartIndex,
        originalValue: cellData?.value || null,
        height,
        isParentDependency
    };
}

export function useIsCellSelected({ rowIndex, columnIndex }) {
    const rowStartIndex = useWindowRowStartIndex();
    const rowStopIndex = useWindowRowStopIndex();
    const columnStartIndex = useWindowColumnStartIndex();
    const columnStopIndex = useWindowColumnStopIndex();

    if (!isSelecting({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex })) {
        return false;
    }

    if (rowStartIndex !== rowStopIndex && columnStartIndex !== columnStopIndex) return false;

    return rowStartIndex === rowIndex && columnStartIndex === columnIndex;
}

export function useIsCellOverlaid({ rowIndex, columnIndex, checkWithCellCopying }) {
    return useSelector(state => {
        const { gridUI } = state;
        const { rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex, isCellCopying } = gridUI;
        if (!isSelecting({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex })) {
            return false;
        }

        const _rowStartIndex = Math.min(rowStartIndex, rowStopIndex);
        const _rowStopIndex = Math.max(rowStartIndex, rowStopIndex);
        const _columnStartIndex = Math.min(columnStartIndex, columnStopIndex);
        const _columnStopIndex = Math.max(columnStartIndex, columnStopIndex);

        return (
            between(rowIndex, _rowStartIndex, _rowStopIndex) &&
            between(columnIndex, _columnStartIndex, _columnStopIndex) &&
            (!checkWithCellCopying || isCellCopying)
        );
    });
}

export function useCharacterKey() {
    return useSelector(state => state?.gridUI?.character);
}

export function useIsOpenCellContext() {
    return useSelector(state => state?.gridUI?.isOpenCellContext);
}

export function useShowCopySource({ column, rowId, columnId }) {
    const { sourceData } = useSourceTextAndSourceLangueColumns({ rowId, columnId });
    if (column && !!sourceData?.sourceText) {
        const { type, editable, customProperties } = column;
        if (
            editable &&
            type === columnTypes.TRANSLATION &&
            customProperties?.localizationType === TRANSLATION_TYPES.TARGET_LANG
        ) {
            return { sourceData, showCopySource: true };
        }
    }
    return { showCopySource: false };
}

export function useIsOpenCellComment() {
    return useSelector(state => state.gridUI?.isOpenCellComment);
}

export function useCellValidations({ rowId, columnId }) {
    return useSelector(state => {
        const data = state?.gridUI?.data;
        const cellData = getCellData({ rowId, columnId, data });
        return cellData?.metadata?.validations;
    });
}

export function useIsSkeleton({ rowIndex }) {
    return useSelector(state => {
        const gridUI = state?.gridUI;
        const { isFetchingMore, isDeletingRecords, ROW_STOP_INDEX, ROW_START_INDEX } = gridUI;
        return (rowIndex < ROW_START_INDEX || rowIndex > ROW_STOP_INDEX) && isFetchingMore && !isDeletingRecords;
    });
}

export function useIsSelectedByRowOrColumn({ columnId, rowIndex }) {
    return useSelector(state => {
        const { gridUI } = state;
        const { columnsSelected, rowsRangeIndexes } = gridUI;
        return (
            rowsRangeIndexes?.some(range => {
                const start = range?.[0];
                const end = range?.[1] || start;
                return rowIndex >= start && rowIndex <= end;
            }) || columnsSelected.includes(columnId)
        );
    });
}

export function useCellData({ rawCellData, rowId, columnId }) {
    return useSelector(state => {
        if (rawCellData) return rawCellData;
        const gridUI = state?.gridUI;
        const { data } = gridUI;
        return data?.[rowId]?.[columnId];
    });
}

export function useIsCellReadyOnly({ rowId, columnId }) {
    const roles = useRole();
    const accessEditRecords = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    const disabledColumnIdsByType = useDisabledColumnsByType();
    const referenceDisabledColumns = useReferenceDisabledColumns();

    return useSelector(state => {
        const gridUI = state?.gridUI;
        const app = state?.app;
        const { metaData, disabledColumns, disabledSourceColumns, processingColumns, dependencies, data } = gridUI;
        const column = metaData?.[columnId];
        const childDependency = dependencies?.find(dpDc => dpDc?.child === columnId);

        const parentColumnId = childDependency?.parent;
        const parentCellData = data?.[rowId]?.[parentColumnId];
        const isParentDisabledStatus = [
            SOURCE_STATUS.DO_NOT_TRANSLATE,
            SOURCE_STATUS.NOT_READY_FOR_TRANSLATION
        ]?.includes(parentCellData?._sourceStatus);
        const cellData = data?.[rowId]?.[columnId];

        const isStatusLocked = cellData?._sourceStatus === SOURCE_STATUS.LOCKED;
        const isCellReadOnly = app?.isShareViewLink || isStatusLocked || isParentDisabledStatus;

        const isDisabled = [
            ...disabledColumns,
            ...disabledSourceColumns,
            ...disabledColumnIdsByType,
            ...referenceDisabledColumns
        ]?.includes(columnId);
        const isProcessing = processingColumns?.includes(columnId);

        const isEditable = column && column.editable && !isDisabled && !isProcessing;

        return accessEditRecords !== roleConst.FULL || !isEditable || isCellReadOnly;
    });
}

export function useCellParentText({ rowId, columnId }) {
    return useSelector(state => {
        const gridUI = state?.gridUI;
        const { data, dependencies } = gridUI;

        const childDependency = dependencies?.find(dpDc => dpDc?.child === columnId);

        return data?.[rowId]?.[childDependency?.parent]?.value;
    });
}
