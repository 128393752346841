import React from 'react';

function LimitSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="260" height="300" viewBox="0 0 260 300" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 5.99999C0 2.68629 2.68629 0 6 0H260V300H6.00001C2.6863 300 0 297.314 0 294V5.99999Z"
                fill="url(#paint_limit0_linear)"
            />
            <path
                d="M0 237C0 233.686 2.68629 231 6 231H260V300H6.00001C2.6863 300 0 297.314 0 294V237Z"
                fill="#443695"
            />
            <path d="M201 230H260V221H201V230Z" fill="url(#paint_limit1_linear)" />
            <path d="M203 221H260V220H203V221Z" fill="url(#paint_limit2_linear)" />
            <path d="M65 231L-7.86805e-07 231L0 222L65 222L65 231Z" fill="url(#paint_limit3_linear)" />
            <path d="M65 222L2 222L2 221L65 221L65 222Z" fill="url(#paint_limit4_linear)" />
            <path d="M80 57V230H91.7182V68.6859H191.282V230H203V57H80Z" fill="#FFD65C" />
            <path d="M87 230H91.5964V69.0761H191.322V230H196V65H87V230Z" fill="#E3BC44" />
            <path
                d="M60.2585 159.124C59.3577 159.124 58.6206 158.387 58.6206 157.487V141.681C58.6206 140.78 59.3577 140.043 60.2585 140.043C61.1594 140.043 61.8964 140.78 61.8964 141.681V157.487C61.8964 158.387 61.1594 159.124 60.2585 159.124Z"
                fill="#C1AAD6"
            />
            <path
                d="M65.336 151.506H57.6379C56.7371 151.506 56 150.769 56 149.868C56 148.968 56.7371 148.23 57.6379 148.23H65.336V151.506Z"
                fill="#433B4A"
            />
            <path d="M68.6937 242L92.0336 230.371V68.7111L68.6937 57.082V242Z" fill="#C4A23B" />
            <path d="M89.6587 146.922L71.7238 146.595V65.1094L89.6587 73.3807V146.922Z" fill="#FFD65C" />
            <path d="M89.6587 225.705L71.7238 233.977V152.492L89.6587 152.164V225.705Z" fill="#FFD65C" />
            <path d="M64.517 240.281L68.6118 241.755V57.3281L64.517 58.8022V240.281Z" fill="#FFD65C" />
            <rect x="92" y="69" width="100" height="161" fill="#392B76" />
            <path
                opacity="0.1"
                d="M113.618 195.61C113.05 196.178 112.327 196.566 111.539 196.724C110.752 196.881 109.935 196.802 109.192 196.495C108.45 196.189 107.815 195.669 107.368 195.001C106.921 194.333 106.682 193.548 106.682 192.745C106.681 191.942 106.919 191.156 107.365 190.488C107.811 189.82 108.445 189.299 109.187 188.991C109.929 188.684 110.746 188.603 111.534 188.76C112.322 188.916 113.046 189.303 113.614 189.871C114.375 190.632 114.802 191.663 114.803 192.739C114.804 193.815 114.377 194.848 113.618 195.61Z"
                fill="white"
            />
            <path
                opacity="0.5"
                d="M183.309 157.691C183 158.001 182.606 158.212 182.177 158.298C181.747 158.384 181.302 158.341 180.898 158.174C180.493 158.007 180.147 157.723 179.904 157.36C179.66 156.996 179.53 156.568 179.53 156.13C179.53 155.693 179.659 155.265 179.902 154.901C180.145 154.537 180.491 154.253 180.895 154.085C181.299 153.917 181.744 153.874 182.174 153.959C182.603 154.044 182.997 154.255 183.307 154.564C183.721 154.979 183.955 155.541 183.955 156.127C183.955 156.714 183.723 157.276 183.309 157.691Z"
                fill="white"
            />
            <path
                d="M106.163 91.0933C105.959 91.2978 105.699 91.4372 105.415 91.4939C105.132 91.5506 104.839 91.522 104.572 91.4117C104.305 91.3015 104.076 91.1145 103.916 90.8746C103.755 90.6346 103.669 90.3523 103.669 90.0635C103.669 89.7747 103.754 89.4923 103.915 89.2521C104.075 89.0119 104.303 88.8246 104.57 88.714C104.837 88.6034 105.13 88.5744 105.413 88.6307C105.697 88.687 105.957 88.8261 106.161 89.0303C106.435 89.3038 106.588 89.6747 106.589 90.0615C106.589 90.4483 106.436 90.8194 106.163 91.0933Z"
                fill="white"
            />
            <path
                d="M140.122 156.773C139.983 156.913 139.804 157.008 139.61 157.047C139.416 157.086 139.215 157.066 139.032 156.991C138.85 156.915 138.693 156.787 138.583 156.623C138.473 156.458 138.414 156.265 138.414 156.067C138.414 155.869 138.473 155.676 138.582 155.511C138.692 155.347 138.848 155.218 139.031 155.143C139.214 155.067 139.415 155.047 139.609 155.086C139.803 155.124 139.981 155.219 140.121 155.359C140.309 155.547 140.414 155.801 140.414 156.066C140.414 156.331 140.309 156.585 140.122 156.773Z"
                fill="white"
            />
            <g clipPath="url(#clip_limit0)">
                <path
                    d="M192 68.8633H152.333C148.972 73.4566 147 79.0547 147 85.0834C147 100.514 159.711 113.002 175.417 113.002C181.627 113.002 187.325 111.064 192 107.763V68.8633Z"
                    fill="#5C2EAB"
                />
                <path
                    d="M191.416 106.828C187.179 109.268 182.284 110.703 177.024 110.703C161.318 110.703 148.607 98.2153 148.607 82.7847C148.607 77.4019 150.214 72.3062 152.99 68C149.192 72.7368 147 78.622 147 85.0813C147 100.512 159.711 113 175.417 113C181.334 113 186.886 111.206 191.416 108.12V106.828Z"
                    fill="#6733BF"
                />
                <path
                    d="M164.13 101.387C165.18 100.584 165.188 98.8675 164.147 97.5539C163.106 96.2404 161.41 95.8271 160.36 96.6308C159.309 97.4344 159.302 99.1508 160.343 100.464C161.384 101.778 163.079 102.191 164.13 101.387Z"
                    fill="#572BA1"
                />
                <path
                    d="M160.88 100.081C159.93 98.8606 159.857 97.3534 160.661 96.4922C160.588 96.564 160.442 96.564 160.368 96.6357C159.346 97.4252 159.273 99.1477 160.368 100.44C161.391 101.731 163.071 102.162 164.167 101.373C164.24 101.301 164.313 101.229 164.386 101.157C163.364 101.66 161.83 101.229 160.88 100.081Z"
                    fill="#4F2791"
                />
                <path
                    d="M173.054 104.895C173.539 104.164 173.164 103.081 172.218 102.475C171.271 101.869 170.111 101.97 169.627 102.701C169.142 103.431 169.517 104.515 170.463 105.12C171.41 105.726 172.57 105.625 173.054 104.895Z"
                    fill="#572BA1"
                />
                <path
                    d="M170.669 104.744C169.792 104.17 169.427 103.237 169.719 102.52C169.646 102.591 169.646 102.663 169.573 102.663C169.062 103.381 169.427 104.457 170.377 105.031C171.326 105.606 172.495 105.534 172.933 104.816C173.006 104.744 173.006 104.673 173.006 104.601C172.568 105.247 171.545 105.247 170.669 104.744Z"
                    fill="#4F2791"
                />
                <path
                    d="M169.959 97.5595C170.469 97.1705 170.481 96.3476 169.986 95.7214C169.491 95.0952 168.677 94.9029 168.167 95.2918C167.657 95.6808 167.645 96.5037 168.14 97.1299C168.634 97.7561 169.449 97.9484 169.959 97.5595Z"
                    fill="#572BA1"
                />
                <path
                    d="M168.404 96.9256C167.966 96.3514 167.893 95.6337 168.331 95.2031C168.258 95.2031 168.185 95.2749 168.185 95.2749C167.674 95.6337 167.674 96.495 168.185 97.1409C168.696 97.7869 169.5 98.0022 170.011 97.5715C170.084 97.5715 170.084 97.4998 170.157 97.428C169.573 97.7151 168.843 97.4998 168.404 96.9256Z"
                    fill="#4F2791"
                />
                <path
                    d="M156.822 91.4503C157.843 90.7408 157.934 89.1424 157.026 87.8802C156.117 86.618 154.553 86.1699 153.532 86.8794C152.51 87.5889 152.419 89.1873 153.327 90.4495C154.236 91.7117 155.8 92.1597 156.822 91.4503Z"
                    fill="#572BA1"
                />
                <path
                    d="M153.867 90.1061C153.063 88.9578 153.063 87.5942 153.794 86.8047C153.721 86.8765 153.575 86.8765 153.502 86.9482C152.479 87.6659 152.406 89.2449 153.282 90.465C154.159 91.6851 155.693 92.1875 156.716 91.4698C156.789 91.398 156.862 91.3262 156.935 91.2544C156.058 91.6851 154.67 91.1827 153.867 90.1061Z"
                    fill="#4F2791"
                />
                <path
                    d="M159.168 104.084C159.882 103.384 159.581 101.952 158.497 100.887C157.413 99.8221 155.956 99.5267 155.243 100.227C154.529 100.928 154.83 102.359 155.914 103.425C156.998 104.49 158.455 104.785 159.168 104.084Z"
                    fill="#572BA1"
                />
                <path
                    d="M156.015 97.1281C156.321 96.9354 156.357 96.4547 156.095 96.0544C155.834 95.6541 155.374 95.4858 155.068 95.6785C154.763 95.8712 154.727 96.3519 154.989 96.7522C155.25 97.1525 155.71 97.3208 156.015 97.1281Z"
                    fill="#572BA1"
                />
                <path
                    d="M167.31 105.501C167.509 105.202 167.341 104.749 166.935 104.489C166.529 104.23 166.04 104.262 165.842 104.56C165.643 104.859 165.811 105.312 166.217 105.572C166.623 105.831 167.112 105.8 167.31 105.501Z"
                    fill="#6733BF"
                />
            </g>
            <path
                d="M103.745 181.677C103.369 181.864 102.955 181.961 102.535 181.96C102.116 181.96 101.702 181.863 101.327 181.676C101.236 181.631 101.133 181.615 101.033 181.631C100.933 181.648 100.84 181.695 100.768 181.767C100.696 181.839 100.648 181.932 100.632 182.033C100.615 182.133 100.631 182.236 100.676 182.327C100.863 182.702 100.961 183.115 100.961 183.535C100.961 183.954 100.864 184.368 100.677 184.744C100.632 184.835 100.616 184.938 100.632 185.038C100.649 185.139 100.696 185.231 100.768 185.303C100.84 185.375 100.932 185.422 101.032 185.438C101.133 185.454 101.235 185.439 101.326 185.393C101.703 185.207 102.116 185.11 102.536 185.11C102.955 185.11 103.369 185.207 103.744 185.394C103.835 185.44 103.938 185.455 104.038 185.439C104.138 185.423 104.231 185.375 104.303 185.303C104.375 185.231 104.423 185.138 104.439 185.038C104.456 184.937 104.44 184.834 104.395 184.743C104.208 184.368 104.111 183.955 104.11 183.535C104.11 183.116 104.207 182.702 104.394 182.326C104.439 182.235 104.455 182.132 104.439 182.032C104.422 181.932 104.375 181.839 104.303 181.767C104.232 181.696 104.139 181.648 104.039 181.632C103.938 181.616 103.836 181.632 103.745 181.677Z"
                fill="white"
            />
            <path
                d="M148.745 110.677C148.369 110.864 147.955 110.961 147.535 110.96C147.116 110.96 146.702 110.863 146.327 110.676C146.236 110.631 146.133 110.615 146.033 110.631C145.933 110.648 145.84 110.695 145.768 110.767C145.696 110.839 145.648 110.932 145.632 111.033C145.615 111.133 145.631 111.236 145.676 111.327C145.863 111.702 145.961 112.115 145.961 112.535C145.961 112.954 145.864 113.368 145.677 113.744C145.632 113.835 145.616 113.938 145.632 114.038C145.649 114.139 145.696 114.231 145.768 114.303C145.84 114.375 145.932 114.422 146.032 114.438C146.133 114.454 146.235 114.439 146.326 114.393C146.703 114.207 147.116 114.11 147.536 114.11C147.955 114.11 148.369 114.207 148.744 114.394C148.835 114.44 148.938 114.455 149.038 114.439C149.138 114.423 149.231 114.375 149.303 114.303C149.375 114.231 149.423 114.138 149.439 114.038C149.456 113.937 149.44 113.834 149.395 113.743C149.208 113.368 149.111 112.955 149.11 112.535C149.11 112.116 149.207 111.702 149.394 111.326C149.439 111.235 149.455 111.132 149.439 111.032C149.422 110.932 149.375 110.839 149.303 110.767C149.232 110.696 149.139 110.648 149.039 110.632C148.938 110.616 148.836 110.632 148.745 110.677Z"
                fill="#F9F785"
            />
            <path
                d="M179.745 201.677C179.369 201.864 178.955 201.961 178.535 201.96C178.116 201.96 177.702 201.863 177.327 201.676C177.236 201.631 177.133 201.615 177.033 201.631C176.933 201.648 176.84 201.695 176.768 201.767C176.696 201.839 176.648 201.932 176.632 202.033C176.615 202.133 176.631 202.236 176.676 202.327C176.863 202.702 176.961 203.115 176.961 203.535C176.961 203.954 176.864 204.368 176.677 204.744C176.632 204.835 176.616 204.938 176.632 205.038C176.649 205.139 176.696 205.231 176.768 205.303C176.84 205.375 176.932 205.422 177.032 205.438C177.133 205.454 177.235 205.439 177.326 205.393C177.703 205.207 178.116 205.11 178.536 205.11C178.955 205.11 179.369 205.207 179.744 205.394C179.835 205.44 179.938 205.455 180.038 205.439C180.138 205.423 180.231 205.375 180.303 205.303C180.375 205.231 180.423 205.138 180.439 205.038C180.456 204.937 180.44 204.834 180.395 204.743C180.208 204.368 180.111 203.955 180.11 203.535C180.11 203.116 180.207 202.702 180.394 202.326C180.439 202.235 180.455 202.132 180.439 202.032C180.422 201.932 180.375 201.839 180.303 201.767C180.232 201.696 180.139 201.648 180.039 201.632C179.938 201.616 179.836 201.632 179.745 201.677Z"
                fill="#F9F785"
            />
            <g clipPath="url(#clip_limit1)">
                <path
                    d="M178.2 229.902C175.6 222.502 170.8 215.902 163.9 211.102C146.3 198.702 122.1 203.002 109.8 220.602C107.7 223.502 106.2 226.702 105 229.902H178.2Z"
                    fill="#792EAB"
                />
                <path
                    d="M107.8 229.902C108.7 228.002 109.8 226.102 111.1 224.302C123.4 206.602 147.7 202.402 165.3 214.702C171 218.702 175.3 224.002 178.1 229.902H178.3C175.7 222.502 170.9 215.902 164 211.102C146.3 198.702 122.1 203.002 109.8 220.602C107.7 223.502 106.2 226.702 105 229.902H107.8Z"
                    fill="#964CC7"
                />
                <path
                    d="M136.167 220.301C138.483 220.183 140.287 218.612 140.194 216.792C140.102 214.972 138.149 213.592 135.833 213.709C133.516 213.827 131.713 215.397 131.805 217.218C131.897 219.038 133.85 220.418 136.167 220.301Z"
                    fill="#6A209C"
                />
                <path
                    d="M135.9 214.704C138 214.604 139.8 215.704 140.1 217.304C140.1 217.104 140.1 217.004 140.1 216.804C140 215.004 138.1 213.604 135.8 213.704C133.5 213.804 131.7 215.404 131.8 217.204C131.8 217.404 131.8 217.504 131.9 217.704C132.2 216.104 133.8 214.804 135.9 214.704Z"
                    fill="#651F94"
                />
                <path
                    d="M124.337 225.436C125.772 224.859 126.567 223.478 126.114 222.35C125.661 221.223 124.13 220.777 122.695 221.354C121.261 221.931 120.465 223.312 120.918 224.44C121.372 225.567 122.902 226.013 124.337 225.436Z"
                    fill="#6A209C"
                />
                <path
                    d="M122.9 221.904C124.2 221.404 125.6 221.704 126.1 222.604C126.1 222.504 126.1 222.404 126 222.304C125.5 221.204 124 220.704 122.6 221.304C121.2 221.904 120.4 223.204 120.8 224.404C120.8 224.504 120.9 224.604 121 224.704C120.9 223.604 121.6 222.404 122.9 221.904Z"
                    fill="#651F94"
                />
                <path
                    d="M133.778 228.297C134.882 228.243 135.741 227.483 135.697 226.6C135.654 225.718 134.724 225.046 133.621 225.101C132.518 225.155 131.659 225.915 131.702 226.797C131.745 227.68 132.675 228.351 133.778 228.297Z"
                    fill="#6A209C"
                />
                <path
                    d="M133.6 225.603C134.6 225.603 135.5 226.103 135.6 226.903C135.6 226.803 135.6 226.703 135.6 226.703C135.6 225.803 134.6 225.203 133.5 225.203C132.4 225.303 131.5 226.003 131.6 226.903C131.6 227.003 131.6 227.103 131.6 227.103C131.8 226.303 132.6 225.603 133.6 225.603Z"
                    fill="#651F94"
                />
                <path
                    d="M152.914 220.003C155.068 219.993 156.808 218.597 156.8 216.885C156.793 215.173 155.04 213.793 152.886 213.803C150.733 213.812 148.993 215.208 149 216.92C149.008 218.632 150.76 220.012 152.914 220.003Z"
                    fill="#6A209C"
                />
                <path
                    d="M152.8 214.804C154.7 214.804 156.3 215.904 156.6 217.404C156.6 217.304 156.7 217.104 156.7 217.004C156.7 215.304 155 214.004 152.8 214.004C150.7 214.004 148.9 215.404 149 217.104C149 217.304 149 217.404 149.1 217.504C149.3 215.904 150.9 214.804 152.8 214.804Z"
                    fill="#651F94"
                />
                <path
                    d="M145.486 211.585C145.506 211.088 144.986 210.664 144.324 210.637C143.661 210.61 143.108 210.991 143.088 211.487C143.068 211.984 143.588 212.408 144.251 212.435C144.913 212.462 145.466 212.081 145.486 211.585Z"
                    fill="#6A209C"
                />
                <path
                    d="M126.12 217.84C126.735 217.592 127.083 217.018 126.897 216.557C126.712 216.096 126.063 215.922 125.448 216.169C124.833 216.417 124.485 216.991 124.671 217.452C124.856 217.913 125.505 218.087 126.12 217.84Z"
                    fill="#964CC7"
                />
                <path
                    d="M136.83 211.265C138.897 210.901 140.378 209.503 140.139 208.143C139.899 206.784 138.029 205.977 135.963 206.341C133.896 206.705 132.415 208.102 132.654 209.462C132.894 210.822 134.763 211.629 136.83 211.265Z"
                    fill="#6A209C"
                />
            </g>
            <g clipPath="url(#clip_limit2)">
                <path
                    opacity="0.64"
                    d="M172.4 174.098L167.3 179.198C167.1 179.398 166.8 179.398 166.6 179.198C166.4 178.998 166.4 178.698 166.6 178.498L169.3 175.798C169.5 175.598 169.5 175.298 169.3 175.098C169.1 174.898 168.8 174.898 168.6 175.098L164.6 179.098C164.4 179.298 164.1 179.298 163.9 179.098C163.7 178.898 163.7 178.598 163.9 178.398L166.9 175.398C167.1 175.198 167.1 174.898 166.9 174.698C166.7 174.498 166.4 174.498 166.2 174.698L152.3 188.698C150.5 190.498 150.5 193.598 152.5 195.298C154.3 196.898 157.1 196.698 158.8 194.998L174.6 179.098C174.9 178.798 174.9 178.398 174.6 178.198C174.3 177.898 173.9 177.898 173.7 178.198L170.3 181.598C170.1 181.798 169.8 181.798 169.6 181.598C169.4 181.398 169.4 181.098 169.6 180.898L171.9 178.598C172.1 178.398 172.1 177.998 171.9 177.798C171.7 177.598 171.3 177.598 171.1 177.798L167.3 181.598C167.1 181.798 166.8 181.798 166.6 181.598C166.4 181.398 166.4 181.098 166.6 180.898L172.8 174.698C173 174.498 173 174.298 172.8 174.098C172.8 173.998 172.5 173.998 172.4 174.098Z"
                    fill="url(#paint_limit5_linear)"
                />
                <path
                    d="M157.933 194.31C159.261 192.983 159.261 190.83 157.933 189.502C156.606 188.174 154.453 188.174 153.125 189.502C151.797 190.83 151.797 192.983 153.125 194.31C154.453 195.638 156.606 195.638 157.933 194.31Z"
                    fill="#FFD65C"
                />
            </g>
            <g clipPath="url(#clip_limit3)">
                <path
                    opacity="0.64"
                    d="M113.33 131.066L109.915 134.481C109.781 134.615 109.58 134.615 109.446 134.481C109.313 134.347 109.313 134.146 109.446 134.012L111.254 132.204C111.388 132.07 111.388 131.869 111.254 131.735C111.121 131.602 110.92 131.602 110.786 131.735L108.107 134.414C107.973 134.548 107.772 134.548 107.638 134.414C107.504 134.28 107.504 134.079 107.638 133.945L109.647 131.936C109.781 131.802 109.781 131.602 109.647 131.468C109.513 131.334 109.313 131.334 109.179 131.468L99.8705 140.843C98.6652 142.048 98.6652 144.124 100.004 145.262C101.21 146.334 103.085 146.2 104.223 145.061L114.804 134.414C115.004 134.213 115.004 133.945 114.804 133.811C114.603 133.61 114.335 133.61 114.201 133.811L111.924 136.088C111.79 136.222 111.589 136.222 111.455 136.088C111.321 135.954 111.321 135.753 111.455 135.619L112.996 134.079C113.129 133.945 113.129 133.677 112.996 133.544C112.862 133.41 112.594 133.41 112.46 133.544L109.915 136.088C109.781 136.222 109.58 136.222 109.446 136.088C109.313 135.954 109.313 135.753 109.446 135.619L113.598 131.468C113.732 131.334 113.732 131.2 113.598 131.066C113.598 130.999 113.397 130.999 113.33 131.066Z"
                    fill="url(#paint_limit6_linear)"
                />
                <path
                    d="M103.643 144.602C104.532 143.713 104.532 142.271 103.643 141.382C102.754 140.493 101.312 140.493 100.423 141.382C99.5339 142.271 99.5339 143.713 100.423 144.602C101.312 145.491 102.754 145.491 103.643 144.602Z"
                    fill="#FFD65C"
                />
            </g>
            <g clipPath="url(#clip_limit4)">
                <path
                    opacity="0.64"
                    d="M167.286 118.073L163.643 121.716C163.5 121.859 163.286 121.859 163.143 121.716C163 121.573 163 121.359 163.143 121.216L165.071 119.287C165.214 119.145 165.214 118.93 165.071 118.787C164.929 118.645 164.714 118.645 164.571 118.787L161.714 121.645C161.571 121.787 161.357 121.787 161.214 121.645C161.071 121.502 161.071 121.287 161.214 121.145L163.357 119.002C163.5 118.859 163.5 118.645 163.357 118.502C163.214 118.359 163 118.359 162.857 118.502L152.929 128.502C151.643 129.787 151.643 132.002 153.071 133.216C154.357 134.359 156.357 134.216 157.571 133.002L168.857 121.645C169.071 121.43 169.071 121.145 168.857 121.002C168.643 120.787 168.357 120.787 168.214 121.002L165.786 123.43C165.643 123.573 165.429 123.573 165.286 123.43C165.143 123.287 165.143 123.073 165.286 122.93L166.929 121.287C167.071 121.145 167.071 120.859 166.929 120.716C166.786 120.573 166.5 120.573 166.357 120.716L163.643 123.43C163.5 123.573 163.286 123.573 163.143 123.43C163 123.287 163 123.073 163.143 122.93L167.571 118.502C167.714 118.359 167.714 118.216 167.571 118.073C167.571 118.002 167.357 118.002 167.286 118.073Z"
                    fill="url(#paint_limit7_linear)"
                />
                <path
                    d="M156.952 132.51C157.901 131.562 157.901 130.024 156.952 129.076C156.004 128.127 154.466 128.127 153.518 129.076C152.569 130.024 152.569 131.562 153.518 132.51C154.466 133.459 156.004 133.459 156.952 132.51Z"
                    fill="#FFD65C"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.2136 15.0558C31.5413 15.0558 30.1857 13.7001 30.1857 12.0279C30.1857 10.3556 31.5413 9 33.2136 9C34.8858 9 36.2415 10.3556 36.2415 12.0279C36.2415 13.7001 34.8858 15.0558 33.2136 15.0558Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1205 15.0558C21.4483 15.0558 20.0926 13.7001 20.0926 12.0279C20.0926 10.3556 21.4483 9 23.1205 9C24.7928 9 26.1484 10.3556 26.1484 12.0279C26.1484 13.7001 24.7928 15.0558 23.1205 15.0558Z"
                fill="white"
            />
            <path
                opacity="0.132045"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1205 25.1495C21.4483 25.1495 20.0926 23.7939 20.0926 22.1216C20.0926 20.4494 21.4483 19.0938 23.1205 19.0938C24.7928 19.0938 26.1484 20.4494 26.1484 22.1216C26.1484 23.7939 24.7928 25.1495 23.1205 25.1495Z"
                fill="white"
            />
            <path
                opacity="0.648414"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1205 35.2433C21.4483 35.2433 20.0926 33.8876 20.0926 32.2154C20.0926 30.5431 21.4483 29.1875 23.1205 29.1875C24.7928 29.1875 26.1484 30.5431 26.1484 32.2154C26.1484 33.8876 24.7928 35.2433 23.1205 35.2433Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0278 15.0558C11.3555 15.0558 9.99989 13.7001 9.99989 12.0279C9.99989 10.3556 11.3555 9 13.0278 9C14.7 9 16.0557 10.3556 16.0557 12.0279C16.0557 13.7001 14.7 15.0558 13.0278 15.0558Z"
                fill="white"
            />
            <path
                opacity="0.132045"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0278 25.1495C11.3555 25.1495 9.99989 23.7939 9.99989 22.1216C9.99989 20.4494 11.3555 19.0938 13.0278 19.0938C14.7 19.0938 16.0557 20.4494 16.0557 22.1216C16.0557 23.7939 14.7 25.1495 13.0278 25.1495Z"
                fill="white"
            />
            <path
                opacity="0.648414"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0278 35.2433C11.3555 35.2433 9.99989 33.8876 9.99989 32.2154C9.99989 30.5431 11.3555 29.1875 13.0278 29.1875C14.7 29.1875 16.0557 30.5431 16.0557 32.2154C16.0557 33.8876 14.7 35.2433 13.0278 35.2433Z"
                fill="white"
            />
            <path
                opacity="0.132045"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M244.121 279.056C242.449 279.056 241.093 277.7 241.093 276.028C241.093 274.356 242.449 273 244.121 273C245.793 273 247.149 274.356 247.149 276.028C247.149 277.7 245.793 279.056 244.121 279.056Z"
                fill="white"
            />
            <path
                opacity="0.648414"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M244.121 289.15C242.449 289.15 241.093 287.794 241.093 286.122C241.093 284.449 242.449 283.094 244.121 283.094C245.793 283.094 247.149 284.449 247.149 286.122C247.149 287.794 245.793 289.15 244.121 289.15Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M234.028 289.15C232.356 289.15 231 287.794 231 286.122C231 284.449 232.356 283.094 234.028 283.094C235.7 283.094 237.056 284.449 237.056 286.122C237.056 287.794 235.7 289.15 234.028 289.15Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint_limit0_linear"
                    x1="130"
                    y1="0"
                    x2="130"
                    y2="300"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5244A5" />
                    <stop offset="0.9999" stopColor="#483D91" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint_limit1_linear"
                    x1="245.5"
                    y1="225.489"
                    x2="200.992"
                    y2="225.489"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4C4098" />
                    <stop offset="1" stopColor="#7B53D1" />
                </linearGradient>
                <linearGradient
                    id="paint_limit2_linear"
                    x1="260.015"
                    y1="220.49"
                    x2="203.019"
                    y2="220.49"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4B4097" />
                    <stop offset="1" stopColor="#996ACC" />
                </linearGradient>
                <linearGradient
                    id="paint_limit3_linear"
                    x1="15.9746"
                    y1="226.489"
                    x2="65.0089"
                    y2="226.489"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4C4098" />
                    <stop offset="1" stopColor="#7B53D1" />
                </linearGradient>
                <linearGradient
                    id="paint_limit4_linear"
                    x1="1.98326"
                    y1="221.49"
                    x2="64.9795"
                    y2="221.49"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4B4097" />
                    <stop offset="1" stopColor="#996ACC" />
                </linearGradient>
                <linearGradient
                    id="paint_limit5_linear"
                    x1="152.316"
                    y1="195.058"
                    x2="173.097"
                    y2="174.277"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00388622" stopColor="#FFD65C" />
                    <stop offset="1" stopColor="#3E1487" />
                </linearGradient>
                <linearGradient
                    id="paint_limit6_linear"
                    x1="99.8815"
                    y1="145.101"
                    x2="113.797"
                    y2="131.185"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00388622" stopColor="#FFD65C" />
                    <stop offset="1" stopColor="#3E1487" />
                </linearGradient>
                <linearGradient
                    id="paint_limit7_linear"
                    x1="152.94"
                    y1="133.044"
                    x2="167.784"
                    y2="118.2"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00388622" stopColor="#FFD65C" />
                    <stop offset="1" stopColor="#3E1487" />
                </linearGradient>
                <clipPath id="clip_limit0">
                    <rect width="45" height="45" fill="white" transform="translate(147 68)" />
                </clipPath>
                <clipPath id="clip_limit1">
                    <rect width="73.2" height="25.9" fill="white" transform="translate(105 204)" />
                </clipPath>
                <clipPath id="clip_limit2">
                    <rect width="23.8" height="22.4" fill="white" transform="translate(151 174)" />
                </clipPath>
                <clipPath id="clip_limit3">
                    <rect width="15.9375" height="15" fill="white" transform="translate(99 131)" />
                </clipPath>
                <clipPath id="clip_limit4">
                    <rect width="17" height="16" fill="white" transform="translate(152 118)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(LimitSVG);
