import React from 'react';
import { Grid } from '@material-ui/core';
import { getOptionColor } from 'utils/color';
import Option from 'components/option/Base';
import { HIGHLIGHT_TYPES, ROW_HEIGHT } from 'const/gridUI';
import { getHighlightWords } from 'utils/gridUI/cell';
import Highlighter from 'components/highlighter';

function SingleSelectRender({
    value,
    rowHeight = ROW_HEIGHT,
    rowIndex,
    columnIndex,
    options = [],
    customProperties,
    highlightPositions = [],
    filterValue
}) {
    if (!value) return null;
    if (typeof value !== 'string' && typeof value !== 'number') {
        return null;
    }

    const mapValue = value?.toString();

    const color = getOptionColor({ options, data: mapValue, customProperties });

    const highlightWords = getHighlightWords(mapValue, highlightPositions);

    return (
        <Grid
            container
            className={`cell_${rowIndex}_${columnIndex} justify-start items-start pointer-events-none`}
            style={{
                height: rowHeight
            }}
        >
            <Grid item>
                <Option
                    size="small"
                    label={
                        <Highlighter
                            className="inline child-link-highlight"
                            highlightClassName="highlight"
                            searchWords={[
                                {
                                    key: filterValue?.toString(),
                                    type: HIGHLIGHT_TYPES.DEFAULT
                                },
                                ...highlightWords?.map(value => ({
                                    key: value?.toString(),
                                    type: HIGHLIGHT_TYPES.DEFAULT
                                }))
                            ]}
                            autoEscape={true}
                            textToHighlight={mapValue + '' || ''}
                        />
                    }
                    color={color}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(SingleSelectRender);
