import React from 'react';

function ApiStatusSVG({ color = '#A69FC4', dotColor = '#A69FC4', size = 20, ...other }) {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <mask id="path-1-inside-1" fill="white">
                <rect x="1.23523" y="1.42639" width="17.5295" height="7.68349" rx="1" />
            </mask>
            <rect
                x="1.23523"
                y="1.42639"
                width="17.5295"
                height="7.68349"
                rx="1"
                stroke={color}
                strokeWidth="3"
                mask="url(#path-1-inside-1)"
            />
            <circle cx="5.78142" cy="5.26812" r="1.93133" fill={dotColor} />
            <mask id="path-3-inside-2" fill="white">
                <rect x="1.23523" y="10.89" width="17.5295" height="7.68349" rx="1" />
            </mask>
            <rect
                x="1.23523"
                y="10.89"
                width="17.5295"
                height="7.68349"
                rx="1"
                stroke={color}
                strokeWidth="3"
                mask="url(#path-3-inside-2)"
            />
            <circle cx="5.78142" cy="14.7317" r="1.93133" fill={dotColor} />
        </svg>
    );
}

export default React.memo(ApiStatusSVG);
