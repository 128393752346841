import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider, Dialog } from '@material-ui/core';
import { COLUMN_FORMAT_STATES, CREATE_COLUMN_TYPES, DEPENDENCY_STATUS, SOURCE_STATUS } from 'const/gridUI';
import ListItem from 'components/list/Item';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import SettingSVG from 'assets/images/svg/SettingSVG';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';
import FolderUploadSVG from 'assets/images/svg/FolderUploadSVG';
import AddColumnLeftSVG from 'assets/images/svg/AddColumnLeftSVG';
import AddColumnRightSVG from 'assets/images/svg/AddColumnRightSVG';
import TMStatusAprrovedSVG from 'assets/images/svg/localization/TMStatusAprrovedSVG';
import ApproveDependencySVG from 'assets/images/svg/dependencies/ApproveSVG';
import SourceStatusSVG from 'assets/images/svg/dependencies/SourceStatusSVG';
import DuplicateSVG from 'assets/images/svg/DuplicateSVG';
import { useDispatch } from 'react-redux';
import { COLOR_TYPES, SYSTEM_COLUMN_IDS } from 'const';
import { useTranslation } from 'react-i18next';
import { sendManualTrack } from 'tracker';
import * as gridUIActions from 'gridUI/actions';
import * as columnTypes from 'const/columnTypes';
import { useIsTmDisabled, useDependencyList } from 'hooks/gridUI';
import { isTempId } from 'utils/uuid';
import hexToRgba from 'hex-to-rgba';
import * as roleConst from 'auth/roleConst';
import AccessControl from 'auth/AccessControl';
import ColumnFormat from './ColumnFormat';
import { useWorkspaceRole, useRole } from 'hooks/auth/role';
import NestedMenuItem from 'components/menus/HoverMenu';
import { getSourceStatusLabelByStatus, getSourceStatusIcon } from 'utils/gridUI/dependency';
import NewColumnFormat from 'gridUI/table/grid/columns/NewColumnFormat';
import ConfirmBox from 'components/confirmBox/Base';
import i18n from 'i18n';
import PlayFileActions from '../cellOverlay/PlayFileActions';
import PlayAutomationMenu from '../cellOverlay/PlayAutomationMenu';
import FindInThisRange from '../cellOverlay/FindInThisRange';

const CONFIRM_TYPES = {
    SET_DEPENDENCY_UP_TO_DATE: i18n.t('dependency_mark_as_uptodate'),
    SET_DEPENDENCY_OUT_OF_DATE: i18n.t('dependency_mark_as_outofdate'),
    SET_DEPENDENCY_UNSET: i18n.t('dependency_mark_as_unset'),
    APPROVED_TM: i18n.t(`column_options_approve_tm`),
    DO_NOT_TRANSLATE: i18n.t(`do_not_translate`),
    LOCKED: i18n.t(`locked`),
    NOT_READY_FOR_TRANSLATION: i18n.t(`not_ready_for_translation`),
    READY_FOR_TRANSLATION: i18n.t(`ready_for_translation`),
    UNSET: i18n.t(`notSet`)
};

function ColumnSetting({
    onUpload,
    column,
    state,
    onChangeState,
    onClickAway,
    onDelete,
    isClickAwayColumnFormat,
    onClose
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const dependencies = useDependencyList();
    const { t } = useTranslation();
    const isTmDisabled = useIsTmDisabled();
    const workspaceRole = useWorkspaceRole();
    const roles = useRole();
    const [insertPosition, setInsertPosition] = React.useState(CREATE_COLUMN_TYPES.RIGHT);
    const [actionType, setActionType] = React.useState(null);
    const [isConfirming, setIsConfirming] = React.useState(false);
    const [isOpenConfirm, setIsOpenConfirm] = React.useState(false);

    const handleOpenConfirm = React.useCallback((e, type) => {
        setActionType(type);
    }, []);

    const handleCloseConfirm = React.useCallback(() => {
        setActionType(null);
    }, []);

    const openDeleteConfirm = React.useCallback(() => {
        setIsOpenConfirm(true);
    }, []);

    const handleCloseDeleteConfirm = React.useCallback(() => {
        setIsOpenConfirm(false);
    }, []);

    const canManageGridColumn = React.useMemo(() => {
        return roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_COLUMN] === roleConst.FULL;
    }, [roles]);

    const columnId = React.useMemo(() => {
        return column?.id;
    }, [column]);

    const isSystemColumn = React.useMemo(() => {
        return SYSTEM_COLUMN_IDS.includes(columnId);
    }, [columnId]);

    const isFileColumn = React.useMemo(() => {
        return column?.type === columnTypes.FILES;
    }, [column]);

    const isChildDependency = React.useMemo(() => {
        const dependenciesWithoutFake = dependencies?.filter(dpDc => !isTempId(dpDc?.id));
        const dpDc = dependenciesWithoutFake.find(dpDc => dpDc.child === columnId);
        return Boolean(dpDc) && dpDc.parent;
    }, [dependencies, columnId]);

    const isTargetLang = React.useMemo(() => {
        return isChildDependency && column?.type === columnTypes.TRANSLATION;
    }, [isChildDependency, column]);

    const isParentDependency = React.useMemo(() => {
        const dependenciesWithoutFake = dependencies?.filter(dpDc => !isTempId(dpDc?.id));
        const dpDc = dependenciesWithoutFake.find(dpDc => dpDc.parent === columnId);
        return Boolean(dpDc) && dpDc.child;
    }, [dependencies, columnId]);

    const handleHideColumn = React.useCallback(
        e => {
            sendManualTrack({
                type: `Hide Column`,
                customData: {
                    columnId
                }
            });
            dispatch(gridUIActions.changeColumnViewable({ columnId, viewable: false }));
            onClickAway && onClickAway(e);
        },
        [columnId, dispatch, onClickAway]
    );

    const handleInsertColumnBefore = React.useCallback(
        e => {
            setInsertPosition(CREATE_COLUMN_TYPES.LEFT);
            onChangeState(COLUMN_FORMAT_STATES.NEW_COLUMN);
        },
        [onChangeState]
    );

    const handleInsertColumnAfter = React.useCallback(
        e => {
            setInsertPosition(CREATE_COLUMN_TYPES.RIGHT);
            onChangeState(COLUMN_FORMAT_STATES.NEW_COLUMN);
        },
        [onChangeState]
    );

    const handleDuplicateColumnAfter = React.useCallback(
        e => {
            dispatch(
                gridUIActions.duplicateRelativeGridColumn({
                    columnId,
                    type: CREATE_COLUMN_TYPES.RIGHT,
                    successCallback: () => {
                        console.log('create column right success');
                    },
                    errorCallback: () => {
                        console.log('create column right failed');
                    }
                })
            );
            onClickAway && onClickAway(e);
        },
        [columnId, dispatch, onClickAway]
    );

    const handleConfirmAgree = React.useCallback(
        e => {
            setIsConfirming(true);

            if (actionType === CONFIRM_TYPES.APPROVED_TM) {
                dispatch(
                    gridUIActions.approveColumnTMStatus({
                        columnId,
                        error: () => {
                            setIsConfirming(false);
                        },
                        success: () => {
                            onClickAway && onClickAway(e);
                            setIsConfirming(false);
                        }
                    })
                );
                return;
            }

            if (actionType === CONFIRM_TYPES.SET_DEPENDENCY_UP_TO_DATE) {
                dispatch(
                    gridUIActions.approveColumnDependencyStatus({
                        columnId,
                        status: DEPENDENCY_STATUS.UP_TO_DATE,
                        error: () => {
                            setIsConfirming(false);
                        },
                        success: () => {
                            onClickAway && onClickAway(e);
                            setIsConfirming(false);
                        }
                    })
                );
                return;
            }

            if (actionType === CONFIRM_TYPES.SET_DEPENDENCY_UNSET) {
                dispatch(
                    gridUIActions.approveColumnDependencyStatus({
                        columnId,
                        status: DEPENDENCY_STATUS.UNSET,
                        error: () => {
                            setIsConfirming(false);
                        },
                        success: () => {
                            onClickAway && onClickAway(e);
                            setIsConfirming(false);
                        }
                    })
                );
                return;
            }

            if (actionType === CONFIRM_TYPES.SET_DEPENDENCY_OUT_OF_DATE) {
                dispatch(
                    gridUIActions.approveColumnDependencyStatus({
                        columnId,
                        status: DEPENDENCY_STATUS.OUT_OF_DATE,
                        error: () => {
                            setIsConfirming(false);
                        },
                        success: () => {
                            onClickAway && onClickAway(e);
                            setIsConfirming(false);
                        }
                    })
                );
            }

            if (actionType === CONFIRM_TYPES.DO_NOT_TRANSLATE) {
                sendManualTrack({
                    type: `Mark Source Status As ${getSourceStatusLabelByStatus({
                        status: SOURCE_STATUS.DO_NOT_TRANSLATE
                    })}`
                });
                dispatch(
                    gridUIActions.approveColumnStatus({
                        columnId,
                        status: SOURCE_STATUS.DO_NOT_TRANSLATE,
                        error: () => {
                            setIsConfirming(false);
                        },
                        success: () => {
                            onClickAway && onClickAway(e);
                            setIsConfirming(false);
                        }
                    })
                );
            }

            if (actionType === CONFIRM_TYPES.LOCKED) {
                sendManualTrack({
                    type: `Mark Source Status As ${getSourceStatusLabelByStatus({
                        status: SOURCE_STATUS.LOCKED
                    })}`
                });
                dispatch(
                    gridUIActions.approveColumnStatus({
                        columnId,
                        status: SOURCE_STATUS.LOCKED,
                        error: () => {
                            setIsConfirming(false);
                        },
                        success: () => {
                            onClickAway && onClickAway(e);
                            setIsConfirming(false);
                        }
                    })
                );
            }

            if (actionType === CONFIRM_TYPES.NOT_READY_FOR_TRANSLATION) {
                sendManualTrack({
                    type: `Mark Source Status As ${getSourceStatusLabelByStatus({
                        status: SOURCE_STATUS.NOT_READY_FOR_TRANSLATION
                    })}`
                });
                dispatch(
                    gridUIActions.approveColumnStatus({
                        columnId,
                        status: SOURCE_STATUS.NOT_READY_FOR_TRANSLATION,
                        error: () => {
                            setIsConfirming(false);
                        },
                        success: () => {
                            onClickAway && onClickAway(e);
                            setIsConfirming(false);
                        }
                    })
                );
            }

            if (actionType === CONFIRM_TYPES.READY_FOR_TRANSLATION) {
                sendManualTrack({
                    type: `Mark Source Status As ${getSourceStatusLabelByStatus({
                        status: SOURCE_STATUS.READY_FOR_TRANSLATION
                    })}`
                });
                dispatch(
                    gridUIActions.approveColumnStatus({
                        columnId,
                        status: SOURCE_STATUS.READY_FOR_TRANSLATION,
                        error: () => {
                            setIsConfirming(false);
                        },
                        success: () => {
                            onClickAway && onClickAway(e);
                            setIsConfirming(false);
                        }
                    })
                );
            }

            if (actionType === CONFIRM_TYPES.UNSET) {
                sendManualTrack({
                    type: `Mark Source Status As ${getSourceStatusLabelByStatus({
                        status: SOURCE_STATUS.UNSET
                    })}`
                });
                dispatch(
                    gridUIActions.approveColumnStatus({
                        columnId,
                        status: SOURCE_STATUS.UNSET,
                        error: () => {
                            setIsConfirming(false);
                        },
                        success: () => {
                            onClickAway && onClickAway(e);
                            setIsConfirming(false);
                        }
                    })
                );
            }
        },
        [dispatch, actionType, onClickAway, columnId]
    );

    const isNotShowMenu = React.useMemo(() => {
        return !canManageGridColumn && !isFileColumn && !(isTargetLang && !isTmDisabled) && !isChildDependency;
    }, [canManageGridColumn, isChildDependency, isFileColumn, isTargetLang, isTmDisabled]);

    React.useEffect(() => {
        if (isNotShowMenu) {
            onClickAway && onClickAway();
        }
    }, [isNotShowMenu, onClickAway]);

    if (COLUMN_FORMAT_STATES.MENU === state) {
        return (
            <Grid container direction="column">
                {!isSystemColumn && (
                    <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_COLUMN}>
                        <Grid item onClick={() => onChangeState(COLUMN_FORMAT_STATES.FORMAT)}>
                            <ListItem icon={<SettingSVG />} name={t('column_options_format')} />
                        </Grid>
                    </AccessControl>
                )}
                {workspaceRole === roleConst.CREATOR && (
                    <Grid item onClick={handleHideColumn}>
                        <ListItem icon={<EyeCloseIconSVG />} name={t('column_options_hide')} />
                    </Grid>
                )}

                <FindInThisRange id="find_in_this_column" onClose={onClose} />

                <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_COLUMN}>
                    <Grid item onClick={handleInsertColumnBefore}>
                        <ListItem icon={<AddColumnLeftSVG />} name={t(`insert_column_before`)} />
                    </Grid>
                </AccessControl>
                <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_COLUMN}>
                    <Grid item onClick={handleInsertColumnAfter}>
                        <ListItem icon={<AddColumnRightSVG />} name={t(`insert_column_after`)} />
                    </Grid>
                </AccessControl>

                {!isSystemColumn && (
                    <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_COLUMN}>
                        <Grid item onClick={handleDuplicateColumnAfter}>
                            <ListItem icon={<DuplicateSVG color="#78778B" />} name={t(`duplicate_column`)} />
                        </Grid>
                    </AccessControl>
                )}
                {isFileColumn && (
                    <Grid item onClick={onUpload}>
                        <ListItem icon={<FolderUploadSVG />} name={t('column_options_upload_folder')} />
                    </Grid>
                )}
                {isTargetLang && !isTmDisabled && (
                    <Grid item onClick={e => handleOpenConfirm(e, CONFIRM_TYPES.APPROVED_TM)}>
                        <ListItem icon={<TMStatusAprrovedSVG />} name={t('column_options_approve_tm')} />
                    </Grid>
                )}

                {isChildDependency && (
                    <>
                        <Grid item onClick={e => handleOpenConfirm(e, CONFIRM_TYPES.SET_DEPENDENCY_UP_TO_DATE)}>
                            <ListItem icon={<ApproveDependencySVG />} name={t('dependency_mark_as_uptodate')} />
                        </Grid>
                        <Grid item onClick={e => handleOpenConfirm(e, CONFIRM_TYPES.SET_DEPENDENCY_OUT_OF_DATE)}>
                            <ListItem
                                icon={<ApproveDependencySVG color={hexToRgba(theme.colors.sun, 0.8)} />}
                                name={t('dependency_mark_as_outofdate')}
                            />{' '}
                        </Grid>

                        <Grid item onClick={e => handleOpenConfirm(e, CONFIRM_TYPES.SET_DEPENDENCY_UNSET)}>
                            <ListItem
                                icon={<ApproveDependencySVG color={hexToRgba(theme.colors.brightRed, 0.8)} />}
                                name={t('dependency_mark_as_unset')}
                            />{' '}
                        </Grid>
                    </>
                )}

                {isParentDependency && (
                    <NestedMenuItem
                        icon={<SourceStatusSVG color={theme.colors.steel} />}
                        label={t('set_source_status')}
                        parentMenuOpen={true}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom'
                        }}
                    >
                        <Grid container direction="column">
                            <Grid item onClick={e => handleOpenConfirm(e, CONFIRM_TYPES.DO_NOT_TRANSLATE)}>
                                <ListItem
                                    icon={getSourceStatusIcon({
                                        status: SOURCE_STATUS.DO_NOT_TRANSLATE,
                                        size: 18
                                    })}
                                    name={t('do_not_translate')}
                                />
                            </Grid>
                            <Grid item onClick={e => handleOpenConfirm(e, CONFIRM_TYPES.NOT_READY_FOR_TRANSLATION)}>
                                <ListItem
                                    icon={getSourceStatusIcon({
                                        status: SOURCE_STATUS.NOT_READY_FOR_TRANSLATION,
                                        size: 18
                                    })}
                                    name={t('not_ready_for_translation')}
                                />
                            </Grid>

                            <Grid item onClick={e => handleOpenConfirm(e, CONFIRM_TYPES.READY_FOR_TRANSLATION)}>
                                <ListItem
                                    icon={getSourceStatusIcon({
                                        status: SOURCE_STATUS.READY_FOR_TRANSLATION,
                                        size: 18
                                    })}
                                    name={t('ready_for_translation')}
                                />
                            </Grid>
                            <Grid item onClick={e => handleOpenConfirm(e, CONFIRM_TYPES.LOCKED)}>
                                <ListItem
                                    icon={getSourceStatusIcon({
                                        status: SOURCE_STATUS.LOCKED,
                                        size: 18
                                    })}
                                    name={t('locked')}
                                />
                            </Grid>
                        </Grid>
                    </NestedMenuItem>
                )}

                <PlayFileActions columnIds={[columnId]} closePopup={onClose} />
                <PlayAutomationMenu defaultColumnIds={[column?.publicId]} closePopup={onClose} />

                {!isSystemColumn && (
                    <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_COLUMN}>
                        {({ isReadOnly }) => (
                            <>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item style={{ paddingTop: theme.spacing(2) }} onClick={openDeleteConfirm}>
                                    <ListItem icon={<DeleteSVG />} name={t('column_options_delete')} />
                                </Grid>
                            </>
                        )}
                    </AccessControl>
                )}

                <Dialog
                    style={{
                        zIndex: 1302
                    }}
                    id="confirm-column-box"
                    open={Boolean(actionType)}
                    onClose={handleCloseConfirm}
                >
                    <ConfirmBox
                        title={`Perform ${actionType}?`}
                        body={
                            <div className="body2">
                                This action will change all records in the column. Are you sure you want to continue?
                            </div>
                        }
                        handleCancel={handleCloseConfirm}
                        onClose={handleCloseConfirm}
                        handleAgreed={handleConfirmAgree}
                        agreeLabel="Confirm"
                        isLoading={isConfirming}
                    />
                </Dialog>

                <Dialog
                    open={isOpenConfirm}
                    onClose={handleCloseDeleteConfirm}
                    style={{
                        zIndex: 1302
                    }}
                >
                    <ConfirmBox
                        title={'Delete column'}
                        body={
                            <>
                                <p className="body1">Are you sure you want to delete this column?</p>{' '}
                                <p className="body1"></p>{' '}
                                <p className="body2">
                                    The column and its data will be totally removed from the Grid if you refresh the
                                    page and cannot be undone.
                                </p>
                            </>
                        }
                        onClose={handleCloseDeleteConfirm}
                        handleAgreed={onDelete}
                        handleCancel={handleCloseDeleteConfirm}
                        agreeLabel={'Yes'}
                        colorType={COLOR_TYPES.SECONDARY}
                    />
                </Dialog>
            </Grid>
        );
    } else if (COLUMN_FORMAT_STATES.NEW_COLUMN === state) {
        return (
            <NewColumnFormat type={insertPosition} columnId={columnId} onClose={onClose} onClickAway={onClickAway} />
        );
    }

    return (
        <ColumnFormat
            isClickAwayColumnFormat={isClickAwayColumnFormat}
            onClose={onClose}
            column={column}
            onClickAway={onClickAway}
        />
    );
}

export default React.memo(ColumnSetting);
