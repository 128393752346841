import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getBGByAction, getIconByAction, getColorByAction } from 'utils/color';
import { UPDATE, DELETE, CREATE, EXTEND_VIEW_TYPES } from 'const/gridUI';
import { parseJson } from 'utils/object';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import Tooltip from 'components/tooltip/Base';
import * as workspaceActions from 'workspaces/actions';
import { useUserGridSettings } from 'hooks/workspace';
import { useParams } from 'react-router-dom';
import { RESOURCE_TYPES, USER_SETTINGS } from 'const';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    diff: {
        marginTop: 8
    },
    wrapper: {
        padding: `0px 8px`,
        borderRadius: 4,
        background: props => getBGByAction({ action: props.action, theme, opacity: 0.1 }),
        width: 'auto'
    },
    showHideText: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    changes: {
        marginTop: 10,
        borderLeft: `1px solid ${theme.colors.border}`,
        padding: `6px 10px`
    },
    ellipsis: {
        cursor: 'pointer'
    }
}));

function getMessage({ action, length }) {
    switch (action) {
        case UPDATE:
            return length > 1 ? `${length} records updated` : i18n.t('1_record_updated');
        case DELETE:
            return length > 1 ? `${length} records deleted` : `1 record deleted`;
        case CREATE:
            return length > 1 ? `${length} records added` : i18n.t('1_record_added');
        default:
            return `default`;
    }
}

function getRecordDetailPrefix({ action }) {
    switch (action) {
        case UPDATE:
            return `Updated record`;
        case DELETE:
            return `Deleted record `;
        case CREATE:
            return `Added record `;
        default:
            return `default`;
    }
}

function RecordDiff({ action, extraJson, t }) {
    const theme = useTheme();
    const color = getColorByAction({ action, theme });
    const classes = useStyles({ action });
    let object = parseJson(extraJson);
    const recordIds = object?.recordIds;
    const length = recordIds?.length || object?.totalRecords;
    const message = getMessage({ action, length });
    const dispatch = useDispatch();
    const [isShowChanges, setIsShowChanges] = React.useState(false);
    const { workspaceId, dbId, branchId } = useParams();
    const userGridSetting = useUserGridSettings({ wsId: workspaceId, dbId, gridId: branchId });

    if (!object) return null;

    const openRecordHistory = recordId => {
        dispatch(
            gridUIActions.openRecordHistory({
                rowId: recordId,
                rowIndex: undefined,
                action,
                message: getRecordDetailPrefix({ action }),
                type: EXTEND_VIEW_TYPES.RECORD_HISTORY
            })
        );

        dispatch(
            workspaceActions.setUserSettings({
                dbId,
                workspaceId,
                resourceId: branchId,
                resourceType: RESOURCE_TYPES.GRID,
                value: {
                    ...userGridSetting,
                    [USER_SETTINGS.GRID.OPEN_EXTEND_VIEW]: true,
                    [USER_SETTINGS.GRID.EXTEND_VIEW_TYPE]: EXTEND_VIEW_TYPES.RECORD_HISTORY
                }
            })
        );
    };

    const toggleShowChanges = () => {
        setIsShowChanges(!isShowChanges);
    };

    return (
        <Grid container direction="column" wrap="nowrap">
            <Grid item>
                <Grid className={classes.diff} container direction="row" spacing={3} alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Grid className={classes.wrapper} container alignItems="center" wrap="nowrap" spacing={2}>
                            <Grid item>{getIconByAction({ action, theme })}</Grid>
                            <Grid item>
                                <p className="body2">{message}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isEmpty(recordIds) && isArray(recordIds) && (
                        <Grid item>
                            <p onClick={toggleShowChanges} className={'body2 cursor-pointer text-text-primary'}>
                                {isShowChanges ? t(`global_hide_changes`) : t(`global_show_changes`)}
                            </p>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {isShowChanges && !isEmpty(recordIds) && isArray(recordIds) && (
                <Grid item className={classes.changes}>
                    <Grid container direction="column" spacing={2} wrap="nowrap">
                        {recordIds.map(recordId => (
                            <Grid item key={recordId}>
                                <p className="body2 flex">
                                    <Tooltip title={t(`global_open_record_history`)}>
                                        <span
                                            onClick={() => openRecordHistory(recordId)}
                                            className={classes.ellipsis}
                                            style={{ color }}
                                        >
                                            {getRecordDetailPrefix({ action })}
                                        </span>
                                    </Tooltip>
                                </p>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(RecordDiff);
