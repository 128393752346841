import React, { useCallback, useMemo, useState } from 'react';
import Dialog from 'components/dialog/Dialog';
import DialogTitle from 'components/dialog/DialogTitle';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import DialogContent from 'components/dialog/DialogContent';
import { Grid } from '@material-ui/core';
import {
    useGetAutomations,
    useListAutomationExecutions,
    useListAutomationExecutionsRunningLength
} from 'hooks/gridUI/automation';
import OverflowTypography from 'components/typography/OverflowTypography';
import AvatarBase from 'components/avatar/Base';
import LogoSVG from 'assets/images/svg/webHook/LogoSVG';
import { getAutomationLogoUrl } from 'utils/images';
import classnames from 'classnames';
import Spinner from 'components/spinner/Base';
import CommentResolvedSVG from 'assets/images/svg/CommentResolvedSVG';
import UploadingErrorSVG from 'assets/images/svg/UploadingErrorSVG';

const useStyles = makeStyles(theme => ({
    ml8: { marginLeft: 8 },
    flx: {
        flex: 1
    },
    viewDetail: {
        color: theme.colors.fuchsiaBlue,
        cursor: 'pointer'
    },
    paper: {
        width: 502
    },
    header: {
        padding: `14px 30px 12px 14px`,
        '& p': {
            color: theme.colors.steel
        }
    },
    body: {
        padding: `0 5px`,
        background: theme.colors.paleGrey,
        height: 314,
        overflow: 'hidden auto',
        '&::-webkit-scrollbar': {
            width: `6px`,
            height: `120px`
        },
        '&::-webkit-scrollbar-track': {
            // background: `#C9C9CE`
        },
        '&::-webkit-scrollbar-thumb': {
            background: `#C9C9CE`,
            borderRadius: 50
        }
    },
    item: {
        height: 44,
        background: theme.colors.white,
        '&:nth-child(odd)': {
            background: theme.colors.ghostwhite
        }
    },
    itemWrapper: {
        height: '100%',
        padding: `0 24px`
    },
    itemName: {
        margin: `0 26px`
    },
    status: {
        marginLeft: 8,
        '&.success': {
            color: theme.colors.atlantis
        },
        '&.fail': {
            color: theme.colors.brightRed
        }
    },
    spinnerWrapper: {
        width: 20
    },
    statusIcon: {
        '& svg': {
            verticalAlign: 'middle'
        }
    },
    logo: {
        '& svg': {
            width: '100% !important',
            height: '100% !important'
        }
    },
    avatar: {
        background: 'transparent !important',
        border: '0 !important'
    }
}));

const ExecutionItem = React.memo(({ item }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isRunning = item.executionStatus === 'STARTED';
    const isSuccess = item.executionStatus === 'SUCCESS';
    const isFailed = item.executionStatus === 'FAILED';

    return (
        <Grid key={item.automationId} item className={classes.item}>
            <Grid className={classes.itemWrapper} container wrap="nowrap" alignItems="center">
                <Grid item className={classes.logo}>
                    <AvatarBase
                        src={getAutomationLogoUrl(item.iconFileId) || ''}
                        size="smaller"
                        className={classes.avatar}
                    >
                        <LogoSVG />
                    </AvatarBase>
                </Grid>
                <Grid item className={classnames(classes.flx, classes.itemName)}>
                    <OverflowTypography variant="body2" maxLines={1}>
                        {item.title}
                    </OverflowTypography>
                </Grid>
                <Grid item>
                    <Grid container wrap="nowrap" alignItems="center">
                        <Grid
                            item
                            className={classnames(classes.statusIcon, {
                                [classes.spinnerWrapper]: isRunning
                            })}
                        >
                            {isRunning ? (
                                <Spinner size={20} thick={2} rotateColor="#96C060" bgColor="#ECECEC" bgOpacity={1} />
                            ) : isSuccess ? (
                                <CommentResolvedSVG width="12" height="12" color="#96C060" />
                            ) : (
                                <UploadingErrorSVG width="12" height="12" />
                            )}
                        </Grid>
                        <Grid item>
                            <p
                                className={classnames(`body2 ml-2`, {
                                    success: isSuccess,
                                    fail: isFailed
                                })}
                            >
                                {t(isRunning ? 'global_running' : isSuccess ? 'global_done' : 'global_failed')}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

const AutomationExecutions = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [openExecutionsDialog, setOpenExecutionsDialog] = useState(false);

    const listAutomationExecutions = useListAutomationExecutions();
    const lengthListAutomationExecutionsRunning = useListAutomationExecutionsRunningLength();

    const automations = useGetAutomations();

    const mappingAutomation = useMemo(() => {
        if (!listAutomationExecutions?.length || !automations?.length) return [];
        return listAutomationExecutions.map(item => {
            const automation = automations.find(a => a.id === item.automationId) || {};
            item.title = automation.title;
            item.iconFileId = automation.iconFileId;
            return item;
        });
    }, [automations, listAutomationExecutions]);

    const handleOpenExecutionsDialog = useCallback(() => {
        setOpenExecutionsDialog(true);
    }, []);

    const handleCloseExecutionsDialog = useCallback(() => {
        setOpenExecutionsDialog(false);
    }, []);

    return (
        <>
            <Grid container wrap="nowrap" alignItems="center" justifyContent="center">
                {lengthListAutomationExecutionsRunning > 0 && (
                    <Grid item className={classes.spinnerWrapper}>
                        <Spinner size={20} thick={2} rotateColor="#96C060" bgColor="#ECECEC" bgOpacity={1} />
                    </Grid>
                )}
                <Grid item className={classes.ml8}>
                    <p className="body2">
                        <Trans
                            t={t}
                            i18nKey={
                                lengthListAutomationExecutionsRunning > 1
                                    ? 'automation_executions_running'
                                    : 'automation_execution_running'
                            }
                            values={{
                                count: lengthListAutomationExecutionsRunning || 0
                            }}
                            components={{
                                b: <b />,
                                h: <span className={classes.viewDetail} onClick={handleOpenExecutionsDialog} />
                            }}
                        />
                    </p>
                </Grid>
            </Grid>
            <Dialog open={openExecutionsDialog} PaperProps={{ className: classes.paper }}>
                <DialogTitle title={t('global_view_detail')} onClose={handleCloseExecutionsDialog} />
                <DialogContent>
                    <Grid className={classes.header} wrap="nowrap" container justifyContent="space-between">
                        <Grid item>
                            <p className="body2">{t('automation_name')}</p>
                        </Grid>
                        <Grid item>
                            <p className="body2">{t('global_status')}</p>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.body}>
                        {mappingAutomation.map(item => (
                            <ExecutionItem key={item.automationId} item={item} />
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default React.memo(AutomationExecutions);
