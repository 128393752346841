import React from 'react';
import Tooltip from 'components/tooltip/Base';
import { sendManualTrack } from 'tracker';
import PopperMenu from 'components/menus/Popper';
import AddRecordPopup from './AddRecordPopup';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import { ROW_HEIGHT } from 'const/gridUI';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

function AddRecordBelow({
    isDisabledCreateRecord,
    positionTop,
    positionBottom,
    nextRowId,
    rowIndex,
    previousRowId,
    style,
    addRecordRef
}) {
    const [recordAnchorEl, setRecordAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const addRecordBelow = React.useCallback(
        count => {
            dispatch(gridUIActions.addViewRecordsBelow({ count, successCallback: () => {}, errorCallback: () => {} }));
            setRecordAnchorEl(null);
        },
        [dispatch]
    );

    return (
        <>
            <Tooltip
                title={
                    isDisabledCreateRecord ? (
                        <p className="text-light text-center">
                            Creating record is not
                            <br />
                            available for the json file
                        </p>
                    ) : (
                        t('global_create_record')
                    )
                }
                placement="right"
            >
                <div
                    pst={positionTop}
                    psb={positionBottom}
                    prid={previousRowId}
                    nrid={nextRowId}
                    rindex={rowIndex}
                    style={{
                        ...style,
                        height: ROW_HEIGHT,
                        display: 'flex',
                        padding: 4
                    }}
                    onClick={() => sendManualTrack({ type: 'Add Multiple Records' })}
                >
                    <div
                        className={classNames(`flex w-full border border-grey-border rounded `, {
                            disabled: isDisabledCreateRecord
                        })}
                    >
                        <div
                            className="flex items-center justify-center bg-white cursor-pointer basis-[65%]"
                            onClick={() => addRecordBelow(1)}
                        >
                            +
                        </div>
                        <div
                            onClick={e => setRecordAnchorEl(e?.currentTarget)}
                            className={
                                'flex justify-center items-center bg-grey-ghost basis-[35%] cursor-pointer hover:bg-grey-light'
                            }
                        >
                            <span style={{ position: 'relative', top: -4 }}>...</span>
                        </div>
                    </div>
                </div>
            </Tooltip>
            {recordAnchorEl && (
                <PopperMenu
                    placement="right-end"
                    anchorEl={recordAnchorEl}
                    handleClickAway={() => setRecordAnchorEl(null)}
                    container={addRecordRef.current}
                >
                    <AddRecordPopup onClose={() => setRecordAnchorEl(null)} />
                </PopperMenu>
            )}
        </>
    );
}

export default AddRecordBelow;
