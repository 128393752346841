import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WorkspaceList from './WorkspaceList';
import AddWorkspaceSVG from 'assets/images/svg/AddWorkspaceSVG';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
    useWorkspaceList,
    useIsFetchingWorkspaces,
    useIsCreatingWorkspace,
    useIsFetchedWorkspaces,
    useFavoriteGridList,
    useFetchingFavoriteGridList
} from 'hooks/workspace';
// import WorkspaceListSkeleton from './WorkspaceListSkeleton';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import { setShowTemplates } from 'app/actions';
import * as workspaceAction from './actions';
import { useDispatch } from 'react-redux';
import { closeSnackbar } from 'notifier/actions';
import { useTranslation } from 'react-i18next';
import { defaultBorder } from 'tutorials/helpers';
// import { fetchWorkspaceRoleAndCompanyId } from 'auth/actions';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import Divider from 'components/divider/Base';
import { fetchFavoriteGridWorkspaces } from 'grids/actions';
import FavoriteList from './FavoriteList';
import { AutoSizer } from 'react-virtualized-dn';
import InviteMembers from 'permission/invitation/inviteMembers';
import CreateProjectModal from './CreateProjectModal';
import Dialog from 'components/dialog/Dialog';

export const HEIGHT_INVITE = 200;

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.colors.lightGreyBlue,
        fontWeight: 'normal'
    },
    accordion: {
        '&.MuiAccordion-root': {
            padding: 0,
            margin: 0,
            boxShadow: 'none',
            '&:before': {
                backgroundColor: 'transparent'
            }
        },
        overflow: 'auto'
    },
    panelSummary: {
        '&.MuiAccordionSummary-root': {
            minHeight: 0
        },
        '& .MuiAccordionSummary-content': {
            margin: '10px 0'
        }
    },
    favorite: {
        // height: HEIGHT_FAVORITE_LIST_GRID
    },
    project: {
        '&.MuiAccordionSummary-root': {
            minHeight: 0
        },
        '& .MuiAccordionSummary-content': {
            margin: '10px 0'
        }
    },
    panelDetail: {
        padding: 0
    },

    icon: {
        width: 18,
        height: 18,
        transform: props => !props.expanded && 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shortest
        })
    },
    iconFavorite: {
        width: 18,
        height: 18,
        transform: props => !props.expandedFavorite && 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shortest
        })
    },
    shortLine: {
        padding: theme.spacing(2, 3),
        position: 'relative',
        background: '#fff',
        bottom: -7,
        zIndex: 1111111
    },
    text: {
        color: theme.colors.lightGreyBlue,
        margin: theme.spacing(0, 3),
        fontSize: '14px'
    }
}));

function Workspace() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { search, pathname } = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(search);
    const workspaceList = useWorkspaceList();
    const favoriteGrids = useFavoriteGridList();
    const isFetchingWorkspaces = useIsFetchingWorkspaces();
    const isFetchedWorkspaces = useIsFetchedWorkspaces();
    const isFetchingFavorite = useFetchingFavoriteGridList();
    // const selectedWorkspaceId = useSelectedWorkspaceId();
    const params = useParams();
    const workspaceId = Number(params.workspaceId);
    const isCreatingWorkspace = useIsCreatingWorkspace();
    const isOpenTemplates = pathname.includes('/projects/') && searchParams.get('open') === 'templates';
    const [isOpenCreateProjectModal, setIsOpenCreateProjectModal] = React.useState(false);
    const [expanded, setExpanded] = React.useState(true);
    const [expandedFavorite, setExpandedFavorite] = React.useState(true);

    const classes = useStyles({ expanded, expandedFavorite });

    const handleModalOpen = e => {
        e.stopPropagation();
        setIsOpenCreateProjectModal(true);
    };
    const handleModalClose = () => setIsOpenCreateProjectModal(false);

    React.useEffect(() => {
        if (isFetchedWorkspaces) return;
        dispatch(workspaceAction.fetchWorkspaces({}));
    }, [dispatch, isFetchedWorkspaces]);

    React.useEffect(() => {
        if (isFetchingFavorite) return;
        dispatch(fetchFavoriteGridWorkspaces({}));
    }, [dispatch, isFetchingFavorite]);

    React.useEffect(() => {
        let workspaces_page = document.getElementById(`workspaces_page`);
        workspaces_page.addEventListener('contextmenu', function(e) {
            e.preventDefault();
        });
    }, []);

    React.useEffect(() => {
        return () => dispatch(closeSnackbar());
    }, [dispatch]);

    React.useEffect(() => {
        if (isOpenTemplates) {
            dispatch(setShowTemplates(true));
        }
    }, [dispatch, isOpenTemplates]);

    const createWorkspaceHandler = ({ name, description, region }) => {
        dispatch(
            workspaceAction.createWorkspace({
                body: { name, description, region },
                successCallback: newWorkspace => {
                    handleModalClose();
                    history.push(`/projects/${newWorkspace.id}`);
                },
                errorCallback: () => {
                    console.log('create project Failed');
                }
            })
        );
    };

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            id={'workspaces_page'}
            style={{ border: defaultBorder, height: '100%' }}
        >
            <Grid item container direction="column" wrap="nowrap" style={{ overflow: 'hidden' }}>
                <Grid item className={classes.favorite}>
                    <Accordion expanded={expandedFavorite} className={classes.accordion}>
                        <AccordionSummary
                            className={classes.panelSummary}
                            onClick={() => setExpandedFavorite(!expandedFavorite)}
                        >
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item style={{ display: 'flex' }}>
                                    <ArrowDownSVG className={classes.iconFavorite} />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} variant="h4">
                                        {t('favorite_grids')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>

                        <AccordionDetails className={classes.panelDetail}>
                            {/* {!isFetchingFavorite && <WorkspaceListSkeleton length={4} />} */}
                            {isFetchingFavorite && (
                                <FavoriteList
                                    workspaces={workspaceList}
                                    favoriteGrids={favoriteGrids}
                                    selectedWorkspaceId={workspaceId}
                                />
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item className={classes.shortLine}>
                    <Divider />
                </Grid>
                <Grid item className={classes.expand}>
                    <AutoSizer>
                        {({ width, height }) => {
                            return (
                                <div style={{ width, height, overflow: 'hidden auto' }}>
                                    {/* {isFetchingWorkspaces && 'loading...'} */}
                                    {!isFetchingWorkspaces && (
                                        <WorkspaceList workspaces={workspaceList} selectedWorkspaceId={workspaceId} />
                                    )}
                                </div>
                            );
                        }}
                    </AutoSizer>
                </Grid>
                <Grid item>
                    <Accordion expanded={expanded} className={classes.accordion}>
                        <AccordionSummary className={classes.project} onClick={() => setExpanded(!expanded)}>
                            <Grid item container alignItems="center" spacing={2}>
                                <Grid item style={{ display: 'flex' }}>
                                    <ArrowDownSVG className={classes.icon} />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} variant="h4">
                                        {t('dashboard_project_list_title')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <AccessControl view={roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE}>
                                {isReadOnly => (
                                    <Tooltip title="Create project">
                                        <Grid item tr-dt="Create Workspace">
                                            <AddWorkspaceSVG
                                                style={{
                                                    pointerEvents: isCreatingWorkspace ? 'none' : '',
                                                    cursor: 'pointer',
                                                    marginTop: 5
                                                }}
                                                id="addWsBtn"
                                                onClick={handleModalOpen}
                                            />
                                        </Grid>
                                    </Tooltip>
                                )}
                            </AccessControl>
                        </AccordionSummary>
                        <AccordionDetails className={classes.panelDetail}>
                            {/* {isFetchingWorkspaces && <WorkspaceListSkeleton />} */}
                            <WorkspaceList
                                isExpand={expandedFavorite}
                                workspaces={workspaceList}
                                selectedWorkspaceId={workspaceId}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Grid item style={{ flex: 1 }} />
            <Grid item>
                <InviteMembers />
            </Grid>
            <Dialog className={classes.dialog} open={isOpenCreateProjectModal} onClose={handleModalClose}>
                <CreateProjectModal onClose={handleModalClose} onSubmit={createWorkspaceHandler} />
            </Dialog>
        </Grid>
    );
}

export default Workspace;
