import React from 'react';
import { Grid } from '@material-ui/core';
import { formatDateTimeLocal } from 'utils/gridUI/formatData';
import { ROW_HEIGHT } from 'const/gridUI';

function DateRender({ value, rowHeight = ROW_HEIGHT, rowIndex, columnIndex, rowId, ...rest }) {
    if (!value || value.length === 0) return null;
    const [date, time] = formatDateTimeLocal(value);
    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
            className={`cell_${rowIndex}_${columnIndex} pointer-events-none RECORD_${rowId}`}
            style={{ ...rest?.style, height: rowHeight }}
            {...rest}
        >
            <Grid item>
                <p
                    className={`body2 cell_${rowIndex}_${columnIndex} w-full overflow-hidden text-ellipsis whitespace-pre-wrap table-cell align-top text-left leading-6`}
                >
                    {date}
                </p>
            </Grid>
            <Grid item>
                <p
                    className={`body2 cell_${rowIndex}_${columnIndex} w-full overflow-hidden text-ellipsis whitespace-pre-wrap table-cell align-top text-left leading-6`}
                >
                    {time}
                </p>
            </Grid>
        </Grid>
    );
}

export default React.memo(DateRender);
