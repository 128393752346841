import React from 'react';

function SettingSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.709 8.6L14.809 9.6C15.109 9.8 15.209 10.2 14.909 10.5L13.609 12.9C13.409 13.2 13.009 13.3 12.709 13.2L11.309 12.8C11.209 13 11.009 13.1 10.809 13.2L10.209 13.5L9.90902 14.9C9.90902 15.2 9.60902 15.5 9.20902 15.5H6.50902C6.10902 15.5 5.80902 15.3 5.80902 14.9L5.50902 13.5C5.00902 13.3 4.70902 13.1 4.30902 12.8L2.90902 13.3C2.50902 13.4 2.20902 13.3 2.00902 13L0.609017 10.6C0.509017 10.3 0.509017 10 0.709017 9.7L1.80902 8.7V7.4L0.709017 6.4C0.509017 6.2 0.409017 5.8 0.609017 5.5L1.90902 3.1C2.10902 2.8 2.50902 2.7 2.80902 2.8L4.20902 3.2C4.30902 3.15 4.40902 3.075 4.50902 3C4.60902 2.925 4.70902 2.85 4.80902 2.8C4.90902 2.75 4.98402 2.7 5.05902 2.65C5.13402 2.6 5.20902 2.55 5.30902 2.5L5.60902 1.1C5.70902 0.8 6.00902 0.5 6.40902 0.5H9.10902C9.40902 0.5 9.80902 0.7 9.80902 1.1L10.209 2.5C10.409 2.6 10.609 2.7 10.709 2.9C10.809 3 11.009 3.1 11.209 3.2L12.609 2.7C13.009 2.6 13.309 2.7 13.509 3L14.909 5.4C15.109 5.7 15.109 6.1 14.809 6.3L13.709 7.3V8.6ZM11.109 11.1L12.609 11.6L13.409 10.4L12.209 9.3L12.309 8.5V7.5L12.109 6.7L13.309 5.6L12.509 4.3L11.009 4.8L10.409 4.4C10.109 4.2 9.80902 4 9.50902 3.9L8.80902 3.6L8.40902 2H6.90902L6.60902 3.6L5.90902 3.9C5.80902 3.9 5.60902 4 5.50902 4.1C5.45902 4.15 5.38402 4.2 5.30902 4.25C5.23402 4.3 5.15902 4.35 5.10902 4.4L4.50902 4.9L3.00902 4.4L2.20902 5.7L3.40902 6.8L3.30902 7.6V8.6L3.40902 9.3L2.20902 10.4L3.00902 11.7L4.50902 11.2L5.20902 11.7C5.30902 11.8 5.40902 11.9 5.60902 12C5.65902 12.05 5.73402 12.075 5.80902 12.1C5.88402 12.125 5.95902 12.15 6.00902 12.2L6.70902 12.5L7.10902 14H8.60902L8.90902 12.4L9.60902 12.1C9.90902 12 10.209 11.8 10.509 11.6L11.109 11.1ZM4.80859 8C4.80859 6.4 6.10859 5 7.80859 5C9.40859 5 10.8086 6.3 10.8086 8C10.8086 9.6 9.50859 11 7.80859 11C6.20859 11 4.80859 9.7 4.80859 8ZM6.30859 8C6.30859 8.8 6.90859 9.5 7.80859 9.5C8.60859 9.5 9.30859 8.9 9.30859 8.1V8C9.30859 7.2 8.60859 6.5 7.80859 6.5C7.00859 6.5 6.30859 7.2 6.30859 8Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(SettingSVG);
