import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import TwoFactorLayout from 'permission/twoFactor/common/TwoFactorLayout';
import { CircularProgress, Grid, Typography, Link } from '@material-ui/core';
import { getSignInFactorStatusApi, resendOtpEmailApi, twoFactorSignInApi } from 'services/auth';
import ws from 'socket';
import { useDispatch } from 'react-redux';
import * as authActions from 'auth/actions';
import InputText from 'components/inputs/InputText';
import ButtonBase from 'components/button/Base';
import { enqueueSnackbar } from 'notifier/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    body: {
        width: '35%',
        margin: 'auto',
        marginTop: 100
    },
    title: {
        fontWeight: 'normal'
    }
}));

const CONTENT_TYPE = {
    APP_OTP: 'APP_OTP',
    EMAIL_OTP: 'EMAIL_OTP',
    BACKUP_CODE: 'BACKUP_CODE',
    HELP: 'HELP'
};

function LoginUserFactorSetup() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const [userFactor, setUserFactor] = React.useState({});
    const [contentType, setContentType] = React.useState(CONTENT_TYPE.APP_OTP);
    const [error, setError] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const [backupCode, setBackupCode] = React.useState('');
    const isValidOtp = /^\d{6}$/gm.test(otp);

    const intendedRoute = React.useMemo(() => {
        return location.state?.intendedRoute;
    }, [location]);

    React.useEffect(() => {
        const fetchFactorStatus = async () => {
            try {
                setIsLoading(true);
                const factorStatus = await getSignInFactorStatusApi();
                factorStatus?.enabledViaEmail
                    ? setContentType(CONTENT_TYPE.EMAIL_OTP)
                    : setContentType(CONTENT_TYPE.APP_OTP);
                setUserFactor(factorStatus);
                setIsLoading(false);
            } catch (error) {
                console.log(error.message);
                setIsLoading(false);
            }
        };
        fetchFactorStatus();
    }, []);

    const backToSignInPage = () => {
        ws.disconnect();
        dispatch(
            authActions.logOut({
                successCallback: () => {
                    history.push('/signin');
                }
            })
        );
    };

    const handleSubmit = async (e, otp) => {
        e.preventDefault();
        try {
            setError('');
            setIsLoading(true);
            await twoFactorSignInApi(otp);
            dispatch(
                authActions.fetchMe({
                    successCallback: () => {
                        setIsLoading(false);
                        history.push({
                            pathname: intendedRoute || '/companies',
                            search: location.search
                        });
                    }
                })
            );
        } catch (error) {
            setError(error.originalMessage);
            setIsLoading(false);
        }
    };

    const goToHelp = () => setContentType(CONTENT_TYPE.HELP);
    const goToAppOtp = () => setContentType(CONTENT_TYPE.APP_OTP);
    const goToEmailOtp = () => setContentType(CONTENT_TYPE.EMAIL_OTP);
    const goToBackupCode = () => setContentType(CONTENT_TYPE.BACKUP_CODE);

    const resendOtpEmail = async () => {
        try {
            await resendOtpEmailApi();
            dispatch(
                enqueueSnackbar({
                    message: t('code_sent'),
                    type: 'info'
                })
            );
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleClickEmailVerify = () => {
        goToEmailOtp();
        resendOtpEmail();
    };

    return (
        <TwoFactorLayout>
            {(contentType === CONTENT_TYPE.APP_OTP || contentType === CONTENT_TYPE.EMAIL_OTP) && (
                <Grid item container direction="column" justify="center" className={classes.body}>
                    <form onSubmit={e => handleSubmit(e, otp)}>
                        <Grid item style={{ marginTop: 50 }}>
                            <Typography variant="h2" className={classes.title}>
                                {t('two_factor_authentication')}
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginTop: 30 }}>
                            <Typography variant="body2" className={classes.title}>
                                Enter the code from your{' '}
                                {contentType === CONTENT_TYPE.APP_OTP ? 'authenticator app' : 'email'} to pass the
                                two-factor authentication
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginTop: 18 }}>
                            <Typography variant="body1">{t('6_digits_code')}</Typography>
                        </Grid>
                        <Grid item style={{ marginTop: 14 }}>
                            <InputText
                                name="code"
                                value={otp}
                                onChange={e => setOtp(e.target.value)}
                                placeholder={t('enter_code')}
                                error={Boolean(error)}
                                errorText={error}
                            />
                        </Grid>
                        {contentType === CONTENT_TYPE.EMAIL_OTP && (
                            <Grid style={{ marginTop: 14, marginLeft: 'auto' }}>
                                <Link style={{ cursor: 'pointer' }} onClick={resendOtpEmail}>
                                    {t('resend_code')}
                                </Link>
                            </Grid>
                        )}
                        <Grid item container style={{ marginTop: 14 }} alignItems="center">
                            <Grid item>
                                <Link style={{ cursor: 'pointer' }} onClick={goToHelp}>
                                    Have a problem?
                                </Link>
                            </Grid>
                            <Grid item style={{ marginLeft: 'auto' }}>
                                <ButtonBase variant="outlined" width={100} onClick={backToSignInPage}>
                                    {t('global_back')}
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                <ButtonBase
                                    disabled={!isValidOtp || isLoading}
                                    variant="contained"
                                    style={{ marginLeft: 8 }}
                                    width={100}
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            )}
            {contentType === CONTENT_TYPE.BACKUP_CODE && (
                <Grid item container direction="column" justify="center" className={classes.body}>
                    <form onSubmit={e => handleSubmit(e, backupCode)}>
                        <Grid item style={{ marginTop: 50 }}>
                            <Typography variant="h2" className={classes.title}>
                                {t('two_factor_authentication')}
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginTop: 30 }}>
                            <Typography variant="body2" className={classes.title}>
                                Enter 1 of your 5 backup codes to pass the two-factor authentication
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginTop: 18 }}>
                            <Typography variant="body1">{t('backup_code')}</Typography>
                        </Grid>
                        <Grid item style={{ marginTop: 14 }}>
                            <InputText
                                name="code"
                                value={backupCode}
                                onChange={e => setBackupCode(e.target.value)}
                                placeholder="Verification code"
                                error={Boolean(error)}
                                errorText={error}
                            />
                        </Grid>
                        <Grid item container style={{ marginTop: 14 }} alignItems="center">
                            <Grid item>
                                <Link style={{ cursor: 'pointer' }} onClick={goToHelp}>
                                    Have a problem?
                                </Link>
                            </Grid>
                            <Grid item style={{ marginLeft: 'auto' }}>
                                <ButtonBase variant="outlined" width={100} onClick={goToHelp}>
                                    {t('global_back')}
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                <ButtonBase
                                    disabled={!backupCode || isLoading}
                                    variant="contained"
                                    style={{ marginLeft: 8 }}
                                    width={100}
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            )}
            {contentType === CONTENT_TYPE.HELP && (
                <Grid item container direction="column" justify="center" className={classes.body}>
                    <Grid item style={{ marginTop: 50 }}>
                        <Typography variant="h2" className={classes.title}>
                            {t('two_factor_authentication')}
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: 30 }}>
                        <Typography variant="body2" className={classes.title}>
                            Here are the other ways you can get an authentication code
                        </Typography>
                    </Grid>
                    {userFactor.enabledViaApp && (
                        <Grid item style={{ marginTop: 24 }}>
                            <ButtonBase variant="outlined" fullWidth onClick={goToAppOtp}>
                                Use an authenticator app
                            </ButtonBase>
                        </Grid>
                    )}
                    {userFactor.enabledViaEmail && (
                        <Grid item style={{ marginTop: 14 }}>
                            <ButtonBase variant="outlined" fullWidth onClick={handleClickEmailVerify}>
                                Use an email address
                            </ButtonBase>
                        </Grid>
                    )}
                    <Grid item style={{ marginTop: 14 }}>
                        <ButtonBase variant="outlined" fullWidth onClick={goToBackupCode}>
                            Use a backup code
                        </ButtonBase>
                    </Grid>
                    <Grid item style={{ marginTop: 24 }}>
                        <Link
                            style={{ cursor: 'pointer' }}
                            href="https://help.gridly.com/hc/en-us/requests/new"
                            target="_blank"
                        >
                            Still having problems? Get help
                        </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 14 }}>
                        <Link style={{ cursor: 'pointer' }} onClick={backToSignInPage}>
                            Back to sign in
                        </Link>
                    </Grid>
                </Grid>
            )}
        </TwoFactorLayout>
    );
}

export default LoginUserFactorSetup;
