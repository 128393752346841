import React, { useCallback, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Grid, useTheme } from '@material-ui/core';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import ArrowUpSVG from 'assets/images/svg/ArrowUpSVG';
import PlayOutlinedSVG from 'assets/images/svg/PlayOutlinedSVG';
import ButtonBase from 'components/button/Base';
import PopperMenu from 'components/menus/Popper';
import { useGetAutomationDetail } from 'hooks/gridUI/automation';
import { useTranslation } from 'react-i18next';
import Search from 'components/search';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import { useDispatch } from 'react-redux';
import { getAutomationHistory, testAutomation } from '../action';
import Tooltip from 'components/tooltip/Base';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import RunSVG from 'assets/images/svg/webHook/RunSVG';
import { DISABLED_OPACITY } from 'const/style';
import { ACTION_STATUS } from '../const';
import TooltipActionDisable from './TooltipActionDisable';
import { DEBOUNCE_TIME_SEARCHING, WEB_HOOK_TRIGGERS_ACTIONS_LABEL } from 'const/gridUI';
import IOSSwitch from 'components/switches/IOS';
import { setAutomationDetail } from '../action';
import { updateAutomation } from '../action';
import { AUTOMATION_STATUS } from '../const';
import { getAutomationStatusName } from '../const';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    textNoWrap: {
        whiteSpace: 'nowrap',
        margin: '0 2px 0 4px'
    },
    iconWrapper: {
        display: 'inline',
        '& svg': {
            verticalAlign: 'bottom'
        }
    },
    paperClassName: {
        padding: '10px 0 0',
        minWidth: 260
    },
    searchWrapper: {
        padding: '0 10px',
        marginBottom: 10
    },
    item: {
        padding: '0 20px',
        minHeight: 36,
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    itemName: {
        marginLeft: 8,
        whiteSpace: 'nowrap'
    },
    list: {
        maxHeight: 180,
        overflow: 'auto'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    testRule: {
        marginRight: theme.spacing(2),
        verticalAlign: 'text-top',
        display: 'inline-flex'
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    errorBox: {
        background: theme.colors.ghostwhite,
        borderRadius: 4,
        padding: `10px 14px`,
        wordBreak: 'break-all',
        maxHeight: 250,
        overflow: 'hidden auto'
    },
    buttonWrapper: {
        position: 'relative'
    },
    status: {
        color: theme.colors.lightGreyBlue
    },
    statusActive: {
        color: '#74BE22'
    }
}));

const TestAutomation = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const rootRef = useRef();
    const timeout = useRef();
    const [search, setSearch] = useState('');
    const [anchorEl, setAnchorEl] = useState();
    const [isTesting, setIsTesting] = useState(false);
    const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const automationDetail = useGetAutomationDetail();
    const triggerRef = useRef();

    const disableTest = useMemo(() => {
        return (
            !automationDetail?.triggerNodes?.filter(el => el.status === ACTION_STATUS.ENABLED)?.length ||
            !automationDetail?.nodes?.filter(el => el.status === ACTION_STATUS.ENABLED)?.length
        );
    }, [automationDetail]);

    const mapTriggerName = useMemo(() => {
        return (automationDetail.triggerNodes || []).map(el => ({
            ...el,
            name: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[el.trigger]
        }));
    }, [automationDetail]);

    const filterOptions = useMemo(() => {
        return mapTriggerName.filter(el => el.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1);
    }, [mapTriggerName, search]);

    const stopPropagation = useCallback(e => {
        if (!e) return;
        e.preventDefault();
        e.stopPropagation();
        e.persist();
    }, []);

    const onClickTestAutomation = useCallback(
        e => {
            stopPropagation(e);
            setAnchorEl(prev => (prev ? null : rootRef.current));
        },
        [stopPropagation]
    );

    const onSearch = useCallback(e => {
        const value = e.target.value;
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            setSearch(value);
        }, 800);
    }, []);

    const handleClickAway = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleCloseErrorModal = useCallback(() => {
        setIsOpenErrorModal(false);
        setErrorMessage('');
        triggerRef.current = null;
    }, []);

    const handleOpenErrorModal = useCallback(() => setIsOpenErrorModal(true), []);

    const handleClickItem = useCallback(
        (e, trigger) => {
            stopPropagation(e);
            if (isTesting) return;
            setIsTesting(true);
            handleClickAway();
            dispatch(
                testAutomation({
                    automationId: automationDetail.id,
                    body: {
                        trigger
                    },
                    successCallback: () => {
                        setIsTesting(false);
                        handleCloseErrorModal();
                        dispatch(
                            getAutomationHistory({
                                automationId: automationDetail.id
                            })
                        );
                    },
                    errorCallback: message => {
                        triggerRef.current = trigger;
                        dispatch(
                            getAutomationHistory({
                                automationId: automationDetail.id
                            })
                        );
                        setTimeout(() => {
                            setIsTesting(false);
                            handleOpenErrorModal();
                            setErrorMessage(message);
                        }, DEBOUNCE_TIME_SEARCHING);
                    }
                })
            );
        },
        [
            stopPropagation,
            isTesting,
            handleClickAway,
            dispatch,
            automationDetail.id,
            handleOpenErrorModal,
            handleCloseErrorModal
        ]
    );

    const onChangeStatus = useCallback(
        (e, value) => {
            const status = !value ? AUTOMATION_STATUS.INACTIVE : AUTOMATION_STATUS.ACTIVE;
            const oldAutomationDetail = { ...automationDetail };
            dispatch(
                setAutomationDetail({
                    ...automationDetail,
                    status
                })
            );
            dispatch(
                updateAutomation({
                    id: automationDetail.id,
                    body: {
                        status
                    },
                    errorCallback: () => {
                        dispatch(setAutomationDetail(oldAutomationDetail));
                    }
                })
            );
        },
        [automationDetail, dispatch]
    );

    return (
        <>
            <Tooltip title={disableTest ? t('automation_disable_test_hint') : ''}>
                <Grid item className={classes.root} ref={rootRef}>
                    <ButtonBase
                        className={classnames({
                            [classes.disabled]: disableTest
                        })}
                        variant="outlined"
                        disabled={disableTest || isTesting}
                        onClick={onClickTestAutomation}
                    >
                        <Grid item className={classes.iconWrapper}>
                            <PlayOutlinedSVG color={theme.colors.steel} />
                        </Grid>
                        <p className="prose inline-block prose-sm font-semibold whitespace-nowrap my-0 mx-1">
                            {t('automation_test_automation')}
                        </p>
                        <Grid item className={classes.iconWrapper}>
                            {anchorEl ? (
                                <ArrowUpSVG color={theme.colors.steel} />
                            ) : (
                                <ArrowDownSVG color={theme.colors.steel} />
                            )}
                        </Grid>
                    </ButtonBase>
                    {isTesting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Grid>
            </Tooltip>
            {anchorEl && (
                <PopperMenu
                    className={classes.popperRoot}
                    anchorEl={anchorEl}
                    container={anchorEl}
                    placement="bottom-end"
                    paperClassName={classes.paperClassName}
                    handleClickAway={handleClickAway}
                >
                    <Grid item>
                        <Grid item className={classes.searchWrapper}>
                            <Search
                                background={theme.colors.paleGrey}
                                placeholder={t('automation_payload_search_variables')}
                                onChange={onSearch}
                            />
                        </Grid>
                        <Grid item className={classes.list}>
                            {filterOptions.map(el => (
                                <TooltipActionDisable key={el.id} disabled={el.status === ACTION_STATUS.DISABLED}>
                                    <Grid
                                        item
                                        container
                                        alignItems="center"
                                        wrap="nowrap"
                                        className={classnames(classes.item)}
                                        onClick={e => handleClickItem(e, el.trigger)}
                                    >
                                        <Grid item>
                                            <WebHookLabel size="small" type={el.trigger} />
                                        </Grid>
                                        <Grid item>
                                            <p className="body2 ml-2 whitespace-nowrap">{el.name}</p>
                                        </Grid>
                                    </Grid>
                                </TooltipActionDisable>
                            ))}
                        </Grid>
                    </Grid>
                </PopperMenu>
            )}
            <Dialog open={isOpenErrorModal} onClose={handleCloseErrorModal}>
                <ConfirmBox
                    title={t(`trigger_test_rule_failed_title`)}
                    body={
                        <Grid container direction="column">
                            <Grid item className={classes.spacing}>
                                <p className="body2" component="div">
                                    {t(`trigger_test_rule_failed_subtitle`, { title: automationDetail.title })}
                                </p>
                            </Grid>
                            <Grid item>
                                <Grid container className={classes.errorBox}>
                                    <p className="body2" component="div">
                                        {errorMessage === 'Automation is inactive.' ? (
                                            <Grid container direction="column" wrap="nowrap" spacing={2}>
                                                <Grid item>{t('automation_test_inactive_content')}</Grid>
                                                <Grid item>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item>
                                                            <b>{t('automation_status')}:</b>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                wrap="nowrap"
                                                                spacing={1}
                                                                alignItems="center"
                                                            >
                                                                <Grid item>
                                                                    <IOSSwitch
                                                                        checked={
                                                                            automationDetail?.status ===
                                                                            AUTOMATION_STATUS.ACTIVE
                                                                        }
                                                                        disabled={isTesting}
                                                                        onChange={onChangeStatus}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className={classnames(classes.status, {
                                                                        [classes.statusActive]:
                                                                            automationDetail?.status ===
                                                                            AUTOMATION_STATUS.ACTIVE
                                                                    })}
                                                                >
                                                                    {getAutomationStatusName(automationDetail?.status)}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            errorMessage
                                        )}
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    handleCancel={e => handleClickItem(e, triggerRef.current)}
                    onClose={handleCloseErrorModal}
                    handleAgreed={handleCloseErrorModal}
                    disagreeWidth={150}
                    disagreeLabel={
                        <Grid item className={classes.buttonWrapper}>
                            <RunSVG
                                className={classnames(classes.testRule, {
                                    [classes.disabled]: isTesting
                                })}
                            />{' '}
                            Test Again
                            {isTesting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Grid>
                    }
                    agreeLabel={t(`global_ok`)}
                />
            </Dialog>
        </>
    );
};

export default React.memo(TestAutomation);
