import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import {
    createLQA,
    getLQAModelDefault,
    getLQAModels,
    getLQATickets,
    requestUpdateLQATicket,
    getLQATicketDetail,
    commentLQATicket,
    deleteComment,
    updateComment,
    deleteTicket,
    addProjectToLQAApi,
    removeProjectToLQAApi,
    setDefaultLqaApi,
    getLQATicketsResponse,
    exportLQAApi
} from 'services/lqa';
import { updateData } from './data';
import uuidv1 from 'uuid/v1';
import * as statusActions from './status';
import i18n from 'i18n';
import { getCellData } from 'utils/gridUI/cell';
import { checkIsTicketOpen } from 'hooks/gridUI';
import { LQA_TICKET_STATUS } from 'const/gridUI';
import { downloadFileFromResponse } from 'utils/fileUtils';

export function fetchLQAModels({ successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const responseData = await getLQAModels();
            dispatch(setLQAModels(responseData));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchLQADefault({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { workspaceId } = gridUI;
            const responseData = await getLQAModelDefault(workspaceId);
            dispatch(setLQAModelDefault(responseData));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function createLQATicket({ body, cellData, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { currentView } = gridUI;
            const responseData = await createLQA(body, currentView?.id);
            successCallback && successCallback(responseData);
            const newData = {
                [body.recordId]: {
                    [body.columnId]: {
                        ...cellData,
                        _ticket: (cellData._ticket || 0) + 1
                    }
                }
            };
            dispatch(updateData({ newData }));
            dispatch(setLqaResetQuery(true));
            dispatch(resetDefaultLQA({ total: false }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function updateLQATicket({ ticket, updateStatus = false, snackbarMessage, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        try {
            dispatch(statusActions.registerDoingAction({ actionId }));
            const { gridUI } = getState();
            const { currentView } = gridUI;
            const responseData = await requestUpdateLQATicket(ticket, currentView?.id);
            successCallback && successCallback(responseData);
            if (updateStatus) {
                dispatch(
                    enqueueSnackbar({
                        message: snackbarMessage,
                        type: 'info'
                    })
                );
                const { gridUI } = getState();
                const { data } = gridUI;
                const cellData = getCellData({ data, rowId: ticket.recordId, columnId: ticket.columnId });
                if (cellData) {
                    const oldTicket = cellData._ticket || 0;
                    const newData = {
                        [ticket.recordId]: {
                            [ticket.columnId]: {
                                ...cellData,
                                _ticket: checkIsTicketOpen(ticket.status)
                                    ? oldTicket + 1
                                    : oldTicket > 0
                                    ? oldTicket - 1
                                    : oldTicket
                            }
                        }
                    };
                    dispatch(updateData({ newData }));
                }
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        } finally {
            dispatch(statusActions.removeDoingAction({ actionId }));
        }
    };
}

export function fetchLQATickets({ params, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const responseData = await getLQATickets(params);
            successCallback && successCallback(responseData);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchLQATicketsTotal({
    dbId,
    viewId,
    params,
    appending = false,
    storeOnlyTotal = false,
    totalKey = '',
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        try {
            if (!dbId || !viewId) return;
            const response = await getLQATicketsResponse({ dbId, viewId, ...params });
            successCallback && successCallback(response?.data);
            const { gridUI: gridUIAfterFetch } = getState();
            const { lqaTicket } = gridUIAfterFetch;
            if (storeOnlyTotal) {
                dispatch(setLqaTotal({ [totalKey]: Number(response?.headers?.['x-total-count'] || 0) }));
            } else {
                dispatch(
                    setLqaTicketAndTotal({
                        lqaTicket: {
                            [totalKey]: appending
                                ? [...lqaTicket[totalKey], ...(response?.data || [])]
                                : response?.data || []
                        },
                        lqaTotal: { [totalKey]: Number(response?.headers?.['x-total-count'] || 0) }
                    })
                );
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchLQATicketDetail({ ticketId, successCallback, errorCallback, finallyCallback }) {
    return async function(dispatch) {
        try {
            const responseData = await getLQATicketDetail(ticketId);
            successCallback && successCallback();
            dispatch(setLQATicketDetail(responseData));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        } finally {
            finallyCallback && finallyCallback();
        }
    };
}

export function commentLQATicketHandler({ ticketId, body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { currentView } = gridUI;
            const responseData = await commentLQATicket(ticketId, body, currentView?.id);
            successCallback && successCallback(responseData);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function deleteCommentHandler({ comment, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { lqaTicketDetail } = gridUI;
            if (lqaTicketDetail) {
                let { comments } = lqaTicketDetail;
                comments = comments.filter(c => c.id !== comment.id);
                lqaTicketDetail.comments = comments;
                dispatch(setLQATicketDetail({ ...lqaTicketDetail }));
            }
            const responseData = await deleteComment(comment.id);
            dispatch(
                enqueueSnackbar({
                    message: i18n.t(`lqa_comment_deleted`),
                    type: 'info'
                })
            );
            successCallback && successCallback(responseData);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            const { gridUI } = getState();
            const { lqaTicketDetail } = gridUI;
            if (lqaTicketDetail) {
                const { comments } = lqaTicketDetail;
                comments.push(comment);
                lqaTicketDetail.comments = comments;
                dispatch(setLQATicketDetail({ ...lqaTicketDetail }));
            }
            errorCallback && errorCallback();
        }
    };
}

export function updateCommentInLQATicket({ comment }) {
    return function(dispatch, getState) {
        const { gridUI } = getState();
        const { lqaTicketDetail } = gridUI;
        if (lqaTicketDetail) {
            const { comments } = lqaTicketDetail;
            const index = comments.findIndex(c => c.id === comment.id);
            if (index > -1) {
                comments[index] = comment;
                lqaTicketDetail.comments = comments;
                dispatch(setLQATicketDetail({ ...lqaTicketDetail }));
            }
        }
    };
}

export function updateCommentHandler({ oldComment, newComment, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { currentView } = gridUI;
            dispatch(updateCommentInLQATicket({ comment: newComment }));
            const responseData = await updateComment(
                newComment.id,
                {
                    description: newComment.description
                },
                currentView?.id
            );
            dispatch(updateCommentInLQATicket({ comment: responseData }));
            successCallback && successCallback(responseData);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(updateCommentInLQATicket({ comment: oldComment }));
            errorCallback && errorCallback();
        }
    };
}

export function deleteTicketHandler({ ticket, successCallback, errorCallback, finallyCallback }) {
    return async function(dispatch, getState) {
        try {
            await deleteTicket(ticket.id);
            dispatch(setOpenLQATicketDetail(false));
            dispatch(setLQATicketDetail(null));
            dispatch(_deleteTicketInLQATickets({ ticket }));
            dispatch(
                enqueueSnackbar({
                    message: i18n.t(`lqa_ticket_deleted`),
                    type: 'info'
                })
            );
            successCallback && successCallback();
            // decrease ticket in cellData
            if (checkIsTicketOpen(ticket.status)) {
                const { gridUI } = getState();
                const { data } = gridUI;
                const cellData = getCellData({ data, rowId: ticket.recordId, columnId: ticket.columnId });
                if (cellData) {
                    const newData = {
                        [ticket.recordId]: {
                            [ticket.columnId]: {
                                ...cellData,
                                _ticket: cellData._ticket > 0 ? cellData._ticket - 1 : cellData._ticket
                            }
                        }
                    };
                    dispatch(updateData({ newData }));
                }
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        } finally {
            finallyCallback && finallyCallback();
        }
    };
}

function _deleteTicketInLQATickets({ ticket }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { lqaTotal, lqaTicket } = gridUI;
        const tabStatus = checkIsTicketOpen(ticket.status) ? LQA_TICKET_STATUS.OPEN : LQA_TICKET_STATUS.RESOLVED;
        const statusIdx = lqaTicket[tabStatus].findIndex(t => t.id === ticket.id);
        const allIdx = lqaTicket[LQA_TICKET_STATUS.ALL].findIndex(t => t.id === ticket.id);
        if (statusIdx > -1) {
            lqaTicket[tabStatus].splice(statusIdx, 1);
            lqaTotal[tabStatus] -= 1;
        }
        if (allIdx > -1) {
            lqaTicket[LQA_TICKET_STATUS.ALL].splice(allIdx, 1);
            lqaTotal[LQA_TICKET_STATUS.ALL] -= 1;
        }
        dispatch(
            setLqaTicketAndTotal({
                lqaTicket: { ...lqaTicket },
                lqaTotal: { ...lqaTotal }
            })
        );
    };
}

export function setLQAModels(payload) {
    return {
        type: types.SET_LQA_MODELS,
        payload
    };
}

export function setLQAModelDefault(payload) {
    return {
        type: types.SET_LQA_MODEL_DEFAULT,
        payload
    };
}

export function setOpenLQATicketDetail(payload) {
    return {
        type: types.SET_OPEN_LQA_TICKET_DETAIL,
        payload
    };
}

export function setLQATicketDetail(payload) {
    return {
        type: types.SET_LQA_TICKET_DETAIL,
        payload
    };
}

export function setOpenConfirmDeleteTicket(payload) {
    return {
        type: types.SET_LQA_CONFIRM_DELETE_TICKET,
        payload
    };
}

export function addProjectsToLqa({ addProject, id, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(_addProjectsToLqa({ id, addProject }));
            await addProjectToLQAApi({ id, body: [addProject?.id] });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
            dispatch(_removeProjectsToLqaSuccess({ id, projectId: addProject?.id }));
        }
    };
}

function _addProjectsToLqa({ id, addProject }) {
    return {
        type: types.ADD_PROJECT_TO_LQA,
        payload: {
            id,
            addProject
        }
    };
}

export function removeProjectsToLqa({ projectId, id, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(_removeProjectsToLqa({ id, projectId }));
            await removeProjectToLQAApi({ id, body: [projectId] });
            dispatch(_removeProjectsToLqaSuccess({ id, projectId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_removeProjectsToLqaFailed({ id, projectId }));
            errorCallback && errorCallback();
        }
    };
}

function _removeProjectsToLqa({ id, projectId }) {
    return {
        type: types.REMOVE_PROJECT_TO_LQA,
        payload: {
            id,
            projectId
        }
    };
}

function _removeProjectsToLqaSuccess({ id, projectId }) {
    return {
        type: types.REMOVE_PROJECT_TO_LQA_SUCCESS,
        payload: {
            id,
            projectId
        }
    };
}

function _removeProjectsToLqaFailed({ id, projectId }) {
    return {
        type: types.REMOVE_PROJECT_TO_LQA_FAILED,
        payload: {
            id,
            projectId
        }
    };
}

export function setDefaultLqaModel({ newLqa, oldLqa, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(setLQAModelDefault(newLqa));
            await setDefaultLqaApi({ id: newLqa?.id });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(setLQAModelDefault(oldLqa));
            errorCallback && errorCallback();
        }
    };
}

export function resetDefaultLQA({ ticket = true, total = true, pageNumber = true, tab = true }) {
    return function(dispatch) {
        const lqaTicket = {};
        const lqaTotal = {};
        const lqaPageNumber = {};
        [LQA_TICKET_STATUS.OPEN, LQA_TICKET_STATUS.RESOLVED, LQA_TICKET_STATUS.ALL].map(status => {
            lqaTicket[status] = [];
            lqaTotal[status] = 0;
            lqaPageNumber[status] = 1;
            return false;
        });
        const payload = {};
        if (ticket) {
            payload.lqaTicket = lqaTicket;
        }
        if (total) {
            payload.lqaTotal = lqaTotal;
        }
        if (pageNumber) {
            payload.lqaPageNumber = lqaPageNumber;
        }
        if (tab) {
            payload.lqaTab = LQA_TICKET_STATUS.OPEN;
        }
        dispatch({
            type: types.RESET_DEFAULT_LQA,
            payload
        });
    };
}

export function setLqaTicket(payload = {}) {
    return {
        type: types.SET_LQA_TICKET,
        payload
    };
}

export function setLqaTotal(payload = {}) {
    return {
        type: types.SET_LQA_TOTAL,
        payload
    };
}

export function setLqaTicketAndTotal(payload = {}) {
    return {
        type: types.SET_LQA_TICKET_AND_TOTAL,
        payload
    };
}

export function setLqaPageNumber(payload = {}) {
    return {
        type: types.SET_LQA_PAGE_NUMBER,
        payload
    };
}

export function setLqaResetQuery(payload) {
    return {
        type: types.SET_LQA_RESET_QUERY,
        payload
    };
}

export function increaseDecreaseLqaTotal({ increaseStatus, decreaseStatus }) {
    return function(dispatch) {
        dispatch(setLqaTotalIncreaseDecrease({ increaseStatus, decreaseStatus }));
    };
}

export function setLqaTab(payload) {
    return {
        type: types.SET_LQA_TAB,
        payload
    };
}

export function setLqaTotalIncreaseDecrease(payload) {
    return {
        type: types.SET_LQA_TOTAL_INCREASE_DECREASE,
        payload
    };
}

export function updateLqaTicketStatus({ ticketId, currentStatus }) {
    return function(dispatch, getState) {
        const { gridUI } = getState();
        const { lqaTicket } = gridUI;
        const allIdx = lqaTicket[LQA_TICKET_STATUS.ALL].findIndex(t => t.id === ticketId);
        if (allIdx > -1) {
            lqaTicket[LQA_TICKET_STATUS.ALL][allIdx] = {
                ...lqaTicket[LQA_TICKET_STATUS.ALL][allIdx],
                status: checkIsTicketOpen(currentStatus) ? LQA_TICKET_STATUS.RESOLVED : LQA_TICKET_STATUS.REOPENED
            };
        }
        const status = checkIsTicketOpen(currentStatus) ? LQA_TICKET_STATUS.OPEN : LQA_TICKET_STATUS.RESOLVED;
        const idx = lqaTicket[status].findIndex(t => t.id === ticketId);
        if (idx > -1) {
            lqaTicket[status].splice(idx, 1);
        }
        dispatch(setLqaTicket({ ...lqaTicket }));
    };
}

export function handleExportLQA() {
    return async function(dispatch, getState) {
        try {
            dispatch(setLQAExporting(true));
            const { gridUI } = getState();
            const { dbId, currentView, workspaceId } = gridUI;
            let viewId = '';
            if (currentView) {
                viewId = currentView.id || currentView.compositeViewId;
            }
            const params = { dbId, projectId: workspaceId, viewId };
            const response = await exportLQAApi(params);
            downloadFileFromResponse(response);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        } finally {
            dispatch(setLQAExporting(false));
        }
    };
}

export function setLQAExporting(payload) {
    return {
        type: types.SET_LQA_EXPORTING,
        payload
    };
}

export function setLQACreateType(payload) {
    return {
        type: types.SET_LQA_CREATE_TYPE,
        payload
    };
}
