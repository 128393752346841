import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Drawer, IconButton } from '@material-ui/core';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import ArrowLeftSVG from 'assets/images/svg/ArrowLeftSVG';
import SearchSidebar from './sidebar';
import Wrapper from './Wrapper';
import { useDispatch } from 'react-redux';
import * as memberActions from 'permission/actions/member';
import * as groupActions from 'permission/actions/group';
import Progressing from './Progressing';
import * as authActions from 'auth/actions';
import * as advancedSearchActions from 'advancedSearch/actions';
import { SIDE_BAR_ADVANCED_SEARCH_WIDTH_OFF, SIDE_BAR_ADVANCED_SEARCH_WIDTH_ON } from 'const';
import * as databaseActions from 'databases/actions';
import { Switch, useRouteMatch, useParams, Route } from 'react-router-dom';
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        background: theme.colors.ghostwhite,
        position: 'relative'
    },
    drawer: {
        width: SIDE_BAR_ADVANCED_SEARCH_WIDTH_ON,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        background: `transparent`,
        borderRadius: 4
    },
    drawerOpen: {
        width: SIDE_BAR_ADVANCED_SEARCH_WIDTH_ON
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.enteringScreen
        // })
    },
    drawerClose: {
        width: SIDE_BAR_ADVANCED_SEARCH_WIDTH_OFF,
        background: theme.colors.border,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    arrow: {
        position: 'absolute',
        left: SIDE_BAR_ADVANCED_SEARCH_WIDTH_ON,
        top: 53,
        zIndex: 100
    },
    arrowOpen: {
        left: SIDE_BAR_ADVANCED_SEARCH_WIDTH_ON - 12
    },
    arrowClose: {
        left: 5,
        transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    paperOnPadding: {
        left: theme.spacing(4),
        transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    paperOffPadding: {
        padding: `0px !important`,
        transition: theme.transitions.create('padding', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    content: {
        flexGrow: 1,
        background: theme.palette.common.white,
        borderLeft: `1px solid ${theme.colors.border}`
    },
    paper: {
        position: 'inherit',
        borderRight: 'none',
        background: 'transparent'
    },
    trigger: {
        width: 24,
        height: 24,
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        border: `1px solid ${theme.colors.border}`,
        padding: 0
    }
}));

export default function MiniDrawer() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(true);
    const { workspaceId } = useParams();
    let { path } = useRouteMatch();

    const toggleDrawer = React.useCallback(() => {
        setOpen(!open);
    }, [open]);

    React.useEffect(() => {
        dispatch(memberActions.fetchMembers({}));
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(groupActions.fetchCompanyGroups({}));
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(advancedSearchActions.setRouterInfo({ workspaceId }));
    }, [workspaceId, dispatch]);

    React.useEffect(() => {
        dispatch(
            databaseActions.getDatabases({
                isForced: true,
                workspaceId,
                successCallback: () => {},
                errorCallback: () => {}
            })
        );
    }, [dispatch, workspaceId]);

    React.useEffect(() => {
        dispatch(
            authActions.fetchUserWorkspaceRole({
                workspaceId: workspaceId,
                successCallback: () => {
                    console.log('fetch project role Successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch project role');
                }
            })
        );
    }, [dispatch, workspaceId]);

    React.useEffect(() => {
        return () => {
            console.log('reset');
            dispatch(advancedSearchActions.resetGrid([]));
        };
    }, [dispatch]);

    return (
        <div className={classes.root}>
            <CssBaseline />

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })}
                classes={{
                    paper: clsx(classes.paper, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                        [classes.paperOnPadding]: open,
                        [classes.paperOffPadding]: !open
                    })
                }}
            >
                <SearchSidebar open={open} />
            </Drawer>

            <div
                className={clsx(classes.arrow, {
                    [classes.arrowOpen]: open,
                    [classes.arrowClose]: !open
                })}
            >
                <IconButton onClick={toggleDrawer} className={classes.trigger}>
                    {open ? <ArrowLeftSVG size="small" /> : <ArrowRightSVG size="small" />}
                </IconButton>
            </div>

            <main className={classes.content}>
                <Switch>
                    <Route path={`${path}/:aId?`}>
                        <AuthenticatedRoute roles={[]}>
                            <Wrapper isSideBarOn={open} />
                        </AuthenticatedRoute>
                    </Route>
                </Switch>
            </main>
            <Progressing />
        </div>
    );
}
