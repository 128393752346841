import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { getAdminCompanyInfo, adminDeleteAccount } from 'auth/actions';
import LoadingPage from 'auth/LoadingPage';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { enqueueSnackbar } from 'notifier/actions';
import LogoGridUISVG from 'assets/images/svg/LogoGridUISVG';
import SadToSeeYouGo from 'assets/images/sad-to-see-you-go.png';
import PreloadImages from 'components/preload/Image';
import { Trans, useTranslation } from 'react-i18next';
import AvatarBase from 'components/avatar/Base';
import { getAvatarUrl } from 'utils/images';
import ListCompanyInfo from './ListCompanyInfo';
import ButtonBase from 'components/button/Base';
import CircularProgress from 'components/CircularProgress';
import { setTimeoutInTime } from 'utils/usage';
import { CRM_EVENT_NAME, track } from 'tracker/crmTracker';
import { AutoSizer } from 'react-virtualized-dn';

const DeleteAccountCSConfirmationPage = () => {
    const { key } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const userInfo = useCurrentUserFullInfo();
    const [data, setData] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [error, setError] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        if (userInfo?.ldManager) {
            const start = Date.now();
            setFetching(true);
            const setFetchingFalse = error => {
                setTimeoutInTime({
                    start,
                    timeout: 2000,
                    cb: () => {
                        setFetching(false);
                        if (error) {
                            dispatch(
                                enqueueSnackbar({
                                    message: error?.message,
                                    type: 'error'
                                })
                            );
                        }
                    }
                });
            };
            dispatch(
                getAdminCompanyInfo({
                    key,
                    successCallback: data => {
                        setFetchingFalse();
                        setData(data);
                    },
                    errorCallback: error => {
                        setError(error?.originalMessage);
                        setFetchingFalse(error);
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, key]);

    const navigateToDashboard = useCallback(() => {
        history.replace('/');
    }, [history]);

    const handleDeleteAccount = useCallback(() => {
        setIsDeleting(true);
        dispatch(
            adminDeleteAccount({
                key,
                successCallback: () => {
                    setIsDeleting(false);
                    track(
                        data?.unsubscribe
                            ? CRM_EVENT_NAME.DELETE_ACCOUNT_WITH_UNSUBSCRIBE
                            : CRM_EVENT_NAME.DELETE_ACCOUNT,
                        {
                            email: data?.requestUser?.email
                        }
                    );
                    dispatch(
                        enqueueSnackbar({
                            type: 'info',
                            message: (
                                <div>
                                    <Trans
                                        i18nKey="admin_delete_account_success"
                                        components={{ b: <b /> }}
                                        values={{ email: data?.requestUser?.email }}
                                    />
                                </div>
                            )
                        })
                    );
                    navigateToDashboard();
                },
                errorCallback: () => {
                    setIsDeleting(false);
                }
            })
        );
    }, [dispatch, key, data, navigateToDashboard]);

    if (!userInfo?.ldManager) {
        return <Redirect to="/" />;
    }

    if (fetching) {
        return (
            <>
                <LoadingPage />
                <PreloadImages paths={[SadToSeeYouGo]} />
            </>
        );
    }

    return (
        <div>
            <div className="p-8 pl-[72px] flex flex-nowrap gap-8 overflow-auto bg-inherit w-screen h-screen">
                <div className="flex-1">
                    <div className="cursor-pointer" onClick={navigateToDashboard}>
                        <LogoGridUISVG color="#2A293A" />
                    </div>
                    <div className="mt-[112px]">
                        {error ? (
                            <>
                                <p className="m-0 !text-[32px] !leading-8">Something went wrong!</p>
                                <p className="!mt-2 !text-[18px] !text-[#78778B]">{error}</p>
                                <div className="mt-6 flex flex-nowrap gap-8">
                                    <div className="flex-1">
                                        <ButtonBase
                                            className="!w-full"
                                            variant="contained"
                                            onClick={navigateToDashboard}
                                        >
                                            Back To Dashboard
                                        </ButtonBase>
                                    </div>
                                    <div className="flex-1"></div>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className="m-0 !text-[32px] !leading-8">{t('permanent_delete_this_account')}</p>
                                <p className="m-0 mt-[30px] font-medium">{t('global_account')}</p>
                                <div className="mt-3 rounded border border-[#D7D7DC] px-5 py-[18px] flex flex-nowrap items-center gap-[14px]">
                                    <div>
                                        <AvatarBase
                                            size={32}
                                            src={getAvatarUrl(data?.requestUser?.id?.toString())}
                                            alt={data?.requestUser?.fullName}
                                        />
                                    </div>
                                    <div>
                                        <p className="m-0 font-medium">{data?.requestUser?.fullName}</p>
                                        <p className="mt-[5px] text-[13px] text-text-secondary">
                                            {data?.requestUser?.email}
                                        </p>
                                    </div>
                                </div>
                                <p className="m-0 mt-6 font-medium">{t('admin_delete_account_delete_company')}</p>
                                <div className="mt-3">
                                    <ListCompanyInfo companies={data?.owners} showPlan />
                                </div>
                                {data?.members?.length > 0 && (
                                    <>
                                        <p className="m-0 mt-6 font-medium">
                                            {t('admin_delete_account_delete_from_company')}
                                        </p>
                                        <div className="mt-3">
                                            <ListCompanyInfo
                                                companies={data?.members}
                                                maxHeight={!!data?.owners ? 218 : 374}
                                                showRole
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="mt-6 flex flex-nowrap gap-8">
                                    <div className="flex-1">
                                        <ButtonBase
                                            className="!w-full"
                                            variant="outlined"
                                            onClick={navigateToDashboard}
                                        >
                                            {t('admin_delete_account_cancel_request')}
                                        </ButtonBase>
                                    </div>
                                    <div className="flex-1">
                                        <ButtonBase
                                            className={classnames('!w-full', {
                                                '!bg-burntSlena': !isDeleting
                                            })}
                                            variant="contained"
                                            disabled={isDeleting}
                                            onClick={handleDeleteAccount}
                                        >
                                            {t('admin_delete_account_accept_request')}
                                            {isDeleting && <CircularProgress />}
                                        </ButtonBase>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="flex-1 relative">
                    <div
                        className="absolute top-0 left-0 w-full pointer-events-none"
                        style={{ height: `calc(100vh - 64px)` }}
                    >
                        <AutoSizer>
                            {({ width, height }) => (
                                <div
                                    className="bg-[#EBEAF3] flex items-center justify-center rounded-[40px] fixed"
                                    style={{ width, height }}
                                >
                                    <img
                                        style={{
                                            maxWidth: 560,
                                            maxHeight: '100%',
                                            height: 'auto',
                                            width: 'auto'
                                        }}
                                        src={SadToSeeYouGo}
                                        alt="SadToSeeYouGo"
                                    />
                                </div>
                            )}
                        </AutoSizer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(DeleteAccountCSConfirmationPage);
