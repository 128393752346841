import React, { useCallback } from 'react';
import SliderSlick from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { makeStyles } from '@material-ui/styles';
import ArrowLeft2SVG from 'assets/images/svg/ArrowLeft2SVG';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';

const useStyles = makeStyles(theme => ({
    root: {
        '& .slick-slide.slick-active': {
            zIndex: 999
        }
    },
    prev: {
        left: 8,
        top: '30%',
        transform: 'translate(0, 30%)',
        '& path': {
            stroke: '#78778B !important'
        },
        '&.slick-disabled': {
            cursor: 'unset',
            '& path': {
                stroke: '#AEADB9 !important'
            }
        }
    },
    next: {
        right: 11,
        top: '30%',
        transform: 'translate(0, 30%)',
        '& path': {
            fill: '#78778B !important'
        },
        '&.slick-disabled': {
            cursor: 'unset',
            '& path': {
                fill: '#AEADB9 !important'
            }
        }
    }
}));

const DefaultArrow = props => {
    const classes = useStyles();
    const { className, style, onClick, type = 'next', afterChange, currentSlide } = props;
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick && onClick();
            afterChange && afterChange(type === 'next' ? currentSlide + 1 : currentSlide - 1);
        }
    }, [onClick, afterChange, currentSlide, type]);
    return (
        <div className={`${className} ${classes[type]}`} style={style} onClick={handleClick}>
            {type === 'prev' ? <ArrowLeft2SVG size="medium" /> : <ArrowRightSVG size="medium" />}
        </div>
    );
};

const Slider = ({ className, children, afterChange, ...rest }) => {
    const classes = useStyles();
    const defaultSettings = {
        dots: true,
        infinite: false,
        fade: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <DefaultArrow afterChange={afterChange} />,
        prevArrow: <DefaultArrow type="prev" afterChange={afterChange} />
    };

    return (
        <SliderSlick className={`${classes.root} ${className}`} {...defaultSettings} {...rest}>
            {children}
        </SliderSlick>
    );
};

export default React.memo(Slider);
