import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ColumnSelects from '../common/ColumnSelects';
import ConditionsSelects from '../common/Conditions';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getConditionsByType, getOperatorName, OPERATOR } from '../conditions';
import * as gridActions from '../actions';
import { ColumnIcon } from '../ColumnTypeDisplay';
import RemoveSVG from 'assets/images/svg/RemoveSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import { sendManualTrack } from 'tracker';
import * as columnTypes from 'const/columnTypes';
import { DEBOUNCE_TIME_SEARCHING, SPECIAL_SPLIT_KEY } from 'const/gridUI';
import OptionSelection from './OptionSelection';
import RefOptionSelect from './RefOptionSelect';
import DateTimeInput from './DatetimeInput';
import BooleanInput from './BooleanInput';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { DISABLED_OPACITY } from 'const/style';
import InputFilter from './components/Input';
import { generateDefaultConditionByColumnType } from 'utils/gridUI/filter';
import { getFloatNumber } from 'utils/gridUI/cell';
import { getOptionColor } from 'utils/color';
import { useMetaData } from 'hooks/gridUI';
import { useIsChildDependencyByColumnId, useIsParentDependencyByColumnId } from 'hooks/gridUI';
import { getRealColumnType } from 'utils/gridUI/column';
import DatetimeRangeInput from './DatetimeRangeInput';

const FILTER_COLUMN_WIDTH = 200;
const FILTER_CONDITION_WIDTH = 160;

const useStyles = makeStyles(theme => ({
    close: {
        position: 'relative',
        top: 2,
        cursor: 'pointer'
    },
    dropdownClassName: {
        width: FILTER_COLUMN_WIDTH
    },
    largePopper: {
        '& .popper': {
            width: '300px !important'
        }
    },
    conditionDropdown: {
        width: FILTER_CONDITION_WIDTH
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    dpFlex: {
        display: 'flex'
    },
    permissionTooltip: {
        width: 300,
        textAlign: 'center'
    },
    OR: {
        width: 32,
        height: 24,
        background: theme.colors.solitude,
        borderRadius: 4,
        cursor: 'pointer',
        '& p': {
            color: theme.palette.primary.main
        },
        '&.disabled': {
            background: 'none',
            cursor: 'unset',
            '& p': {
                color: theme.colors.disabledText
            }
        }
    }
}));

function SingleSelectionInput({ defaultValue, column, handleSingleSelectionChange, isReadOnly, t }) {
    const [selectedOption, setSelectedOption] = React.useState(null);

    React.useEffect(() => {
        if (isEmpty(defaultValue)) {
            setSelectedOption(null);
        } else {
            setSelectedOption({
                label: defaultValue,
                value: defaultValue
            });
        }
    }, [defaultValue]);

    return (
        <OptionSelection
            disabled={isReadOnly}
            defaultValue={selectedOption}
            column={column}
            handleOptionChange={handleSingleSelectionChange}
            t={t}
        />
    );
}

function MultiSelectionInput({ defaultValue, column, handleMultiSelectionChange, isReadOnly, t }) {
    const [selectedOptions, setSelectedOptions] = React.useState([]);

    React.useEffect(() => {
        if (isEmpty(defaultValue)) {
            setSelectedOptions([]);
        } else {
            let options = defaultValue.split(SPECIAL_SPLIT_KEY);
            let selectedOpts = options.map(opt => {
                const color = getOptionColor({
                    options: column?.options,
                    data: opt,
                    customProperties: column?.customProperties
                });
                return {
                    label: opt,
                    value: opt,
                    color
                };
            });
            setSelectedOptions(selectedOpts);
        }
    }, [defaultValue, column]);

    return (
        <OptionSelection
            disabled={isReadOnly}
            defaultValue={selectedOptions}
            column={column}
            handleOptionChange={handleMultiSelectionChange}
            isMulti={true}
            t={t}
        />
    );
}

function BooleanSelect({ defaultValue, onBooleanChangeHandler, isReadOnly }) {
    return <BooleanInput defaultValue={defaultValue} onChange={onBooleanChangeHandler} disabled={isReadOnly} />;
}

function DateTimeSelect({ defaultValue, onDateTimeChangeHandler, isReadOnly, t, condition }) {
    if ([OPERATOR.between, OPERATOR.notBetween].includes(condition)) {
        return (
            <DatetimeRangeInput
                defaultValue={defaultValue}
                onChange={onDateTimeChangeHandler}
                disabled={isReadOnly}
                t={t}
            />
        );
    }
    return <DateTimeInput defaultValue={defaultValue} onChange={onDateTimeChangeHandler} disabled={isReadOnly} t={t} />;
}

function RefSelectionInput({ defaultValue, column, handleReferenceSelectionChange, isReadOnly, t }) {
    const [selectedOptions, setSelectedOptions] = React.useState([]);

    React.useEffect(() => {
        if (isEmpty(defaultValue)) {
            setSelectedOptions([]);
        } else {
            let options = defaultValue.split(SPECIAL_SPLIT_KEY);
            let selectedOpts = options.map(opt => ({
                label: opt,
                value: opt
            }));
            setSelectedOptions(selectedOpts);
        }
    }, [defaultValue]);

    return (
        <RefOptionSelect
            defaultValue={selectedOptions}
            column={column}
            handleOptionChange={handleReferenceSelectionChange}
            isMulti={true}
            disabled={isReadOnly}
            t={t}
        />
    );
}

function generateFilterInput({ type, condition, ...rest }) {
    switch (type) {
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.NUMBER:
        case columnTypes.FILES:
        case columnTypes.SINGLE_LINE:
        case columnTypes.RICH_TEXT:
        case columnTypes.MARKDOWN:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
        case columnTypes.RECORD_ID:
        case columnTypes.CREATED_BY:
        case columnTypes.ALTERED_BY:
            return <InputFilter {...rest} />;

        case columnTypes.SINGLE_SELECTION:
            return <SingleSelectionInput {...rest} />;
        case columnTypes.GROUP_TAGS:
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultiSelectionInput {...rest} />;
        case columnTypes.DATETIME:
        case columnTypes.ALTERED_TIME:
        case columnTypes.CREATED_TIME:
            return <DateTimeSelect condition={condition} {...rest} />;
        case columnTypes.BOOLEAN:
            return <BooleanSelect {...rest} />;

        case columnTypes.REFERENCE:
            return <RefSelectionInput {...rest} />;
        default:
            return <InputFilter {...rest} />;
    }
}

function FilterItem({ id, columnId, groupId, operator, values, subField, dependencies, t, handleOr }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const metaData = useMetaData();
    const [selectedColumn, setSelectedColumn] = React.useState(null);
    const [conditionOptions, setConditionOptions] = React.useState([]);
    const [selectedCondition, setSelectedCondition] = React.useState(null);
    const [filterValues, setFilterValues] = React.useState(values);
    const [filterSubField, setFilterSubField] = React.useState(subField);

    const isChildDependency = useIsChildDependencyByColumnId(columnId);
    const isParentDependency = useIsParentDependencyByColumnId(columnId);

    const isDependency = React.useMemo(() => {
        return dependencies?.find(dpDc => {
            return dpDc?.child === columnId || dpDc?.parent === columnId;
        });
    }, [dependencies, columnId]);

    const selectedRealColumnType = React.useMemo(() => {
        return getRealColumnType(selectedColumn?.column);
    }, [selectedColumn]);

    const timer = React.useRef();

    React.useEffect(() => {
        setFilterSubField(subField);
    }, [subField]);

    React.useEffect(() => {
        const column = metaData?.[columnId] || {};
        const columnType = getCorrectColumnType(column);
        const defaultSelectedColumn = {
            label: column?.name,
            value: column?.id,
            type: columnType,
            column,
            icon: () => <ColumnIcon group={column.group} type={columnType} customProperties={column.customProperties} />
        };
        const realColumnType = getRealColumnType(column);
        let validOperators = getConditionsByType(realColumnType);
        // console.log('validOperators', validOperators);
        if (!validOperators) return setConditionOptions([]);

        let selectedCondition = operator
            ? {
                  value: operator,
                  label: getOperatorName(operator)
              }
            : generateDefaultConditionByColumnType({
                  column,
                  validOperators,
                  isChildDependency,
                  isParentDependency
              });

        // console.log('selectedCondition', selectedCondition);
        // console.log('operator', operator);
        setSelectedCondition(selectedCondition);
        setConditionOptions(validOperators);
        setSelectedColumn(defaultSelectedColumn);
        setFilterValues(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChildDependency, isParentDependency]);

    const deleteViewFilterHandler = () => {
        sendManualTrack({
            type: `Delete View Filter`,
            customData: {
                filterId: id
            }
        });
        dispatch(
            gridActions.deleteViewFilter({
                filterId: id
            })
        );
    };

    const handleUpdateFilterValue = React.useCallback(
        ({ value, subField }) => {
            const isNumber = selectedColumn?.type === columnTypes.NUMBER;
            setFilterValues(value);
            setFilterSubField(subField);
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(function() {
                dispatch(
                    gridActions.updateViewFilter({
                        filterId: id,
                        newFilter: {
                            id,
                            operator,
                            columnId,
                            groupId,
                            values: isNumber ? (value ? getFloatNumber(value) : '') : value,
                            subField
                        }
                    })
                );
            }, DEBOUNCE_TIME_SEARCHING);
        },
        [columnId, dispatch, selectedColumn, id, operator, groupId]
    );

    const handleConditionChange = option => {
        setSelectedCondition(option);
        if (!filterValues) {
            updateViewFilter({
                operator: option.value,
                columnId: selectedColumn.value,
                subField: null
            });
        } else {
            const isNumber = selectedColumn?.type === columnTypes.NUMBER;

            const isKeepSubfield = [OPERATOR.equal, OPERATOR.notEqual].includes(option?.value);

            let newFilterValues = !subField
                ? isNumber
                    ? filterValues
                        ? getFloatNumber(filterValues)
                        : ''
                    : filterValues
                : isKeepSubfield
                ? filterValues
                : '';

            if (
                [columnTypes.DATETIME, columnTypes.ALTERED_TIME, columnTypes.CREATED_TIME].includes(
                    selectedRealColumnType
                )
            ) {
                const betweenTypes = [OPERATOR.between, OPERATOR.notBetween];
                const conditionValue = selectedCondition?.value;
                const nextConditionValue = option?.value;
                if (
                    (betweenTypes.includes(conditionValue) && !betweenTypes.includes(nextConditionValue)) ||
                    (!betweenTypes.includes(conditionValue) && betweenTypes.includes(nextConditionValue))
                ) {
                    newFilterValues = null;
                    setFilterValues(null);
                }
            }

            updateViewFilterAndFetchingData({
                operator: option.value,
                columnId: selectedColumn.value,
                filterValues: newFilterValues,
                subField: ['_dependencyStatus', '_tm'].includes(subField) && isKeepSubfield ? subField : null
            });
        }
    };

    const handleColumnChange = option => {
        if (option.value === columnId) return;
        const realColumnType = getRealColumnType(option?.column);
        let validOperators = getConditionsByType(realColumnType);
        const newColumnId = option?.value;
        const isChildDependency = dependencies?.find(dpdc => dpdc?.child === newColumnId);

        if (!validOperators) return setConditionOptions([]);
        let selectedCondition = generateDefaultConditionByColumnType({
            column: option,
            validOperators,
            isChildDependency
        });
        setSelectedCondition(selectedCondition);
        setConditionOptions(validOperators);
        setSelectedColumn(option);

        setFilterValues('');
        if (!filterValues) {
            updateViewFilter({
                operator: selectedCondition.value,
                columnId: option.value,
                subField: null
            });
        } else {
            updateViewFilterAndFetchingData({
                operator: selectedCondition.value,
                columnId: option.value,
                filterValues: '',
                subField: null
            });
        }
    };

    const updateViewFilterAndFetchingData = React.useCallback(
        ({ operator, columnId, filterValues, subField }) => {
            const isNoNeedValue = [OPERATOR.isEmpty, OPERATOR.isNotEmpty].includes(operator);

            dispatch(
                gridActions.updateViewFilter({
                    filterId: id,
                    isFetchServer: true,
                    newFilter: {
                        id,
                        operator,
                        columnId,
                        values: isNoNeedValue ? null : filterValues,
                        subField,
                        groupId
                    }
                })
            );

            if (isNoNeedValue || !filterValues) {
                setFilterValues(null);
            }
        },
        [dispatch, id, groupId]
    );

    const updateViewFilter = React.useCallback(
        ({ operator, columnId, subField }) => {
            const isNoNeedValue = [OPERATOR.isEmpty, OPERATOR.isNotEmpty].includes(operator);
            dispatch(
                gridActions.updateViewFilter({
                    filterId: id,
                    isFetchServer: true,
                    newFilter: {
                        id,
                        values: isNoNeedValue ? null : filterValues,
                        operator,
                        columnId,
                        subField,
                        groupId
                    }
                })
            );

            if (isNoNeedValue) {
                setFilterValues(null);
            }
        },
        [dispatch, filterValues, id, groupId]
    );

    const handleSingleSelectionChange = option => {
        let values = !option ? '' : option.value;
        setFilterValues(values);
        dispatch(
            gridActions.updateViewFilter({
                filterId: id,
                newFilter: {
                    id,
                    operator,
                    columnId,
                    values,
                    groupId
                }
            })
        );
    };

    const handleReferenceSelectionChange = options => {
        let values = options.length === 0 ? '' : options.map(opt => opt.label).join(SPECIAL_SPLIT_KEY);
        setFilterValues(values);

        dispatch(
            gridActions.updateViewFilter({
                filterId: id,
                newFilter: {
                    id,
                    operator,
                    columnId,
                    values,
                    groupId
                }
            })
        );
    };

    const handleMultiSelectionChange = options => {
        let values = options.length === 0 ? '' : options.map(opt => opt.value).join(SPECIAL_SPLIT_KEY);
        setFilterValues(values);
        dispatch(
            gridActions.updateViewFilter({
                filterId: id,
                newFilter: {
                    id,
                    operator,
                    columnId,
                    values,
                    groupId
                }
            })
        );
    };

    const onDateTimeChangeHandler = date => {
        let values = !date ? '' : date;
        setFilterValues(date);
        dispatch(
            gridActions.updateViewFilter({
                filterId: id,
                newFilter: {
                    id,
                    operator,
                    columnId,
                    values,
                    groupId
                }
            })
        );
    };

    const onBooleanChangeHandler = values => {
        setFilterValues(values);
        dispatch(
            gridActions.updateViewFilter({
                filterId: id,
                newFilter: {
                    id,
                    operator,
                    columnId,
                    values,
                    groupId
                }
            })
        );
    };

    return (
        <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_FILTER}>
            {({ isReadOnly }) => (
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                    <Tooltip
                        title={
                            isReadOnly ? (
                                <Grid container className={classes.permissionTooltip}>
                                    {t('toolbar_no_permission')}
                                </Grid>
                            ) : (
                                ``
                            )
                        }
                    >
                        <Grid item>
                            <ColumnSelects
                                isDisabled={isReadOnly}
                                dropdownClassName={`${classes.dropdownClassName}`}
                                defaultValue={selectedColumn}
                                handleOptionChange={handleColumnChange}
                                ddPlaceholder=""
                                menuPlaceholder=""
                                placement="bottom-start"
                                showTooltip={true}
                            />
                        </Grid>
                    </Tooltip>

                    <Tooltip
                        title={
                            isReadOnly ? (
                                <Grid container className={classes.permissionTooltip}>
                                    {t('toolbar_no_permission')}
                                </Grid>
                            ) : (
                                ``
                            )
                        }
                    >
                        <Grid item>
                            <ConditionsSelects
                                isDisabled={isReadOnly}
                                dropdownClassName={`${classes.conditionDropdown}`}
                                options={conditionOptions}
                                defaultValue={selectedCondition}
                                handleOptionChange={handleConditionChange}
                                ddPlaceholder=""
                                menuPlaceholder=""
                            />
                        </Grid>
                    </Tooltip>

                    {![OPERATOR.isEmpty, OPERATOR.isNotEmpty]?.includes(operator) ? (
                        <Tooltip
                            title={
                                isReadOnly ? (
                                    <Grid container className={classes.permissionTooltip}>
                                        {t('toolbar_no_permission')}
                                    </Grid>
                                ) : (
                                    ``
                                )
                            }
                        >
                            <Grid item style={{ flex: 1 }}>
                                <Grid container className={isReadOnly ? classes.inputDisabled : ''}>
                                    {generateFilterInput({
                                        type: selectedRealColumnType,
                                        condition: selectedCondition?.value,
                                        column: selectedColumn?.column,
                                        columnType: selectedRealColumnType,
                                        defaultValue: filterValues,
                                        onChange: handleUpdateFilterValue,
                                        handleSingleSelectionChange: handleSingleSelectionChange,
                                        handleMultiSelectionChange: handleMultiSelectionChange,
                                        onDateTimeChangeHandler: onDateTimeChangeHandler,
                                        onBooleanChangeHandler: onBooleanChangeHandler,
                                        handleReferenceSelectionChange: handleReferenceSelectionChange,
                                        isReadOnly,
                                        isShowExtra:
                                            isDependency &&
                                            [OPERATOR.equal, OPERATOR.notEqual].includes(selectedCondition?.value),
                                        subField: filterSubField,
                                        isChildDependency,
                                        isParentDependency,
                                        t
                                    })}
                                </Grid>
                            </Grid>
                        </Tooltip>
                    ) : (
                        <Grid item style={{ flex: 1 }} />
                    )}
                    <Grid item>
                        <Grid
                            className={`${classes.OR} ${!handleOr ? 'disabled' : ''}`}
                            container
                            justify="center"
                            alignItems="center"
                            onClick={handleOr ? () => handleOr(id, groupId || id) : null}
                        >
                            <p className="body1">{t('filter_or')}</p>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container justify="flex-start">
                            <Tooltip
                                title={
                                    isReadOnly ? (
                                        <Grid container className={classes.permissionTooltip}>
                                            {t('toolbar_no_permission')}
                                        </Grid>
                                    ) : (
                                        ``
                                    )
                                }
                            >
                                <Grid item>
                                    <RemoveSVG
                                        onClick={deleteViewFilterHandler}
                                        className={`${classes.close} ${isReadOnly ? classes.disabled : ''}`}
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </AccessControl>
    );
}

export default React.memo(FilterItem);
