import React from 'react';
import { useSelectedCellData } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import TicketTabs from './TicketTabs';
import { LQA_TICKET_STATUS } from 'const/gridUI';
import { checkIsTicketOpen } from 'hooks/gridUI/lqa';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ViewCellTicket = ({ onClose }) => {
    const { viewId, dbId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectedCellData = useSelectedCellData();

    const [tab, setTab] = React.useState(LQA_TICKET_STATUS.OPEN);
    const rootRef = React.useRef();
    const [tickets, setTickets] = React.useState({
        loading: true,
        data: []
    });

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const _fetchCellTickets = React.useCallback(() => {
        if (!selectedCellData) return;
        setTickets(prev => ({ ...prev, loading: true }));
        dispatch(
            gridActions.fetchLQATickets({
                params: {
                    dbId,
                    viewId,
                    recordId: selectedCellData?.rowId,
                    columnId: selectedCellData?.columnId,
                    pageSize: 1000
                },
                loading: false,
                storing: false,
                successCallback: data => setTickets(prev => ({ ...prev, data: data || [], loading: false })),
                errorCallback: () => setTickets(prev => ({ ...prev, loading: false }))
            })
        );
    }, [dispatch, dbId, viewId, selectedCellData]);

    React.useEffect(() => {
        _fetchCellTickets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openLqaTickets = React.useMemo(() => {
        if (!selectedCellData) return [];
        return tickets.data.filter(
            ticket =>
                ticket.columnId === selectedCellData?.columnId &&
                ticket.recordId === selectedCellData?.rowId &&
                checkIsTicketOpen(ticket.status)
        );
    }, [selectedCellData, tickets]);

    const resolvedLqaTickets = React.useMemo(() => {
        if (!selectedCellData) return [];
        return tickets.data.filter(
            ticket =>
                ticket.columnId === selectedCellData?.columnId &&
                ticket.recordId === selectedCellData?.rowId &&
                ticket.status === LQA_TICKET_STATUS.RESOLVED
        );
    }, [selectedCellData, tickets]);

    const queryTickets = React.useMemo(() => {
        let qTickets = checkIsTicketOpen(tab) ? openLqaTickets : resolvedLqaTickets;
        let queryTickets = qTickets;
        queryTickets.sort((a, b) => new Date(b.audit.createdTime).getTime() - new Date(a.audit.createdTime).getTime());
        return queryTickets;
    }, [tab, openLqaTickets, resolvedLqaTickets]);

    const tabs = React.useMemo(() => {
        return [
            { label: `${t('global_open')} (${openLqaTickets.length})`, value: LQA_TICKET_STATUS.OPEN },
            { label: `${t('global_resolved')} (${resolvedLqaTickets.length})`, value: LQA_TICKET_STATUS.RESOLVED }
        ];
    }, [openLqaTickets, resolvedLqaTickets, t]);

    const handleChangeTab = React.useCallback((e, value) => {
        setTab(value);
    }, []);

    const onChangeStatus = React.useCallback(
        (ticket, newStatus) => {
            const idx = tickets.data.findIndex(t => t.id === ticket.id);
            if (idx > -1) {
                tickets.data[idx].status = newStatus;
                setTickets({ ...tickets });
            }
        },
        [tickets]
    );

    const onChangeAssignee = React.useCallback(
        (ticket, assignee) => {
            const idx = tickets.data.findIndex(t => t.id === ticket.id);
            if (idx > -1) {
                tickets.data[idx].assignee = assignee;
                setTickets({ ...tickets });
            }
        },
        [tickets]
    );

    if (!selectedCellData) return null;

    const handleClickAway = e => {
        onClose && onClose();
    };

    return (
        <TicketTabs
            tabs={tabs}
            tab={tab}
            handleChangeTab={handleChangeTab}
            queryTickets={queryTickets}
            isLoading={tickets.loading}
            isRightSideBar={false}
            onChangeStatus={onChangeStatus}
            onChangeAssignee={onChangeAssignee}
            onClose={onClose}
        />
    );
};

export default React.memo(ViewCellTicket);
