import React from 'react';

function StrikethroughSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M6.03135405,0 C3.53107938,0 1.49537275,1.82726373 1.49537275,4.07612988 C1.49537275,5.20204228 2.00810014,6.25620872 2.89695515,7.02083016 L0.610761785,6.93227036 C0.273599629,6.93227036 0,7.20586999 0,7.54301803 C0,7.88016606 0.273599629,8.15376569 0.610747664,8.15376569 L6.03136037,8.15308119 C7.86016082,8.15308119 9.34584004,9.43566514 9.34584004,11.0077157 C9.34584004,12.5804631 7.86030635,13.8631717 6.03135405,13.8631717 C4.20231235,13.8631717 2.71686807,12.5805042 2.71686807,11.0077157 C2.71686807,10.6705677 2.44326844,10.3969681 2.10612041,10.3969681 C1.76897237,10.3969681 1.49537275,10.6705677 1.49537275,11.0077157 C1.49537275,13.2572744 3.53093632,15.0846671 6.03135405,15.0846671 C8.53153885,15.0846671 10.5673354,13.2573612 10.5673354,11.0085372 C10.5673354,9.88340107 10.0549702,8.82978608 9.16670472,8.06530708 L11.4518114,8.15376569 C11.7889716,8.15376569 12.0625712,7.88016606 12.0625712,7.54301803 C12.0625712,7.20586999 11.7889716,6.93227036 11.4518235,6.93227036 L6.03135405,6.93227036 C4.20233659,6.93227036 2.71686807,5.64962367 2.71686807,4.07695131 C2.71686807,2.50483264 4.20248864,1.22217982 6.03135405,1.22217982 C7.86013006,1.22217982 9.34584004,2.5048738 9.34584004,4.07695131 C9.34584004,4.41396547 9.61930542,4.68687755 9.9565877,4.68687755 C10.2937357,4.68687755 10.5673354,4.41327794 10.5673354,4.07612988 C10.5673354,1.82730589 8.53153885,0 6.03135405,0 Z"
                    transform="translate(6 4)"
                />
            </g>
        </svg>
    );
}

export default React.memo(StrikethroughSVG);
