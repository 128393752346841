import React from 'react';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Divider from 'components/divider/Base';
import SwitchSVG from 'assets/images/svg/SwitchSVG';
import OrganizationSettingSVG from 'assets/images/svg/OrganizationSetttingSVG';
import AddonSVG from 'assets/images/svg/AddonSVG';
import LocalizationSVG from 'components/svg-icon/LocalizationSVG';
import * as roleConst from 'auth/roleConst';
import { useHistory } from 'react-router-dom';
import { useRole } from 'hooks/auth/role';
import ListItem from 'components/list/Item';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { useDispatch } from 'react-redux';
import { setRightSidebar } from 'app/actions';
import { useTranslation } from 'react-i18next';
import { sendManualTrack } from 'tracker';
import { isExternal } from 'config';
import NestedMenuItem from 'components/menus/HoverMenu';
import { useCompanyList } from 'hooks/permission';
import * as actions from 'permission/actions/company';
import Avatar from 'components/avatar/Company';
import { getCompanyLogoUrl } from 'utils/images';
import { changeAccountCompany } from 'auth/actions';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import { useCompanyId } from 'hooks/auth';
import { _invalidateWorkspaces } from 'workspaces/actions';
import Search from 'components/search';

function DatabasePopup({ handleClickAway }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const rootRef = React.useRef();
    const theme = useTheme();
    const companies = useCompanyList();
    const companyId = useCompanyId();
    const [filterValue, setFilterValue] = React.useState('');

    React.useEffect(() => {
        dispatch(actions.fetchCompanies({}));
    }, [dispatch]);

    const roles = useRole();

    useClickAwaitListener(rootRef, e => {
        clickAwayHandler(e);
    });

    const clickAwayHandler = () => {};

    const handleLinkToSetting = () => {
        sendManualTrack({ type: 'Open Company Settings' });
        dispatch(setRightSidebar({ type: null }));
        history.push('/company-settings');
        handleClickAway();
    };

    const mapOptionsCompanies = React.useMemo(() => {
        const cloneCompanies = companies?.map(company => ({
            ...company,
            label: company.name,
            value: company.id,
            icon: () => <Avatar src={getCompanyLogoUrl(company?.logoUrl)} size={24} alt="avatar" />
        }));
        return cloneCompanies;
    }, [companies, companyId]);

    const companyFilter = React.useMemo(() => {
        return mapOptionsCompanies?.filter(company =>
            company?.label?.toLowerCase().includes(filterValue.trim().toLowerCase())
        );
    }, [filterValue, mapOptionsCompanies]);

    const gotoCreateCompany = () => {
        history.push('/create-company');
    };

    const handleCompanySelect = React.useCallback(
        async company => {
            dispatch(
                changeAccountCompany({
                    companyId: company.id,
                    successCallback: () => {
                        dispatch(_invalidateWorkspaces());
                        history.push('/');
                    },
                    errorCallback: error => {}
                })
            );
        },
        [dispatch, history]
    );

    const isHavePermission = React.useMemo(() => {
        return (
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_COMPANY] === roleConst.FULL ||
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_GROUP] === roleConst.FULL ||
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_ROLE] === roleConst.FULL ||
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_MEMBER] === roleConst.FULL ||
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_BILLING] === roleConst.FULL ||
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_API_KEY] === roleConst.FULL ||
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_STATISTICS] === roleConst.FULL
        );
    }, [roles]);

    const isHavingManageAddonPermission = React.useMemo(() => {
        return roles?.[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON] === roleConst.FULL;
    }, [roles]);

    const isHavingLocalizationPermission = React.useMemo(() => {
        return (
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_TM] === roleConst.FULL ||
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_LOCALE] === roleConst.FULL ||
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_LQA] === roleConst.FULL ||
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_GLOSSARY]
        );
    }, [roles]);

    const handleLinkToLocalizationSetting = () => {
        sendManualTrack({ type: 'Open Localize Settings' });

        dispatch(setRightSidebar({ type: null }));
        history.push('/localization-settings');
        handleClickAway();
    };

    const handleLinkToIntegrationSetting = () => {
        sendManualTrack({ type: 'Open Intergration Settings' });
        dispatch(setRightSidebar({ type: null }));
        history.push('/integration-settings');
        handleClickAway();
    };

    const stopPropagation = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <div ref={rootRef} id={`grid_popup`} className="flex flex-col w-52">
            {isHavePermission && (
                <div onClick={handleLinkToSetting} tr-dt="Open Company Settings">
                    <ListItem name={t('company_settings_dropdown')} icon={<OrganizationSettingSVG />} />
                </div>
            )}
            {isHavingLocalizationPermission && (
                <div onClick={handleLinkToLocalizationSetting} tr-dt="Open Localize Settings">
                    <ListItem name={t('company_settings_localization')} icon={<LocalizationSVG />} />
                </div>
            )}
            {isHavingManageAddonPermission && !isExternal && (
                <div onClick={handleLinkToIntegrationSetting} tr-dt="Open Intergration Settings">
                    <ListItem name={t('integration_settings')} icon={<AddonSVG color={'#78778B'} />} />
                </div>
            )}
            {isHavePermission && (
                <div className={`py-2`}>
                    <Divider />
                </div>
            )}
            <div tr-dt="Switch Company">
                <NestedMenuItem
                    label={t('company_settings_switch')}
                    parentMenuOpen={true}
                    icon={<SwitchSVG />}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    ContainerProps={{
                        onClick: stopPropagation,
                        placement: 40
                    }}
                >
                    <div className="w-52 max-h-[412px] overflow-x-hidden overflow-y-auto ">
                        <div className="px-2 mb-2">
                            <Search
                                width={'100%'}
                                onChange={e => setFilterValue(e.target.value)}
                                autoFocus
                                background={theme.colors.paleGrey}
                                placeholder={t('Find a company')}
                            />
                        </div>
                        <div className="border-t border-b py-2">
                            {companyFilter.map(el => {
                                return (
                                    <ListItem
                                        onClick={() => handleCompanySelect(el)}
                                        name={el.label}
                                        isSelected={el.id === companyId}
                                        icon={el.icon}
                                        key={el.value}
                                        style={{ pointerEvents: el.id === companyId && 'none' }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div
                        className={`flex items-center gap-2 justify-center h-[36px] mt-2 cursor-pointer hover:bg-[#f7f8fa]`}
                        onClick={gotoCreateCompany}
                    >
                        <ColorAddSVG color={theme.colors.lightLavender} />
                        <Typography variant="body1">{t(`auth_create_a_new_company`)}</Typography>
                    </div>
                </NestedMenuItem>
            </div>
        </div>
    );
}

export default React.memo(DatabasePopup);
