import React, { useCallback, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SettingSVG from 'assets/images/svg/SettingSVG';
import Tooltip from 'components/tooltip/Base';
import LDBasePortalGroup from 'components/selects/LDBasePortalGroup';
import { LIST_TIME_ZONES } from 'const/time';
import { useTranslation } from 'react-i18next';
import PopperMenu from 'components/menus/Popper';
import { checkContainId } from 'utils/clickAway';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: 14
    }
}));

const ConfigTimezone = ({ value, onChange, anchorRef, ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState();

    const handleClickSetting = useCallback(
        e => {
            e.stopPropagation();
            e.preventDefault();
            setAnchorEl(anchorRef.current || e.currentTarget);
        },
        [anchorRef]
    );

    const handleClickAway = useCallback(e => {
        if (checkContainId(e, 'ldbase-portal-config-timezone')) {
            return;
        }
        setAnchorEl(null);
    }, []);

    return (
        <>
            <Tooltip title="Config Time zone">
                <Grid item {...rest} onClick={handleClickSetting}>
                    <SettingSVG />
                </Grid>
            </Tooltip>
            {anchorEl && (
                <PopperMenu
                    paperClassName={classes.paper}
                    style={{ width: anchorEl.offsetWidth }}
                    anchorEl={anchorEl}
                    handleClickAway={handleClickAway}
                    id={`popper-config-timezone`}
                >
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <Typography variant="body1">{t('select_time_zone')}</Typography>
                        </Grid>
                        <Grid item>
                            <LDBasePortalGroup
                                ddPlaceholder={t('column_format_time_zone_select')}
                                menuPlaceholder={t('column_format_time_zone_search')}
                                options={LIST_TIME_ZONES}
                                onChange={onChange}
                                defaultValue={value}
                                isMulti={false}
                                isUsingContainer={false}
                                popperId="ldbase-portal-config-timezone"
                            />
                        </Grid>
                    </Grid>
                </PopperMenu>
            )}
        </>
    );
};

export default React.memo(ConfigTimezone);
