import * as types from './types';
import { combinedData } from 'utils/gridUI/data';
import { combinedRecordMetaData } from 'utils/gridUI/recordMetadata';

export function revertHandler({ type, body, state }) {
    switch (type) {
        case types.OPTIMISTIC_UPDATE_CELL:
            return optimisticUpdateCellHandler({ state, body });
        case types.OPTIMISTIC_RESIZE_RECORD:
            return optimisticResizeRowHandler({ state, body });
        case types.OPTIMISTIC_DELETE_RECORD:
            return optimisticDeleteRecordsHandler({ state, body });
        case types.OPTIMISTIC_DELETE_FILE_OF_CELL:
            return optimisticDeleteFileOfCell({ state, body });
        case types.OPTIMISTIC_REORDER_COLUMNS:
            return optimisticReorderColumns({ state, body });
        case types.OPTIMISTIC_RESIZE_COLUMN:
            return optimisticResizeColumnHandler({ state, body });
        default:
            return state;
    }
}

function optimisticResizeColumnHandler({ state, body }) {
    const { columnId, oldColumnWidth } = body;
    const { advancedSearch } = state;

    const newColumns = advancedSearch?.columns?.map(column => {
        if (column?.hashColumnId === columnId) {
            return {
                ...column,
                width: oldColumnWidth
            };
        }
        return column;
    });

    return {
        ...state,
        advancedSearch: {
            ...advancedSearch,
            columns: newColumns
        }
    };
}

function optimisticReorderColumns({ state, body }) {
    const { columnIds } = body;

    return {
        ...state,
        columnIds
    };
}

function optimisticResizeRowHandler({ state, body }) {
    const { recordMetaData } = state;
    const { undoRecordMetaData } = body;

    return {
        ...state,
        recordMetaData: combinedRecordMetaData({ recordMetaData, newRecordMetaData: undoRecordMetaData })
    };
}

function optimisticDeleteFileOfCell({ state, body }) {
    let { rowId, columnId, cellData } = body;
    const { data } = state;
    const newData = combinedData({
        data,
        newData: {
            [rowId]: {
                [columnId]: cellData
            }
        }
    });
    return {
        ...state,
        data: newData
    };
}

function optimisticDeleteRecordsHandler({ state, body }) {
    const { indexes, recordIds } = body;
    const { rows, totalRecords, gridTotalRecords } = state;
    let newRows = [...rows];
    for (let i = 0; i < recordIds?.length; i++) {
        let index = indexes[i];
        let recordId = recordIds[i];
        newRows.splice(index, 0, recordId);
    }

    return {
        ...state,
        rows: newRows,
        totalRecords: totalRecords + recordIds?.length,
        gridTotalRecords: gridTotalRecords + recordIds?.length
    };
}

function optimisticUpdateCellHandler({ state, body }) {
    let { data } = state;
    const { columnId, rowId, cellData } = body;

    const newData = combinedData({
        data,
        newData: {
            [rowId]: {
                [columnId]: cellData
            }
        }
    });

    return {
        ...state,
        data: newData
    };
}
