import React from 'react';
import { Grid } from '@material-ui/core';
import Option from '../components/Option';

function SingleSelection({ previousData, isHideMarkIcon = false, data, options = [], customProperties = {} }) {
    return (
        <Grid container spacing={1}>
            {previousData && (
                <Grid item>
                    <Option
                        data={previousData}
                        state={'delete'}
                        options={options}
                        customProperties={customProperties}
                        isHideMarkIcon={isHideMarkIcon}
                    />
                </Grid>
            )}
            {data && (
                <Grid item>
                    <Option
                        data={data}
                        state={'update'}
                        isHideMarkIcon={isHideMarkIcon}
                        options={options}
                        customProperties={customProperties}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(SingleSelection);
