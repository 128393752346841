import request from './request';
import { API_URL, APP_API_PREFIX_TB } from '../config';
import { getDownloadedFile } from 'utils/fileUtils';

const VERSION = 'v1';

const tokenSetApis = {
    tokensets: `${APP_API_PREFIX_TB}/${VERSION}/tokensets`
};

export async function getTagModelsApi() {
    const response = await request.get(tokenSetApis.tokensets);
    return response.data;
}

export async function getTagModelApi({ tokenSetId, params }) {
    const response = await request.get(`${tokenSetApis.tokensets}/${tokenSetId}`, { params });
    return response.data;
}

export async function createTagModelApi(body) {
    const response = await request.post(tokenSetApis.tokensets, body);
    return response.data;
}

export async function updateTagModelApi({ tokenSetId, body }) {
    const response = await request.put(`${tokenSetApis.tokensets}/${tokenSetId}`, body);
    return response.data;
}

export async function deleteTagModelApi(tokenSetId, body) {
    const response = await request.delete(`${tokenSetApis.tokensets}/${tokenSetId}`, { data: body });
    return response.data;
}

export async function exportTokenSetsApi(tokenSetId, format) {
    let url = `${API_URL}${tokenSetApis.tokensets}/${tokenSetId}/export?format=${format}`;
    getDownloadedFile(url);
    return false;
}

export async function importTokenSetsApi(tokenSetId, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.post(`${tokenSetApis.tokensets}/${tokenSetId}/import`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function deleteTokensApi({ tokenSetId, body }) {
    const response = await request.delete(`${tokenSetApis.tokensets}/${tokenSetId}/tokens`, { data: body });
    return response.data;
}

export async function getTokenSetsApi({ tokenSetId, params }) {
    const response = await request.get(`${tokenSetApis.tokensets}/${tokenSetId}/tokens`, { params });
    return response;
}

export async function createTokenSetsApi({ tokenSetId, body }) {
    const response = await request.post(`${tokenSetApis.tokensets}/${tokenSetId}/tokens`, body);
    return response.data;
}

export async function updateTokenSetsApi({ tokenSetId, body, tokenId }) {
    const response = await request.put(`${tokenSetApis.tokensets}/${tokenSetId}/tokens/${tokenId}`, body);
    return response.data;
}

export async function deleteTokenSetsApi({ tokenSetId, tokenId }) {
    const response = await request.delete(`${tokenSetApis.tokensets}/${tokenSetId}/tokens/${tokenId}`);
    return response.data;
}
