import React from 'react';

function ShareSVG({ color = 'white', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7 2.4C10.982 2.4 10.4 2.98203 10.4 3.7C10.4 3.92527 10.4573 4.13715 10.5581 4.32187C10.565 4.3323 10.5716 4.34296 10.5779 4.35386C10.5842 4.36466 10.5902 4.37557 10.5959 4.38657C10.8254 4.75488 11.2341 5 11.7 5C12.418 5 13 4.41797 13 3.7C13 2.98203 12.418 2.4 11.7 2.4ZM9.79999 5.61832C10.2878 6.10155 10.9591 6.4 11.7 6.4C13.1912 6.4 14.4 5.19117 14.4 3.7C14.4 2.20883 13.1912 1 11.7 1C10.2088 1 9 2.20883 9 3.7C9 3.94546 9.03275 4.18326 9.09413 4.40929L5.60004 6.44836C5.11217 5.96511 4.44094 5.66666 3.7 5.66666C2.20883 5.66666 1 6.87549 1 8.36666C1 9.85783 2.20883 11.0667 3.7 11.0667C4.44108 11.0667 5.11242 10.7681 5.60031 10.2847L9.09493 12.3211C9.03304 12.548 9 12.7868 9 13.0333C9 14.5245 10.2088 15.7333 11.7 15.7333C13.1912 15.7333 14.4 14.5245 14.4 13.0333C14.4 11.5421 13.1912 10.3333 11.7 10.3333C10.9602 10.3333 10.2898 10.6309 9.80216 11.1128L6.30598 9.07554C6.36728 8.84964 6.4 8.61197 6.4 8.36666C6.4 8.12121 6.36725 7.88342 6.30588 7.6574L9.79999 5.61832ZM4.80415 7.68013C4.80981 7.69112 4.81579 7.70203 4.8221 7.71283C4.82845 7.72371 4.83505 7.73436 4.84189 7.74477C4.9427 7.9295 5 8.14139 5 8.36666C5 8.59199 4.94267 8.80394 4.8418 8.9887C4.83491 8.99919 4.82826 9.00993 4.82187 9.0209C4.81568 9.03153 4.80979 9.04226 4.80422 9.05307C4.57472 9.42147 4.16599 9.66666 3.7 9.66666C2.98203 9.66666 2.4 9.08463 2.4 8.36666C2.4 7.64869 2.98203 7.06666 3.7 7.06666C4.16595 7.06666 4.57464 7.31179 4.80415 7.68013ZM10.4 13.0333C10.4 12.8231 10.4499 12.6245 10.5385 12.4487C10.5551 12.4267 10.5706 12.4035 10.5848 12.3791C10.5984 12.3557 10.6105 12.3319 10.6212 12.3077C10.8547 11.9612 11.2507 11.7333 11.7 11.7333C12.418 11.7333 13 12.3153 13 13.0333C13 13.7513 12.418 14.3333 11.7 14.3333C10.982 14.3333 10.4 13.7513 10.4 13.0333Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ShareSVG);
